import React, { Fragment } from 'react';
import Slider from 'react-slick';

import { SENICHISAKU } from './constants';

import './BodySenichisaku.scss';

export const BodySenichisaku = ({ baseImgUrl }) => {
  const bsSlider = {
    autoplay: true,
    autoplaySpeed: 0,
    arrows: false,
    dots: false,
    centerMode: true,
    centerPadding: '25%',
    cssEase: 'linear',
    draggable: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 15000,
    touchMove: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerPadding: '15%',
          speed: 10000,
        },
      },
    ],
  };

  const renderProducts = (products, order) => {
    return products.map(({ alt }, index) => (
      <div key={alt} className="body-senichisaku__bs__slider-item">
        <img alt={alt} src={`${baseImgUrl}/bs-slide-0${order}-0${index + 1}.webp`} loading="lazy" />
      </div>
    ));
  };

  return (
    <Fragment>
      <section className="body-senichisaku__bs">
        <div className="base-senichisaku__container">
          <h2 className="body-senichisaku__bs__title" data-aos="fade-up">
            Brand Story<small>The Making of Senichisaku</small>
          </h2>
        </div>

        <div className="base-senichisaku__container base-senichisaku__container--sm">
          <div
            className="body-senichisaku__bs__img body-senichisaku__bs__img--glasses"
            data-aos="fade-up"
          >
            <img alt="the making of senichisaku" src={`${baseImgUrl}/bs-01.webp`} loading="lazy" />
          </div>

          <h5 className="body-senichisaku__bs__subtitle" data-aos="fade-up">
            Handcrafted in Sabae
            <br />
            The Origin of the Finest Japanese Craftsmanship
          </h5>

          <p className="body-senichisaku__bs__text" data-aos="fade-up">
            Sabae, a city located in the Fukui Prefecture, Japan, is widely regarded as the City of
            Eyewear. This is where Senichisaku frames are crafted, with every pair carefully put
            together by hand. Senichisaku frames are made of a scarce celluloid material that has
            excellent durability and a unique, rich colour that adds lustre to the frames. Like a
            prized gem that only gets better with age, the colour of the frame becomes deeper the
            more you use it and that is when you can truly revel in the beauty of a Senichisaku
            frame.
          </p>
        </div>

        <Slider {...bsSlider} className="body-senichisaku__bs__slider">
          {renderProducts(SENICHISAKU.Products.first, 1)}
        </Slider>

        <div className="base-senichisaku__container">
          <div className="base-senichisaku__btn-group" data-aos="fade-up">
            <a className="base-senichisaku__btn" href="/sg/en/eyeglasses/brands/Senichisaku.html">
              <span>See all Senichisaku frames</span>
            </a>

            <a className="base-senichisaku__btn" href="/stores/sg">
              <span>Search for nearest OWNDAYS stores</span>
            </a>
          </div>

          <h5 className="body-senichisaku__bs__subtitle" data-aos="fade-up">
            Sabae – the City of Eyewear
          </h5>

          <div
            className="body-senichisaku__bs__subtitle__img body-senichisaku__bs__img--town"
            data-aos="fade-up"
          >
            <picture>
              <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/bs-02_pc.webp`} />
              <img alt="sabae" src={`${baseImgUrl}/bs-02_sp.webp`} loading="lazy" />
            </picture>
          </div>
        </div>

        <div className="base-senichisaku__container base-senichisaku__container--sm">
          <p className="body-senichisaku__bs__text" data-aos="fade-up">
            Situated in the Heavy Snow Area of Japan, the Hokuriku region has long been plagued by
            heavy snowfalls. During the winter, rice fields and farms are covered by snow, making it
            difficult to grow crops or even head outdoors. Since agriculture is impracticable,
            people have instead turned to traditional craft-making works such as decorating
            lacquerware. During the Meiji era, manufacturing flourished with the adoption of
            techniques derived from such craft-making.
          </p>
        </div>

        <Slider {...bsSlider} className="body-senichisaku__bs__slider">
          {renderProducts(SENICHISAKU.Products.seconds, 2)}
        </Slider>

        <div className="base-senichisaku__container base-senichisaku__container--sm">
          <p className="body-senichisaku__bs__text" data-aos="fade-up">
            Sabae, which is located within the Hokuriku region, is believed to have started eyewear
            manufacturing in 1905 and becoming the largest manufacturer of eyewear in Japan in 1935.
            The materials used for making spectacle frames at that time were red copper and brass.
            Celluloid and nickel alloys then became the most common frame materials in the Showa
            era. During the period of economic boom in Japan, sales of eyewear increased, leading to
            rapid growth in the eyewear industry in Sabae. <br />
            <br />
            Today, Sabae is widely regarded as the City of Eyewear in Japan where there is a
            proliferation of eyewear workshops and artisans. One can even find symbols in the
            spectacle shape all over the streets.
          </p>
        </div>

        <div className="base-senichisaku__container base-senichisaku__container--lg">
          <div
            className="body-senichisaku__bs__img body-senichisaku__bs__img--frame"
            data-aos="fade-up"
          >
            <img alt="千一作 senichisaku glasses" src={`${baseImgUrl}/bs-04.webp`} loading="lazy" />
          </div>
        </div>

        <div className="base-senichisaku__container base-senichisaku__container--sm">
          <h5 className="body-senichisaku__bs__subtitle" data-aos="fade-up">
            Celluloid that has a brilliant lustre
            <br />
            In a beautiful colour that intensifies with use
          </h5>

          <p className="body-senichisaku__bs__text" data-aos="fade-up">
            Celluloid is one of the most long-standing materials used for making spectacle frames. A
            large celluloid sheet is usually cut and then transformed into spectacle frames.
            Celluloid is elastic, impact resistant, durable and is able to retain its shape. <br />
            <br />
            However, the manufacturing body-senichisaku__process to turn celluloid into spectacle
            frames is tedious and requiring many steps. This is why most of the plastic frames
            available in the market are made of another material called acetate. Unlike acetate,
            celluloid has a unique, rich colour that gives it a brilliant lustre. It is beautiful,
            luxury and even considered to be a precious gem at times.
          </p>

          <div
            className="body-senichisaku__bs__img body-senichisaku__bs__img--room"
            data-aos="fade-up"
          >
            <img alt="celluloid/glasses" src={`${baseImgUrl}/bs-05.webp`} loading="lazy" />
          </div>
        </div>
      </section>

      <section className="body-senichisaku__craftsmen">
        <div className="body-senichisaku__craftsmen__top">
          <picture>
            <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/pc_12pics.webp`} />
            <img
              alt="craftsman in sabae"
              className="body-senichisaku__craftsmen__img"
              data-aos="fade-up"
              src={`${baseImgUrl}/sp_12pics.webp`}
              loading="lazy"
            />
          </picture>

          <div className="base-senichisaku__container base-senichisaku__container--sm">
            <h5 className="body-senichisaku__craftsmen__title" data-aos="fade-up">
              Bringing spectacle frames to life
              <br />
              With traditional techniques from the Sabae artisans
            </h5>

            <p className="body-senichisaku__craftsmen__text" data-aos="fade-up">
              In Sabae, spectacle frames are made through a division of labour. From die-cutting a
              frame from the celluloid sheet, polishing and attaching metal parts to finally
              completing a spectacle frame, each step along the way is handled by an artisan who is
              specialised in that particular body-senichisaku__process.
            </p>
          </div>
        </div>

        <div className="base-senichisaku__container base-senichisaku__container--lg">
          <div className="body-senichisaku__craftsmen__block body-senichisaku__craftsmen__block--odd">
            <div className="body-senichisaku__craftsmen__block-img" data-aos="fade-up">
              <img alt="polishing" src={`${baseImgUrl}/bs-06.webp`} loading="lazy" />
            </div>

            <div className="body-senichisaku__craftsmen__block-details">
              <h5 className="body-senichisaku__craftsmen__block-title" data-aos="fade-up">
                Polishing
              </h5>
              <p className="body-senichisaku__craftsmen__block-text" data-aos="fade-up">
                Polishing is an important step in creating a beautiful finishing on a frame. The
                entire polishing body-senichisaku__process is highly dependent on the experience of
                the artisan, requiring skills and precise intuition on the angle, pressure and
                length of time a frame is applied to the polishing wheel that will give it an
                exquisite shine. <br />
                <br />
                Each Senichisaku frame is delicately hand polished by artisans from a family-run
                workshop specialised in making celluloid frames. Founded in 1947, the workshop is
                currently run by its 2nd generation owner whose two sons and their families are also
                working in the family business. Every one of them is highly skilled in every step of
                the polishing body-senichisaku__process, producing the finest eyewear that are
                filled with their heart and soul.
              </p>
            </div>
          </div>

          <div className="body-senichisaku__craftsmen__block body-senichisaku__craftsmen__block--even">
            <div className="body-senichisaku__craftsmen__block-img" data-aos="fade-up">
              <img alt="metalwork" src={`${baseImgUrl}/bs-07.webp`} loading="lazy" />
            </div>
            <div className="body-senichisaku__craftsmen__block-details">
              <h5 className="body-senichisaku__craftsmen__block-title" data-aos="fade-up">
                Metalwork
              </h5>
              <p className="body-senichisaku__craftsmen__block-text" data-aos="fade-up">
                In a workshop founded in 1972 when its artisan-owner was 21 years old, a 3-person
                team made up of the owner, his wife and an employee work on metalwork such as
                attaching hinges that connect the front of the frame to the temples. In the past,
                they used to work mainly on metal spectacle frames decorated with jewels but had
                subsequently made a bold decision to switch their focus to celluloid frames when
                demand for bejewelled metal frames decreased over time. Their expertise in handling
                delicate metalwork such as attaching decorative jewels has made them much admired by
                other artisans in the field. The workshop celebrates its 50th anniversary in 2022.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="body-senichisaku__process">
        <picture>
          <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/process-cover_pc.webp`} />
          <img
            alt="process"
            className="body-senichisaku__process__cover"
            data-aos="fade-up"
            src={`${baseImgUrl}/process-cover_sp.webp`}
            loading="lazy"
          />
        </picture>

        <div className="base-senichisaku__container">
          <h2 className="body-senichisaku__process__title" data-aos="fade-up">
            Manufacturing Process
          </h2>

          <div className="body-senichisaku__process__block body-senichisaku__process__block--odd body-senichisaku__process__block--sm">
            <div className="body-senichisaku__process__block-media" data-aos="fade-up">
              <img alt="material selection" src={`${baseImgUrl}/process-01.webp`} loading="lazy" />
            </div>

            <div className="body-senichisaku__process__block-details" data-aos="fade-up">
              <h5 className="body-senichisaku__process__block-title">1. Material Selection </h5>
              <p className="body-senichisaku__process__block-text">
                A celluloid with the most suitable colour and thickness is selected based on the
                frame design and the latest trends in eyewear fashion.
              </p>

              <h5 className="body-senichisaku__process__block-title">2. Bending Celluloid Sheet</h5>
              <p className="body-senichisaku__process__block-text">
                The celluloid sheet is bent according to the size of the frame to create a gentle
                curvature for the frame front.
              </p>
            </div>
          </div>

          <div className="body-senichisaku__process__block body-senichisaku__process__block--even body-senichisaku__process__block--lg">
            <div className="body-senichisaku__process__block-media" data-aos="fade-up">
              <video autoPlay loop muted playsInline width="100%">
                <source src={`${baseImgUrl}/C0008.mp4`} type="video/mp4" />
              </video>
            </div>

            <div className="body-senichisaku__process__block-details" data-aos="fade-up">
              <h5 className="body-senichisaku__process__block-title">3. Die-Cutting</h5>
              <p className="body-senichisaku__process__block-text">
                The celluloid sheet is die-cut to the required shape and size of the frame.
              </p>
            </div>
          </div>

          <div className="body-senichisaku__process__block body-senichisaku__process__block--odd body-senichisaku__process__block--md">
            <div className="body-senichisaku__process__block-media" data-aos="fade-up">
              <img alt="drum polishing" src={`${baseImgUrl}/process-02.webp`} loading="lazy" />
            </div>

            <div className="body-senichisaku__process__block-details" data-aos="fade-up">
              <h5 className="body-senichisaku__process__block-title">4. Drum Polishing</h5>
              <p className="body-senichisaku__process__block-text">
                The frame is placed in a large polishing drum with abrasives and polishing chips and
                spun continuously to smooth out the edges.
              </p>

              <h5 className="body-senichisaku__process__block-title">
                5. Buffing and Attaching Hinges
              </h5>

              <p className="body-senichisaku__process__block-text">
                A clay-like abrasive is used to polish the frame until it shines. Small slots are
                cut for the hinges which are embedded into the frame manually using a machine that
                generates heat to set the hinges in place.
              </p>
            </div>
          </div>

          <div className="body-senichisaku__process__block body-senichisaku__process__block--even body-senichisaku__process__block--sm2">
            <div className="body-senichisaku__process__block-media" data-aos="fade-up">
              <img alt="attaching pad arms" src={`${baseImgUrl}/process-03.webp`} loading="lazy" />
            </div>

            <div className="body-senichisaku__process__block-details" data-aos="fade-up">
              <h5 className="body-senichisaku__process__block-title">6. Attaching Pad Arms</h5>
              <p className="body-senichisaku__process__block-text">
                Holes are created to embed the pad arms into the frame.
              </p>
              <h5 className="body-senichisaku__process__block-title">7. Temples and Hinges</h5>
              <p className="body-senichisaku__process__block-text">
                Hinges are fastened onto the temples. The screws are then tightened and the frame is
                adjusted to ensure that it is at the correct pantoscopic angle.
              </p>
            </div>
          </div>

          <div className="body-senichisaku__process__block body-senichisaku__process__block--odd body-senichisaku__process__block--md">
            <div className="body-senichisaku__process__block-media" data-aos="fade-up">
              <img alt="hand polishing" src={`${baseImgUrl}/process-04.webp`} loading="lazy" />
            </div>
            <div className="body-senichisaku__process__block-details" data-aos="fade-up">
              <h5 className="body-senichisaku__process__block-title">8. Hand Polishing</h5>
              <p className="body-senichisaku__process__block-text">
                The frame is polished using different buffing wheels in sequence. This is the step
                that gives rise to the brilliant lustre and beautiful colour gradient on the
                celluloid.
              </p>
              <h5 className="body-senichisaku__process__block-title">9. Completion</h5>
              <p className="body-senichisaku__process__block-text">
                A Senichisaku mark is engraved onto the frame after it clears quality inspection.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
