import React from 'react';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';
import { PrivacyPolicy } from '../../../CommonComponents/PrivacyPolicy';
const PrivacyPolicyPage = () => {
  return (
    <main>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'Explore OWNDAYS Privacy Policy. Your data’s security is our priority. Understand how we handle your personal information. Act now, stay informed and secure!',
          },
          {
            name: 'twitter:description',
            content:
              'Explore OWNDAYS Privacy Policy. Your data’s security is our priority. Understand how we handle your personal information. Act now, stay informed and secure!',
          },
          {
            name: 'og:title',
            content: 'Privacy Policy | OWNDAYS Singapore',
          },
          {
            name: 'twitter:title',
            content: 'Privacy Policy | OWNDAYS Singapore',
          },
        ]}
        description="Explore OWNDAYS Privacy Policy. Your data’s security is our priority. Understand how we handle your personal information. Act now, stay informed and secure!"
        title="Privacy Policy | OWNDAYS Singapore"
      />
      <PrivacyPolicy />
    </main>
  );
};

export default PrivacyPolicyPage;
