import React, { Fragment } from 'react';

import { HOW_TO_USE, TERMS } from './constants';

import './BaseGiftCardsProducts.scss';
import './FooterGiftCardsProducts.scss';

export const FooterGiftCardsProducts = props => {
  const { yourName, message, handleSubmit, selectCardDesign, selectedDenomination } = props;

  const formatter = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return (
    <Fragment>
      <section className="footer-gift-cards-products__preview">
        <div className="base-gift-cards-products__container">
          <h2 className="footer-gift-cards-products__preview__title">PREVIEW</h2>
          <div className="footer-gift-cards-products__preview__card">
            {selectCardDesign && <img alt="preview-card-design" src={selectCardDesign} />}

            <pre>{message}</pre>

            <p>From {yourName}</p>
          </div>
        </div>

        <div className="base-gift-cards-products__container">
          <h2 className="footer-gift-cards-products__preview__summary__price">
            S${selectedDenomination ? selectedDenomination.price : 0}
          </h2>
          <div className="footer-gift-cards-products__preview__summary__exp">
            <p>Expiry Date</p>
            <p>
              {formatter.format(new Date(new Date().setFullYear(new Date().getFullYear() + 1)))}
            </p>
          </div>
          <button
            className="footer-gift-cards-products__preview__summary__add-cart-btn"
            onClick={handleSubmit}
          >
            ADD TO CART
          </button>
        </div>
      </section>

      <section className="footer-gift-cards-products__detail">
        <div className="base-gift-cards-products__container">
          <h4 className="footer-gift-cards-products__detail__how-to-use__title">How to use</h4>
          <ul className="footer-gift-cards-products__detail__how-to-use">
            {HOW_TO_USE.map(({ title, desc, link }) => (
              <li key={title} className="footer-gift-cards-products__detail__how-to-use__item">
                <h5 className="footer-gift-cards-products__detail__how-to-use__sub-title">
                  {title}
                </h5>
                <p className="footer-gift-cards-products__detail__how-to-use__desc">{desc}</p>
                {link && (
                  <a className="base-gift-cards-products__link" href={link.to}>
                    {link.title}
                  </a>
                )}
              </li>
            ))}
          </ul>

          <h4 className="footer-gift-cards-products__detail__terms__title">Terms and Conditions</h4>
          <ul className="footer-gift-cards-products__detail__terms__list">
            {TERMS.map(({ text }, index) => (
              <li key={`gift-cards-products-term-${index}`}>{text}</li>
            ))}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
