import React from 'react';

export const HOW_TO_USE = [
  {
    title: 'Physical Stores',
    desc: 'Please flash eGift on your mobile device prior to payment.',
    link: null,
  },
  {
    title: 'Online Store',
    desc: (
      <>
        At the{' '}<a className="base-gift-cards-products__link" href="/sg/en">OWNDAYS Online Store</a>, select “Enter and use gift code” at the Use Gift Code section during checkout and enter the above gift code
      </>
    ),
    link: {
      title: (
        <>
          <small>▶︎</small>More details on using the eGift
        </>
      ),
      to: '/sg/en/gift-ticket',
    },
  },
];

export const TERMS = [
  {
    text: 'Each eGift is valid within one year of purchase.',
  },
  {
    text: 'Each eGift is valid for one time use only.',
  },
  {
    text: 'Multiple eGifts may be used in one transaction.',
  },
  {
    text: 'eGift is non-refundable nor exchangeable for cash or other reward forms and cannot be re-issued.',
  },
  {
    text: 'Purchase exceeding the eGift value shall be topped up with other accepted payment methods.',
  },
  {
    text: 'The full value must be utilised in a single purchase. There is strictly no refund of any kind for any unused value.',
  },
  {
    text: 'eGift must be used before it expires. There is strictly no extension of expiry date.',
  },
  {
    text: 'For in-store purchase, eGift must be presented prior to payment; for online purchase, gift code must be entered during checkout.',
  },
  {
    text: 'The management reserves the right to amend the terms and conditions at any time without prior notice.',
  },
];

export const LIMIT = {
  name: 20,
  message: 260,
};
