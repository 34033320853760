export const LINEUP_MENU = [
  {
    id: 'plastic',
    title: 'Plastic Frame',
    img: '/plastic-frame-menu.webp',
  },
  {
    id: 'metal',
    title: 'Metal Frame',
    img: '/metal-frame-menu.webp',
  },
];

export const TERMS = [
  'Frames are available while stocks last.',
  'Frames are available at random, and specific serialized numbers cannot be selected.',
  'OWNDAYS Care+ cannot be purchased with frames from the 10th Anniversary Collection.',
  'The 20% off new frame purchase benefit from OWNDAYS Care+ is not valid for frames in the 10th Anniversary Collection.',
  'Frames cannot be combined with any discounts or promotions.',
  'The management reserves the right to amend these Terms of Purchase at any time without prior notice.',
];

export const STORES = [
  'OWNDAYS nex',
  'OWNDAYS Jurong Point',
  'OWNDAYS Suntec City',
  'OWNDAYS Waterway Point',
  'OWNDAYS MBS Premium Concept Store',
  'OWNDAYS IMM Outlet',
  'OWNDAYS Takashimaya S.C Premium Concept Store',
];

export const PLASTIC_SGSP2001B_3S = {
  BANNER: {
    pc: '/lineup-banner-pc-1.webp',
    sp: '/lineup-banner-sp-1.webp',
  },
  SKU: 'SGSP2001B-3S',
  DESC: `Wellington frame in a mixed material construction with the frame front in clear plastic and the temples in titanium. This is a style that is super easy to pull off and will always be pleasing to the eye.`,
  COLORS: ['clear-grey', 'clear-brown'],
  C1: [
    {
      title: 'Feature 01',
      alt: 'SGSP2001B-3S_C1',
      img: {
        sp: '/plastic1-c1-point-1-sp.webp',
        pc: '/plastic1-c1-point-1-pc.webp',
      },
      desc: 'Frame front in clear plastic as a nod to the see-through trend that is highly popular',
    },
    {
      title: 'Feature 02',
      alt: 'SGSP2001B-3S_C1',
      img: {
        sp: '/plastic1-c1-point-2-sp.webp',
        pc: '/plastic1-c1-point-2-pc.webp',
      },
      desc: 'Titanium temples for enhanced durability while allowing the frame to remain lightweight',
    },
    {
      title: 'Feature 03',
      alt: 'SGSP2001B-3S_C1',
      img: {
        sp: '/plastic1-c1-point-3-sp.webp',
        pc: '/plastic1-c1-point-3-pc.webp',
      },
      desc: 'A unique, serialised number on the temple tip so you finally have a frame to call your own',
    },
  ],
  C2: [
    {
      title: 'Feature 01',
      alt: 'SGSP2001B-3S_C2',
      img: {
        sp: '/plastic1-c2-point-1-sp.webp',
        pc: '/plastic1-c2-point-1-pc.webp',
      },
      desc: 'Frame front in clear plastic as a nod to the see-through trend that is highly popular',
    },
    {
      title: 'Feature 02',
      alt: 'SGSP2001B-3S_C2',
      img: {
        sp: '/plastic1-c2-point-2-sp.webp',
        pc: '/plastic1-c2-point-2-pc.webp',
      },
      desc: 'Titanium temples for enhanced durability while allowing the frame to remain lightweight',
    },
    {
      title: 'Feature 03',
      alt: 'SGSP2001B-3S_C1',
      img: {
        sp: '/plastic1-c2-point-3-sp.webp',
        pc: '/plastic1-c2-point-3-pc.webp',
      },
      desc: 'A unique, serialised number on the temple tip so you finally have a frame to call your own',
    },
  ],
};

export const PLASTIC_SGSP2002B_3S = {
  SKU: 'SGSP2002B-3S',
  DESC: `A true presentation of the less-is-more look, featuring a lightweight frame in the classic rectangle style. The usually humble, often-overlooked spectacle hinges have been reworked with a one-of-a-kind design that keeps the overall frame looking sharp and polished.`,
  COLORS: ['clear-grey', 'olive-green'],
  C1: [
    {
      title: 'Feature 01',
      alt: 'SGSP2002B-3S_C1',
      img: {
        sp: '/plastic2-c1-point-1-sp-v2.webp',
        pc: '/plastic2-c1-point-1-pc-v2.webp',
      },
      desc: 'Classic rectangle style that is both chic and business-formal',
    },
    {
      title: 'Feature 02',
      alt: 'SGSP2002B-3S_C1',
      img: {
        sp: '/plastic2-c1-point-2-sp.webp',
        pc: '/plastic2-c1-point-2-pc.webp',
      },
      desc: 'Hinges are specially designed to keep the overall frame looking sharp and polished',
    },
    {
      title: 'Feature 03',
      alt: 'SGSP2002B-3S',
      img: {
        sp: '/plastic2-c1-point-3-sp.webp',
        pc: '/plastic2-c1-point-3-pc.webp',
      },
      desc: 'A unique, serialised number on the temple tip so you finally have a frame to call your own',
    },
  ],
  C2: [
    {
      title: 'Feature 01',
      alt: 'SGSP2002B-3S_C2',
      img: {
        sp: '/plastic2-c2-point-1-sp.webp',
        pc: '/plastic2-c2-point-1-pc.webp',
      },
      desc: 'Classic rectangle style that is both chic and business-formal',
    },
    {
      title: 'Feature 02',
      alt: 'SGSP2002B-3S_C2',
      img: {
        sp: '/plastic2-c2-point-2-sp.webp',
        pc: '/plastic2-c2-point-2-pc.webp',
      },
      desc: 'Hinges are specially designed to keep the overall frame looking sharp and polished',
    },
    {
      title: 'Feature 03',
      alt: 'SGSP2002B-3S_C2',
      img: {
        sp: '/plastic2-c2-point-3-sp-v3.webp',
        pc: '/plastic2-c2-point-3-pc.webp',
      },
      desc: 'A unique, serialised number on the temple tip so you finally have a frame to call your own',
    },
  ],
};

export const METAL_SGSP1001B_3S = {
  BANNER: {
    pc: '/lineup-banner-pc-2.webp',
    sp: '/lineup-banner-sp-2.webp',
  },
  SKU: 'SGSP1001B-3S',
  DESC: `A crossover between the classic rectangle style and the currently-trending polygonal style. The frame body features dark-toned, matte finishing that adds a utilitarian touch to the frame design. Being lightweight, durable and anti-rust, this titanium frame is perfectly poised between form and function.`,
  COLORS: ['navy-blue', 'olive-green'],
  C1: [
    {
      title: 'Feature 01',
      alt: 'SGSP1001B-3S_C1',
      img: {
        sp: '/plastic1-c1-point-1-sp.webp',
        pc: '/plastic1-c1-point-1-pc.webp',
      },
      desc: 'Frame shape that is a crossover between rectangle and polygon',
    },
    {
      title: 'Feature 02',
      alt: 'SGSP1001B-3S_C1',
      img: {
        sp: '/metal1-c1-point-2-sp.webp',
        pc: '/metal1-c1-point-2-pc.webp',
      },
      desc: 'Frame body made of titanium that is known for being lightweight, durable and anti-rust',
    },
    {
      title: 'Feature 03',
      alt: 'SGSP1001B-3S_C1',
      img: {
        sp: '/metal1-c1-point-3-sp.webp',
        pc: '/metal1-c1-point-3-pc.webp',
      },
      desc: 'A unique, serialised number on the temple tip so you finally have a frame to call your own',
    },
  ],
  C2: [
    {
      title: 'Feature 01',
      alt: 'SGSP1001B-3S_C2',
      img: {
        sp: '/metal1-c2-point-1-sp.webp',
        pc: '/metal1-c2-point-1-pc.webp',
      },
      desc: 'Frame shape that is a crossover between rectangle and polygon',
    },
    {
      title: 'Feature 02',
      alt: 'SGSP1001B-3S_C2',
      img: {
        sp: '/metal1-c2-point-2-sp.webp',
        pc: '/metal1-c2-point-2-pc.webp',
      },
      desc: 'Frame body made of titanium that is known for being lightweight, durable and anti-rust',
    },
    {
      title: 'Feature 03',
      alt: 'SGSP1001B-3S_C1',
      img: {
        sp: '/metal1-c2-point-3-sp.webp',
        pc: '/metal1-c2-point-3-pc.webp',
      },
      desc: 'A unique, serialised number on the temple tip so you finally have a frame to call your own',
    },
  ],
};

export const METAL_SGSP1002B_3S = {
  SKU: 'SGSP1002B-3S',
  DESC: `Polygonal frame with a double-bridge design that oozes '80s retro vibes. Inspired by Singaporeans’ new-found love for vintage wear, this oversized titanium frame with a sleek silhouette is set to be a showstopper that will stand the test of time.`,
  COLORS: ['gunmetal', 'silver'],
  C1: [
    {
      title: 'Feature 01',
      alt: 'SGSP1002B-3S_C1',
      img: {
        sp: '/metal2-c1-point-1-sp.webp',
        pc: '/metal2-c1-point-1-pc.webp',
      },
      desc: 'Polygonal frame in oversized style with double-bridge design',
    },
    {
      title: 'Feature 02',
      alt: 'SGSP1002B-3S_C1',
      img: {
        sp: '/metal2-c1-point-2-sp.webp',
        pc: '/metal2-c1-point-2-pc.webp',
      },
      desc: 'Frame body made of titanium that is known for being lightweight, durable and anti-rust',
    },
    {
      title: 'Feature 03',
      alt: 'SGSP1002B-3S',
      img: {
        sp: '/metal2-c1-point-3-sp.webp',
        pc: '/metal2-c1-point-3-pc.webp',
      },
      desc: 'A unique, serialised number on the temple tip so you finally have a frame to call your own',
    },
  ],
  C2: [
    {
      title: 'Feature 01',
      alt: 'SGSP1002B-3S_C2',
      img: {
        sp: '/metal2-c2-point-1-sp.webp',
        pc: '/metal2-c2-point-1-pc.webp',
      },
      desc: 'Polygonal frame in oversized style with double-bridge design',
    },
    {
      title: 'Feature 02',
      alt: 'SGSP1002B-3S_C2',
      img: {
        sp: '/metal2-c2-point-2-sp.webp',
        pc: '/metal2-c2-point-2-pc.webp',
      },
      desc: 'Frame body made of titanium that is known for being lightweight, durable and anti-rust',
    },
    {
      title: 'Feature 03',
      alt: 'SGSP1002B-3S_C2',
      img: {
        sp: '/metal2-c2-point-3-sp.webp',
        pc: '/metal2-c2-point-3-pc.webp',
      },
      desc: 'A unique, serialised number on the temple tip so you finally have a frame to call your own',
    },
  ],
};
