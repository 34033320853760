import React, { Fragment } from 'react';

import { SNS_POMPOM } from './constants';

import './FooterPompompurin.scss';

export const FooterPompompurin = ({ baseImgUrl }) => {
  const renderButton = (text, isWhite, link) => {
    return (
      <a
        className={`base-pompompurin__btn ${isWhite ? 'base-pompompurin__btn--white' : ''}`}
        href={link}
        rel={text === 'OFFICIAL WEBSITE' ? 'noreferrer' : undefined}
        target={text === 'OFFICIAL WEBSITE' ? '_blank' : undefined}
      >
        <i>{text}</i>
        <span className="base-pompompurin__btn-arrow">
          <svg
            fill="none"
            height="13"
            viewBox="0 0 14 13"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.05527 1.07825L7.05527 1.07825C6.68429 1.44925 6.68428 2.05075 7.05527 2.42175L7.05527 2.42175L10.2065 5.57297L2.25 5.57297H2L1.75 5.57297C1.22533 5.57297 0.8 5.9983 0.8 6.52297C0.8 7.04765 1.22533 7.47297 1.75 7.47297L2 7.47297H2.25L10.2065 7.47297L7.05527 10.6242L7.19669 10.7656L7.05527 10.6242C6.68428 10.9952 6.68429 11.5967 7.05526 11.9677L7.19669 11.8263L7.05527 11.9677C7.42627 12.3387 8.02779 12.3387 8.39877 11.9677C8.39877 11.9677 8.39878 11.9677 8.39878 11.9677L13.1717 7.19472C13.5427 6.82373 13.5427 6.22221 13.1717 5.85122L8.39878 1.07826C8.0278 0.707244 7.42627 0.707255 7.05527 1.07825Z"
              fill="#FFCB14"
              stroke="#FFCB14"
              strokeWidth="0.4"
            ></path>
          </svg>
        </span>
      </a>
    );
  };

  return (
    <Fragment>
      <section className="footer-pompompurin__pompompurin">
        <div className="footer-pompompurin__pompompurin__buttons">
          {renderButton('ALL ITEMS', true, '/sg/en/eyeglasses/brands/pompompurin.html')}
          {renderButton('SEARCH FOR NEARBY STORES', true, '/stores/sg')}
        </div>

        <div className="base-pompompurin__container">
          <div className="footer-pompompurin__pompompurin__grid">
            <h2 className="footer-pompompurin__pompompurin__title">
              <img alt="POMPOMPURIN" src={`${baseImgUrl}/pompompurin.svg`} />
            </h2>
            <img
              alt="POMPOMPURIN"
              className="footer-pompompurin__pompompurin__img"
              src={`${baseImgUrl}/pompompurin-img.svg`}
            />
            <div className="footer-pompompurin__pompompurin__details">
              <p>
                This boy golden retriever is unmistakable in his trademark brown beret. He has a
                laidback demeanour, loves the phrase ‘let’s go out’ and dislikes the phrase ‘stay at
                home.’ His hobby is collecting shoes and he secretly hides them, like his
                owner-father’s leather shoes and his owner-mother’s sandals. He has a talent for
                napping and doing ‘purin aerobics’, and can make friends with anyone. His dream is
                to get even bigger. He lives in his own basket by the entrance of his owner-sister’s
                house.
              </p>

              {renderButton(
                'OFFICIAL WEBSITE',
                false,
                'https://www.sanrio.co.jp/characters/pompompurin'
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="footer-pompompurin__sns">
        <img
          alt=""
          className="footer-pompompurin__sns__fg footer-pompompurin__sns__fg--cloud"
          src={`${baseImgUrl}/cloud.svg`}
        />
        <div className="base-pompompurin__container">
          <img
            alt="Pompompurin and Muffin"
            className="footer-pompompurin__sns__fg footer-pompompurin__sns__fg--char"
            data-aos="fade-up"
            src={`${baseImgUrl}/sns-char.svg`}
          />
          <h2 className="footer-pompompurin__sns__title">SHARE</h2>

          <ul className="footer-pompompurin__sns__list">
            {SNS_POMPOM.map(({ link, className }, index) => (
              <li key={index}>
                <a href={link} rel="noopener noreferrer" target="_blank">
                  <i aria-hidden="true" className={`fab ${className}`}></i>
                </a>
              </li>
            ))}
          </ul>

          <p className="footer-pompompurin__sns__copyright">© 2024 SANRIO CO., LTD.</p>
        </div>
      </section>
    </Fragment>
  );
};
