import React, { Fragment, useState, useEffect } from 'react';
import Slider from 'react-slick';

import { SHARE_TOM_AND_JERRY, SHOPS, SHOPS_01, SHOPS_02 } from './constants';
import './FooterTomAndJerry.scss';

export const FooterTomAndJerry = ({ baseImgUrl }) => {
  const [position, setPosition] = useState('fixed');

  const copyrightText = `TOM AND JERRY and all related characters and elements\n © & ™Turner Entertainment Co. s25)`;

  const accessoriesSetting = {
    infinite: true,
    speed: 500,
    centerMode: true,
    variableWidth: false,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768, // Below 768px
        settings: {
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1023, // Above 768px to 1023px
        settings: {
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 9999, // Above 1024px
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const renderAccessoriesButton = () => (
    <a
      className="base-tom-and-jerry__btn"
      href="/sg/en/tomandjerry-tj-cleaner001-5s-cbrown-accessories.html"
    >
      ONLINE STORE
    </a>
  );

  const renderStoreButton = () => (
    <a className="base-tom-and-jerry__btn" href="/stores/sg">
      SEARCH FOR NEARBY STORES
    </a>
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.querySelector('.base-tom-and-jerry')?.offsetHeight || 0;

      if (scrollTop + windowHeight >= documentHeight) {
        setPosition('static');
      } else {
        setPosition('fixed');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <section className="footer-tom-and-jerry__case">
        <div className="footer-tom-and-jerry__case__bg__header">
          <picture>
            <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/case/bg-blue-top_pc.svg`} />
            <img
              alt="Original case & cleaning cloth set"
              loading="lazy"
              src={`${baseImgUrl}/case/bg-blue-top_sp.svg`}
            />
          </picture>
        </div>

        <div className="footer-tom-and-jerry__case__bg__body">
          <div className="base-tom-and-jerry__container">
            <div className="footer-tom-and-jerry__case__card">
              <div className="footer-tom-and-jerry__case__header">
                <picture>
                  <source
                    media="(min-width: 768px)"
                    srcSet={`${baseImgUrl}/case/case-title_pc.svg`}
                  />
                  <img
                    alt="Original case & cleaning cloth set"
                    loading="lazy"
                    src={`${baseImgUrl}/case/case-title_sp.svg`}
                  />
                </picture>

                <p>
                  Each frame comes with a stylish yet adorable Tyvek spectacle case designed to
                  resemble a paper bag, along with a cheese-shaped lens cleaning cloth featuring the
                  classic chase scene.
                </p>
              </div>

              <div className="footer-tom-and-jerry__case__footer">
                <img
                  alt="Cheese-Shaped Lens Cleaning Cloth"
                  className="footer-tom-and-jerry__case__footer__figure-1"
                  data-aos="fade-left"
                  data-aos-delay={200}
                  loading="lazy"
                  src={`${baseImgUrl}/case/figure-1.svg`}
                />
                <img
                  alt="Comic-Patterned Tyvek Case"
                  className="footer-tom-and-jerry__case__footer__figure-2"
                  data-aos="fade-right"
                  data-aos-delay={200}
                  loading="lazy"
                  src={`${baseImgUrl}/case/figure-2.svg`}
                />
                <img
                  alt="Cheese-Shaped Lens Cleaning Cloth"
                  className="footer-tom-and-jerry__case__footer__main-img"
                  loading="lazy"
                  src={`${baseImgUrl}/case/case-set.webp`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="footer-tom-and-jerry__case__bg__footer">
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={`${baseImgUrl}/case/bg-blue-footer_pc.svg`}
            />
            <img
              alt="Original case & cleaning cloth set"
              loading="lazy"
              src={`${baseImgUrl}/case/bg-blue-footer_sp.svg`}
            />
          </picture>
        </div>
      </section>

      <section className="footer-tom-and-jerry__accessories" id="collaboration-limited">
        <div className="footer-tom-and-jerry__accessories__header">
          <img
            alt="Limited Quantities"
            loading="lazy"
            src={`${baseImgUrl}/accessories/accessories-limited.svg`}
          />
          <img
            alt="ACCESSORIES"
            loading="lazy"
            src={`${baseImgUrl}/accessories/accessories-title.svg`}
          />
        </div>

        <div className="footer-tom-and-jerry__accessories__body">
          <img
            alt="Waffle Jerry （Lens Cleaning Cloth）"
            className="footer-tom-and-jerry__accessories__body__figure"
            data-aos="fade-right"
            data-aos-delay={200}
            src={`${baseImgUrl}/accessories/figure.svg`}
          />

          <Slider {...accessoriesSetting} class="footer-tom-and-jerry__accessories__slider">
            {Array.from({ length: 3 }, (_, index) => (
              <li key={`TJ-CLEANER001-5S-${index}`}>
                <img
                  alt="TJ-CLEANER001-5S"
                  loading="lazy"
                  src={`${baseImgUrl}/accessories/0${index + 1}.webp`}
                />
              </li>
            ))}
          </Slider>

          <div className="footer-tom-and-jerry__accessories__body__message">
            <div className="base-tom-and-jerry__container footer-tom-and-jerry__accessories__body__message__header">
              <p>TJ-CLEANER001-5S</p>
              <p>S$10</p>
            </div>

            <div className="base-tom-and-jerry__container footer-tom-and-jerry__accessories__body__message__body">
              <p>
                The kittens have squished Jerry into a waffle shape in this fluffy mascot screen
                cleaner. Inside, you’ll find an attached lens cleaning cloth. This palm-sized Jerry
                is so irresistibly cute you’ll want to take him with you everywhere!
              </p>
            </div>

            <div className="footer-tom-and-jerry__accessories__body__message__footer">
              {renderAccessoriesButton()}
            </div>
          </div>
        </div>
      </section>

      <section className="footer-tom-and-jerry__shops">
        <div className="footer-tom-and-jerry__shops__bg">
          <picture>
            <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/shops/shop-bg_pc.svg`} />
            <img
              alt="AVAILABLE SHOPS"
              loading="lazy"
              src={`${baseImgUrl}/shops/shop-bg_sp_v2.svg`}
            />
          </picture>
        </div>

        <h2 className="footer-tom-and-jerry__shops__title">
          <picture>
            <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/shops/shop-title_pc.svg`} />
            <img
              alt="AVAILABLE SHOPS"
              loading="lazy"
              src={`${baseImgUrl}/shops/shop-title_sp.svg`}
            />
          </picture>
        </h2>

        <ul className="footer-tom-and-jerry__shops__list-sp">
          {SHOPS.map(shop => (
            <li key={shop}>{shop}</li>
          ))}
        </ul>

        <div className="footer-tom-and-jerry__shops__list-pc">
          <ul>
            {SHOPS_01.map(shop => (
              <li key={shop}>{shop}</li>
            ))}
          </ul>

          <ul>
            {SHOPS_02.map(shop => (
              <li key={shop}>{shop}</li>
            ))}
          </ul>
        </div>

        <div className="footer-tom-and-jerry__shops__footer">{renderStoreButton()}</div>

        <img
          alt="TOM and JERRY"
          className="footer-tom-and-jerry__shops__figure-footer"
          data-aos="fade-left"
          data-aos-delay={200}
          loading="lazy"
          src={`${baseImgUrl}/share/share-tom-jerry.svg`}
        />
      </section>

      <section className="footer-tom-and-jerry__share">
        <div className="base-tom-and-jerry__container">
          <div>
            <h2 className="footer-tom-and-jerry__share__header">
              <img alt="Accessories" src={`${baseImgUrl}/share/share-title.svg`} />
            </h2>

            <ul className="footer-tom-and-jerry__share__list">
              {SHARE_TOM_AND_JERRY.map(({ link, className }, index) => (
                <li key={index}>
                  <a href={link} rel="noopener noreferrer" target="_blank">
                    <i aria-hidden="true" className={`fab ${className}`}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <img
            alt="TOM and JERRY"
            className="footer-tom-and-jerry__share__tom-and-jerry"
            loading="lazy"
            src={`${baseImgUrl}/share/share-tom-jerry.svg`}
          />

          <div className="footer-tom-and-jerry__share__copyright">
            <img
              alt="TOM and JERRY"
              loading="lazy"
              src={`${baseImgUrl}/share/share-copyright.svg`}
            />
            <p dangerouslySetInnerHTML={{ __html: copyrightText.replace(/\n/g, '<br />') }} />
          </div>
        </div>

        <div className="footer-tom-and-jerry__navs-sticky">
          <div
            className="footer-tom-and-jerry__navs-sticky__inner"
            style={{
              position,
            }}
          >
            <div className="footer-tom-and-jerry__navs-sticky__actions">
              <a
                className="footer-tom-and-jerry__btn footer-tom-and-jerry__btn--primary"
                href="/sg/en/eyeglasses/brands/tomandjerry.html"
              >
                See All Frames
              </a>

              <a
                className="footer-tom-and-jerry__btn footer-tom-and-jerry__btn--secondary"
                href="/stores/sg"
              >
                Search For <br className="base-tom-and-jerry__hide--pc" />
                Nearest Stores
              </a>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
