import React, { Fragment } from 'react';

import { KIMETSU_SHOPS, SHARES } from './constants';

import './HeaderKimetsu.scss';
import './FooterKimetsu.scss';

export const FooterKimetsu = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <section className="footer-kimetsu__lens">
        <h2 className="header-kimetsu__sub-title">
          <span className="header-kimetsu__sub-title-line header-kimetsu__sub-title-line--left header-kimetsu__sub-title-line--dark"></span>{' '}
          <span className="header-kimetsu__sub-title-text">
            Demon Slayer <br />
            Available Shops
          </span>
          <span className="header-kimetsu__sub-title-line header-kimetsu__sub-title-line--right header-kimetsu__sub-title-line--dark"></span>
        </h2>

        <ul className="footer-kimetsu__lens__shops">
          {KIMETSU_SHOPS.map(({ title, link }) => (
            <li key={title}>
              <a>{title}</a>
            </li>
          ))}
        </ul>
      </section>

      <section className="footer-kimetsu__share">
        <div className="base-kimetsu__container">
          <ul>
            {SHARES.map(({ link, key }) => (
              <li key={key}>
                <a href={link} rel="noreferrer" target="_blank">
                  <i className={`fab fa-${key}`}></i>
                </a>
              </li>
            ))}
          </ul>

          <p className="footer-kimetsu__copyright">
            ©Koyoharu Gotoge / SHUEISHA, Aniplex, ufotable
          </p>
        </div>
      </section>
    </Fragment>
  );
};
