import React from 'react';

const Pagination = ({ setCurrentPage, totalPages, currentPage }) => {
  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      {totalPages > 1 && (
        <div className="information__pagination">
          {currentPage > 1 && (
            <button
              className="information__pagination-btn"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <svg
                fill="#000"
                height={8}
                viewBox="0 0 320 512"
                width={8}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
              </svg>
            </button>
          )}
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`information__pagination-btn ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          {currentPage < totalPages && (
            <button
              className="information__pagination-btn"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <svg
                fill="#000"
                height={8}
                viewBox="0 0 320 512"
                width={8}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
              </svg>
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Pagination;
