import React from 'react';

import { HeaderKimetsu, BodyKimetsu, FooterKimetsu } from '../CommonComponents/Kimetsu';
import '../CommonComponents/Kimetsu/BaseKimetsu.scss';

const KimetsuCollectionHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/kimetsu';

  return (
    <div className="base-kimetsu">
      <HeaderKimetsu baseImgUrl={baseImgUrl} />
      <BodyKimetsu baseImgUrl={baseImgUrl} />
      <FooterKimetsu baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default KimetsuCollectionHTML;
