import React, { Fragment, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { MODELS_POMPOM, ACCESSORIES_POMPOM } from './constants';

import './BodyPompompurin.scss';

export const BodyPompompurin = ({ baseImgUrl }) => {
  const [selectedSR1007B_4A, setSelectedSR1007B_4A] = useState('C1');
  const [selectedSR1008B_4A, setSelectedSR1008B_4A] = useState('C1');
  const [selectedSR2003J_4A, setSelectedSR2003J_4A] = useState('C1');
  const [selectedSR2004N_4A, setSelectedSR2004N_4A] = useState('C1');
  const [selectedSR2005N_4A, setSelectedSR2005N_4A] = useState('C1');

  const pictureRef = useRef(null);

  useEffect(() => {
    if (pictureRef.current) {
      pictureRef.current.setAttribute('uk-parallax', 'y: 30%,-30%;');
    }
  }, []);

  const featureSetting = {
    arrows: false,
    dots: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          infinite: true,
          slidesToShow: 1,
          centerMode: true,
          dots: true,
        },
      },
    ],
  };

  const pompomProductsSetting = {
    initialSlide: 0,
    className: 'center',
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    arrows: true,
    dots: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          variableWidth: false,
          centerPadding: '10%',
        },
      },
    ],
  };

  const accessoriesSetting = {
    arrows: false,
    dots: true,
    slidesToShow: 1,
  };

  const handleSelectedModel = (key, model) => {
    const setterMapping = {
      'SR1007B-4A': setSelectedSR1007B_4A,
      'SR1008B-4A': setSelectedSR1008B_4A,
      'SR2003J-4A': setSelectedSR2003J_4A,
      'SR2004N-4A': setSelectedSR2004N_4A,
      'SR2005N-4A': setSelectedSR2005N_4A,
    };

    if (setterMapping[model]) {
      setterMapping[model](key);
    }
  };

  const getProductAlt = model => {
    const selectedValue = {
      'SR1007B-4A': selectedSR1007B_4A,
      'SR1008B-4A': selectedSR1008B_4A,
      'SR2003J-4A': selectedSR2003J_4A,
      'SR2004N-4A': selectedSR2004N_4A,
      'SR2005N-4A': selectedSR2005N_4A,
    }[model];

    return selectedValue !== undefined ? `${model} ${selectedValue}` : '';
  };

  const getProductColorClassName = (key, model) => {
    const selectedVariable = {
      'SR1007B-4A': selectedSR1007B_4A,
      'SR1008B-4A': selectedSR1008B_4A,
      'SR2003J-4A': selectedSR2003J_4A,
      'SR2004N-4A': selectedSR2004N_4A,
      'SR2005N-4A': selectedSR2005N_4A,
    }[model];

    return selectedVariable === key ? 'active' : '';
  };

  const renderProductSlider = model => {
    const modelConfig = {
      'SR1007B-4A': { limit: 6, cNumber: selectedSR1007B_4A },
      'SR1008B-4A': { limit: 5, cNumber: selectedSR1008B_4A },
      'SR2003J-4A': { limit: 4, cNumber: selectedSR2003J_4A },
      'SR2004N-4A': { limit: selectedSR2004N_4A === 'C1' ? 6 : 5, cNumber: selectedSR2004N_4A },
      'SR2005N-4A': { limit: 8, cNumber: selectedSR2005N_4A },
    };

    const { limit = 6, cNumber = 'C1' } = modelConfig[model] || {};

    return (
      <Slider {...pompomProductsSetting} className="body-pompompurin__models__products-slider">
        {Array.from({ length: limit }).map((_i, index) => (
          <picture key={index}>
            <img
              alt={getProductAlt(model)}
              src={`${baseImgUrl}/products/${model}_${cNumber}_0${index + 1}.webp`}
            />
          </picture>
        ))}
      </Slider>
    );
  };

  const renderButton = (model, url) => {
    const isProductSection = model;

    const link = isProductSection
      ? `/sg/en/owndays-pompompurin-${model.toLowerCase()}-${(
          {
            'SR1007B-4A': selectedSR1007B_4A,
            'SR1008B-4A': selectedSR1008B_4A,
            'SR2003J-4A': selectedSR2003J_4A,
            'SR2004N-4A': selectedSR2004N_4A,
            'SR2005N-4A': selectedSR2005N_4A,
          }[model] || ''
        ).toLowerCase()}-eyeglasses.html`
      : url;

    return (
      <a className="base-pompompurin__btn" href={link}>
        <i>ONLINE STORE</i>
        <span className="base-pompompurin__btn-arrow">
          <svg
            fill="none"
            height="13"
            viewBox="0 0 14 13"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.05527 1.07825L7.05527 1.07825C6.68429 1.44925 6.68428 2.05075 7.05527 2.42175L7.05527 2.42175L10.2065 5.57297L2.25 5.57297H2L1.75 5.57297C1.22533 5.57297 0.8 5.9983 0.8 6.52297C0.8 7.04765 1.22533 7.47297 1.75 7.47297L2 7.47297H2.25L10.2065 7.47297L7.05527 10.6242L7.19669 10.7656L7.05527 10.6242C6.68428 10.9952 6.68429 11.5967 7.05526 11.9677L7.19669 11.8263L7.05527 11.9677C7.42627 12.3387 8.02779 12.3387 8.39877 11.9677C8.39877 11.9677 8.39878 11.9677 8.39878 11.9677L13.1717 7.19472C13.5427 6.82373 13.5427 6.22221 13.1717 5.85122L8.39878 1.07826C8.0278 0.707244 7.42627 0.707255 7.05527 1.07825Z"
              fill="#FFCB14"
              stroke="#FFCB14"
              strokeWidth="0.4"
            ></path>
          </svg>
        </span>
      </a>
    );
  };

  return (
    <Fragment>
      <section className="body-pompompurin__models">
        {MODELS_POMPOM.map((item, index) => {
          return (
            <div key={item.id} className="body-pompompurin__models__item" id={item.id}>
              <div className="body-pompompurin__models__top">
                <div className="base-pompompurin__container">
                  <h2 className="body-pompompurin__models__title" data-aos="fade-right">
                    <span>{item.title}</span>MODEL
                  </h2>

                  <picture
                    ref={pictureRef}
                    className={`body-pompompurin__models__char body-pompompurin__models__char--${
                      index + 1
                    }`}
                  >
                    <img
                      alt={item.title}
                      data-aos="fade-left"
                      data-aos-delay="800"
                      src={`${baseImgUrl}/models${item.character}`}
                    />
                  </picture>
                </div>
              </div>

              <div className="body-pompompurin__models__content">
                <div className="base-pompompurin__container">
                  <div className="body-pompompurin__models__content-header">
                    <h3>{item.model}</h3>
                    <h4>{item.price}</h4>
                  </div>
                  <p className="body-pompompurin__models__content-text">{item.desc}</p>
                </div>
                <picture className="body-pompompurin__models__content-product">
                  <source
                    media="(min-width: 1024px)"
                    srcSet={`${baseImgUrl}/products${item.product.pc}`}
                  />
                  <img
                    alt={getProductAlt(item.alt)}
                    data-aos="fade-right"
                    src={`${baseImgUrl}/products${item.product.sp}`}
                  />
                </picture>
              </div>

              <div className="body-pompompurin__models__feature">
                <div className="base-pompompurin__container">
                  <Slider {...featureSetting} className="body-pompompurin__models__feature-list">
                    {item.features.map(({ src, desc }, index) => (
                      <div
                        key={index}
                        className="body-pompompurin__models__feature-item"
                        data-aos="fade-right"
                        data-aos-delay="100"
                      >
                        <h4 className="body-pompompurin__models__feature-title">FEATURE</h4>
                        <div className="body-pompompurin__models__feature-content">
                          <span>{index + 1}</span>
                          <img alt={desc} src={`${baseImgUrl}/models${src}`} />
                          <p>
                            {desc.split('\n').map((line, index) => (
                              <Fragment key={index}>
                                {line}
                                <br />
                              </Fragment>
                            ))}
                          </p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>

              <div className="body-pompompurin__models__products">
                <div className="body-pompompurin__models__products-colors">
                  <p>COLOUR</p>
                  <ul>
                    {item.colors.map(({ key, title }) => (
                      <li
                        key={title}
                        className={getProductColorClassName(key, item.model)}
                        data-product={title}
                        onClick={() => handleSelectedModel(key, item.model)}
                      >
                        <span></span>
                      </li>
                    ))}
                  </ul>
                </div>

                {renderProductSlider(item.model)}
                {renderButton(item.model)}
              </div>
            </div>
          );
        })}

        <div className="body-pompompurin__models__bottom">
          <p className="body-pompompurin__models__note">
            *Images are for reference only. <br className="base-pompompurin__hide--pc" />
            Actual products may differ in design and specifications.
          </p>
        </div>
      </section>

      <section className="body-pompompurin__case">
        <div className="base-pompompurin__container">
          <div className="body-pompompurin__case__content">
            <img
              alt="OWNDAYS & POMPOMPURIN"
              className="body-pompompurin__case__fg"
              data-aos="fade-up"
              src={`${baseImgUrl}/case-char.svg`}
            />
            <h2 className="body-pompompurin__case__heading">
              ORIGINAL CASE & <br />
              CLEANING CLOTH SET
            </h2>
            <img
              alt="original case cleaning cloth set"
              className="body-pompompurin__case__img"
              src={`${baseImgUrl}/case-img.webp`}
            />
            <p className="body-pompompurin__case__text">
              Each frame comes with an original spectacle case in Pompompurin-inspired colours,
              along with a pudding-shaped lens cleaning cloth which adds a cute, comforting touch.
            </p>
          </div>
        </div>
      </section>

      <section className="body-pompompurin__accessories">
        <div className="base-pompompurin__container">
          <h2 className="body-pompompurin__accessories__title">
            <span>Collaboration Exclusive</span>ACCESSORIES
          </h2>

          <ul className="body-pompompurin__accessories__list">
            {ACCESSORIES_POMPOM.map(({ title, price, link, desc }, index) => {
              return (
                <li key={title} className="body-pompompurin__accessories__item">
                  <Slider
                    {...accessoriesSetting}
                    className="body-pompompurin__accessories__slider loaded"
                  >
                    {Array.from({ length: 3 }).map((_i, slideIndex) => (
                      <img
                        key={slideIndex}
                        alt={title}
                        src={`${baseImgUrl}/accessories/accessories-${index + 1}-${
                          slideIndex + 1
                        }.webp`}
                      />
                    ))}
                  </Slider>

                  <div className="body-pompompurin__accessories__content">
                    <div className="body-pompompurin__accessories__header">
                      <h3>{title}</h3>
                      <h4>{price}</h4>
                    </div>
                    <p>{desc}</p>

                    {renderButton(null, link)}
                  </div>
                </li>
              );
            })}
          </ul>

          <p className="body-pompompurin__accessories__note">
            *Images are for reference only. <br className="base-pompompurin__hide--pc" />
            Actual products may differ in design and specifications.
          </p>

          <picture className="body-pompompurin__accessories__fg">
            <img
              alt="OWNDAYS & POMPOMPURIN"
              data-aos="fade-up"
              src={`${baseImgUrl}/stores-char.svg`}
            />
          </picture>
        </div>
      </section>
    </Fragment>
  );
};
