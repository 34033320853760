import React, { useState, useRef } from 'react';

import { LIMIT } from './constants';
import './BodyGiftCardsProducts.scss';

export const BodyGiftCardsProducts = props => {
  const {
    message,
    setMessage,
    yourName,
    setYourName,
    errors,
    setErrors,
    giftCardImages,
    denominations,
    selectedDenomination,
    setSelectedDenomination,
    selectCardDesign,
    setSelectCardDesign,
  } = props;

  const [isCustomPrice, setIsCustomPrice] = useState(false);
  const [customPriceText, setCustomPriceText] = useState('0');

  const priceRef = useRef(null);

  const firstFourDenominations = denominations?.slice(0, 4);

  const handleChangeMessage = e => {
    setMessage(e.target.value);
  };

  const handleChangeYourName = e => {
    setYourName(e.target.value);
    setErrors({ yourName: Boolean(e.target.value.length <= 0) });
  };

  const handleSelectedDenomination = (item, isCustomPrice) => {
    setSelectedDenomination(item);
    setErrors({ denomination: false });
    setIsCustomPrice(isCustomPrice);

    if (!isCustomPrice) {
      setCustomPriceText('0');
    }
  };

  const handleSelectedCardDesign = image => {
    setSelectCardDesign(image);
  };

  const handleClickCustomPrice = () => {
    setIsCustomPrice(true);

    if (customPriceText === '0') {
      setSelectedDenomination(null);
    }
  };

  const getNearestDenomination = value => {
    for (let i = 0; i < denominations.length; i++) {
      if (value <= denominations[i].price) {
        if (i === 0) return denominations[i];

        const lower = denominations[i - 1].price;
        const upper = denominations[i].price;
        const threshold = lower + (upper - lower) * 0.5;

        return value >= threshold ? denominations[i] : denominations[i - 1];
      }
    }
    return denominations[denominations.length - 1];
  };

  const handleChangeCustomPrice = e => {
    setCustomPriceText(e.target.value);

    const value = parseInt(e.target.value, 10) || 0;

    if (!isNaN(value)) {
      const nearestDenomination = getNearestDenomination(value);
      handleSelectedDenomination(nearestDenomination, true);
    }
  };

  const handleBlurCustomPrice = () => {
    const value = parseInt(customPriceText, 10);

    if (value === 0 || customPriceText === '') {
      setCustomPriceText(0);
      setSelectedDenomination(null);
      setIsCustomPrice(true);
      return;
    }

    if (!isNaN(value)) {
      const nearestDenomination = getNearestDenomination(value);
      setCustomPriceText(nearestDenomination.price);
      handleSelectedDenomination(nearestDenomination, true);
    }
  };

  const handleKeyDown = e => {
    const value = parseInt(customPriceText, 10) || 0;

    if (e.key === 'ArrowUp') {
      const nextDenomination = denominations.find(d => d.price > value);
      if (nextDenomination) {
        setCustomPriceText(nextDenomination.price);
        handleSelectedDenomination(nextDenomination, true);
      }
    }

    if (e.key === 'ArrowDown') {
      const prevDenominations = denominations.filter(d => d.price < value);
      if (prevDenominations.length > 0) {
        const prevDenomination = prevDenominations[prevDenominations.length - 1];
        setCustomPriceText(prevDenomination.price);
        handleSelectedDenomination(prevDenomination, true);
      }
    }

    if (e.key === 'Enter') {
      handleBlurCustomPrice();
    }
  };

  return (
    <div className="base-gift-cards-products__container body-gift-cards-products__form">
      <section className="body-gift-cards-products__form-card-design" id="denomination">
        <h2 className="body-gift-cards-products__form-title">1. Select card design</h2>
        <ul>
          {giftCardImages.map((image, index) => (
            <li key={`gift-card-${index}`} onClick={() => handleSelectedCardDesign(image)}>
              <label
                className={
                  selectCardDesign === image
                    ? 'body-gift-cards-products__form-card-design__selected'
                    : ''
                }
              >
                <img alt="Gift card" src={image} />
              </label>
            </li>
          ))}
        </ul>
      </section>

      <section className="body-gift-cards-products__form-card-denomination">
        <h2 className="body-gift-cards-products__form-title">2. Select the card denomination</h2>
        <ul>
          {firstFourDenominations.map(item => (
            <li key={item.id} onClick={() => handleSelectedDenomination(item, false)}>
              <label
                className={
                  item.id === selectedDenomination?.id && !isCustomPrice
                    ? 'body-gift-cards-products__form-card-denomination__selected'
                    : ''
                }
              >
                S${item.price}
              </label>
            </li>
          ))}

          {denominations.length > 0 && (
            <li onClick={handleClickCustomPrice}>
              <label
                className={
                  isCustomPrice ? 'body-gift-cards-products__form-card-denomination__selected' : ''
                }
              >
                S${' '}
                <input
                  ref={priceRef}
                  className="body-gift-cards-products__form-card-denomination__custom-price"
                  max="1000"
                  min="50"
                  placeholder="Select the card denomination"
                  step="50"
                  type="number"
                  value={customPriceText}
                  onBlur={handleBlurCustomPrice}
                  onChange={handleChangeCustomPrice}
                  onKeyDown={handleKeyDown}
                ></input>
              </label>
            </li>
          )}
        </ul>

        {denominations.length > 0 && (
          <p className="body-gift-cards-products__form-card-denomination__remark">
            When entering an amount, please specify a number in increments of S$50 within the range
            of S$50 to S$1000
          </p>
        )}

        {errors.denomination && (
          <p className="body-gift-cards-products__form-error-message">
            Select the card denomination
          </p>
        )}
      </section>

      <section className="body-gift-cards-products__form-message">
        <h2 className="body-gift-cards-products__form-title">3. Enter a personal message</h2>
        <p className="body-gift-cards-products__form-remain-message">
          Remaining characters: {LIMIT.message - message.length}
        </p>
        <textarea
          maxLength="260"
          name="card_message"
          placeholder="Enter a personal message"
          rows="5"
          onChange={handleChangeMessage}
        >
          {message}
        </textarea>
      </section>

      <section className="body-gift-cards-products__form-name" id="name">
        <h2 className="body-gift-cards-products__form-title">4. Enter your name</h2>
        <p className="body-gift-cards-products__form-remain-message">
          Remaining characters: {LIMIT.name - yourName.length}
        </p>
        <input
          maxLength="20"
          placeholder="Enter your name"
          type="text"
          value={yourName}
          onChange={handleChangeYourName}
        />
        {errors.yourName && (
          <p className="body-gift-cards-products__form-error-message">Please enter your name</p>
        )}
      </section>
    </div>
  );
};
