import React, { Fragment, useEffect, useRef, useState } from 'react';

import Breadcrumbs from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';
import { CONCEPT, FEATURES } from './constants';

import './HeaderHuawei2Titanium.scss';

export const HeaderHuawei2Titanium = ({ baseImgUrl }) => {
  const title = 'OWNDAYS × HUAWEI Eyewear 2';
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: title },
  ];
  const ref = useRef(null);
  const conceptRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isPCSize, setIsPCSize] = useState(false);

  const scrollToLineup = () => {
    const element = document.getElementById('lineup');

    if (element) {
      const headerOffset = document.querySelector('header')?.offsetHeight || 0;
      const elementTop = element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementTop - headerOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current || !conceptRef.current) return;

      const conceptRect = conceptRef.current.getBoundingClientRect();
      const elementRect = ref.current.getBoundingClientRect();

      if (window.innerWidth >= 1024) {
        setIsPCSize(true);
        if (conceptRect.top <= 0 && conceptRect.bottom > elementRect.height) {
          // When scrolling inside `.concept`
          setIsSticky(true);
          setIsAtBottom(false);
        } else if (conceptRect.bottom <= elementRect.height) {
          // When reaching the bottom of `.concept`
          setIsSticky(false);
          setIsAtBottom(true);
        } else {
          // When above `.concept`
          setIsSticky(false);
          setIsAtBottom(false);
        }
      } else {
        setIsPCSize(false);
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  let topValue;
  if (isSticky) {
    topValue = '0px';
  } else if (isAtBottom) {
    topValue = 'auto';
  } else {
    topValue = '0';
  }

  let posValue = 'unset';
  if (isPCSize) {
    posValue = isSticky ? 'fixed' : 'absolute';
  }

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-huawei-2-titanium__main">
        <h1 className="header-huawei-2-titanium__main__logo">
          {title}
          <img alt={title} src={`${baseImgUrl}/main-logo.svg`} />
        </h1>

        <picture>
          <source
            media="(min-width: 768px)"
            srcSet={`${baseImgUrl}/main-glasses-pc.webp`}
            type="image/webp"
          />
          <img
            alt={title}
            className="header-huawei-2-titanium__main__glasses"
            data-aos="fade-up"
            src={`${baseImgUrl}/main-glasses-sp.webp`}
          />
        </picture>

        <div
          className="header-huawei-2-titanium__main__new-arrivals-layout"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-offset="0"
        >
          <p className="header-huawei-2-titanium__main__new-arrivals-title">
            <span>NEW ARRIVALS</span>
          </p>

          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={`${baseImgUrl}/main-text-pc.svg`}
              type="image/svg"
            />
            <img
              alt="Titanium Edition"
              className="header-huawei-2-titanium__main__new-arrivals-titanium"
              src={`${baseImgUrl}/main-text-sp.svg`}
            />
          </picture>

          <p className="header-huawei-2-titanium__main__new-arrivals-date"></p>
        </div>
      </section>

      <section ref={conceptRef} className="header-huawei-2-titanium__concept">
        <div className="base-huawei-2-titanium__container">
          <h2 className="header-huawei-2-titanium__concept__title" data-aos="fade-up">
            All-New Upgraded <br />
            Smart Audio Glasses
          </h2>

          <div className="header-huawei-2-titanium__concept__details" data-aos="fade-up">
            <p className="header-huawei-2-titanium__concept__details-text">
              Introducing the Titanium Edition of the OWNDAYS × HUAWEI Eyewear 2 Smart Audio
              Glasses. With this addition, the collection now features six models, each available in
              two colour variations. Designed for an enhanced auditory and visual experience, these
              smart audio glasses offer improved sound quality while weighing lighter than previous
              generation.
            </p>

            <a
              className="base-huawei-2-titanium__btn base-huawei-2-titanium__btn--sm"
              onClick={scrollToLineup}
            >
              View LINEUP
            </a>
          </div>

          <picture>
            <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/FRONT_R1_pc.webp`} />
            <img
              ref={ref}
              alt="All-New UpgradedSmart Audio Glasses OWNDAYS × HUAWEI Eyewear 2"
              className="header-huawei-2-titanium__concept__glasses"
              src={`${baseImgUrl}/FRONT_R1_sp.webp`}
              style={{
                position: posValue,
                top: topValue,
                bottom: isAtBottom ? '0' : 'auto',
              }}
            />
          </picture>

          <ul className="header-huawei-2-titanium__concept__options" data-aos="fade-up">
            {CONCEPT.OPTIONS.map(({ title, desc }, index) => (
              <li key={title}>
                <img alt={title} src={`${baseImgUrl}/concept-option-${index + 1}.svg`} />
                <h3>{title}</h3>
                <p>{desc}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="header-huawei-2-titanium__feature">
        <div className="base-huawei-2-titanium__container">
          <ul className="header-huawei-2-titanium__feature__list" data-aos="face-up">
            {FEATURES.map(({ title, desc }, index) => (
              <li key={title.replace('\n', '')}>
                <img alt={title.replace('\n', '')} src={`${baseImgUrl}/feature-${index + 1}.svg`} />
                <div>
                  <h4 dangerouslySetInnerHTML={{ __html: title.replace(/\n/g, '<br />') }} />
                  <p>{desc}</p>
                </div>
              </li>
            ))}
          </ul>

          <p className="header-huawei-2-titanium__feature__remark">
            Titanium Edition (HW1001-4A/HW1002-4A) is not compatible with SNAP LENS
          </p>
        </div>
      </section>

      <section className="header-huawei-2-titanium__scene">
        <div className="header-huawei-2-titanium__scene__header__layout">
          <div className="header-huawei-2-titanium__scene__header__img">
            <picture>
              <source
                media="(min-width:768px)"
                srcSet={`${baseImgUrl}/scene1-2-pc.webp`}
                type="image/webp"
              />
              <img alt="2 New Models with Titanium Front" src={`${baseImgUrl}/scene1-2-sp.webp`} />
            </picture>
          </div>

          <div className="header-huawei-2-titanium__scene__header__content">
            <span className="header-huawei-2-titanium__scene__tag">NEW</span>

            <h2 className="header-huawei-2-titanium__scene__title">
              2 New Models with Titanium Front
            </h2>

            <p className="header-huawei-2-titanium__scene__desc">
              Introducing the Titanium Edition of the OWNDAYS × HUAWEI Eyewear 2 Smart Audio
              Glasses. <br />
              Crafted with a luxurious titanium front, the new models feature two sleek rectangle
              designs, each available in two refined colour options. Engineered for exceptional
              durability and lightness, these smart audio glasses offer the perfect fusion of style,
              sophistication and performance, making them ideal for both daily wear and professional
              settings.
            </p>

            <a
              className="base-huawei-2-titanium__btn base-huawei-2-titanium__btn--sm"
              onClick={scrollToLineup}
            >
              View LINEUP
            </a>
          </div>
        </div>

        <div className="header-huawei-2-titanium__scene__video">
          <a data-fancybox="etc" href="https://youtu.be/37BawUPZolI">
            <iframe
              allow="autoplay"
              height="100%"
              src="https://www.youtube.com/embed/37BawUPZolI?autoplay=1&mute=1&loop=1&controls=0&showinfo=0&playlist=37BawUPZolI"
              title="OWNDAYS x HUAWEI Eyewear 2"
              width="100%"
            ></iframe>
          </a>
        </div>

        <div className="header-huawei-2-titanium__scene__body__layout header-huawei-2-titanium__scene__body__layout--reverse">
          <div className="base-huawei-2-titanium__container">
            <h2 className="header-huawei-2-titanium__scene__title">Free Your Ears and Hands</h2>

            <p className="header-huawei-2-titanium__scene__desc">
              Take calls and attend virtual meetings while wearing the smart audio glasses. Even in
              open spaces, the caller’s voice stays clear and private. <br />
              Hands-free operation allows you to multitask efficiently.
            </p>
          </div>

          <div className="header-huawei-2-titanium__scene__header__img">
            <picture>
              <source
                media="(min-width:768px)"
                srcSet={`${baseImgUrl}/scene2-2-pc.webp`}
                type="image/webp"
              />
              <img alt="Free Your Ears and Hands" src={`${baseImgUrl}/scene2-2-sp.webp`} />
            </picture>
          </div>
        </div>

        <div className="header-huawei-2-titanium__scene__body__layout">
          <div className="base-huawei-2-titanium__container">
            <h2 className="header-huawei-2-titanium__scene__title">Ideal for Any Activity</h2>

            <p className="header-huawei-2-titanium__scene__desc">
              A versatile design that is perfect for both casual and active use. The open-ear design
              lets your ears breathe, giving you a more comfortable audio experience at all times.
              <br />
              Attach the polarised SNAP LENS (sold separately) to convert your smart audio glasses
              into sunglasses instantly.
            </p>

            <p className="header-huawei-2-titanium__feature__remark">
              Titanium Edition (HW1001-4A/HW1002-4A) is not compatible with SNAP LENS
            </p>
          </div>

          <div className="header-huawei-2-titanium__scene__header__img">
            <picture>
              <source
                media="(min-width:768px)"
                srcSet={`${baseImgUrl}/scene3-2-pc.webp`}
                type="image/webp"
              />
              <img alt="Ideal for Any Activity" src={`${baseImgUrl}/scene3-2-sp.webp`} />
            </picture>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
