import React from 'react';
import { UNDERSTANDING, CMMP_LIST, RS_PRODUCTS, RS_NOTES } from './constants.js';

const BodyFreeMyopiaControl2Lenses = ({
  baseImgUrl,
  baseImgUrl2,
  setIsModalOpen,
  setIsModalTermsOpen,
}) => {
  return (
    <>
      <section className="fmc2l__what">
        <div className="fmc2l__container">
          <div className="fmc2l__what-video" onClick={() => setIsModalOpen(true)}>
            <picture>
              <source media="(min-width: 768px)" srcSet={`${baseImgUrl2}/MCL-Thumbnail-PC.webp`} />
              <img
                alt="Free Myopia Control 2 Lenses"
                src={`${baseImgUrl2}/MCL-Thumbnail-SP.webp`}
              />
            </picture>
          </div>
          <div className="fmc2l__what-content">
            <h2 className="fmc2l__heading">
              <span className="fmc2l__heading-red">W</span>hat is <br className="md-none" />
              <span className="fmc2l__heading-mark">Myopia Control 2 Lens</span>
            </h2>
            <p className="fmc2l__what-description">
              Myopia Control 2 lens is designed with special optical properties that are effective
              in reducing the rate at which myopia progresses. The lens is developed using the
              latest technology to reduce peripheral hyperopic defocus which is a primary cause of
              worsening myopia.
              <br />
              <br />
              Compared to the first generation of myopia control lens offered at OWNDAYS which slows
              down myopia progression by 30%, Myopia Control 2 is clinically proven to slow down
              myopia progression by up to 50%<sup>1</sup>. Besides, the new lens design provides
              better comfort, allowing the wearer to adjust to wearing the lenses quickly and
              smoothly.
            </p>
          </div>
        </div>
      </section>
      <section className="fmc2l__uds">
        <img alt="cloud" className="fmc2l__cloud fmc2l__cloud--1" src={`${baseImgUrl}/cloud.svg`} />
        <img alt="cloud" className="fmc2l__cloud fmc2l__cloud--2" src={`${baseImgUrl}/cloud.svg`} />
        <div className="fmc2l__container">
          <div className="fmc2l__uds-grid">
            <div className="fmc2l__uds-content">
              <h2 className="fmc2l__heading">
                <span className="fmc2l__heading-red">U</span>nderstanding Myopia
              </h2>
              <p>
                Myopia or short-sightedness occurs when the eyeball becomes elongated or the
                cornea/crystalline lens is too curved. <br /> This causes distant objects to look
                blurry.
              </p>
            </div>
            {UNDERSTANDING.features && (
              <div className="fmc2l__uds-card">
                <h3 className="fmc2l__uds-card-title">Lens Features</h3>
                {UNDERSTANDING.features && (
                  <ul className="fmc2l__uds-card-list fmc2l__uds-card-list--features">
                    {UNDERSTANDING.features.map((item, index) => (
                      <li key={index}>
                        <img alt={item.iconAlt} src={item.iconSrc} />
                        {item.text}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            {UNDERSTANDING.benefits && (
              <div className="fmc2l__uds-card">
                <h3 className="fmc2l__uds-card-title">Key Benefits of Myopia Control 2</h3>
                {UNDERSTANDING.benefits && (
                  <ul className="fmc2l__uds-card-list fmc2l__uds-card-list--benefits">
                    {UNDERSTANDING.benefits.map((item, index) => (
                      <li key={index}>
                        <img alt={item.iconAlt} src={item.iconSrc} />
                        <span dangerouslySetInnerHTML={{ __html: item.text }} />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
          {UNDERSTANDING.contents && (
            <ul className="fmc2l__uds-contents">
              {UNDERSTANDING.contents.map((content, contentIdx) => (
                <li key={contentIdx} className="fmc2l__uds-contents-item">
                  <div className="fmc2l__uds-contents-img">
                    <picture>
                      <img alt={content.imgAlt} src={content.imgSrc} />
                    </picture>
                  </div>
                  <div className="fmc2l__uds-contents-details">
                    <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
                    <p dangerouslySetInnerHTML={{ __html: content.description }} />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
      <section className="fmc2l__about">
        <img alt="cloud" className="fmc2l__cloud fmc2l__cloud--1" src={`${baseImgUrl}/cloud.svg`} />
        <div className="fmc2l__container">
          <h2 className="fmc2l__about-title">
            <span>Free Myopia Control 2 Lens</span>
          </h2>
          <picture className="fmc2l__about-img">
            <img alt="FREE Myopia Control 2 Lenses" src={`${baseImgUrl2}/MCL-IMG-3.webp`} />
          </picture>
          <div className="fmc2l__about-content">
            <p className="fmc2l__about-text">
              Keep your child’s myopia in check with a FREE upgrade to the all-new Myopia Control 2
              lenses (worth $100) when you purchase spectacles for them at OWNDAYS. This offer is
              exclusive to children aged 14 and below.
              <br />
              <br />
              Make an appointment or walk in to your nearest OWNDAYS store for a complimentary eye
              test for your child today! Do note that eye tests for children under 8 years old are
              only available at selected stores with optometrists.
            </p>
            <ul className="fmc2l__about-notes">
              <li>* 2-week processing time is required</li>
              <li>
                <em onClick={() => setIsModalTermsOpen(true)}>* T&Cs apply</em>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="fmc2l__cmmp">
        <img alt="cloud" className="fmc2l__cloud fmc2l__cloud--1" src={`${baseImgUrl}/cloud.svg`} />
        <div className="fmc2l__container">
          <h2 className="fmc2l__heading">
            <span className="fmc2l__heading-red">C</span>omprehensive Myopia Management Programme
          </h2>
          <p className="fmc2l__cmmp-text">
            At OWNDAYS, we are committed to providing a holistic approach to managing your child's
            myopia. Our all-round Myopia Management Program includes:
          </p>
          {CMMP_LIST && (
            <ul className="fmc2l__cmmp-list">
              {CMMP_LIST.map(item => (
                <li key={item.title}>
                  <img alt={item.imgAlt} src={item.imgSrc} />
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </li>
              ))}
            </ul>
          )}
          <a className="fmc2l__btn fmc2l__btn--yellow" href="https://www.owndays.com/stores/sg">
            Book an eye test
          </a>
        </div>
      </section>
      <section className="fmc2l__rs">
        <div className="fmc2l__container">
          <div className="fmc2l__rs-card">
            <div className="fmc2l__rs-grid">
              <h2 className="fmc2l__heading">
                <span className="fmc2l__heading-red">R</span>ecommended Styles
              </h2>
              <h3 className="fmc2l__rs-logo">
                Junni <img alt="Junni" src={`${baseImgUrl}/junni.webp`} />
              </h3>
              <picture className="fmc2l__rs-img">
                <source
                  media="(min-width: 768px)"
                  srcSet={`${baseImgUrl}/recommended-junni-pc.webp`}
                />
                <img
                  alt="FREE Myopia Control 2 Lenses"
                  src={`${baseImgUrl}/recommended-junni-sp.webp`}
                />
              </picture>
              <p className="fmc2l__rs-text">
                Fashion is not just for the adults – this is the concept behind Junni, a collection
                created for the children of today, featuring stylish frames even adults would love.
                With an emphasis on perfect frame fitting, Junni frames cater for both everyday use
                and children who are first-time eyewear users. It is a collection that pairs
                functionality with a grown-up aesthetic ideal for the little fashionistas.
              </p>
            </div>
            {RS_PRODUCTS && (
              <ul className="fmc2l__rs-list">
                {RS_PRODUCTS.map(item => (
                  <li key={item.no}>
                    <img alt={item.imgAlt} src={item.imgSrc} />
                    <p>
                      {item.no}
                      <span style={{ backgroundColor: item.colorCode }}></span>
                    </p>
                    <a className="fmc2l__btn fmc2l__btn--blue" href={item.url}>
                      More Details
                    </a>
                  </li>
                ))}
              </ul>
            )}
            <div className="fmc2l__rs-actions">
              <a
                className="fmc2l__btn fmc2l__btn--yellow"
                href="/sg/en/eyeglasses/brands/Junni.html"
              >
                See All Products
              </a>
              <a className="fmc2l__btn fmc2l__btn--yellow" href="https://www.owndays.com/stores/sg">
                Search for OWNDAYS stores
              </a>
            </div>
          </div>
          {RS_NOTES && (
            <ol className="fmc2l__rs-notes">
              {RS_NOTES.map((item, index) => (
                <li dangerouslySetInnerHTML={{ __html: item }} key={index} />
              ))}
            </ol>
          )}
        </div>
      </section>
    </>
  );
};

export default BodyFreeMyopiaControl2Lenses;
