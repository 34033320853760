import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';

import apiClient from '../../../helpers/apiClient';
import { callClevertapEventWithCallback, getProductPrice } from '../../../own-days/helper';
import { addToCart } from '../../../actionCreators/cart';
import { apiPath } from '../../../config';
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';

import { HeaderGiftCardsProducts } from './HeaderGiftCardsProducts';
import { BodyGiftCardsProducts } from './BodyGiftCardsProducts';
import { FooterGiftCardsProducts } from './FooterGiftCardsProducts';

const GiftCardsProducts = ({ history }) => {
  const [giftCardImages, setGiftCardImages] = useState([]);
  const [selectCardDesign, setSelectCardDesign] = useState('');
  const [giftCardCatId, setGiftCardCatId] = useState(null);
  const [message, setMessage] = useState(`Here's a OWNDAYS E-Gift ticket for you.`);
  const [yourName, setYourName] = useState('');
  const [selectedDenomination, setSelectedDenomination] = useState(null);
  const [denominations, setDenominations] = useState([]);
  const [errors, setErrors] = useState({
    yourName: false,
    denomination: false,
  });

  const dispatch = useDispatch();

  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '#', label: 'eGift' },
  ];

  const scrollToSection = id => {
    if (!id) return;

    const elementId = id.startsWith('#') ? id.substring(1) : id;
    const element = document.getElementById(elementId);

    if (element) {
      const headerOffset = document.querySelector('header')?.offsetHeight || 0;
      const elementTop = element.getBoundingClientRect().top + window.scrollY;
      const elementName = !yourName && selectedDenomination ? 100 : 0;

      window.scrollTo({
        top: elementTop - headerOffset - elementName,
        behavior: 'smooth',
      });
    }
  };

  const handleSubmit = () => {
    if (!yourName) {
      scrollToSection('#name');
    }

    if (selectedDenomination === null) {
      scrollToSection('#denomination');
    }

    setErrors({
      yourName: Boolean(!yourName),
      denomination: Boolean(selectedDenomination === null),
    });

    if (yourName && selectedDenomination) {
      dispatch(
        addToCart({
          productId: selectedDenomination.id,
          giftCardDetails: {
            message,
            imageName: selectCardDesign || giftCardImages[0],
            senderName: yourName,
          },
        })
      );

      callClevertapEventWithCallback(
        'Added to Cart',
        {
          productData: {
            id: selectedDenomination.id,
            classification: 'Gift Cards',
            brand: null,
            thumbnailImage: selectCardDesign || giftCardImages[0],
            giftCardPrice: selectedDenomination?.price,
          },
        },
        () => {
          history.push('/cart');
        }
      );
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        window?.location?.href?.includes('preproduction') ||
        window?.location?.href?.includes('localhost')
      ) {
        setGiftCardCatId(91351);
      } else {
        setGiftCardCatId(17131);
      }
    }
  }, []);

  useEffect(() => {
    if (giftCardCatId) {
      const client = apiClient();
      client.setHeader();
      client
        .get(`${apiPath.dataCategory}${giftCardCatId}?page-size=500&include-images=true`)
        .then(res => {
          const giftCards = res?.result?.product_list || [];

          // Set the first gift card's image URLs
          setGiftCardImages(giftCards[0]?.imageUrls || []);

          // Extract denominations and IDs
          const denominationsAndId = giftCards.map(gc => ({
            id: gc?.id,
            price: getProductPrice(gc),
          }));

          // Sort and update state if there's valid data
          if (denominationsAndId.length) {
            setDenominations(denominationsAndId.sort((a, b) => a.price - b.price));
          }
        })
        .catch(err => {
          console.error('Error fetching gift cards:', err);
        });
    }
  }, [giftCardCatId]);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />
      <div className="base-gift-cards-products__layout">
        <div className="base-gift-cards-products__main">
          <HeaderGiftCardsProducts />
          <BodyGiftCardsProducts
            denominations={denominations}
            errors={errors}
            giftCardImages={giftCardImages}
            message={message}
            selectCardDesign={selectCardDesign}
            selectedDenomination={selectedDenomination}
            setErrors={setErrors}
            setMessage={setMessage}
            setSelectCardDesign={setSelectCardDesign}
            setSelectedDenomination={setSelectedDenomination}
            setYourName={setYourName}
            yourName={yourName}
          />
        </div>

        {giftCardImages && (
          <FooterGiftCardsProducts
            handleSubmit={handleSubmit}
            message={message}
            selectCardDesign={selectCardDesign || giftCardImages[0]}
            selectedDenomination={selectedDenomination}
            yourName={yourName}
          />
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(GiftCardsProducts);
