import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getSetCookie from './../../../cookie';
import { provideHooks } from 'redial';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import Header from '../Header/Header';
import { renderRoutes } from 'react-router-config';
import { cleanReturnExchange } from '../../../actionCreators/returnExchange';
import Footer from '../../components/Footer/Footer';
// return if Shortlist popup needed
// import Shortlist from '../../components/Common/Shortlist';
import ToastMessage from '../../../mobile/components/ToastMessage/ToastMessage';
import * as headerCallsActionCreators from './../../../actionCreators/header';
import * as commonCallsActionCreators from './../../../actionCreators/common';
import { showGlobalHeaderFooter } from '../../../actionCreators/header';
import * as homeCallsActionCreators from './../../../actionCreators/home';
import * as checkoutCallsActionCreators from '../../../actionCreators/checkout';
import { getLocaleData } from '../../../actionCreators/locale';
import { getCart } from '../../../actionCreators/cart';
import { getYotpoSiteRatings } from '../../../actionCreators/yotpo';
import ExchangeSessionDesktop from '../../../desktop/components/MyAccount/exchangeSessionDesktop';
import sessionStorageHelper from '../../../utils/sessionStorageHelper';
import { CookiesModal } from '../../../../src/own-days/CommonComponents/CookiesModal/CookiesModal';

import {
  isSearchCrawler,
  isEmptyObject,
  getUserEventData,
  setGAEventListener,
  showZendeskWidget,
} from '../../../utils/helper';
import 'custom-event-polyfill';
import './App.scss';
import AppHelmet from '../../../CommonComponents/Helmet/AppHelmet';
import { langCodeAllowed } from '../../../locales';
import { setCountryData } from '../../../helpers/countryConfig';
import SecondaryHeader from '../../../own-days/views/desktop/SecondaryHeader/SecondaryHeader';
import {
  initializeComponentLogicHelper,
  handleUpdateLogicHelper,
  dtmLoginStatusHelper,
  passingUtmDataHelper,
  provideHooksFetchHelper,
} from './App.helper';
import CommonLoader from '../../components/Common/Loader';
import { PopupNotice } from '../../../own-days/CommonComponents/PopupNotice/PopupNotice';
// OD_NOTE: this is required file for carousel DO NOT REMOVE THIS!
import 'theme/vendor/slick.min.scss';
let countryCode = process.env.COUNTRY;

@provideHooks({
  fetch: async ({ location, reqHeaders, store: { dispatch, getState } }) => {
    if (__SERVER__) {
      countryCode = langCodeAllowed[countryCode.toLowerCase()] ? countryCode.toLowerCase() : 'in';
      dispatch(commonCallsActionCreators.localeInfo(langCodeAllowed[countryCode]));
      setCountryData(langCodeAllowed[countryCode]);
    }
    const state = getState();

    provideHooksFetchHelper(
      location,
      dispatch,
      showGlobalHeaderFooter,
      commonCallsActionCreators,
      state
    );
    const mainNavigationData = state.header.mainNavigationData;
    const redisCommonData = state.common.redisCommonData;
    if (__SERVER__) {
      const uA = reqHeaders['user-agent'];
      const isBot = uA ? isSearchCrawler(uA) : false;
      if (!mainNavigationData || isEmptyObject(mainNavigationData)) {
        await dispatch(headerCallsActionCreators.getMainNav());
      }
      if (!redisCommonData || isEmptyObject(redisCommonData)) {
        await dispatch(commonCallsActionCreators.getRedisData(isBot));
      }
    }
  },
  defer: ({ store: { dispatch, getState } }) => {
    const state = getState();
    const { countryData, localeInfo } = state.common;
    const countryCodeCookie = getSetCookie.getCookie('countryCode');
    const trafficSource = getSetCookie.getCookie('trafficSource');
    countryCode =
      countryCodeCookie && langCodeAllowed.hasOwnProperty(countryCodeCookie.toLowerCase())
        ? countryCodeCookie.toLowerCase()
        : 'in';

    const { showGlobalHeader } = state.header;
    if (!localeInfo) {
      dispatch(commonCallsActionCreators.localeInfo(langCodeAllowed[countryCode]));
    }
    setCountryData(langCodeAllowed[countryCode]);
    // get locale config data
    if (isEmptyObject(state.locale)) {
      dispatch(getLocaleData());
    }
    if (
      countryCodeCookie &&
      trafficSource &&
      countryCodeCookie.toLowerCase() !== trafficSource.toLowerCase() &&
      trafficSource.toLowerCase() !== 'in' &&
      !countryData &&
      showGlobalHeader
    ) {
      dispatch(commonCallsActionCreators.getCountryData(trafficSource));
    }
    if (__SERVER__) {
      dispatch(getYotpoSiteRatings());
    }
  },
})
class App extends Component {
  static propTypes = {
    commonCallsActionCreators: PropTypes.any.isRequired,
    headerCallsActionCreators: PropTypes.any.isRequired,
    homeCallsActionCreators: PropTypes.any.isRequired,
    redisCommonData: PropTypes.any,
    mainNavigationData: PropTypes.any,
    isMenuVisible: PropTypes.bool,
    showGlobalHeader: PropTypes.bool,
    showGlobalSecondaryHeader: PropTypes.bool,
    loginStatus: PropTypes.bool,
    wishlistTrue: PropTypes.bool,
    route: PropTypes.objectOf(PropTypes.any).isRequired,
    showWishlist: PropTypes.func,
    isWishlistCount: PropTypes.number,
    isCartCount: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.checkId = this.checkId.bind(this);
    this.state = {
      notExchangeSession: false,
      isAppLoaded: false,
    };
  }

  async componentDidMount() {
    setGAEventListener();
    const {
      commonCallsActionCreators,
      loginStatus,
      homeCallsActionCreators,
      location,
      redisCommonData,
      localeInfo,
    } = this.props;

    const visitedBefore = JSON.parse(localStorage.getItem('visitedBefore'));

    if (visitedBefore === null) {
      localStorage.setItem('visitedBefore', JSON.stringify(true));
    }

    const lastVisitTime = localStorage.getItem('lastVisitTime');
    const currentTime = new Date().getTime();
    const isNewSession = !lastVisitTime || currentTime - lastVisitTime > 30 * 60 * 1000;

    if (isNewSession) {
      localStorage.setItem('lastVisitTime', currentTime.toString());
    }

    const urlParams = new URLSearchParams(window.location.search);
    const utmParameters = {};
    for (const [key, value] of urlParams) {
      if (key.startsWith('utm_')) {
        utmParameters[key] = value;
      }
    }

    const exchangeMethod = getSetCookie.getCookie('exchangeMethod');
    const returnAction =
      sessionStorageHelper.getItem('returnAction') ||
      sessionStorageHelper.getItem('returnProduct')?.returnAction;
    const returnMode = sessionStorageHelper.getItem('returnProduct')?.returnMode === 'store_return';
    const isExchangeSessionValid = returnAction || returnMode;

    if (exchangeMethod && !isExchangeSessionValid) {
      this.setState({ notExchangeSession: true });
    }

    const { facebookPixel } = localeInfo;
    let path =
      location && location.pathname.length > 1
        ? location.pathname.slice(1).replace(/(\/+)$/g, '')
        : '/';
    path = path !== '' ? path : '/';

    this.setState({ isAppLoaded: false });

    try {
      await initializeComponentLogicHelper({
        location: this.props.location,
        path,
        homeCallsActionCreators,
        facebookPixel,
        loginStatus,
        commonCallsActionCreators,
      });

      commonCallsActionCreators.getAudienceType();

      if (redisCommonData?.ENABLE_FRESHWORKS_DESKTOP === 'OFF') {
        setTimeout(() => {
          const freshwidget = document.getElementById('freshworks-container');
          if (freshwidget) {
            freshwidget.style.display = 'none';
          }
        }, 0);
      }
    } catch (error) {
      console.error('Error during component initialization:', error);
    } finally {
      this.setState({ isAppLoaded: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loginStatus,
      userInfo,
      isValidSession,
      commonCallsActionCreators,
      addToCartFailed,
      location,
    } = this.props;

    handleUpdateLogicHelper(
      location,
      prevProps,
      isValidSession,
      commonCallsActionCreators,
      this.props.isWishlistCount
    );

    // passingUtmData
    passingUtmDataHelper(
      this.props.location,
      userInfo,
      prevProps,
      loginStatus,
      this.props.getCart,
      this.passingUtmData,
      this.props.commonCallsActionCreators
    );

    if (
      userInfo &&
      prevProps.userInfo !== userInfo &&
      prevProps.userInfo?.result?.id !== userInfo?.result?.id
    ) {
      getSetCookie.setCookie('customerid', userInfo.result.id, 30);
    }

    if (loginStatus !== prevProps.loginStatus) {
      if (loginStatus && userInfo) {
        const {
          result: { phoneCode, telephone, firstName, gender, email },
        } = userInfo;

        localStorage.setItem(
          'user-info',
          JSON.stringify({
            name: firstName,
            email,
            phone: phoneCode && telephone ? `${phoneCode?.substr(1)}${telephone}` : '',
            gender,
          })
        );
      } else {
        localStorage.removeItem('user-info');
      }
    }

    // update DTM login status
    dtmLoginStatusHelper(loginStatus, userInfo, prevProps);

    if (
      prevProps.addToCartFailed === null &&
      addToCartFailed !== null &&
      addToCartFailed.status === 422 &&
      location.pathname !== '/HTO'
    ) {
      if (addToCartFailed.message?.indexOf('out of stock') !== -1) {
        this.props?.setToastMessage({
          message: 'Product is out of stock!',
          timeout: 5000,
          warning: true,
        });
      } else {
        this.props?.setToastMessage({
          message: addToCartFailed?.message,
          timeout: 5000,
          warning: true,
        });
      }
      if (location.pathname === '/cart') return;
      return window.location.reload();
    }
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.sessionStorage.setItem('previousChannel', window.dtm.channel);
    }
  }

  passingUtmData(eventName, phoneCode, telephone, commonCallsActionCreators) {
    const data = getUserEventData(eventName);
    if (phoneCode && telephone) {
      data.mobileNumber = `${phoneCode}${telephone}`;
    }
    commonCallsActionCreators.passUtmData(data);
  }

  checkId() {
    if (this.props.shortListData.result.productList !== null) {
      this.props.shortListData.result.productList.forEach(value => {
        if (this.props.productPageId === value.id) {
          this.props.toggleWishlist(true);
        }
      });
    } else if (this.props.shortListData.result.productList.length === 0) {
      this.props.toggleWishlist(false);
    }
  }

  loadZendeskChatScript = () => {
    // Create a <script> element
    const script = document.createElement('script');
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=85b011ea-d0e4-463a-b1ee-d7e54f7e2a0f';
    script.async = true;
    script.id = 'ze-snippet';

    // Append the <script> element to the document body
    document.body.appendChild(script);
  };

  toggleChatWidget() {
    if (typeof window !== 'undefined' && window?.$zopim?.livechat) {
      window.$zopim.livechat.window.show();
    } else if (showZendeskWidget(window?.location?.href)) {
      this.loadZendeskChatScript();
      setTimeout(() => {
        window.$zopim?.livechat?.window?.show();
      }, 1000);
    }
    // Toggle the visibility by toggling the CSS class
    // const chatWidget = document.querySelector('.chat-widget');
    // chatWidget.classList.toggle('visible');
  }

  render() {
    const {
      mainNavigationData,
      section,
      loginStatus,
      route,
      isFilterEnable,
      showGlobalHeader,
      showGlobalFooter,
      location,
      countryData,
      dataLocale,
      cleanReturnExchange,
      localeInfo,
      utmMapping,
      showGlobalSecondaryHeader,
    } = this.props;
    const { notExchangeSession, isAppLoaded } = this.state;

    const trafficSource = getSetCookie.getCookie('trafficSource');

    if (!isAppLoaded) {
      return <CommonLoader />;
    }

    return (
      <React.Fragment>
        <AppHelmet
          localeInfo={localeInfo}
          location={location}
          pathname={location.pathname}
          section={section}
        ></AppHelmet>
        <div className={isFilterEnable ? 'vertical-filter' : ''}>
          {notExchangeSession && (
            <ExchangeSessionDesktop
              cleanReturnExchange={cleanReturnExchange}
              dataLocale={dataLocale}
            />
          )}
          {/* redisLoading ? <div style={{ zIndex: '99999', left: '0', width: '100%', position: 'fixed', top: '0' }}><Pace height={4} color="orange" /></div> : null */}
          {showGlobalHeader && <Header {...this.props} />}
          {showGlobalSecondaryHeader && <SecondaryHeader {...this.props} />}
          <ToastMessage />
          {/* routes setup */}
          {renderRoutes(route.routes)}
          {showGlobalFooter && (
            <Footer
              location={location}
              loginStatus={loginStatus}
              mainNavigationData={mainNavigationData}
              section={section}
            />
          )}
          <CookiesModal />
          <PopupNotice />
          {showZendeskWidget(window?.location?.href) ? (
            <div id="zendesk-chat-widget" onClick={() => this.toggleChatWidget()}>
              {' '}
              <img
                alt="zendesk"
                className="zendesk-chat-desktop-img"
                src="https://static.lenskart.com/media/owndays/desktop/img/message-chat-zendesk.png"
              />{' '}
              Need help?
            </div>
          ) : null}
          {/* return if Shortlist popup needed 
          <Shortlist checkId={this.checkId} {...this.props} /> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  utmMapping: state.common.utmMapping,
  loginStatus: state.common.login,
  wishlistTrue: state.common.wishlistTrue,
  mainNavigationData: state.header.mainNavigationData,
  showGlobalHeader: state.header.showGlobalHeader,
  showGlobalSecondaryHeader: state.header.showGlobalSecondaryHeader,
  showGlobalFooter: state.header.showGlobalFooter,
  isMenuVisible: state.header.isMenuVisible,
  userInfo: state.common.userInfo,
  loginLoader: state.common.loginLoader,
  shortListData: state.common.shortListData,
  cartData: state.cart.cartData,
  redisLoading: state.common.redisLoading,
  countryData: state.common.countryData,
  isFilterEnable: state.category.isFilterEnable,
  router: state.router,
  isWishlistCount: state.common.isWishlistCount,
  isCartCount: state.common.isCartCount,
  isSupported: state.common.isSupported,
  checkBrowser: state.common.checkBrowser,
  section: state.common.section,
  isValidSession: state.common.errorCode,
  addToCartFailed: state.cart.addToCartFailed,
  categoryData: state.category.categoryData,
  productData: state.product.productData,
  dataLocale: state.locale,
  localeInfo: state.common.localeInfo,
  platform: state.DTM.platform,
});

function mapDispatchToProps(dispatch) {
  return {
    commonCallsActionCreators: bindActionCreators(commonCallsActionCreators, dispatch),
    headerCallsActionCreators: bindActionCreators(headerCallsActionCreators, dispatch),
    checkoutCallsActionCreators: bindActionCreators(checkoutCallsActionCreators, dispatch),
    homeCallsActionCreators: bindActionCreators(homeCallsActionCreators, dispatch),
    getCart: data => dispatch(getCart(data)),
    setToastMessage: data => dispatch(commonCallsActionCreators.setToastMessage(data)),
    cleanReturnExchange: () => dispatch(cleanReturnExchange()),
  };
}

App.defaultProps = {
  loginStatus: false,
  wishlistTrue: false,
  mainNavigationData: {},
  showGlobalHeader: true,
  isMenuVisible: true,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
