export const STAFF_CONTENTS = [
  {
    title: 'As an Eyewear Concierge',
    description: `We recommend the best pair of glasses based on our customers' fashion sense and
      lifestyle. To this end, we listen to the wishes of individual customers and recommend
      the optimum frames, lenses approach to usage and more.\nAs professionals in customer service, fashion coordination and eyewear fields, we
      provide our customers with reassurance, security and high quality service.`,
  },
  {
    title: 'Providing High Quality Service to Customers',
    description: `OWNDAYS has established an internal qualification system called "eyewear processing and
      testing practitioner", where we conduct internal training and qualification tests to
      evaluate and assess each staff member using a resultant "grade" so that we can provide
      better services to our customers.`,
  },
  {
    title: 'Eyewear Processing and Testing Engineer',
    description: `Confidently providing highly skilled progressive lens processing. Without sacrificing
      product quality, we can provide your glasses as fast as 20 minutes and replace the
      lenses of frames from another shop.`,
    table: [
      {
        grade: '3rd GRADE',
        imgSrc: 'https://static1.lenskart.com/owndays/img/services/staff/icon-3rd.webp',
        description: `This grade is given to the staff members who have gained required knowledge in
          relation to eyewear and exhibit great thoughtfulness as well as good manners
          when serving customers.`,
      },
      {
        grade: '2nd GRADE',
        imgSrc: 'https://static1.lenskart.com/owndays/img/services/staff/icon-2nd.webp',
        description: `In addition to the 3rd grade, holders of the 2nd grade are shop manager level
          personnel who have acquired high level practical skills such as lens
          processing and eye examination skills. Holders of the 3rd grade need to
          accumulate three months or more of experience before they sit the test for the
          2nd grade.`,
      },
      {
        grade: '1st GRADE',
        imgSrc: 'https://static1.lenskart.com/owndays/img/services/staff/icon-1st.webp',
        description: `This is a high level grade for those who possess specialist knowledge and
          skills related to eyewear and can support staff training and education.
          Holders of the 2nd grade need to have accumulated six or more months of
          experience before allowed to take the test for the 1st grade.`,
      },
      {
        grade: 'SPECIAL GRADE',
        imgSrc: 'https://static1.lenskart.com/owndays/img/services/staff/icon-special.webp',
        description: `This is the highest grade set for an eyewear processing and testing engineer.
          Holders of the 1st grade need to have three-year or more experience before
          they sit the test for the special grade.`,
      },
    ],
  },
  {
    title: 'Floor staff certification',
    description: `This is a mandatory qualification for our floor staff that came about to increase not
      only eyewear-related skills and knowledge but also customer service skills in order to
      provide reassurance to our customers when they make a purchase. When staff members
      obtain this certification, they are given an original pin badge to wear. You can see it
      shining alongside the name plate when a staff member greets you in one of our shops.`,
    table: [
      {
        grade: 'STAFF',
        imgSrc: 'https://static1.lenskart.com/owndays/img/services/staff/icon-floor.webp',
        description: `These staff members have a full understanding of the details of our services,
          such as knowledge of products and prices, and are able to provide customers
          with a reassuring level of service. To obtain certification, candidates must
          pass both a written and a practical examination.`,
      },
    ],
  },
];
