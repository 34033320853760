import React from 'react';
import StaffSection from '../views/services/Staff/StaffSection';
import Breadcrumbs from '../CommonComponents/Breadcrumbs/Breadcrumbs';
import Header from '../CommonComponents/Services/Header/Header';
import Navigation from '../CommonComponents/Services/Navigation/Navigation';
import '../views/services/Top/BaseService.scss';
import {
  getBreadcrumbItems,
  getCurrentPath,
} from '../CommonComponents/Breadcrumbs/breadcrumbUtils';

const ServicesStaffHTML = () => {
  const crumbs = getBreadcrumbItems([{ path: '/sg/en/services/staff', label: 'Staff' }]);
  const currentPath = getCurrentPath('/staff');

  const headerContent = {
    title: 'STAFF',
    description: 'Multilingual support with the most professional skills and knowledge',
    bgSp: 'https://static.lenskart.com/owndays/img/services/staff/main-1534x1023.webp',
    bgPc: 'https://static.lenskart.com/owndays/img/services/staff/main-1536x480.webp',
    bgAlt: 'STAFF',
    align: 'center',
    theme: 'dark',
    id: 'staff',
  };

  return (
    <main className="service_l">
      <Breadcrumbs crumbs={crumbs} />
      <div className="service_l__section">
        <Navigation currentPath={currentPath} size="desktop" />
        <Header headerContent={headerContent} />
        <div className="service_l__container">
          <StaffSection />
        </div>
        <div className="service_l__container">
          <Navigation currentPath={currentPath} size="mobile" />
        </div>
      </div>
    </main>
  );
};

export default ServicesStaffHTML;
