import React, { Fragment, useEffect, useState } from 'react';

import Breadcrumbs from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';

import './HeaderSun.scss';

export const HeaderSun = ({ baseImgUrl }) => {
  const [poster, setPoster] = useState(`${baseImgUrl}/about-video-poster_pc.webp`);

  const breadcrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'OWNDAYS | SUN' },
  ];

  const links = [
    { href: '#sunglasses', label: 'SUNGLASSES' },
    { href: '#snap-sunglasses', label: 'SNAP SUNGLASSES' },
    { href: '#clear-sunglasses', label: 'CLEAR SUNGLASSES' },
  ];

  const scrollToSection = id => {
    if (!id) return;

    const elementId = id.startsWith('#') ? id.substring(1) : id;
    const element = document.getElementById(elementId);

    window.location.hash = elementId;

    if (element) {
      const headerOffset = document.querySelector('header')?.offsetHeight || 0;
      const elementTop = element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementTop - headerOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scrollToSection(window.location.hash);
      }, 0);
    }
  }, []);

  useEffect(() => {
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  useEffect(() => {
    const updatePoster = () => {
      const isMobile = window.innerWidth <= 430;
      const newPoster = isMobile
        ? `${baseImgUrl}/about-video-poster_sp.webp`
        : `${baseImgUrl}/about-video-poster_pc.webp`;
      setPoster(newPoster);
    };

    updatePoster();

    window.addEventListener('resize', updatePoster);

    return () => window.removeEventListener('resize', updatePoster);
  }, [baseImgUrl]);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-sun__main">
        <div className="header-sun__main__bg">
          <picture>
            <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/main_pc.webp`} />
            <img alt="OWNDAYS | SUN" loading="lazy" src={`${baseImgUrl}/main_sp.webp`} />
          </picture>
        </div>
        <div className="header-sun__main__text">
          <div className="base-sun__container">
            <h1>
              <img alt="OWNDAYS | SUN" loading="lazy" src={`${baseImgUrl}/sun_logo.svg`} />
            </h1>
            <h2>
              Heart’s Leaping, <br />
              Under The Sun
            </h2>
          </div>
        </div>
      </section>

      <section className="header-sun__about">
        <div className="header-sun__about__bg">
          <video autoPlay loop muted playsInline poster={poster} width="100%">
            <source src={`${baseImgUrl}/video-pc.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="header-sun__about__text">
          <div className="base-sun__container">
            <p>
              Where fashion meets function. Coming in an array of vibrant designs, these sunglasses
              not only reduce glare and protect your eyes from UV rays but also enable you to see
              the world more vividly. With SUN by your side, every moment becomes extra special.
            </p>
          </div>
        </div>

        <a
          className="header-sun__about__play"
          data-fancybox="etc"
          href="https://youtu.be/79ni2iH3GIk"
        >
          Play
        </a>
      </section>

      <section className="header-sun__check">
        <div className="base-sun__container">
          <div className="header-sun__check__inner">
            <div className="header-sun__check__ribbon">
              <i className="header-sun__check-bg-svg">
                <svg
                  fill="none"
                  height="40"
                  viewBox="0 0 98 40"
                  width="98"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M90.416 4.865c0-5.06 6.584-5.246 6.584 0v2.04"
                    stroke="#F7F6F2"
                    strokeLinecap="square"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                  <path
                    d="M90.416 5.18V39l-44.708-6.467L1 39V5.18c0-2.943 1.648-4.144 3.297-4.163h88.427"
                    fill="#F7F6F2"
                  ></path>
                  <path
                    d="M90.416 5.18V39l-44.708-6.467L1 39V5.18c0-2.943 1.648-4.144 3.297-4.163h88.427"
                    stroke="#F7F6F2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  ></path>
                </svg>
              </i>
              <span className="header-sun__check__ribbon-text">CHECK!</span>
            </div>

            <h2 className="header-sun__check__title">
              Is it true that UV rays entering your <br />
              eyes can cause suntan?
            </h2>
            <p>
              When UV rays enter the eyes, the brain sends a signal to the body to produce melanin
              which causes the skin to turn dark. Your sunscreen products will therefore be less
              effective if your eyes are left without UV protection. Don’t forget to safeguard your{' '}
              eyes against UV rays too!
            </p>
          </div>
        </div>
      </section>

      <section className="header-sun__navs">
        <div className="base-sun__container">
          <ul>
            {links.map(({ href, label }) => (
              <li key={href} onClick={() => scrollToSection(href)}>
                <a>{label}</a>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
