import React, { useEffect, useState } from 'react';
import './PopupNotice.scss';
import { POPUP_MESSAGES } from './constants';

export const PopupNotice = () => {
  const TARGET_PATH = '/sg/en';
  const EXPIRATION_DATE = new Date('2025-03-26T16:00:00Z');

  const isCorrectPath = window.location.pathname === TARGET_PATH;
  const isBeforeExpirationDate = new Date() < EXPIRATION_DATE;

  const [isVisible, setIsVisible] = useState(isCorrectPath && isBeforeExpirationDate);

  useEffect(() => {
    const updateVisibilityBasedOnRoute = () => {
      setIsVisible(window.location.pathname === TARGET_PATH && isBeforeExpirationDate);
    };

    window.addEventListener('popstate', updateVisibilityBasedOnRoute);
    return () => {
      window.removeEventListener('popstate', updateVisibilityBasedOnRoute);
    };
  }, [isBeforeExpirationDate]);

  const handleMoreDetails = () => {
    setIsVisible(false);
    window.location.href = '/sg/en/information/5003';
  };

  if (!isVisible) return null;

  return (
    <div className="popup">
      <div className="popup__overlay" onClick={() => setIsVisible(false)}>
        <div className="popup__content" onClick={e => e.stopPropagation()}>
          <div className="popup__message-wrapper">
            <button className="popup__close" onClick={() => setIsVisible(false)}></button>
            {POPUP_MESSAGES.map((message, index) => (
              <p key={index} className="popup__message">
                {message}
              </p>
            ))}
          </div>
          <div className="popup__button-wrapper">
            <button className="popup__button" onClick={handleMoreDetails}>
              MORE DETAILS
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
