import React, { useEffect } from 'react';
import AOS from 'aos';

import { HeaderAir, BodyAir, FooterAir } from '../CommonComponents/Air';

import '../CommonComponents/Air/BaseAir.scss';
import { odPushClevertapEvent } from '../../utils/clevertap';

const AirHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/air';

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'OWNDAYS | AIR',
      pageUrl: window.location.href,
    });

    const config = {
      kitId: 'nxu3avl',
      scriptTimeout: 3000,
      async: true,
    };

    const h = document.documentElement;
    const t = setTimeout(() => {
      const currentClass = h.className;
      const updatedClass = currentClass.replace(/\bwf-loading\b/g, '') + ' wf-inactive';
      h.className = updatedClass;
    }, config.scriptTimeout);

    const tk = document.createElement('script');
    let f = false;
    const s = document.getElementsByTagName('script')[0];
    let a;

    h.className += ' wf-loading';

    tk.src = `https://use.typekit.net/${config.kitId}.js`;
    tk.async = true;

    const onLoadHandler = function () {
      a = this.readyState;
      if (f || (a && a !== 'complete' && a !== 'loaded')) return;
      f = true;
      clearTimeout(t);

      // Check if Typekit is available on the window object
      if (window.Typekit) {
        try {
          window.Typekit.load(config);
        } catch (e) {
          console.error('Typekit loading error: ', e);
        }
      } else {
        console.error('Typekit is not defined');
      }
    };

    tk.onload = onLoadHandler;
    tk.onreadystatechange = onLoadHandler;

    s.parentNode.insertBefore(tk, s);

    // Cleanup function to remove the script if the component unmounts
    return () => {
      clearTimeout(t);
      s.parentNode.removeChild(tk);
    };
  }, []);

  return (
    <div className="base-air base-air__scroll-hidden">
      <HeaderAir baseImgUrl={baseImgUrl} />
      <BodyAir baseImgUrl={baseImgUrl} />
      <FooterAir baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default AirHTML;
