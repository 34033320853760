import React from 'react';

import { ABOUT_LIST, RECRUIT_LIST } from './constants';
import './BodyContactUs.scss';

export const BodyContactUs = ({ baseImgUrl }) => {
  return (
    <div className="body-contact-us__container">
      <section className="body-contact-us__about" id="section_products">
        <h2><span><img alt="About Our Products and Services" src={`${baseImgUrl}/contact_products.svg`} /></span>About Our Products and Services</h2>

        <ul>
          {ABOUT_LIST.map(({ link, name }) => (
            <li key={name}>
              <a
                href={link}
                rel={name !== 'Warranty & FAQs' ? 'noreferrer' : undefined}
                target={name !== 'Warranty & FAQs' ? '_blank' : undefined}
              >
                {name}
              </a>
            </li>
          ))}
        </ul>
      </section>

      <section className="body-contact-us__recruit" id="section_recruit">
        <h2><span><img alt="Recruitment and Employment-Related Enquiries" src={`${baseImgUrl}/contact_recruit.svg`} /></span>Recruitment and Employment-Related Enquiries</h2>

        <ul>
          {RECRUIT_LIST.map(({ title, desc }) => (
            <li key={title}>
              <h4>{title}</h4>
              <p>{desc}</p>
            </li>
          ))}
        </ul>

        <div className="body-contact-us__recruit__link">
          <a href="https://www.odsaiyou.com/sg" rel="noreferrer" target="_blank">
            Recruitment Site
          </a>
        </div>
      </section>
    </div>
  );
};
