export const SERVICE_CONTENTS = [
  {
    imageSrcSet: 'https://www.owndays.com/images/services/top/thumb-price-1536x800.webp',
    imageSrc: 'https://static.lenskart.com/owndays/img/services/top/thumb-price.webp',
    alt: 'Price',
    title: 'Simple Price',
    subtitle: 'No additional charge for standard lenses of any power',
    description:
      'In OWNDAYS, we pride ourselves in our simple price system. All you need to pay for a pair of spectacles is the price indicated on the frame. There is no additional charge for standard high index aspheric lenses of any power.',
    link: '/sg/en/services/price',
    buttonText: 'VIEW MORE',
    price: 'From S$98〜',
  },
  {
    imageSrcSet: 'https://www.owndays.com/images/services/top/thumb-lens-1536x800.webp',
    imageSrc: 'https://static.lenskart.com/owndays/img/services/top/thumb-lens.webp',
    alt: 'Lens',
    title: 'Lens',
    subtitle:
      'Our standard lenses are high quality high index aspheric lenses that cause less visual distortion',
    description:
      'The lenses we use in OWNDAYS are high index aspheric lenses manufactured by top international lens makers.',
    link: '/sg/en/services/lens',
    buttonText: 'VIEW MORE',
  },
  {
    imageSrcSet: 'https://www.owndays.com/images/services/top/sg/thumb-speed-1536x800.webp',
    imageSrc: 'https://static.lenskart.com/owndays/img/services/top/thumb-speed.webp',
    alt: 'Speed',
    title: '20-Minutes Quick Processing',
    subtitle: 'Glasses ready in only 20 minutes after purchase',
    description:
      'We know your time is valuable and that’s why we aim to deliver your glasses to you in as little time as possible. Backed by solid knowledge and skills, we are able to process your glasses within 20 minutes upon payment.',
  },
  {
    imageSrcSet: 'https://www.owndays.com/images/services/top/thumb-lens-replacement-1536x800.webp',
    imageSrc: 'https://static.lenskart.com/owndays/img/services/top/thumb-lens-replacement.webp',
    alt: 'Lens Replacement',
    title: 'Lens Replacement',
    subtitle: 'Replace lenses of spectacles bought from other shops at simple price!',
    description:
      'Let us help you breathe new life into the old spectacles that you cherish by replacing the lenses. We are happy to do so even for spectacles purchased from other shops.',
    link: '/sg/en/services/lens-replacement',
    buttonText: 'VIEW MORE',
    price: 'From S$98〜',
  },
  {
    imageSrcSet: 'https://www.owndays.com/images/services/top/thumb-frame-1536x800.webp',
    imageSrc: 'https://static.lenskart.com/owndays/img/services/top/thumb-frame.webp',
    alt: 'Frame',
    title: 'Frame',
    subtitle: 'Designed and manufactured in-house',
    description: 'Every pair of OWNDAYS spectacles is carefully assembled by hand.',
  },
  {
    imageSrcSet: 'https://www.owndays.com/images/services/top/thumb-warranty-1536x800.webp',
    imageSrc: 'https://static.lenskart.com/owndays/img/services/top/thumb-warranty.webp',
    alt: 'Warranty',
    title: 'Customer Guarantees',
    subtitle: 'Offering you the most comprehensive after-sales service in the optical industry',
    description:
      'We endeavour to provide a consistent level of after-sales support across all our stores even as we continue to expand our retail network around the world.',
    link: '/sg/en/services/warranty',
    buttonText: 'VIEW MORE',
  },
  {
    imageSrcSet: 'https://www.owndays.com/images/services/top/thumb-staff-1536x800.webp',
    imageSrc: 'https://static.lenskart.com/owndays/img/services/top/thumb-staff.webp',
    alt: 'Staff',
    title: 'Staff',
    subtitle: 'Multilingual support with the most professional skills and knowledge',
    description:
      'We listen to your individual needs in order to help you find the right eyewear that complements your personality and style while meeting your daily needs!',
    link: '/sg/en/services/staff',
    buttonText: 'VIEW MORE',
  },
];
