import React from 'react';
import { MAIN_LIST } from './constants.js';

const HeaderFreeMyopiaControl2Lenses = ({ baseImgUrl, baseImgUrl2 }) => {
  return (
    <section className="fmc2l__main">
      <img alt="cloud" className="fmc2l__cloud fmc2l__cloud--1" src={`${baseImgUrl}/cloud.svg`} />
      <img alt="cloud" className="fmc2l__cloud fmc2l__cloud--2" src={`${baseImgUrl}/cloud.svg`} />
      <div className="fmc2l__main-img">
        <picture>
          <source media="(min-width: 768px)" srcSet={`${baseImgUrl2}/MCL-Main-PC.webp`} />
          <img alt="FREE Myopia Control 2 Lenses" src={`${baseImgUrl2}/MCL-Main-SP.webp`} />
        </picture>
      </div>
      <div className="fmc2l__main-content">
        <div className="fmc2l__container">
          <h1 className="fmc2l__main-heading">
            <img alt="FREE" src={`${baseImgUrl}/free.svg`} />
            <span>Myopia Control 2 Lenses</span>
          </h1>
          {MAIN_LIST && (
            <ul className="fmc2l__main-list">
              {MAIN_LIST.map(item => (
                <li key={item.text}>
                  <img alt={item.text} src={item.iconPath} />
                  <span dangerouslySetInnerHTML={{ __html: item.text }} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeaderFreeMyopiaControl2Lenses;
