import React, { useState, useEffect } from 'react';
import { LENS_CATEGORIES, LENS_LIST } from '../../../../../own-days/CommonComponents/Services/constants/lens';
import './LensCategories.scss';

const LensCategories = () => {
  // State to keep track of the active filter, default is "ALL"
  const [activeFilter, setActiveFilter] = useState('ALL');

  // Function to handle filter click
  const handleFilterClick = category => {
    setActiveFilter(category);
  };

  // Function to filter content based on the active filter
  const filteredContent = LENS_LIST.filter(content => {
    if (activeFilter === 'ALL') {
      return true;
    }
    return content.categories.includes(activeFilter);
  });

  const scrollToContent = id => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 100, // Adjust scroll position by header height
        behavior: 'smooth',
      });
      window.location.hash = `#${id}`;
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        scrollToContent(hash.replace('#', ''));
      }
    };

    handleHashChange();
  }, [window.location.hash]);

  return (
    <div className="services__lens-filter">
      <ul className="services__lens-filter-list">
        {LENS_CATEGORIES.map(category => (
          <li
            key={category}
            className={activeFilter === category ? 'active' : ''}
            onClick={() => handleFilterClick(category)}
          >
            {category}
          </li>
        ))}
      </ul>
      <div className="services__lens-filter-content">
        {filteredContent.length > 0 ? (
          <ul>
            {filteredContent.map((content, index) => (
              <li key={index} onClick={() => scrollToContent(content.id)}>
                <img className="services__lens-filter-icon" src={content.imgPath} />
                <div className="services__lens-filter-details">
                  <span className={content.tag.toLowerCase()}>{content.tag}</span>
                  <div className="services__lens-filter-title">
                    <h4 dangerouslySetInnerHTML={{ __html: content.type }} />
                    <p>
                      {content.price}{' '}
                      {content.typeAdditional && <small>{content.typeAdditional}</small>}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No content available for this category.</p>
        )}
      </div>
    </div>
  );
};

export default LensCategories;
