import React, { useState, useEffect, Fragment } from 'react';
import { BRANDS_LIST_FILTER } from './brands';
import Item from './Item';
import ItemDetails from './ItemDetails';

const List = ({ openCard, setOpenCard, selectedBrand, setSelectedBrand }) => {
  const [brandIndex, setBrandIndex] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [itemsPerRow, setItemsPerRow] = useState(2);

  const renderBrandItems = () => {
    const filteredBrands = BRANDS_LIST_FILTER.filter(brand => brand.show);
    return filteredBrands.map((brand, index) => (
      <Fragment key={index}>
        <Item
          brand={brand}
          openCard={openCard}
          setOpenCard={setOpenCard}
          setSelectedBrand={setSelectedBrand}
          brandIndex={index}
          setBrandIndex={setBrandIndex}
        />
        {((index + 1) % itemsPerRow === 0 || index === filteredBrands.length - 1) && (
          <>
            {brandIndex !== null &&
              brandIndex > index - itemsPerRow &&
              brandIndex <= index &&
              Math.floor(brandIndex / itemsPerRow) === Math.floor(index / itemsPerRow) && (
                <ItemDetails
                  isVisible={isVisible}
                  selectedBrand={selectedBrand}
                  brandIndex={brandIndex}
                  setBrandIndex={setBrandIndex}
                  setOpenCard={setOpenCard}
                />
              )}
          </>
        )}
      </Fragment>
    ));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setItemsPerRow(4);
      } else if (window.innerWidth >= 768) {
        setItemsPerRow(3);
      } else {
        setItemsPerRow(2);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (brandIndex !== null) {
      setIsVisible(true);
      requestAnimationFrame(() => {
        const detailsElement = document.querySelector('.brands__details.is-visible');
        if (detailsElement) {
          const offset = window.innerWidth >= 768 ? 390 : 280;
          const elementPosition = detailsElement.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      });
    } else {
      setIsVisible(false);
    }
  }, [brandIndex]);

  return (
    <>
      <ul className="brands__list">{renderBrandItems()}</ul>
    </>
  );
};

export default List;
