import App from '../../src/desktop/containers/App/App.js';
import Home from './../desktop/containers/Home/Loadable';
import RedisMapping from '../RedisMappingLoadable';
import Checkout from '../desktop/components/Checkout/Loadable';
import MyAccount from './../desktop/containers/MyAccount/Loadable';
import ForgotPassword from './../desktop/containers/ForgotPassword/Loadable';
import ResetPassword from './../desktop/containers/ResetPassword/Loadable';
import HTO from '../own-days/views/desktop/HTO/HTO';
import Membership from '../own-days/views/desktop/Membership/Membership';
import CartLoadable from '../desktop/components/MainNav/NewCart/CartLoadable';
import Sharktank from '../CommonComponents/Sharktank/Sharktank';
import UploadPrescription from '../own-days/views/desktop/UploadPrescription/UploadPrescription';
import PaymentPage from '../own-days/views/desktop/PaymentPage/PaymentPage';
import PaymentSuccess from '../own-days/views/desktop/PaymentSuccess/PaymentSuccess';
import CorporateInformationPage from '../own-days/views/desktop/CorporateInformationPage/CorporateInformationPage';
import ServicesPage from '../own-days/views/desktop/ServicesPage/ServicesPage';
import DontKnowPowerPage from '../own-days/views/desktop/DontKnowPowerPage/DontKnowPowerPage';
import FaqPage from '../own-days/views/desktop/FaqPage/FaqPage';
import CarePage from '../own-days/views/desktop/CarePage/CarePage';
import PrescriptionPage from '../own-days/views/desktop/PrescriptionPage/PrescriptionPage';
import AboutReferral from '../own-days/views/desktop/AboutReferral/AboutReferral';
import WishlistPage from '../own-days/views/desktop/WishlistPage/WishlistPage';
import OdRetryPayment from '../own-days/views/desktop/RetryPayment/OdRetryPayment';
import AddPrescription from '../own-days/views/desktop/AddPrescription/AddPrescription';
import BundleSaleHTML from '../own-days/html/BundleSaleHTML';
import StoresHTML from '../own-days/html/StoresHTML';
import MembershipTermsHTML from '../own-days/html/MembershipTermsHTML';
import GiftTicketHTML from '../own-days/html/GiftTicketHTML.jsx';
import GundamHTML from '../own-days/html/GundamHTML';
import GundamOnlineOrderHTML from '../own-days/html/GundamOnlineOrderHTML';
import GundamAnniversaryHTML from '../own-days/html/GundamAnniversaryHTML';
import Brands from '../own-days/views/Brands/Brands.jsx';
import WhatsAppAccountHTML from '../own-days/html/WhatsAppAccountHTML';
import FacebookAccountHTML from '../own-days/html/FacebookAccountHTML';
import HowToUseHTML from '../own-days/html/HowToUseHTML';
import WearGundamHTML from '../own-days/html/WearGundamHTML';
import MimolletCollectionHTML from '../own-days/html/MimolletCollectionHTML';
import OwndaysMeetsJunHaganHTML from '../own-days/html/OwndaysMeetsJunHaganHTML';
import OwndaysMeetsYoheiShinomiyaHTML from '../own-days/html/OwndaysMeetsYoheiShinomiyaHTML';
import OwndaysMeetsAkiyoNohuchiHTML from '../own-days/html/OwndaysMeetsAkiyoNohuchiHTML';
import OwndaysMeetsTakanoriGomiHTML from '../own-days/html/OwndaysMeetsTakanoriGomiHTML';
import OwndaysMeetsRyuKoshinoHTML from '../own-days/html/OwndaysMeetsRyuKoshinoHTML';
import OwndaysMeetsKeitaSuzukiHTML from '../own-days/html/OwndaysMeetsKeitaSuzukiHTML';
import OwndaysMeetsShuzoNagumoHTML from '../own-days/html/OwndaysMeetsShuzoNagumoHTML';
import OwndaysMeetsHitoshiUedaHTML from '../own-days/html/OwndaysMeetsHitoshiUedaHTML';
import OwndaysMeetsChocomooHTML from '../own-days/html/OwndaysMeetsChocomooHTML';
import OwndaysMeetsKenshiHirokaneHTML from '../own-days/html/OwndaysMeetsKenshiHirokaneHTML';
import OwndaysMeetsSabuHTML from '../own-days/html/OwndaysMeetsSabuHTML';
import OwndaysMeetsMikeHavenaarHTML from '../own-days/html/OwndaysMeetsMikeHavenaarHTML';
import OwndaysMeetsDawnYeohHTML from '../own-days/html/OwndaysMeetsDawnYeohHTML';
import OwndaysMeetsYuniHadiHTML from '../own-days/html/OwndaysMeetsYuniHadiHTML';
import OwndaysMeetsHamTranHTML from '../own-days/html/OwndaysMeetsHamTranHTML';
import OwndaysMeetsFujiwaraKatsuakiHTML from '../own-days/html/OwndaysMeetsFujiwaraKatsuakiHTML';
import SystemRequirementHTML from '../own-days/html/SystemRequirementHTML';
import ServicesHTML from '../own-days/html/ServicesHTML';
import ServicesWarrantyHTML from '../own-days/html/ServicesWarrantyHTML';
import LensGuideHTML from '../own-days/html/LensGuideHTML';
import LensReplacementHTML from '../own-days/html/LensReplacementHTML';
import ServicesOnlineStoreHTML from '../own-days/html/ServicesOnlineStoreHTML';
import RetailPriceHTML from '../own-days/html/RetailPriceHTML';
import ServicesStaffHTML from '../own-days/html/ServicesStaffHTML';
import ServicesCareHTML from '../own-days/html/ServicesCareHTML';
import OwndaysMeetsHirotadaOtotakeHTML from '../own-days/html/OwndaysMeetsHirotadaOtotakeHTML';
import SiteMapHTML from '../own-days/html/SiteMapHTML';
import OwndaysPcHTML from '../own-days/html/OwndaysPcHTML';
import ShingoAibaHTML from '../own-days/html/ShingoAibaHTML.jsx';
import OwndaysMeets201408HTML from '../own-days/html/OwndaysMeets201408HTML.jsx';
import Login from '../desktop/containers/LoginSignup/Login.jsx';
import Signup from '../desktop/containers/LoginSignup/Signup.jsx';
import Gundam5Zeon from '../own-days/views/desktop/Gundam5Zeon/Gundam5Zeon.jsx';
import GundamZakuhead from '../own-days/views/desktop/GundamZakuhead/GundamZakuhead.jsx';
import HarryPotter from '../own-days/views/desktop/HarryPotter/HarryPotter.jsx';
import OwndaysProgressiveCPHTML from '../own-days/html/OwndaysProgressiveCPHTML.jsx';
import FreeMyopiaControl2Lenses from '../own-days/CommonComponents/FreeMyopiaControl2Lenses';
import OrthoKLensHTML from '../own-days/html/OrthoKLensHTML.jsx';

import commonRoutes from './commonRoutes.jsx';

const routes = [
  {
    component: Sharktank,
    path: '/quiz_front_page',
  },
  {
    component: App,
    routes: [
      // Home page
      { path: '/', exact: true, component: Home },
      { path: '/sg/en', exact: true, component: Home },

      // Auth Route and Reset and Fogot Password
      { path: '/(sg/en)?/customer/account/login', component: Login },
      { path: '/(sg/en)?/customer/account/signup', component: Signup },
      { path: '/customer/account/resetpassword', exact: true, component: ResetPassword },
      { path: '/customer/account/forgotpassword', exact: true, component: ForgotPassword },

      // Checkout Routes
      { path: '/cart', exact: true, component: CartLoadable },
      { path: '/checkout/onepage', component: Checkout }, // For Select Address
      { path: '/payment-page', component: PaymentPage },
      { path: '/retry-payment', component: OdRetryPayment },
      { path: '/(payment-success|payment-confirm)', component: PaymentSuccess },

      // Book eye test
      { path: '/bookeyetest', component: HTO },

      { path: '/upload-prescription/:id?', component: UploadPrescription },
      { path: '/add-prescription', component: AddPrescription },

      { path: '/customer/*', component: MyAccount },

      { path: '/about-referral', component: AboutReferral },
      { path: '/(sg/en)?/news/gundam5-zakuhead', component: GundamZakuhead },
      { path: '/wishlist', exact: true, component: WishlistPage },
      { path: '/corporate-information', exact: true, component: CorporateInformationPage },
      { path: '/faq', exact: true, component: FaqPage },
      { path: '/care', exact: true, component: CarePage },
      { path: '/prescription', exact: true, component: PrescriptionPage },
      { path: '/(sg/en)?/news/bundle-sale', component: BundleSaleHTML },
      { path: '/(sg/en)?/news/500stores', component: StoresHTML },
      { path: '/(sg/en)?/news/progressive-cp', component: OwndaysProgressiveCPHTML },
      { path: '/(sg/en)?/membership-terms', component: MembershipTermsHTML },
      { path: '/(sg/en)?/gift-ticket', component: GiftTicketHTML },
      { path: '/(sg/en)?/collabo/gundam/pj-snap', component: GundamHTML },
      {
        path: '/(sg/en)?/collabo/gundam/pj-head/reservation',
        component: GundamOnlineOrderHTML,
      },
      { path: '/(sg/en)?/collabo/gundam/pj-head', component: GundamAnniversaryHTML },
      { path: '/(sg/en)?/account/whatsapp', component: WhatsAppAccountHTML },
      { path: '/(sg/en)?/account/facebook', component: FacebookAccountHTML },
      { path: '/(sg/en)?/contact-lens-guide', component: HowToUseHTML },

      {
        path: '/(sg/en)?/news/gundam',
        component: WearGundamHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201408',
        component: OwndaysMeets201408HTML,
      },
      {
        path: '/(sg/en)?/news/shingo-aiba',
        component: ShingoAibaHTML,
      },
      {
        path: '/(sg/en)?/news/mi-mollet',
        component: MimolletCollectionHTML,
      },
      {
        path: '/(sg/en)?/contacts/orthok-contactlens',
        component: OrthoKLensHTML,
      },
      {
        path: '/(sg/en)?/news/owndayspc',
        component: OwndaysPcHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201312',
        component: OwndaysMeetsJunHaganHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201401',
        component: OwndaysMeetsYoheiShinomiyaHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201402',
        component: OwndaysMeetsAkiyoNohuchiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201403',
        component: OwndaysMeetsAkiyoNohuchiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201404',
        component: OwndaysMeetsTakanoriGomiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201905',
        component: OwndaysMeetsHirotadaOtotakeHTML,
      },
      {
        path: '/(sg/en)?/sitemap',
        component: SiteMapHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201405',
        component: OwndaysMeetsRyuKoshinoHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201406',
        component: OwndaysMeetsKeitaSuzukiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201407',
        component: OwndaysMeetsShuzoNagumoHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-hitoshi-ueda',
        component: OwndaysMeetsHitoshiUedaHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201409',
        component: OwndaysMeetsChocomooHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201410',
        component: OwndaysMeetsKenshiHirokaneHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201506',
        component: OwndaysMeetsSabuHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201610',
        component: OwndaysMeetsMikeHavenaarHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201701',
        component: OwndaysMeetsDawnYeohHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201702-2',
        component: OwndaysMeetsYuniHadiHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201702-3',
        component: OwndaysMeetsHamTranHTML,
      },
      {
        path: '/(sg/en)?/news/owndays-meets-201807',
        component: OwndaysMeetsFujiwaraKatsuakiHTML,
      },
      {
        path: '/(sg/en)?/services/warranty',
        component: ServicesWarrantyHTML,
      },
      {
        path: '/(sg/en)?/services/lens',
        component: LensGuideHTML,
      },
      {
        path: '/(sg/en)?/services/lens-replacement',
        component: LensReplacementHTML,
      },
      {
        path: '/(sg/en)?/services/online-store/frequency',
        component: DontKnowPowerPage,
      },
      {
        path: '/(sg/en)?/services/onlinestore',
        component: ServicesOnlineStoreHTML,
      },
      {
        path: '/(sg/en)?/services/price',
        component: RetailPriceHTML,
      },
      {
        path: '/(sg/en)?/services/staff',
        component: ServicesStaffHTML,
      },
      {
        path: '/(sg/en)?/services/care',
        component: ServicesCareHTML,
      },
      {
        path: '/(sg/en)?/services',
        component: ServicesHTML,
      },
      {
        path: '/(sg/en)?/requirement',
        component: SystemRequirementHTML,
      },
      {
        path: '/(sg/en)?/news/harry-potter',
        component: HarryPotter,
      },
      {
        path: '/(sg/en)?/news/gundam5-zeon',
        component: Gundam5Zeon,
      },
      { path: '/(sg/en)?/news/free-myopia-control-2-lenses', component: FreeMyopiaControl2Lenses },
      { path: '/(sg/en)?/brands', component: Brands },
      { path: '/services', exact: true, component: ServicesPage },
      { path: '/account', component: Membership },
      ...commonRoutes,
      { component: RedisMapping },
    ],
  },
];
export { routes };
