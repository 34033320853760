import React from 'react';

export const KIMETSU_MENU = [
  {
    id: 'sec1',
    src: '/sect1_frame9.webp',
    firstName: 'Tanjiro',
    lastName: 'Kamado',
  },
  {
    id: 'sec2',
    src: '/sect2_frame9.webp',
    firstName: 'Nezuko',
    lastName: 'Kamado',
  },
  {
    id: 'sec3',
    src: '/sect3_frame9.webp',
    firstName: 'Zenitsu',
    lastName: 'Agatsuma',
  },
  {
    id: 'sec4',
    src: '/sect4_frame9.webp',
    firstName: 'Inosuke',
    lastName: 'Hashibira',
  },
  {
    id: 'sec5',
    src: '/sect5_frame9.webp',
    firstName: 'Giyu',
    lastName: 'Tomioka',
  },
  {
    id: 'sec6',
    src: '/sect6_frame9.webp',
    firstName: 'Shinobu',
    lastName: 'Kocho',
  },
  {
    id: 'sec7',
    src: '/sect7_frame9.webp',
    firstName: 'Kyojuro',
    lastName: 'Rengoku',
  },
];

export const KIMETSU_SHOPS = [
  {
    title: 'OWNDAYS 313@somerest',
    link: '/sg/en/shops/907',
  },
  {
    title: 'OWNDAYS Bedok Mall',
    link: '/sg/en/shops/908',
  },
  {
    title: 'OWNDAYS nex',
    link: '/sg/en/shops/909',
  },
  {
    title: 'OWNDAYS IMM Outlet',
    link: '/sg/en/shops/922',
  },
  {
    title: 'OWNDAYS Jurong Point',
    link: '/sg/en/shops/928',
  },
  {
    title: 'OWNDAYS Vivo City',
    link: '/sg/en/shops/931',
  },
  {
    title: 'OWNDAYS Waterway Point',
    link: '/sg/en/shops/933',
  },
  {
    title: 'OWNDAYS Northpoint City',
    link: '/sg/en/shops/968',
  },
  {
    title: 'OWNDAYS Causeway Point',
    link: '/sg/en/shops/1005',
  },
  {
    title: 'OWNDAYS AMK Hub',
    link: '/sg/en/shops/976',
  },
  {
    title: 'OWNDAYS Jewel Changi',
    link: '/sg/en/shops/989',
  },
  {
    title: 'OWNDAYS MBS Premium Concept Store',
    link: '/sg/en/shops/1007',
  },
];

export const SHARES = [
  {
    key: 'facebook',
    link: 'https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/kimetsu',
  },
  {
    key: 'twitter',
    link: 'https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/kimetsu',
  },
  {
    key: 'line',
    link: 'https://line.me/R/msg/text/?https://www.owndays.com/sg/en/news/kimetsu',
  },
];

export const KIMETSU_LINEUP = [
  {
    name: 'Tanjiro Kamado',
    desc: `A versatile design in the Boston style that is made of acetate and metal. The frame features a glossy black colour that resembles Tanjiro’s Nichirin Sword. The end pieces (the part that extends from the front of the frame to the temple) come in a red pattern mirroring that on his sword handle, offering an extra accent to the eye area. A metal emblem in the design of Tanjiro’s Nichirin Sword handguard is set on the tip of the temples while the reverse side features the iconic checked pattern from his haori jacket.`,
    price: {
      value: 'S$258',
      desc: 'Comes with exclusive spectacle case and cleaning cloth',
    },
    materials: (
      <dd>
        Lens: Acrylic (demo lens) / <br className="base-kimetsu__hide--pc" />
        Frame: Acetate, Alloy
      </dd>
    ),
    size: (
      <dd>
        Lens Width: Approx. 48mm / <br className="base-kimetsu__hide--pc" />
        Nose Bridge Length: Approx. 20mm / <br />
        Temple Length: Approx. 140mm
      </dd>
    ),
  },
  {
    name: 'Nezuko Kamado',
    desc: `Boston-style frame in a soft pink tone much like the colour of Nezuko’s kimono. The temples feature a metallic accent inspired by bamboo with the reverse side in a checked pattern taken from Nezuko’s obi sash. This is a cute pair that exudes a gentle but delightful charm.`,
    price: {
      value: 'S$258',
      desc: 'Comes with exclusive spectacle case and cleaning cloth',
    },
    materials: (
      <dd>
        Lens: Acrylic (demo lens) / <br className="base-kimetsu__hide--pc" />
        Frame: Acetate, Alloy
      </dd>
    ),
    size: (
      <dd>
        Lens Width: Approx. 50mm / <br className="base-kimetsu__hide--pc" />
        Nose Bridge Length: Approx. 19mm / <br className="base-kimetsu__hide--sp" />
        Temple Length: Approx. 140mm
      </dd>
    ),
  },
  {
    name: 'Zenitsu Agatsuma',
    desc: `A chic design in the trending Crown Panto style. The front features a gradient tone while the end pieces come in a yellow pattern mirroring that on Zenitsu’s Nichirin Sword handle, offering an extra accent to the eye area. A metal emblem in the design of his sword handguard is set on the tip of the temples while the reverse side features a fish scale motif from the haori jacket that Zenitsu wears.`,
    price: {
      value: 'S$258',
      desc: 'Comes with exclusive spectacle case and cleaning cloth',
    },
    materials: (
      <dd>
        Lens: Acrylic (demo lens) / <br className="base-kimetsu__hide--pc" />
        Frame: Acetate, Alloy
      </dd>
    ),
    size: (
      <dd>
        Lens Width: Approx. 48mm / <br className="base-kimetsu__hide--pc" />
        Nose Bridge Length: Approx. 20mm / <br />
        Temple Length: Approx. 140mm
      </dd>
    ),
  },
  {
    name: 'Inosuke Hashibira',
    desc: `A sharp design in the rectangle style created based on Inosuke’s fierce, impulsive personality. The metal temples are designed to resemble the jagged blade of Inosuke’s Nichirin Swords and are encased in clear acetate. With a minimalist colourway, it is a versatile pair suitable for all occasions.`,
    price: {
      value: 'S$258',
      desc: 'Comes with exclusive spectacle case and cleaning cloth',
    },
    materials: (
      <dd>
        Lens: Acrylic (demo lens) / <br className="base-kimetsu__hide--pc" />
        Frame: Acetate, Alloy
      </dd>
    ),
    size: (
      <dd>
        Lens Width: Approx. 55mm / <br className="base-kimetsu__hide--pc" />
        Nose Bridge Length: Approx. 16mm / <br />
        Temple Length: Approx. 145mm
      </dd>
    ),
  },
  {
    name: 'Giyu Tomioka',
    desc: `Wellington-style frame in a deep sapphire colour that denotes the blue, melancholic eyes of Giyu, a.k.a. the Water Hashira. The end pieces of the frame come in a pattern mirroring that on Giyu’s Nichirin Sword handle, offering an extra accent to the eye area. The temples follow the split design of Giyu’s haori jacket, featuring a burgundy shade on the external and a rhombille tiling tessellation on the reverse side. With a metal emblem in the kanji character for “metsu”, meaning destroy, set on the tip of the temples, this is a frame that conveys the comportment of the Demon Slayer Corps.`,
    price: {
      value: 'S$258',
      desc: 'Comes with exclusive spectacle case and cleaning cloth',
    },
    materials: (
      <dd>
        Lens: Acrylic (demo lens) / <br className="base-kimetsu__hide--pc" />
        Frame: Acetate, Alloy
      </dd>
    ),
    size: (
      <dd>
        Lens Width: Approx. 53mm / <br className="base-kimetsu__hide--pc" />
        Nose Bridge Length: Approx. 17mm / <br />
        Temple Length: Approx. 140mm
      </dd>
    ),
  },
  {
    name: 'Shinobu Kocho',
    desc: `A feminine Boston-styled frame in a bright, alluring shade of purple that denotes Shinobu Kocho, a.k.a. the Insect Hashira. The reverse side of the metal temples features an exquisite butterfly wing motif from the haori jacket that Shinobu wears. The end pieces of the frame come in a pattern mirroring that on Shinobu’s Nichirin Sword handle with a floral embellishment in the design of her sword handguard. This is a frame that is both elegant and cute.`,
    price: {
      value: 'S$258',
      desc: 'Comes with exclusive spectacle case and cleaning cloth',
    },
    materials: (
      <dd>
        Lens: Acrylic (demo lens) / <br className="base-kimetsu__hide--pc" />
        Frame: Acetate, Alloy
      </dd>
    ),
    size: (
      <dd>
        Lens Width: Approx. 49mm / <br className="base-kimetsu__hide--pc" />
        Nose Bridge Length: Approx. 20mm / <br />
        Temple Length: Approx. 140mm
      </dd>
    ),
  },
  {
    name: 'Kyojuro Rengoku',
    desc: `A bold design in the browline style that resembles a flame with a metal emblem in the shape of Rengoku’s signature eyebrows added as a decorative element on each side of the frame front. To symbolise the righteousness of Rengoku’s character, a luxurious champagne-gold tone is used for all metal components of the frame. Temple tips come in a flame-like colourway and are adorned with the kanji character for “metsu” which means destroy. This is a frame that conveys the vigour and burning passion of a chivalrous swordsman.`,
    price: {
      value: 'S$258',
      desc: 'Comes with exclusive spectacle case and cleaning cloth',
    },
    materials: (
      <dd>
        Lens: Plastic (99% UV reduction; <br className="base-kimetsu__show--xl" />
        Up to 33% (based on International Standard); <br className="base-kimetsu__show--xl" />
        with super water-repellent coating) / Frame: Titanium; Acetate
      </dd>
    ),
    size: (
      <dd>
        Lens Width: Approx. 48mm / <br className="base-kimetsu__hide--pc" />
        Nose Bridge Length: Approx. 20mm / <br />
        Temple Length: Approx. 140mm
      </dd>
    ),
  },
];
