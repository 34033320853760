import React, { Fragment, useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';

import {
  PLASTIC_SGSP2001B_3S,
  PLASTIC_SGSP2002B_3S,
  METAL_SGSP1001B_3S,
  METAL_SGSP1002B_3S,
} from './constants';
import './BodyAnniversary10thCollection.scss';

export const BodyAnniversary10thCollection = ({ baseImgUrl }) => {
  const [stateSGSP2001B_3S, setStateSGSP2001B_3S] = useState({
    currentKey: 'C1',
    C1: PLASTIC_SGSP2001B_3S.C1,
    C2: PLASTIC_SGSP2001B_3S.C2,
    colors: PLASTIC_SGSP2001B_3S.COLORS,
    desc: PLASTIC_SGSP2001B_3S.DESC,
    currentIndexSlider: 0,
  });
  const [stateSGSP2002B_3S, setStateSGSP2002B_3S] = useState({
    currentKey: 'C1',
    C1: PLASTIC_SGSP2002B_3S.C1,
    C2: PLASTIC_SGSP2002B_3S.C2,
    colors: PLASTIC_SGSP2002B_3S.COLORS,
    desc: PLASTIC_SGSP2002B_3S.DESC,
    currentIndexSlider: 0,
  });
  const [stateSGSP1001B_3S, setStateSGSP1001B_3S] = useState({
    currentKey: 'C1',
    C1: METAL_SGSP1001B_3S.C1,
    C2: METAL_SGSP1001B_3S.C2,
    colors: METAL_SGSP1001B_3S.COLORS,
    desc: METAL_SGSP1001B_3S.DESC,
    currentIndexSlider: 0,
  });
  const [stateSGSP1002B_3S, setStateSGSP1002B_3S] = useState({
    currentKey: 'C1',
    C1: METAL_SGSP1002B_3S.C1,
    C2: METAL_SGSP1002B_3S.C2,
    colors: METAL_SGSP1002B_3S.COLORS,
    desc: METAL_SGSP1002B_3S.DESC,
    currentIndexSlider: 0,
  });
  const [dotsTop, setDotsTop] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const ref_SGSP2001B_3S = useRef(null);
  const ref_SGSP2002B_3S = useRef(null);
  const ref_SGSP1001B_3S = useRef(null);
  const ref_SGSP1002B_3S = useRef(null);

  const imgRef = useRef(null);

  const updateDotsPosition = () => {
    if (imgRef.current) {
      const imgHeight = imgRef.current.clientHeight || imgRef.current.naturalHeight;
      const offset = windowWidth >= 768 ? 17 : 65;

      if (imgHeight > 0) {
        setDotsTop(imgHeight + offset);
      }
    }
  };

  useEffect(() => {
    updateDotsPosition();
    window.addEventListener('resize', updateDotsPosition);

    return () => window.removeEventListener('resize', updateDotsPosition);
  }, [updateDotsPosition, windowWidth]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const defaultSetting = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    fade: true,
    appendDots: dots => (
      <ul className="slick-dots" style={{ top: `${dotsTop}px` }}>
        {dots}
      </ul>
    ),
  };

  const settings_SGSP2001B_3S = {
    ...defaultSetting,
    afterChange: async current => {
      await Promise.all([
        ref_SGSP2001B_3S.current.slickGoTo(current, true),
        setStateSGSP2001B_3S(prev => ({ ...prev, currentIndexSlider: current })),
      ]);
    },
  };

  const settings_SGSP2002B_3S = {
    ...defaultSetting,
    afterChange: async current => {
      await Promise.all([
        ref_SGSP2002B_3S.current.slickGoTo(current, true),
        setStateSGSP2002B_3S(prev => ({ ...prev, currentIndexSlider: current })),
      ]);
    },
  };

  const settings_SGSP1001B_3S = {
    ...defaultSetting,
    afterChange: async current => {
      await Promise.all([
        ref_SGSP1001B_3S.current.slickGoTo(current, true),
        setStateSGSP1001B_3S(prev => ({ ...prev, currentIndexSlider: current })),
      ]);
    },
  };

  const settings_SGSP1002B_3S = {
    ...defaultSetting,
    afterChange: async current => {
      await Promise.all([
        ref_SGSP1002B_3S.current.slickGoTo(current, true),
        setStateSGSP1002B_3S(prev => ({ ...prev, currentIndexSlider: current })),
      ]);
    },
  };

  const renderProducts = (list, settings, ref) => {
    const url = 'https://static1.lenskart.com/owndays/img/anniversary-10th-collection';

    return (
      <Slider ref={ref} {...settings} className="body-anniversary-10th-collection__lineup__slider">
        {list.map(({ title, alt, img, desc }) => (
          <li key={title}>
            <div className="body-anniversary-10th-collection__lineup__slider__title body-anniversary-10th-collection__lineup__slider__title--hide-pc">
              <h3>{title}</h3>
            </div>

            <picture>
              <source
                media="(min-width:768px)"
                srcSet={`${url}/products${img.pc}`}
                type="image/webp"
              />
              <img
                ref={imgRef}
                alt={alt}
                className="body-anniversary-10th-collection__lineup__slider__img"
                loading="lazy"
                src={`${baseImgUrl}/products${img.sp}`}
              />
            </picture>

            <div className="body-anniversary-10th-collection__lineup__slider__title body-anniversary-10th-collection__lineup__slider__title--hide-sp">
              <h3>{title}</h3>
            </div>
            <p className="body-anniversary-10th-collection__lineup__slider__desc">{desc}</p>
          </li>
        ))}
      </Slider>
    );
  };

  const handleSelectedColor = (sku, index) => {
    const skuMap = {
      'SGSP2001B-3S': { setState: setStateSGSP2001B_3S, ref: ref_SGSP2001B_3S },
      'SGSP2002B-3S': { setState: setStateSGSP2002B_3S, ref: ref_SGSP2002B_3S },
      'SGSP1001B-3S': { setState: setStateSGSP1001B_3S, ref: ref_SGSP1001B_3S },
      'SGSP1002B-3S': { setState: setStateSGSP1002B_3S, ref: ref_SGSP1002B_3S },
    };

    if (skuMap[sku]) {
      const { setState, ref } = skuMap[sku];

      setState(prev => ({
        ...prev,
        currentKey: `C${index + 1}`,
        currentIndexSlider: 0,
      }));

      ref.current?.slickGoTo(0);
    }
  };

  const handleSelectedPoint = (index, ref, setStateLineup) => {
    setStateLineup(prev => ({ ...prev, currentIndexSlider: index }));
    ref.current?.slickGoTo(index);
  };

  const renderLineupBanner = (banner, name) => {
    return (
      <div className="base-anniversary-10th-collection__container">
        <div className="body-anniversary-10th-collection__lineup__title">
          <h3 className="base-anniversary-10th-collection__heading">
            <span className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
              {name.charAt(0).toUpperCase() + name.slice(1)} Frame
            </span>
          </h3>
        </div>

        <div className="body-anniversary-10th-collection__lineup__banner">
          <div data-aos className="base-anniversary-10th-collection__frame">
            <picture>
              <source
                media="(min-width: 768px)"
                srcSet={`${baseImgUrl}${banner.pc}`}
                type="image/webp"
              />
              <img alt="Plastic Frame" loading="lazy" src={`${baseImgUrl}${banner.sp}`} />
            </picture>
          </div>
        </div>
      </div>
    );
  };

  const renderLineupContent = (sku, colors, currentKey, desc) => {
    return (
      <div className="body-anniversary-10th-collection__lineup__content">
        <div className="body-anniversary-10th-collection__lineup__content__detail" data-aos="fade">
          <div className="base-anniversary-10th-collection__container">
            <div className="body-anniversary-10th-collection__lineup__content__detail__sku">
              <h4>{sku}</h4>

              <ul className="body-anniversary-10th-collection__lineup__content__chips">
                {colors.map((color, index) => (
                  <li
                    key={color}
                    className={
                      currentKey === `C${index + 1}`
                        ? 'body-anniversary-10th-collection__lineup__content__chips__active'
                        : ''
                    }
                    data-color={color}
                    onClick={() => handleSelectedColor(sku, index)}
                  ></li>
                ))}
              </ul>
            </div>

            <p className="body-anniversary-10th-collection__lineup__content__detail__desc">
              {desc}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderLineupImages = (
    sku,
    currentKey,
    currentIndexSlider,
    products,
    ref,
    setStateLineup
  ) => {
    return (
      <div className="body-anniversary-10th-collection__lineup__img-layout">
        <div className="base-anniversary-10th-collection__container">
          <div className="body-anniversary-10th-collection__lineup__points-wrapper">
            <img
              alt={sku}
              className="body-anniversary-10th-collection__lineup__points-main-img"
              loading="lazy"
              src={`${baseImgUrl}/products/${sku}_${currentKey}.webp`}
            />

            <div className="body-anniversary-10th-collection__lineup__selected-wrapper">
              <ul className="body-anniversary-10th-collection__lineup__selected-dots">
                {[0, 1, 2].map(index => (
                  <li
                    key={index}
                    className={
                      currentIndexSlider === index
                        ? 'body-anniversary-10th-collection__lineup__selected-dots__active'
                        : ''
                    }
                    onClick={() => handleSelectedPoint(index, ref, setStateLineup)}
                  >
                    <span>
                      <p>{`0${index + 1}`}</p>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {products}
        </div>
      </div>
    );
  };

  const renderLineup = (sku, productData) => (
    <>
      {renderLineupContent(sku, productData.colors, productData.currentKey, productData.desc)}
      {renderLineupImages(
        sku,
        productData.currentKey,
        productData.currentIndexSlider,
        productData.products,
        productData.ref,
        productData.setStateLineup
      )}
    </>
  );

  const getProductData = (data, settings, ref, setStateLineup) => {
    const { currentKey } = data;

    const products = renderProducts(currentKey === 'C1' ? data.C1 : data.C2, settings, ref);
    return { ...data, products, ref, setStateLineup };
  };

  const renderLineupItem = (name, sku1, sku2) => {
    let banner = PLASTIC_SGSP2001B_3S.BANNER;
    let productData1 = getProductData(
      stateSGSP2001B_3S,
      settings_SGSP2001B_3S,
      ref_SGSP2001B_3S,
      setStateSGSP2001B_3S
    );
    let productData2 = getProductData(
      stateSGSP2002B_3S,
      settings_SGSP2002B_3S,
      ref_SGSP2002B_3S,
      setStateSGSP2002B_3S
    );

    if (name === 'plastic') {
      banner = PLASTIC_SGSP2001B_3S.BANNER;
      productData1 = getProductData(
        stateSGSP2001B_3S,
        settings_SGSP2001B_3S,
        ref_SGSP2001B_3S,
        setStateSGSP2001B_3S
      );
      productData2 = getProductData(
        stateSGSP2002B_3S,
        settings_SGSP2002B_3S,
        ref_SGSP2002B_3S,
        setStateSGSP2002B_3S
      );
    }

    if (name === 'metal') {
      banner = METAL_SGSP1001B_3S.BANNER;
      productData1 = getProductData(
        stateSGSP1001B_3S,
        settings_SGSP1001B_3S,
        ref_SGSP1001B_3S,
        setStateSGSP1001B_3S
      );
      productData2 = getProductData(
        stateSGSP1002B_3S,
        settings_SGSP1002B_3S,
        ref_SGSP1002B_3S,
        setStateSGSP1002B_3S
      );
    }

    return (
      <div id={name}>
        {renderLineupBanner(banner, name)}
        {renderLineup(sku1, productData1)}
        {renderLineup(sku2, productData2)}
      </div>
    );
  };

  return (
    <Fragment>
      <section className="body-anniversary-10th-collection__lineup">
        {renderLineupItem('plastic', PLASTIC_SGSP2001B_3S.SKU, PLASTIC_SGSP2002B_3S.SKU)}
        {renderLineupItem('metal', METAL_SGSP1001B_3S.SKU, METAL_SGSP1002B_3S.SKU)}
      </section>
    </Fragment>
  );
};
