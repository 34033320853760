import React from 'react';
import Breadcrumbs from '../CommonComponents/Breadcrumbs/Breadcrumbs';
import Header from '../CommonComponents/Services/Header/Header';
import Navigation from '../CommonComponents/Services/Navigation/Navigation';
import RetailSection from '../views/services/RetailPrice/RetailSection';
import '../views/services/Top/BaseService.scss';
import {
  getBreadcrumbItems,
  getCurrentPath,
} from '../CommonComponents/Breadcrumbs/breadcrumbUtils';

const RetailPriceHTML = () => {
  const crumbs = getBreadcrumbItems([{ path: '/sg/en/services/price', label: 'Retail Price' }]);
  const currentPath = getCurrentPath('/price');

  const headerContent = {
    title: 'Retail Price',
    description:
      'OWNDAYS offers an all-in-one price system that is simple and easy to understand. Every pair of glasses in OWNDAYS comes in a set price inclusive of a pair of high index aspheric lenses.',
    bgSp: 'https://static.lenskart.com/owndays/img/services/price/main-1534x1023.webp',
    bgPc: 'https://static.lenskart.com/owndays/img/services/price/main-1536x480.webp',
    bgAlt: 'OWNDAYS Retail Price',
    align: 'center',
    theme: 'dark',
  };

  return (
    <main className="service_l">
      <Breadcrumbs crumbs={crumbs} />
      <div className="service_l__section">
        <Navigation currentPath={currentPath} size="desktop" />
        <Header headerContent={headerContent} />
        <div className="service_l__container-retail">
          <RetailSection />
        </div>
        <div className="service_l__container">
          <Navigation currentPath={currentPath} size="mobile" />
        </div>
      </div>
    </main>
  );
};

export default RetailPriceHTML;
