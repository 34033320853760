import { call, put, takeLatest, all, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import config from 'config';
import * as actionTypes from '../actionTypes/category';
import apiClient from '../helpers/apiClient';
import { commonState } from './state';
import { readQueryParam } from '../utils/helper';
import getSetCookie from '../cookie';
import { getExchangeOrderId, getExchangeItemId } from '../selectors/returnExchange';
import sessionStorageHelper from '../utils/sessionStorageHelper';

let _client = null;
let _location = null;
let categoryPageSizeRedisCount = 0;
let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line

function modifyFilterObject(filterData) {
  const finalFilters = { filters: [] };
  filterData.filters.forEach(filter => {
    const obj = { ...filter };
    if (filter.options && filter.options.length && filter.id?.indexOf('color_id') === -1) {
      obj.listUnitType = 'filter';
      obj.hideFilters = [];
      obj.options.forEach(option => {
        const optionObj = option;
        if (optionObj.title) {
          optionObj.displayTitle = optionObj.title || '';
          if (obj.id?.indexOf('price') > -1) {
            optionObj.title = optionObj.title.replaceAll('. ', '');
            optionObj.displayTitle = optionObj.title.replace(/Rs\./g, '\u20B9');
          }
          if (optionObj.title && optionObj.title.length > 32) {
            optionObj.displayTitle = optionObj.title.substr(0, 29) + '...';
          }
          // Modify title for image
          if (config.msiteGlobals && config.msiteGlobals.viewImagesList?.indexOf(obj.id) > -1) {
            optionObj.trimmedTitle = optionObj.title.replace(/\s/g, '');
          }
        } else {
          optionObj.displayTitle = optionObj.displayTitle || '';
          optionObj.trimmedTitle = optionObj.trimmedTitle || '';
        }
      });
    }
    finalFilters.filters.push(obj);
  });
  return finalFilters;
}

function* getSearchCategoryData({ params, pageCounter }) {
  const successAction = { type: actionTypes.CATEGORY_DATA_SUCCESS };
  const errorAction = { type: actionTypes.CATEGORY_DATA_FAIL };
  const search = window.location.search;
  let query = search
    .substr(1)
    .split('&')
    .find(param => param?.indexOf('q=') > -1)
    .split('=')[1];
  const client = { ..._client };
  const reduxState = yield select(commonState);
  const listPageSize =
    xApiClient === 'mobilesite'
      ? reduxState.redisCommonData.LISTING_PAGE_SIZE
      : reduxState.redisCommonData.CATEGORY_PAGE_SIZE_COUNT;
  categoryPageSizeRedisCount = listPageSize === undefined ? 15 : listPageSize;
  // In case redis category page count set is not divisible by 3
  // categoryPageSizeRedisCount = validateCategoryListingPageSize(categoryPageSizeRedisCount);
  // ends
  query = query.replace(/[\])}?[{(]/g, '');
  client.setHeader();

  let categoryData;
  try {
    let searchBody = {
      query: decodeURIComponent(query),
      objectID: decodeURIComponent(query),
      page: !pageCounter ? 0 : pageCounter,
      'page-size': categoryPageSizeRedisCount,
      filtersMap: {},
      similarProductId: '',
    };

    if (params?.sortValue) {
      searchBody = { ...searchBody, sort: params?.sortValue };
    }
    categoryData = yield call(async () => client.post(`${config.searchBar.url}`, searchBody));

    // categoryData = yield call(async () =>
    //   client.get(
    //     `${config.apiPath.categorySearch}${query}?page-size=${categoryPageSizeRedisCount}&page=${
    //       !pageCounter ? 0 : pageCounter
    //     }${params ? `&${params}` : params}`
    //   )
    // );

    successAction.data = categoryData;
    successAction.data.query = query;
    successAction.data.pathname = window.location.pathname;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getSearchFilterData({ location, params, pageCounter }) {
  if (!(location.pathname?.indexOf('/search') > -1)) {
    const successAction = { type: actionTypes.SEARCH_FILTER_SUCCESS };
    const errorAction = { type: actionTypes.SEARCH_FILTER_FAIL };
    let paramsForFilter = '';
    if (params) {
      params = params
        .split('&')
        .filter(
          filter =>
            !filter.includes('dir=') && !filter.includes('gan_data=') && !filter.includes('sort=')
        )
        .join('&');
      paramsForFilter = `${params ? `&${params}` : params}`;
    }
    let query = '';
    if (location.search?.indexOf('&') > -1) {
      query = location.search.slice(
        location.search?.indexOf('=') + 1,
        location.search?.indexOf('&')
      );
    } else {
      query = location.search.slice(location.search?.indexOf('=') + 1);
    }
    const reduxState = yield select(commonState);
    const listPageSize =
      xApiClient === 'mobilesite'
        ? reduxState.redisCommonData.LISTING_PAGE_SIZE
        : reduxState.redisCommonData.CATEGORY_PAGE_SIZE_COUNT;
    categoryPageSizeRedisCount = listPageSize === undefined ? 15 : listPageSize;
    // In case redis category page count set is not divisible by 3
    // categoryPageSizeRedisCount = validateCategoryListingPageSize(categoryPageSizeRedisCount);
    // ends
    query = query.replace(/[\])}?[{(]/g, '');
    const requestUrl = `${config.apiPath.categorySearchFilter}${query}${paramsForFilter}`;
    const client = { ..._client };
    client.setHeader();
    try {
      const data = yield call(async () => client.get(requestUrl));
      if (!data.result) throw new Error({ message: 'empty filters' });
      successAction.data = { result: modifyFilterObject({ filters: data.result.filters }).filters };
      if (xApiClient === 'mobilesite') {
        successAction.data.result.number_of_products = data.result.number_of_products;
      }
      if (!params) {
        localStorage.setItem('filters', JSON.stringify({ result: { filters: data.result } }));
      }
      successAction.data.counter = pageCounter;
      yield put(successAction);
    } catch (er) {
      errorAction.error = er;
      yield put(errorAction);
    }
  }
}

function* getCategoryData({ categoryId, params, pageCounter }) {
  const successAction = { type: actionTypes.CATEGORY_DATA_SUCCESS };
  const errorAction = { type: actionTypes.CATEGORY_DATA_FAIL };
  const saveCategoryToReduxAction = { type: actionTypes.SAVE_CATEGORYDATA_TO_REDUX };
  const reduxState = yield select(commonState);
  const listPageSize =
    xApiClient === 'mobilesite'
      ? reduxState.redisCommonData.LISTING_PAGE_SIZE
      : reduxState.redisCommonData.CATEGORY_PAGE_SIZE_COUNT;
  categoryPageSizeRedisCount = listPageSize === undefined ? 15 : listPageSize;
  // In case redis category page count set is not divisible by 3
  // categoryPageSizeRedisCount = validateCategoryListingPageSize(categoryPageSizeRedisCount);
  // ends
  const client = { ..._client };
  client.setHeader();
  let isExchange = false;
  if (__SERVER__) {
    const getCookies = client.getServerRequestHeader().cookies;
    if (getCookies.exchangeMethod) {
      isExchange = getCookies.exchangeMethod;
    }
  }
  if (__CLIENT__) {
    let isExchangeSessionValid;
    if (xApiClient === 'mobilesite') {
      isExchangeSessionValid =
        sessionStorageHelper.getItem('returnAction') ||
        sessionStorageHelper.getItem('returnProduct')?.returnAction;
    } else {
      isExchangeSessionValid =
        sessionStorageHelper.getItem('exchangeNP') &&
        (sessionStorageHelper.getItem('returnAction') ||
          sessionStorageHelper.getItem('returnProduct')?.returnAction);
    }
    if (getSetCookie.getCookie('exchangeMethod') && isExchangeSessionValid) {
      isExchange = getSetCookie.getCookie('exchangeMethod');
    } else {
      isExchange = false;
    }
  }
  if (isExchange) {
    const orderId = getSetCookie.getCookie('exchangeOrderId');
    const itemId = getSetCookie.getCookie('exchangeItemId');
    const exchangeParams = orderId && itemId ? `orderId=${orderId}&itemId=${itemId}` : '';
    params = (params && `${params}&${exchangeParams || ''}`) || exchangeParams || '';
  }
  try {
    const data = yield call(async () =>
      client.getCategoryData(
        categoryId,
        params,
        categoryPageSizeRedisCount,
        !pageCounter ? 0 : pageCounter,
        isExchange
      )
    );
    successAction.data = data;
    successAction.data.counter = pageCounter;
    saveCategoryToReduxAction.data = data;
    saveCategoryToReduxAction.data.result.categoryId = categoryId;
    saveCategoryToReduxAction.data.location = _location;
    yield put(successAction);
    yield delay(500);
    yield put(saveCategoryToReduxAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* loadScroll({ pathname, categoryId, counter, mystring }) {
  const successAction = { type: actionTypes.APPEND_DATA_AFTER };
  const saveCategoryToReduxAction = { type: actionTypes.SAVE_CATEGORYDATA_TO_REDUX };
  const errorAction = { type: actionTypes.FAIL_APPEND_DATA };
  const client = { ..._client };
  client.setHeader();
  const search = window.location.search;
  const reduxState = yield select(commonState);
  const listPageSize =
    xApiClient === 'mobilesite'
      ? reduxState.redisCommonData.LISTING_PAGE_SIZE
      : reduxState.redisCommonData.CATEGORY_PAGE_SIZE_COUNT;
  categoryPageSizeRedisCount = listPageSize === undefined ? 15 : listPageSize;
  // In case redis category page count set is not divisible by 3
  // categoryPageSizeRedisCount = validateCategoryListingPageSize(categoryPageSizeRedisCount);
  // ends
  // const query = search?.indexOf('&') > -1 ? search.slice(search?.indexOf('=') + 1, search?.indexOf('&')) : search.slice(search?.indexOf('=') + 1);
  const query = readQueryParam('q', search);
  let requestUrl = '';
  let isExchange;
  if (xApiClient === 'mobilesite') {
    isExchange =
      getSetCookie.getCookie('exchangeMethod') &&
      (sessionStorageHelper.getItem('returnAction') ||
        sessionStorageHelper.getItem('returnProduct')?.returnAction);
  } else {
    isExchange =
      sessionStorageHelper.getItem('exchangeNP') &&
      getSetCookie.getCookie('exchangeMethod') &&
      (sessionStorageHelper.getItem('returnAction') ||
        sessionStorageHelper.getItem('returnProduct')?.returnAction);
  }
  if (pathname?.indexOf('/search') > -1) {
    requestUrl = `${config.searchBar.url}`;
  } else {
    let exchangeParams;
    if (isExchange) {
      const orderId = yield select(getExchangeOrderId);
      const itemId = yield select(getExchangeItemId);
      exchangeParams = orderId && itemId ? `&orderId=${orderId}&itemId=${itemId}` : '';
    }
    requestUrl = `${
      __SERVER__ ? config.apiPath.albDataCategory : config.apiPath.dataCategory
    }${categoryId}?page-size=${categoryPageSizeRedisCount}&page=${counter}${mystring}${
      exchangeParams || ''
    }&withMainProductColorOption=true&layout=2&newFilterFlowEnabled=true&oos=true`;
  }
  const options = {
    headers: {
      'X-Api-Client': xApiClient,
    },
  };
  if (isExchange) {
    options.headers['X-Service-Type'] = 'exchange';
  }
  try {
    let data;
    if (pathname?.indexOf('/search') > -1) {
      const decodedQuery = decodeURIComponent(query);

      const searchBody = {
        query: decodedQuery,
        objectID: decodedQuery,
        page: counter,
        'page-size': categoryPageSizeRedisCount,
        filtersMap: {},
        similarProductId: '',
      };

      data = yield call(async () => client.post(requestUrl, searchBody));
      successAction.data = data;
      saveCategoryToReduxAction.data = data;
    } else {
      data = yield call(async () => client.get(requestUrl, options));
      successAction.data = data;
      saveCategoryToReduxAction.data = data;
    }

    saveCategoryToReduxAction.data.result.categoryId = categoryId;
    saveCategoryToReduxAction.data.location = _location;
    yield put(successAction);
    yield put(saveCategoryToReduxAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getFilterData({ categoryId, filterParams }) {
  getSetCookie.deleteCookie('lastVisiteUrlForFitMySize');
  const successAction = { type: actionTypes.FILTER_DATA_SUCCESS };
  const errorAction = { type: actionTypes.FILTER_DATA_FAIL };
  let paramsForFilter = '';
  if (filterParams) {
    paramsForFilter = `?${filterParams}`;
  }
  const requestUrl = `${config.apiPath.filterDataApi}${categoryId}`;
  const client = { ..._client };
  const isExchange =
    xApiClient === 'mobilesite' &&
    __CLIENT__ &&
    getSetCookie.getCookie('exchangeMethod') &&
    (sessionStorageHelper.getItem('returnAction') ||
      sessionStorageHelper.getItem('returnProduct')?.returnAction);
  if (isExchange) {
    client.setHeader({ 'X-Service-Type': 'exchange' });
  } else {
    client.setHeader();
  }
  try {
    const data = yield call(async () => client.get(requestUrl));
    successAction.data = { result: modifyFilterObject(data.result) };
    if (xApiClient === 'mobilesite') {
      successAction.data.result.number_of_products = data.result.number_of_products;
    }
    if (!filterParams) {
      localStorage.setItem('filters', JSON.stringify({ result: { filters: data.result } }));
    }

    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getCatAndFilterData({ categoryId, filterParams, pageCounter }) {
  if (!filterParams) {
    filterParams = '';
  }
  if (window.location.pathname?.indexOf('/search') > -1) {
    yield call(getSearchFilterData, {
      location: window.location,
      params: filterParams,
      pageCounter,
    });
    yield call(getSearchCategoryData, { params: filterParams, pageCounter });
  } else {
    yield call(getFilterData, { categoryId, filterParams });
    yield call(getCategoryData, { categoryId, params: filterParams, pageCounter });
  }
}

function* saveCategoryDataOnScoll(categoryData) {
  // yield call(delay, 100);
  yield put({ type: actionTypes.SAVE_CATEGORY_DATA_ON_SCROLL, data: categoryData });
}

function* getSubcategoriesData({ data }) {
  const { gen, cat } = data;
  const successAction = { type: actionTypes.SUBCATEGORY_DATA_SUCCESS };
  const errorAction = { type: actionTypes.SUBCATEGORY_DATA_FAIL };
  const client = { ..._client };
  const requestUrl = config.apiPath.subCatApiUrl + '/gender/' + gen + '/catalog/' + cat;
  client.setHeader();
  try {
    const response = yield call(async () => client.get(requestUrl));
    successAction.data = response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

// OD_NOT_REQUIRED - categoryConfigJson
function* getCategoryConfigJSON() {
  const successAction = { type: actionTypes.CATEGORY_CONFIG_JSON_SUCCESS };
  const errorAction = { type: actionTypes.CATEGORY_CONFIG_JSON_FAIL };
  const client = { ..._client };
  const requestUrl = config.apiPath.categoryConfigJson;
  client.setHeader();
  try {
    const response = yield call(async () => client.get(requestUrl));
    successAction.data = response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runCategoryCalls(client, location) {
  _client = client ? Object.assign({}, client) : apiClient();
  _location = location;
  yield all([
    takeLatest(actionTypes.CATEGORY_DATA_LOAD, getCategoryData),
    takeEvery(actionTypes.APPEND_DATA_LOAD, loadScroll),
    takeEvery(actionTypes.SEARCH_FILTER_LOAD, getSearchFilterData),
    takeEvery(actionTypes.SEARCH_CATEGORY_DATA_LOAD, getSearchCategoryData),
    takeEvery(actionTypes.FILTER_DATA_LOAD, getFilterData),
    takeLatest(actionTypes.CATEGORY_AND_FILTER_DATA_LOAD, getCatAndFilterData),
    takeLatest(actionTypes.DEBOUNCE_CATEGORY_DATA_ON_SCROLL, saveCategoryDataOnScoll),
    takeLatest(actionTypes.SUBCATEGORY_DATA_LOAD, getSubcategoriesData),
    // OD_NOT_REQUIRED - categoryConfigJson
    // takeLatest(actionTypes.CATEGORY_CONFIG_JSON_LOAD, getCategoryConfigJSON),
  ]);
}
