import React, { Fragment, useEffect, useState } from 'react';

import './FooterSun.scss';

export const FooterSun = () => {
  const [position, setPosition] = useState('fixed');

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.querySelector('.base-sun')?.offsetHeight || 0;

      if (scrollTop + windowHeight >= documentHeight) {
        setPosition('static');
      } else {
        setPosition('fixed');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <section className="footer-sun__gap"></section>

      <section className="footer-sun__navs-sticky">
        <div
          className="footer-sun__navs-sticky__inner"
          style={{
            position,
          }}
        >
          <div className="base-sun__container">
            <div className="footer-sun__navs-sticky__actions">
              <div className="base-sun__hide--pc">
                <a
                  className="base-sun__btn base-sun__btn--primary"
                  href="/sg/en/eyeglasses/brands/sun.html"
                >
                  See All SUN <br /> Frames
                </a>
              </div>

              <div className="base-sun__hide--sp">
                <a
                  className="base-sun__btn base-sun__btn--primary"
                  href="/sg/en/eyeglasses/brands/sun.html"
                >
                  See All SUN Frames
                </a>
              </div>

              <a className="base-sun__btn base-sun__btn--secondary" href="/stores/sg">
                Search For <br className="base-sun__hide--pc" />
                Nearest Stores
              </a>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
