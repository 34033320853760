import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';
import { INFORMATION_GENRES, INFORMATION_DATA } from '../../constants/information';
import Genre from './components/Genre';
import List from './components/List';
import Pagination from './components/Pagination';
import './Information.scss';

const ITEMS_PER_PAGE = 15;

const Information = () => {
  const getCategoryFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('category') || 'ALL';
  };

  const [selectedGenre, setSelectedGenre] = useState(getCategoryFromUrl);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const handleUrlChange = () => {
      setSelectedGenre(getCategoryFromUrl());
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);

  const sortedInformations = INFORMATION_DATA?.toSorted(
    (a, b) => new Date(b?.releaseDate) - new Date(a?.releaseDate)
  );

  const filteredInformations =
    selectedGenre === 'ALL'
      ? sortedInformations
      : sortedInformations?.filter(info => info?.genre === selectedGenre);

  const totalPages = Math.ceil(filteredInformations?.length / ITEMS_PER_PAGE);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = filteredInformations?.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const crumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/information', label: 'See all the Information' },
  ];
  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="information">
        <div className="information__container">
          <h1 className="information__title">Information</h1>
          <Genre
            genres={INFORMATION_GENRES}
            selectedGenre={selectedGenre}
            setCurrentPage={setCurrentPage}
            setSelectedGenre={setSelectedGenre}
          />
          <List items={currentItems} />
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </main>
    </>
  );
};

export default Information;
