import React, { Fragment, useEffect } from 'react';

import {
  HeaderOwndaysProgressiveCP,
  BodyOwndaysProgressiveCP,
  FooterOwndaysProgressiveCP,
} from '../CommonComponents/OwndaysProgressiveCP';
import { odPushClevertapEvent } from '../../utils/clevertap';

const OwndaysProgressiveCPHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/progressive-cp';

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'OWNDAYS PROGRESSIVE GLASSES | OWNDAYS ONLINE STORE - OPTICAL SHOP',
      pageUrl: window.location.href,
    });
  });

  return (
    <Fragment>
      <HeaderOwndaysProgressiveCP baseImgUrl={baseImgUrl} />
      <BodyOwndaysProgressiveCP baseImgUrl={baseImgUrl} />
      <FooterOwndaysProgressiveCP baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default OwndaysProgressiveCPHTML;
