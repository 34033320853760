import React from 'react';

import './BaseGiftCardsProducts.scss';
import './HeaderGiftCardsProducts.scss';

export const HeaderGiftCardsProducts = () => {
  return (
    <section className="header-gift-cards-products">
      <div className="base-gift-cards-products__container">
        <h1 className="header-gift-cards-products__title">eGift</h1>

        <p className="header-gift-cards-products__desc">
          A digital gift card that can be used at both the physical stores and the online store.
          <br /> You can choose between $50 and $1000 denominations, add a personalised message and
          send it to your friend via email or Facebook.
        </p>

        <a
          className="base-gift-cards-products__link"
          href="/sg/en/gift-ticket"
          id="gift-ticket-index"
        >
          <small>▶︎</small> What is OWNDAYS eGift?
        </a>
      </div>
    </section>
  );
};
