// From Old Version
export const DEFAULT_COOKIES_SETTINGS = {
  necessaryServices: true,
  improvedSettings: true,
  marketingSettings: true,
};

export const COOKIES = [
  {
    key: 'necessaryServices',
    name: 'Essential Cookies',
    desc: 'These cookies are needed for our website to function properly. The shopping cart feature and payment services on the website are some examples in which essential cookies are required.',
    disabled: true,
  },
  {
    key: 'improvedSettings',
    name: 'Performance Cookies',
    desc: 'We use these cookies to collect data on how you interact with our website. Information such as the pages you visit and the links you click will allow us to optimise the functionality of our website and improve our marketing communications with you. Data collected by performance cookies are anonymous and cannot be used to identify the user.',
    disabled: false,
  },
  {
    key: 'marketingSettings',
    name: 'Advertising Cookies',
    desc: 'These cookies collect information that allows us to optimise advertisements based on user preference and to measure performance of our advertisements.',
    disabled: false,
  },
];
