import React, { useState } from 'react';
import './WishlistPageItem.scss';

import Pricing from '../../../../../CommonComponents/ProductCard/Pricing';

import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commonCallsActionCreators from '../../../../../actionCreators/common';
import {
  getCtaLabel,
  getProductCurrency,
  getProductPrice,
  removeDomainName,
  sunglassOrAccessories,
} from '../../../../helper';
import { addToCart } from '../../../../../actionCreators/cart';
import SelectLenses from '../../../../CommonComponents/SelectLensesModal/SelectLenses';
import { toggleLensesSelectionPopup } from '../../../../../actionCreators/product';
import { isGundamCollection } from '../../Gundam/gundamHelper';
import { odPushClevertapEvent } from '../../../../../utils/clevertap';

const WishlistPageItem = props => {
  const [toggleSelectLens, setToggleSelectLens] = useState(false);
  const { dataLocale, productData, localeInfo, commonCallsAction, login } = props;
  const { CURRENCY_SYMBOL, ADD_TO_CART, REMOVE } = dataLocale;

  const lenskartPrice = productData && productData?.prices[1].price;
  const firstBuyPrice =
    productData && productData?.prices.length === 3 && productData?.prices[2]?.price;
  const finalprice = productData?.prices.length === 3 ? firstBuyPrice : lenskartPrice;
  const marketPrice = productData?.prices[0].price;
  const productId = productData?.id;

  const dispatch = useDispatch();

  const redirectToProduct = () => {
    const url = productData.productUrl;
    if (url?.length > 0 && url.includes('sg/en/')) {
      window.location.assign(`/${removeDomainName(url)}`);
    } else {
      window.location.assign(`/sg/en/${removeDomainName(url)}`);
    }
  };

  const removeFromWishlist = () => {
    odPushClevertapEvent('Removed From Wishlist', {
      productID: productData?.id,
      productName: productData?.productName,
      category: productData?.classification,
      price: getProductPrice(productData),
      currency: getProductCurrency(productData),
      brand: productData?.brandName,
      variant: productData?.modelName,
      productURL: productData?.productUrl,
      imageURL: productData?.imageUrls?.[0],
    });

    commonCallsAction.shortListType(true);

    if (window.dtm.LenskartRewamp)
      window.dtm.LenskartRewamp.Listing.click.removeProdcutShortlist(login, productData.id);

    commonCallsAction.shortlist(productData.id, 'delete', `/${productData.id}`);
    if (window) {
      window?.location?.reload();
    }
  };

  const handleAddToCartCTA = () => {
    if (sunglassOrAccessories(productData?.classification)) {
      if (isGundamCollection(productId)) {
        dispatch(addToCart({ productId }));
      } else {
        dispatch(addToCart({ productId, powerType: 'Frame Only' }));
      }
      // setAddingToCart(true);
    } else if (productData?.classification === 'contact_lens') {
      // TODO: handle for contact lens
    } else {
      setToggleSelectLens(true);
    }
  };

  const handleLensesModal = value => {
    setToggleSelectLens(value);
  };

  return (
    <>
      {toggleSelectLens && (
        <div style={{ position: 'absolute', zIndex: '15' }}>
          <SelectLenses handleModal={handleLensesModal} productData={productData} />
        </div>
      )}
      <div className="wishlist-page-item">
        <div className="wishlist-page-item__layout">
          <div
            className="wishlist-page-item__image"
            style={{
              backgroundImage: `url(${productData?.productImageUrl})`,
            }}
            onClick={redirectToProduct}
          />

          <div className="wishlist-page-item__details">
            <p className="wishlist-page-item__title od-font-bold" onClick={redirectToProduct}>
              {productData?.brandName} {productData?.modelName}
            </p>

            <Pricing
              isMobile
              currencySymbol={CURRENCY_SYMBOL}
              dataLocale={dataLocale}
              finalprice={finalprice}
              localeInfo={localeInfo}
              marketPrice={marketPrice}
            />

            <hr className="wishlist-page-item__divider" />

            <div className="wishlist-page-item__actions">
              <button
                className="wishlist-page-item__action od-font-bold"
                onClick={removeFromWishlist}
              >
                {REMOVE}
              </button>

              <div className="wishlist-page-item__actions-divider" />

              <button
                className="wishlist-page-item__action od-font-bold"
                disabled={!productData?.inStock}
                style={!productData?.inStock ? { color: '#666', textDecoration: 'none' } : {}}
                onClick={handleAddToCartCTA}
              >
                {getCtaLabel(productData?.inStock ? 1 : 0, productData?.classification)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
    localeInfo: state.common.localeInfo,
    login: state.common.login,
    isLensesSelectionPopupOpen: state.product.isLensesSelectionPopupOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    commonCallsAction: bindActionCreators(commonCallsActionCreators, dispatch),
    toggleLensesSelectionPopup: data => dispatch(toggleLensesSelectionPopup(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPageItem);
