import React, { useState } from 'react';
import Breadcrumb from '../../CommonComponents/Breadcrumbs/Breadcrumbs';
import List from './List';
import './Brands.scss';

const Brands = () => {
  const [openCard, setOpenCard] = useState('');
  const [selectedBrand, setSelectedBrand] = useState({});

  const crumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/brands', label: 'Collections' },
  ];

  return (
    <>
      <Breadcrumb crumbs={crumbs} />
      <div className="brands">
        <div className="brands__container">
          <p className="brands__heading">COLLECTIONS</p>
          <h1 className="brands__heading--long">
            OWNDAYS offers a variety of in-house-designed frames and sunglasses that are both
            functional and stylish.
          </h1>
          <List
            openCard={openCard}
            selectedBrand={selectedBrand}
            setOpenCard={setOpenCard}
            setSelectedBrand={setSelectedBrand}
          />
        </div>
      </div>
    </>
  );
};

export default Brands;
