import React from 'react';
import { WARRANTY_CONTENTS } from './constants';
import RenderWarrantyContent from './RenderWarrantyContent';

import './WarrantySection.scss';

const WarrantySection = () => (
  <div className="l-services-warranty">
    <div className="l-services-warranty--l-content--inner">
      {WARRANTY_CONTENTS.map((section, index) => (
        <section key={index} className="l-services-warranty--l-warranty__content">
          <h2 className="l-services-warranty--title">{section.section}</h2>
          {section.items.map(warranty => (
            <RenderWarrantyContent key={warranty.number} warranty={warranty} />
          ))}
          {index === WARRANTY_CONTENTS.length - 1 && (
            <p className="u-text-comment u-mt__10">Terms and conditions apply.</p>
          )}
        </section>
      ))}
    </div>
  </div>
);

export default WarrantySection;
