import React from 'react';

import './FooterSenichisaku.scss';

export const FooterSenichisaku = ({ baseImgUrl }) => {
  return (
    <section className="footer-senichisaku">
      <picture>
        <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/owndays-store_pc.webp`} />
        <img
          alt="OWNDAYS stores"
          className="footer-senichisaku__img"
          data-aos="fade-up"
          src={`${baseImgUrl}/owndays-store_sp.webp`}
          loading="lazy"
        />
      </picture>

      <div className="base-senichisaku__container base-senichisaku__container--sm">
        <h2 className="footer-senichisaku__title" data-aos="fade-up">
          Eyewear that stands the test of time
        </h2>
        <p className="footer-senichisaku__text" data-aos="fade-up">
          With increasing global interest towards building a sustainable society guided by the SDGs,
          people begin moving away from mass production and mass consumption. Consumers are starting
          to embrace the concept of ethical consumption and “good and cheap” products are no longer
          attractive to them. More consumers perceive buying one pair of spectacles at $280 and
          using it for 2 years to have less environmental impact compared to buying four pairs of
          spectacles worth $70 each in 2 years.
          <br />
          <br />
          In view of this, OWNDAYS then finetuned its business policies 6 years ago, creating
          eyewear of the highest standard in the optical industry without lowering prices. With
          products that are more durable, re-purchase cycle is longer and thus contributing to a
          more sustainable economy.
          <br />
          <br />
          Senichisaku frames are the true epitome of Japanese craftsmanship. Made using the finest
          materials, every pair is carefully crafted to stand the test of time. Even though
          affordable spectacles are readily available nowadays, we hope you are able to find a
          perfect pair that can last you for the longest time.
        </p>
      </div>

      <div className="base-senichisaku__container">
        <div className="base-senichisaku__btn-group" data-aos="fade-up">
          <a className="base-senichisaku__btn" href="/sg/en/eyeglasses/brands/Senichisaku.html">
            <span>See all Senichisaku frames</span>
          </a>

          <a className="base-senichisaku__btn" href="/stores/sg">
            <span>Search for nearest OWNDAYS stores</span>
          </a>
        </div>
      </div>
    </section>
  );
};
