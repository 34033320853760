import React, { Fragment } from 'react';

import { TERMS, STORES } from './constants';
import './FooterAnniversary10thCollection.scss';

export const FooterAnniversary10thCollection = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <section className="footer-anniversary-10th-collection__case-cloth">
        <div className="base-anniversary-10th-collection__container">
          <h3 className="base-anniversary-10th-collection__heading">
            <span data-aos="fade-up" data-aos-delay="300">
              EXCLUSIVE CASE
              <br /> AND CLOTH
            </span>
          </h3>

          <p data-aos="fade-up" data-aos-delay="300">
            Each frame comes with an exclusive 10th anniversary-edition spectacle case and lens
            cleaning cloth.
          </p>

          <div className="footer-anniversary-10th-collection__case-cloth__img">
            <div data-aos className="base-anniversary-10th-collection__frame">
              <picture>
                <source
                  media="(min-width:768px)"
                  srcSet={`${baseImgUrl}/case-cloth-pc.webp`}
                  type="image/webp"
                />

                <img alt="Exclusive Case and Cloth" src={`${baseImgUrl}/case-cloth-sp.webp`} />
              </picture>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-anniversary-10th-collection__terms">
        <div className="base-anniversary-10th-collection__container">
          <h4 className="base-anniversary-10th-collection__heading">Terms of Purchase</h4>
          <ul>
            {TERMS.map((term, index) => (
              <li key={index}>{term}</li>
            ))}
          </ul>
        </div>
      </section>

      <section className="footer-anniversary-10th-collection__shops">
        <div className="base-anniversary-10th-collection__container">
          <h4 className="base-anniversary-10th-collection__heading">Product Availability</h4>
          <ul className="base-anniversary-10th-collection__shops">
            {STORES.map(store => (
              <li key={store}>{store}</li>
            ))}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
