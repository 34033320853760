import React, { Fragment, useRef, useEffect, useState } from 'react';

import { SHARES, ATTENTION } from './constants';
import './FooterHuawei2Titanium.scss';

export const FooterHuawei2Titanium = ({ baseImgUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  const pdfLink = 'https://static.lenskart.com/owndays/img/huawei2-titanium/guide.pdf';

  useEffect(() => {
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <Fragment>
      <section className="footer-huawei-2-titanium__spec" data-aos="fade-up" data-aos-offset="300">
        <div className="base-huawei-2-titanium__container">
          <h2 className="footer-huawei-2-titanium__spec__title">Specifications</h2>

          <ul className="footer-huawei-2-titanium__spec__list footer-huawei-2-titanium__spec__list--sp">
            <li className="footer-huawei-2-titanium__spec__item-speakers">
              <h3>
                <span>
                  <img alt="Speakers" src={`${baseImgUrl}/speakers-icon.svg`} />
                </span>
                <span>Speakers</span>
              </h3>

              <p>Co-directional dual diaphragm dynamic driver</p>
              <p>Audio formats: SBC/AAC </p>
              <p>Frequency response range: 100Hz - 12,000Hz</p>
            </li>

            <li className="footer-huawei-2-titanium__spec__item-microphones">
              <h3>
                <span>
                  <img alt="Microphones" src={`${baseImgUrl}/microphones-icon.svg`} />
                </span>
                <span>Microphones</span>
              </h3>

              <p>Dual-mic design</p>
              <p>Call noise cancellation: supported</p>
            </li>

            <li className="footer-huawei-2-titanium__spec__item-battery">
              <h3>
                <span>
                  <img alt="Battery" src={`${baseImgUrl}/battery-icon.svg`} />
                </span>
                <span>Battery</span>
              </h3>

              <p>Music playback: up to 11 hours</p>
              <p>Voice call: up to 9 hours</p>
              <p>Standby: 94 hours</p>
              <p>(not in active use when worn)</p>
              <p>Time required for full charge: </p>
              <p>approximately 50 minutes</p>

              <ul className="footer-huawei-2-titanium__spec__item__remarks">
                <li>
                  Battery life and charging data are based on audio played at 60% volume level in
                  AAC format with default settings used for other features. Battery life may vary
                  depending on the volume level, audio source, environment interference, product
                  settings and usage.
                </li>
                <li>
                  Charging data comes from Huawei lab tests conducted at a 25℃ ambient temperature
                  with a Type-C charging cable and a standard charger of 5V1A or higher. Charging
                  speed slows down as the ambient temperature decreases.
                </li>
              </ul>
            </li>

            <li className="footer-huawei-2-titanium__spec__item-product-weight">
              <h3>
                <span>
                  <img alt="Main Product Weight" src={`${baseImgUrl}/product-weight-icon.svg`} />
                </span>
                <span>Main Product Weight</span>
              </h3>

              <p>HW1001-4A：31.5g</p>
              <p>HW1002-4A：30.0g</p>
              <p>HWF2003N-3A：29.5g</p>
              <p>HWF2004N-3A：32.5g</p>
              <p>HWF2005N-3A：31.0g</p>
              <p>HWF2006N-3A：31.0g</p>

              <ul className="footer-huawei-2-titanium__spec__item__remarks">
                <li>
                  Test data comes from Huawei labs and OWNDAYS. Dimensions, weight and
                  specifications may vary from the actual product. Actual product specifications
                  shall prevail.
                </li>
              </ul>
            </li>

            <li className="footer-huawei-2-titanium__spec__item-others">
              <h3>
                <span>
                  <img alt="others" src={`${baseImgUrl}/other-icon.svg`} />
                </span>
                <span>Others</span>
              </h3>

              <p>Connectivity: Bluetooth 5.3</p>
              <p>Dual device connection: supported</p>
              <p>Wearing detection: supported</p>
              <p>Controls: long tap / slide / double tap</p>
              <p>Water resistance: IP54</p>

              <ul className="footer-huawei-2-titanium__spec__item__remarks">
                <li>
                  This product is dust and splash resistant during normal use. It is rated IP54
                  under the IEC standard 60529 when tested under controlled lab conditions. It is
                  recommended that you avoid wearing the product during water-related activities.
                  Splash and dust resistant is not permanent and the protection may decrease with
                  daily wear and tear. Do not charge the product in a damp environment.
                </li>
              </ul>

              <p>OS compatibility: iOS / Android / Mac / Windows</p>
            </li>

            <li className="footer-huawei-2-titanium__spec__item-in-box">
              <h3>
                <span>
                  <img alt="In the Box" src={`${baseImgUrl}/box-icon.svg`} />
                </span>
                <span>In the Box</span>
              </h3>

              <p className="footer-huawei-2-titanium__spec__item-in-box__manual">
                <a href={pdfLink} rel="noreferrer" target="_blank">
                  <u>Quick Start Guide</u>
                  <img alt="Quick Start Guide" src={`${baseImgUrl}/pdf-icon.svg`} />
                </a>
              </p>
              <p>Information on warranty and after-sales services</p>
              <p>Spectacle cloth</p>

              <ul className="footer-huawei-2-titanium__spec__item__accessories">
                <li>
                  <img alt="Foldable case" src={`${baseImgUrl}/accessories_case.webp`} />
                  <p>Foldable case</p>
                </li>

                <li>
                  <img alt="Charging converter" src={`${baseImgUrl}/accessories_charger.webp`} />
                  <p>Charging converter</p>
                </li>

                <li>
                  <img
                    alt="Spare hinges (1 set in size S)"
                    src={`${baseImgUrl}/accessories_hinge.webp`}
                  />
                  <p>
                    Spare hinges <br />
                    (1 set in size S)
                  </p>
                </li>
              </ul>

              <div className="footer-huawei-2-titanium__spec__item-in-box__box-arrow">
                <p>
                  This product can be used with two types of hinges with different lengths. All
                  online orders, except those who have selected the Lens Replacement Voucher option,
                  are pre-fitted with size L hinges. Please bring the smart audio glasses along with
                  the spare hinges to your <a href="/stores/sg">nearest OWNDAYS store</a> if you
                  would like to change them to size S hinges. Changing of hinges is complimentary.
                </p>
              </div>

              <ul className="footer-huawei-2-titanium__spec__item__remarks">
                <li>
                  Immersing the spectacle case in water may cause permanent damage to the smart
                  audio glasses stored inside the case.
                </li>
                <li>
                  Do not store charging converter in the spectacle case with the smart audio
                  glasses.
                </li>
              </ul>
            </li>
          </ul>

          <div className="footer-huawei-2-titanium__spec__list--pc">
            <ul className="footer-huawei-2-titanium__spec__list">
              <li className="footer-huawei-2-titanium__spec__item-speakers">
                <h3>
                  <span>
                    <img alt="Speakers" src={`${baseImgUrl}/speakers-icon.svg`} />
                  </span>
                  <span>Speakers</span>
                </h3>

                <p>Co-directional dual diaphragm dynamic driver</p>
                <p>Audio formats: SBC/AAC </p>
                <p>Frequency response range: 100Hz - 12,000Hz</p>
              </li>

              <li className="footer-huawei-2-titanium__spec__item-microphones">
                <h3>
                  <span>
                    <img alt="Microphones" src={`${baseImgUrl}/microphones-icon.svg`} />
                  </span>
                  <span>Microphones</span>
                </h3>

                <p>Dual-mic design</p>
                <p>Call noise cancellation: supported</p>
              </li>

              <li className="footer-huawei-2-titanium__spec__item-battery">
                <h3>
                  <span>
                    <img alt="Battery" src={`${baseImgUrl}/battery-icon.svg`} />
                  </span>
                  <span>Battery</span>
                </h3>

                <p>Music playback: up to 11 hours</p>
                <p>Voice call: up to 9 hours</p>
                <p>Standby: 94 hours</p>
                <p>(not in active use when worn)</p>
                <p>Time required for full charge: </p>
                <p>approximately 50 minutes</p>

                <ul className="footer-huawei-2-titanium__spec__item__remarks">
                  <li>
                    Battery life and charging data are based on audio played at 60% volume level in
                    AAC format with default settings used for other features. Battery life may vary
                    depending on the volume level, audio source, environment interference, product
                    settings and usage.
                  </li>
                  <li>
                    Charging data comes from Huawei lab tests conducted at a 25℃ ambient temperature
                    with a Type-C charging cable and a standard charger of 5V1A or higher. Charging
                    speed slows down as the ambient temperature decreases.
                  </li>
                </ul>
              </li>
            </ul>

            <ul className="footer-huawei-2-titanium__spec__list">
              <li className="footer-huawei-2-titanium__spec__item-product-weight">
                <h3>
                  <span>
                    <img alt="Main Product Weight" src={`${baseImgUrl}/product-weight-icon.svg`} />
                  </span>
                  <span>Main Product Weight</span>
                </h3>

                <p>HW1001-4A：31.5g</p>
                <p>HW1002-4A：30.0g</p>
                <p>HWF2003N-3A：29.5g</p>
                <p>HWF2004N-3A：32.5g</p>
                <p>HWF2005N-3A：31.0g</p>
                <p>HWF2006N-3A：31.0g</p>

                <ul className="footer-huawei-2-titanium__spec__item__remarks">
                  <li>
                    Test data comes from Huawei labs and OWNDAYS. Dimensions, weight and
                    specifications may vary from the actual product. Actual product specifications
                    shall prevail.
                  </li>
                </ul>
              </li>

              <li className="footer-huawei-2-titanium__spec__item-others">
                <h3>
                  <span>
                    <img alt="others" src={`${baseImgUrl}/other-icon.svg`} />
                  </span>
                  <span>Others</span>
                </h3>

                <p>Connectivity: Bluetooth 5.3</p>
                <p>Dual device connection: supported</p>
                <p>Wearing detection: supported</p>
                <p>Controls: long tap / slide / double tap</p>
                <p>Water resistance: IP54</p>

                <ul className="footer-huawei-2-titanium__spec__item__remarks">
                  <li>
                    This product is dust and splash resistant during normal use. It is rated IP54
                    under the IEC standard 60529 when tested under controlled lab conditions. It is
                    recommended that you avoid wearing the product during water-related activities.
                    Splash and dust resistant is not permanent and the protection may decrease with
                    daily wear and tear. Do not charge the product in a damp environment.
                  </li>
                </ul>

                <p>OS compatibility: iOS / Android / Mac / Windows</p>
              </li>
            </ul>

            <ul className="footer-huawei-2-titanium__spec__list">
              <li className="footer-huawei-2-titanium__spec__item-in-box">
                <h3>
                  <span>
                    <img alt="In the Box" src={`${baseImgUrl}/box-icon.svg`} />
                  </span>
                  <span>In the Box</span>
                </h3>

                <p className="footer-huawei-2-titanium__spec__item-in-box__manual">
                  <a href={pdfLink} rel="noreferrer" target="_blank">
                    <u>Quick Start Guide</u>
                    <img alt="Quick Start Guide" src={`${baseImgUrl}/pdf-icon.svg`} />
                  </a>
                </p>
                <p>Information on warranty and after-sales services</p>
                <p>Spectacle cloth</p>

                <div className="footer-huawei-2-titanium__spec__item__accessories__accessories-pc">
                  <ul className="footer-huawei-2-titanium__spec__item__accessories">
                    <li>
                      <img alt="Foldable case" src={`${baseImgUrl}/accessories_case.webp`} />
                      <p>Foldable case</p>
                    </li>

                    <li>
                      <img
                        alt="Charging converter"
                        src={`${baseImgUrl}/accessories_charger.webp`}
                      />
                      <p>Charging converter</p>
                    </li>

                    <li>
                      <img
                        alt="Spare hinges (1 set in size S)"
                        src={`${baseImgUrl}/accessories_hinge.webp`}
                      />
                      <p>
                        Spare hinges <br />
                        (1 set in size S)
                      </p>
                    </li>
                  </ul>

                  <div className="footer-huawei-2-titanium__spec__item-in-box__box-arrow">
                    <p>
                      This product can be used with two types of hinges with different lengths. All
                      online orders, except those who have selected the Lens Replacement Voucher
                      option, are pre-fitted with size L hinges. Please bring the smart audio
                      glasses along with the spare hinges to your{' '}
                      <a href="/stores/sg">nearest OWNDAYS store</a> if you would like to change
                      them to size S hinges. Changing of hinges is complimentary.
                    </p>
                  </div>
                </div>

                <ul className="footer-huawei-2-titanium__spec__item__remarks">
                  <li>
                    Immersing the spectacle case in water may cause permanent damage to the smart
                    audio glasses stored inside the case.
                  </li>
                  <li>
                    Do not store charging converter in the spectacle case with the smart audio
                    glasses.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section
        className="footer-huawei-2-titanium__attention"
        data-aos="fade-up"
        data-aos-offset="300"
      >
        <div className="base-huawei-2-titanium__container">
          <h2 className="footer-huawei-2-titanium__attention__title">ATTENTION</h2>

          <div className="footer-huawei-2-titanium__attention__question">
            <div
              className={`footer-huawei-2-titanium__attention__question__layout--q ${
                isOpen ? 'footer-huawei-2-titanium__attention__question__layout--q__open' : ''
              }`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="footer-huawei-2-titanium__attention__question__q">
                Before making a purchase
              </span>
            </div>

            <div
              ref={contentRef}
              className="footer-huawei-2-titanium__attention__question__layout--a"
              style={{
                maxHeight: `${height}px`,
                opacity: isOpen ? 1 : 0,
              }}
            >
              <h3>Please read the safety precautions if you have purchased the product.</h3>

              <ul>
                <li>
                  <span>①</span>
                  <span>
                    This product is not waterproof. Do not use spray, solvents, chemicals, or
                    cleaning solutions containing alcohol and ammonia. Do not wash the product in
                    water.
                  </span>
                </li>
                <li>
                  <span>②</span>
                  <span>
                    Only the nose pad arms may be adjusted for better fitting. Do not adjust any
                    other parts of the smart audio glasses.
                  </span>
                </li>
              </ul>

              <p>
                1 year from the date of purchase
                <br />
                Valid for: Non-user-related faulty performance and manufacturing defects
                <br />
                Coverage: Temples (faulty sound quality, bad connection, unable to power on, etc.);
                Front Attachment (stains, defects, etc.)
                <br />
                Warranty Exclusions: Faulty performance or damage caused by improper usage,
                mishandling, accidents, or operating the product outside stated guidelines; loss of
                product
              </p>

              <p className="footer-huawei-2-titanium__attention__question__note">
                We do not accept returns if prescription lenses cannot be added due to lens power
                constraints. Please consult our staff in-store before making a purchase if you
                require prescription lenses.
              </p>

              <h4>■ Safety Precautions</h4>
              <ul>
                <li>
                  <span>①</span>
                  <span>
                    Listening to loud music for prolonged periods may harm your hearing. Avoid loud
                    volumes, especially when using the smart audio glasses continuously for long
                    periods. Adjust the volume to avoid ear strain, and turn down the playback
                    device volume before wearing the glasses. Then, gradually increase the volume to
                    a comfortable level.
                  </span>
                </li>
                <li>
                  <span>②</span>
                  <span>
                    Please comply with the laws and regulations regarding mobile phone use while
                    driving in your country or local region.
                  </span>
                </li>
                <li>
                  <span>③</span>
                  <span>
                    Exercise caution when near roads, construction sites, railway tracks, etc.
                    Remove the smart audio glasses or lower the volume to hear alarms or warning
                    sounds from the surroundings.
                  </span>
                </li>
                <li>
                  <span>④</span>
                  <span>
                    If you experience abnormal sound quality, stop using the glasses immediately,
                    power them off, and contact Customer Service.
                  </span>
                </li>
                <li>
                  <span>⑤</span>
                  <span>
                    Do not immerse the smart audio glasses in water or use them during swimming,
                    water skiing, surfing, or other water sports.
                  </span>
                </li>
                <li>
                  <span>⑥</span>
                  <span>
                    Remove the smart audio glasses immediately if they become hot or produce no
                    sound.
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <ul className="footer-huawei-2-titanium__attention__remarks">
            {ATTENTION.REMARKS.map(remark => (
              <li key={remark}>{remark}</li>
            ))}
          </ul>
        </div>
      </section>

      <section className="footer-huawei-2-titanium__share">
        <h2>SHARE</h2>
        <ul className="footer-huawei-2-titanium__share__list">
          {SHARES.map(({ link, className }, index) => (
            <li key={index}>
              <a href={link} rel="noopener noreferrer" target="_blank">
                <i aria-hidden="true" className={`fab ${className}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </section>
    </Fragment>
  );
};
