import React, { Fragment, useRef, useState } from 'react';
import Slider from 'react-slick';

import { LINEUP_FUNNY, LINEUP_CHASE, LINEUP_FRIENDLY, LINEUP_CHEESE } from './constants';
import './BodyTomAndJerry.scss';

export const BodyTomAndJerry = ({ baseImgUrl }) => {
  const [stateFUNNY, setStateFUNNY] = useState({
    currentIndexPoint: 0,
    currentIndexProduct: 0,
    currentKey: 'C1',
    colors: LINEUP_FUNNY.products.colors,
  });
  const [stateCHASE, setStateCHASE] = useState({
    currentIndexPoint: 0,
    currentIndexProduct: 0,
    currentKey: 'C1',
    colors: LINEUP_CHASE.products.colors,
  });
  const [stateFRIENDLY, setStateFRIENDLY] = useState({
    currentIndexPoint: 0,
    currentIndexProduct: 0,
    currentKey: 'C1',
    colors: LINEUP_FRIENDLY.products.colors,
  });
  const [stateCHEESE, setStateCHEESE] = useState({
    currentIndexPoint: 0,
    currentIndexProduct: 0,
    currentKey: 'C1',
    colors: LINEUP_CHEESE.products.colors,
  });

  const pointFunnyRef = useRef(null);
  const productFunnyRef = useRef(null);

  const pointChaseRef = useRef(null);
  const productChaseRef = useRef(null);

  const pointFriendlyRef = useRef(null);
  const productFriendlyRef = useRef(null);

  const pointCheeseRef = useRef(null);
  const productCheeseRef = useRef(null);

  const pointDefaultSetting = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dots: false,
    fade: true,
    responsive: [
      {
        breakpoint: 961,
        settings: {
          arrows: true,
          dots: true,
        },
      },
    ],
  };

  const pointFunnySetting = {
    ...pointDefaultSetting,
    afterChange: async current => {
      await Promise.all([
        pointFunnyRef.current.slickGoTo(current, true),
        setStateFUNNY(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const pointChaseSetting = {
    ...pointDefaultSetting,
    afterChange: async current => {
      await Promise.all([
        pointChaseRef.current.slickGoTo(current, true),
        setStateCHASE(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const pointFriendlySetting = {
    ...pointDefaultSetting,
    afterChange: async current => {
      await Promise.all([
        pointFriendlyRef.current.slickGoTo(current, true),
        setStateCHASE(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const pointCheeseSetting = {
    ...pointDefaultSetting,
    afterChange: async current => {
      await Promise.all([
        pointCheeseRef.current.slickGoTo(current, true),
        setStateCHEESE(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const productSetting = {
    infinite: true,
    dots: false,
    speed: 500,
    centerMode: true,
    variableWidth: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768, // Below 768px
        settings: {
          slidesToShow: 1,
          centerMode: true,
          variableWidth: false,
          arrows: false,
        },
      },
      {
        breakpoint: 961, // From 768px to 961px
        settings: {
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          arrows: true,
        },
      },
      {
        breakpoint: 99999, // Above 1024px
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: true,
          arrows: true,
        },
      },
    ],
  };

  const productFunnySetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([
        pointFunnyRef.current.slickGoTo(current, true),
        setStateFUNNY(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const productChaseSetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([
        pointFunnyRef.current.slickGoTo(current, true),
        setStateFUNNY(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const productFriendlySetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([
        productFriendlyRef.current.slickGoTo(current, true),
        setStateFRIENDLY(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const productCheeseSetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([
        productCheeseRef.current.slickGoTo(current, true),
        setStateCHEESE(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const renderMainBgHeader = (title, bg, key) => {
    return (
      <div className="body-tom-and-jerry__lineup__main__bg-header" data-key={key}>
        <picture>
          <source
            media="(min-width: 768px)"
            srcSet={`${baseImgUrl}${bg.headerPC}`}
            type="image/webp"
          />
          <img alt={title} loading="lazy" src={`${baseImgUrl}${bg.headerSP}`} />
        </picture>
      </div>
    );
  };

  const renderTitle = title => {
    return (
      <h2 className="body-tom-and-jerry__lineup__main__header-title">
        <span>{title}</span>
        <img
          alt="model"
          className="body-tom-and-jerry__lineup__main__header-model"
          loading="lazy"
          src={`${baseImgUrl}/lineup_model.svg`}
        />
      </h2>
    );
  };

  const renderFigures = (figures, category) => {
    return (
      <>
        {figures.map(({ alt, aos, delay }, index) => (
          <img
            key={alt}
            alt={alt}
            className={`body-tom-and-jerry__lineup__main__figure-${index + 1}`}
            data-aos={aos}
            data-aos-delay={delay}
            data-key={category}
            loading="lazy"
            src={`${baseImgUrl}/products/${category}/figure-${
              category === 'cheese' ? `${index + 1}-v2` : index + 1
            }.webp`}
          />
        ))}
      </>
    );
  };

  const handleSelectedPoint = (index, ref, setStateLineup) => {
    setStateLineup(prev => ({ ...prev, currentIndexPoint: index }));
    ref.current?.slickGoTo(index);
  };

  const renderDesc = (category, sku, desc, orders, currentIndexPoint, ref, setStateLineup) => {
    return (
      <div className="body-tom-and-jerry__lineup__main__header-desc">
        <img
          alt={sku}
          loading="lazy"
          src={`${baseImgUrl}/products/${category}/lineup_${category}-main.webp`}
        />

        <p>{desc}</p>

        <>
          {Array.from({ length: orders }, (_, index) => (
            <div
              key={index + 1}
              className={`body-tom-and-jerry__lineup__main__header__order-${category}-${index + 1}`}
              onClick={() => handleSelectedPoint(index, ref, setStateLineup)}
            >
              <div
                className={`body-tom-and-jerry__lineup__main__header-option body-tom-and-jerry__lineup__main__header-option__${category} ${
                  currentIndexPoint === index
                    ? `body-tom-and-jerry__lineup__main__header-option__${category}--active`
                    : ''
                }`}
              >
                <span>0{index + 1}</span>
              </div>
            </div>
          ))}
        </>
      </div>
    );
  };

  const renderPoints = (category, points, ref, setting) => {
    return (
      <div className="body-tom-and-jerry__lineup__main__layout-point">
        <Slider {...setting} ref={ref}>
          {points.map(({ desc }, index) => (
            <li key={index}>
              <div className="body-tom-and-jerry__lineup__main__slider__points__desc">
                <p>
                  <span>FEATURE 0{index + 1}</span>
                </p>
                <p dangerouslySetInnerHTML={{ __html: desc.replace(/\n/g, '<br />') }} />
              </div>
              <img
                alt="point 01"
                className="body-tom-and-jerry__lineup__main__slider__points__img"
                loading="lazy"
                src={`${baseImgUrl}/products/${category}/point-${index + 1}.webp`}
              />
            </li>
          ))}
        </Slider>
      </div>
    );
  };

  const renderProducts = (setting, ref, limit, sku, category, currentKey) => {
    return (
      <Slider {...setting} ref={ref} className="body-tom-and-jerry__lineup__main__slider__products">
        {Array.from({ length: limit }, (_, index) => (
          <li key={`${sku}-${index + 1}`}>
            <img
              alt={sku}
              className={`product-${category}-img`}
              data-index="1"
              src={`${baseImgUrl}/products/${category.toLowerCase()}/${sku
                .toLowerCase()
                .replace('-', '_')}_${currentKey.toLowerCase()}_0${index + 1}.webp`}
            />
          </li>
        ))}
      </Slider>
    );
  };

  const handleSelectedColor = (sku, index) => {
    const skuMap = {
      'TJ1001X-5S': { setState: setStateFUNNY, ref: productFunnyRef },
      'TJ2001B-5S': { setState: setStateCHASE, ref: productChaseRef },
      'TJ2002B-5S': { setState: setStateFRIENDLY, ref: productFriendlyRef },
      'TJ2003N-5S': { setState: setStateCHEESE, ref: productCheeseRef },
    };

    if (skuMap[sku]) {
      const { setState } = skuMap[sku];

      setState(prev => ({
        ...prev,
        currentKey: `C${index + 1}`,
      }));
    }
  };

  const renderProductDesc = (sku, stateLineup, colors, price, category) => {
    const selectedColor = stateLineup.currentKey === 'C1' ? colors[0] : colors[1];
    return (
      <div className="body-tom-and-jerry__lineup__main__product__layout-row">
        <div>
          <p className="body-tom-and-jerry__lineup__main__product__sku">{sku}</p>
          <p
            className={`body-tom-and-jerry__lineup__main__product__color-name body-tom-and-jerry__lineup__main__product__color-name__${category}`}
            data-c1={colors[0]}
            data-c2={colors[1]}
          >
            {stateLineup.currentKey} {stateLineup.currentKey === 'C1' ? colors[0] : colors[1]}
          </p>
          <p
            className={`body-tom-and-jerry__lineup__main__product__price ${
              category === 'cheese'
                ? 'body-tom-and-jerry__lineup__main__product__price__cheese'
                : ''
            }`}
          >
            {price}
          </p>
        </div>

        <div className="body-tom-and-jerry__lineup__main__product__color-layout">
          {colors.map((color, index) => (
            <div
              key={color}
              className={`body-tom-and-jerry__lineup__main__product__color-chip__${category} ${
                selectedColor === color
                  ? `body-tom-and-jerry__lineup__main__product__color-chip__${category}--selected`
                  : ''
              }`}
              data-color={color}
              id={`${category}-c${index + 1}`}
              onClick={() => handleSelectedColor(sku, index)}
            >
              <div
                className={`body-tom-and-jerry__lineup__main__product__color-c${index + 1}`}
                data-key={category}
              ></div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderButton = (sku, currentKey) => {
    return (
      <a
        className="base-tom-and-jerry__btn"
        href={`/sg/en/tomandjerry-${sku.toLowerCase()}-${currentKey.toLowerCase()}-eyeglasses.html`}
      >
        ONLINE STORE
      </a>
    );
  };

  const lineupSection = ({
    id,
    lineupData,
    state,
    pointRef,
    setState,
    productRef,
    pointSetting,
    productSetting,
  }) => {
    return (
      <div className={`body-tom-and-jerry__lineup__${id}`} id={`model-${id.toUpperCase()}`}>
        {renderMainBgHeader(lineupData.title, lineupData.bg, id)}

        <div className="body-tom-and-jerry__lineup__main__bg-body" data-key={id} id={id}>
          <div className="body-tom-and-jerry__lineup__main__layout-pc">
            {renderFigures(lineupData.figures, id)}

            <div className="body-tom-and-jerry__lineup__main__header body-tom-and-jerry__lineup__main__header-pc">
              {renderTitle(lineupData.title)}
            </div>

            <div className="body-tom-and-jerry__lineup__main__layout-pc--inner">
              <div className="body-tom-and-jerry__lineup__main__layout-model">
                <div className="body-tom-and-jerry__lineup__main__header body-tom-and-jerry__lineup__main__header-sp">
                  {renderTitle(lineupData.title)}
                </div>
                {renderDesc(
                  id,
                  lineupData.products.sku,
                  lineupData.desc,
                  lineupData.orders,
                  state.currentIndexPoint,
                  pointRef,
                  setState
                )}
              </div>
              {renderPoints(id, lineupData.points, pointRef, pointSetting)}
            </div>
          </div>
        </div>

        <div className="body-tom-and-jerry__lineup__main__bg-footer" data-key={id}>
          <div className="body-tom-and-jerry__lineup__main__layout-product">
            {renderProducts(
              productSetting,
              productRef,
              lineupData.products.limit,
              lineupData.products.sku,
              id,
              state.currentKey
            )}
            <div>
              {renderProductDesc(
                lineupData.products.sku,
                state,
                lineupData.products.colors,
                lineupData.products.price,
                id
              )}
              <div
                className={`body-tom-and-jerry__lineup__main__product__button body-tom-and-jerry__lineup__main__product__button__${id}`}
              >
                {renderButton(lineupData.products.sku, state.currentKey)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const lineupDataArray = [
    {
      id: 'funny',
      lineupData: LINEUP_FUNNY,
      state: stateFUNNY,
      pointRef: pointFunnyRef,
      setState: setStateFUNNY,
      productRef: productFunnyRef,
      pointSetting: pointFunnySetting,
      productSetting: productFunnySetting,
    },
    {
      id: 'chase',
      lineupData: LINEUP_CHASE,
      state: stateCHASE,
      pointRef: pointChaseRef,
      setState: setStateCHASE,
      productRef: productChaseRef,
      pointSetting: pointChaseSetting,
      productSetting: productChaseSetting,
    },
    {
      id: 'friendly-face',
      lineupData: LINEUP_FRIENDLY,
      state: stateFRIENDLY,
      pointRef: pointFriendlyRef,
      setState: setStateFRIENDLY,
      productRef: productFriendlyRef,
      pointSetting: pointFriendlySetting,
      productSetting: productFriendlySetting,
    },
    {
      id: 'cheese',
      lineupData: LINEUP_CHEESE,
      state: stateCHEESE,
      pointRef: pointCheeseRef,
      setState: setStateCHEESE,
      productRef: productCheeseRef,
      pointSetting: pointCheeseSetting,
      productSetting: productCheeseSetting,
    },
  ];

  return (
    <Fragment>
      <section className="body-tom-and-jerry__lineup">
        {lineupDataArray.map(item => lineupSection(item))}
      </section>
    </Fragment>
  );
};
