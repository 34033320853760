export const COMPARISONS = [
  {
    name: 'ENHANCED',
    label: 'ENHANCED',
    image: 'progressive-lenses-1-pc.webp',
    distance: 3,
    intermediate: 2,
    near: 2,
    keyDifferences: [
      {
        name: 'Easy adaptation',
      },
      {
        name: 'Balanced viewing zones',
      },
    ],
    price: {
      value: 0,
      isPlus: false,
      suffix: '',
    },
    isShowRemark: true,
    remark: '* T&Cs',
  },
  {
    name: 'PRESTIGE',
    label: 'PRESTIGE',
    image: 'progressive-lenses-2-pc.webp',
    distance: 4,
    intermediate: 3,
    near: 3,
    keyDifferences: [
      {
        name: 'Easy adaptation',
      },
      {
        name: 'Smaller area of distortion at the edges of the lens',
      },
    ],
    price: {
      value: 200,
      isPlus: true,
      suffix: '',
    },
    isShowRemark: false,
    remark: '',
  },
  {
    name: 'PRESTIGE GOLD',
    label: 'PRESTIGE GOLD',
    image: 'prestige-gold.webp',
    distance: 4,
    intermediate: 4,
    near: 4,
    keyDifferences: [
      {
        name: 'Comfortable vision for any type of activity',
      },
      {
        name: 'Easy accessibility for intermediate zone allows for smooth transition',
      },
    ],
    price: {
      value: 400,
      isPlus: true,
      suffix: '',
    },
    isShowRemark: false,
    remark: '',
  },
  {
    name: 'PRESTIGE DIAMOND',
    label: 'PRESTIGE DIAMOND',
    image: 'progressive-lenses-4-pc.webp',
    distance: 5,
    intermediate: 5,
    near: 5,
    keyDifferences: [
      {
        name: 'Widened intermediate viewing zone',
      },
      {
        name: 'Significantly improved near viewing zone',
      },
    ],
    price: {
      value: 600,
      isPlus: true,
      suffix: '',
    },
    isShowRemark: false,
    remark: '* Available at MBS and Takashimaya S.C. premium concept stores only',
  },
];

export const OFFER_TERMS = [
  'Offer is subject to the customers’ suitability for progressive lens wear and lens stock availability.',
  'Offer is valid with every prescription glasses purchase and excludes the lens replacement service.',
  '$100 discount will be applied for upgrades to Prestige, Prestige Gold, or Prestige Platinum progressive lenses only.',
  'Regular top up fees apply for other additional lens options.',
  'The standard warranty coverage of one-time complimentary change of lens degree is not applicable for the free upgrade to Enhanced progressive lenses.',
  'Free Enhanced progressive lens is offered at all OWNDAYS retail shops in Singapore, except the premium concept stores at Takashimaya S.C. and MBS.',
  'A two-week processing time is required during the campaign period.',
  'Offer cannot be used in conjunction with other discounts, vouchers, and/or promotions.',
  'The management reserves the right to amend the offer and the terms and conditions without prior notice.',
];
