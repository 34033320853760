import React from 'react';
import MapComponent from '../../../CommonComponents/MapComponent/MapComponent';

const Access = ({ access, storeId }) => {
  return (
    <div className="information-details__access">
      <h2>Access</h2>
      {access?.notes && (
        <div
          dangerouslySetInnerHTML={{ __html: access?.notes }}
          className="information-details__access-notes"
        />
      )}
      <MapComponent lat={access?.lat} long={access?.long} />
      <div className="information-details__access-view-map">
        <a
          href={`https://www.google.com/maps/@${access?.lat},${access?.long},17z?entry=ttu`}
          rel="noreferrer"
          target="_blank"
        >
          View larger map
        </a>
      </div>
      <a
        className="information-details__btn"
        href={`https://www.owndays.com/stores/sg?storeId=OD${storeId}`}
      >
        More Details
      </a>
    </div>
  );
};

export default Access;
