import React from 'react';
import { getGenreLabel } from '../../../constants/information';

const Header = ({ info, isTemporaryClosure }) => {
  const formatDate = dateString => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  return (
    <div className={`information-details__header ${isTemporaryClosure ? 'temporary-closure' : ''}`}>
      <div className="information-details__container">
        {info?.releaseDate && (
          <p className="days">
            {formatDate(info?.releaseDate)} ｜ {getGenreLabel(info?.genre)}
          </p>
        )}
        <h1>{info?.title}</h1>
      </div>
    </div>
  );
};

export default Header;
