const gundamProductIds = [221922, 221921, 221924, 221923];
const gundamProductAllIds = [221922, 221921, 221924, 221923, 221925, 222063, 222062, 222061];
const gundamCollectionIds = [222063, 222062, 222061];

export const gundamProductRestrictions = id => {
  if (gundamProductIds.includes(id)) {
    return true;
  }
  return false;
};

export const getGundamCollectionName = id => {
  if (gundamProductAllIds.includes(Number(id))) {
    return 'Gundam';
  }
  return false;
};

export const isGundamProduct = id => {
  if (gundamProductAllIds.includes(Number(id))) {
    return true;
  }
  return false;
};

export const isGundamCollection = id => {
  if (gundamCollectionIds.includes(Number(id))) {
    return true;
  }
  return false;
};

export const transformGundamImages = (id, index) => {
  const pID = Number(id);
  if (pID === 222062) {
    return 'scale(1.8)';
  } else if (pID === 222061 && index < 4) {
    return 'scale(1.3)';
  } else if (pID === 222061 && index >= 4) {
    return 'scale(1.8)';
  } else if (pID === 222063 && index === 0) {
    return 'scale(1.3)';
  } else if (pID === 222063 && index === 1) {
    return 'scale(1.8)';
  } else if (pID === 222063 && index === 2) {
    return 'scale(1.8)';
  } else if (pID === 221925 && index === 0) {
    return 'scale(1.7)';
  } else if (pID === 221925 && index === 1) {
    return 'scale(1.2)';
  } else if (pID === 221925 && index > 1) {
    return 'scale(1.7)';
  }
  return false;
};

export const gundamProductNames = {
  221925: 'ZEON EXCLUSIVE SPECTACLE CASE',
  221922: 'ZEON EXCLUSIVE SPECTACLES',
  221921: 'ZEON EXCLUSIVE SPECTACLES',
  221924: 'ZEON EXCLUSIVE SUNGLASSES',
  221923: 'ZEON EXCLUSIVE SUNGLASSES',
  222063: "ZAKU 'ZEON' Collection",
  222062: "ZAKU 'CHAR' Collection",
  222061: "ZAKU 'Perfect' Collection",
};
