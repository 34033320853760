import React, { Fragment, useEffect } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import { KIMETSU_MENU } from './constants';

import './HeaderKimetsu.scss';

export const HeaderKimetsu = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: `[Demon Slayer: Kimetsu no Yaiba] Character Frame Collection` },
  ];

  const scrollToSection = id => {
    if (!id) return;

    const elementId = id.startsWith('#') ? id.substring(1) : id;
    const element = document.getElementById(elementId);
    if (element) {
      const topOffset = element.offsetTop;

      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scrollToSection(window.location.hash);
      }, 0);
    }
  }, [window.location.hash]);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-kimetsu__main">
        <div className="base-kimetsu__container">
          <picture>
            <source media="(min-width:800px)" srcSet={`${baseImgUrl}/main@2x.webp`} />
            <img
              alt="[Demon Slayer] Key Visual"
              className="header-kimetsu__main--img"
              src={`${baseImgUrl}/main.webp`}
            />
          </picture>
          <img
            alt="BANDAI"
            className="header-kimetsu__main--ban-dai"
            src={`${baseImgUrl}/bandai.svg`}
          />
          <div className="header-kimetsu__main--owndays">
            <h6>Get yours with</h6>
            <h5>
              prescription lenses
              <br />
              only at OWNDAYS
            </h5>
            <img alt="spectacles,glasses,eye glasses" src={`${baseImgUrl}/glasses.svg`} />
          </div>
          <div className="header-kimetsu__main-inner">
            <img
              alt="Demon Slayer: Kimetsu no Yaiba"
              className="header-kimetsu__main--kimetsu"
              src={`${baseImgUrl}/sg/kimetsu.svg`}
            />
            <div className="header-kimetsu__main__heading">
              <img
                alt=""
                className="header-kimetsu__main__heading-bg"
                src={`${baseImgUrl}/main-heading-bg.svg`}
              />
              <h1>
                <span>KIMETSU NO YAIBA</span>CHARACTER
                <br />
                FRAME COLLECTION
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="header-kimetsu__sub">
        <h2 className="header-kimetsu__sub-title">
          <span className="header-kimetsu__sub-title-line header-kimetsu__sub-title-line--left"></span>
          <span className="header-kimetsu__sub-title-text">Concept</span>
          <span className="header-kimetsu__sub-title-line header-kimetsu__sub-title-line--right"></span>
        </h2>

        <div className="base-kimetsu__container">
          <p className="header-kimetsu__sub-text">
            A collection featuring six spectacle frames inspired by the six characters, namely
            Tanjiro, Nezuko, Zenitsu,
            <br className="base-kimetsu__hide--tb" /> Inosuke, Giyu and Shinobu, from the anime
            television series Demon Slayer: Kimetsu no Yaiba.
            <br className="base-kimetsu__hide--tb" /> Each frame is a distinct representation of
            each of the six characters, with frame temples decorated in motifs
            <br className="base-kimetsu__hide--tb" /> depicting iconic patterns found on both the
            Nichirin Sword and the outfit of the respective character.
          </p>

          <ul className="header-kimetsu__sub__nav">
            {KIMETSU_MENU.map(({ id, src, firstName, lastName }) => {
              const key = `${firstName} ${lastName} Model`;

              return (
                <li
                  key={key}
                  className="header-kimetsu__sub__nav-item"
                  onClick={() => scrollToSection(id)}
                >
                  <a className="header-kimetsu__sub__nav-link">
                    <div className="header-kimetsu__sub__nav-img">
                      <img alt={key} src={`${baseImgUrl}/${src}`} />
                    </div>
                    <h5 className="header-kimetsu__sub__nav-title">
                      {firstName}
                      <br className="base-kimetsu__hide--xl" /> {lastName}
                    </h5>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
