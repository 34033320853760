import React, { Fragment } from 'react';

import { HeaderContactUs, BodyContactUs, FooterContactUs } from '../CommonComponents/ContactUs';

const ContactUsHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/owndays/img/contact-us';

  return (
    <Fragment>
      <HeaderContactUs baseImgUrl={baseImgUrl} />
      <BodyContactUs baseImgUrl={baseImgUrl} />
      <FooterContactUs baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default ContactUsHTML;
