export const NAVS_POMPOM = [
  {
    model: 'SR1007B-4A',
    title: 'Caramel Pudding',
    navImg: '/nav-1.svg',
    productImg: '/SR1007B-4A_C1_04.webp',
    id: 'model-01',
  },
  {
    model: 'SR1008B-4A',
    title: 'Pudding À La Mode',
    navImg: '/nav-2.svg',
    productImg: '/SR1008B-4A_C1_04.webp',
    id: 'model-02',
  },
  {
    model: 'SR2003J-4A',
    title: 'Soft Pudding',
    navImg: '/nav-3.svg',
    productImg: '/SR2003J-4A_C1_04.webp',
    id: 'model-03',
  },
  {
    model: 'SR2004N-4A',
    title: 'Laid-Back',
    navImg: '/nav-4.svg',
    productImg: '/SR2004N-4A_C1_04.webp',
    id: 'model-04',
  },
  {
    model: 'SR2005N-4A',
    title: 'Going Out',
    navImg: '/nav-5.svg',
    productImg: '/SR2005N-4A_C1_05.webp',
    id: 'model-05',
  },
];

export const MODELS_POMPOM = [
  {
    id: 'model-01',
    title: 'Caramel Pudding',
    character: '/01/character-pom.svg',
    model: 'SR1007B-4A',
    price: 'S$138',
    desc: `Cute round frame in a glossy colour that looks like it has been drizzled in cameral sauce. Spot Pompompurin and Muffin peeking out from the temples, adding a playful touch to the design.`,
    product: {
      sp: '/SR1007B-4A_C1_02.webp',
      pc: '/SR1007B-4A_C1_02_pc.webp',
    },
    features: [
      {
        src: '/01/point-1.webp',
        desc: '2D figures of Pompompurin \nand Muffin on the temples',
      },
      {
        src: '/01/point-2.webp',
        desc: 'Glossy finish that looks like it has been drizzled in caramel sauce',
      },
      {
        src: '/01/point-3.webp',
        desc: 'Special mark \non the temple tips',
      },
    ],
    colors: [
      {
        key: 'C1',
        title: '#product-SR1007B-4A-C1',
      },
      {
        key: 'C2',
        title: '#product-SR1007B-4A-C2',
      },
    ],
  },
  {
    id: 'model-02',
    title: 'Pudding À La Mode',
    character: '/02/character-pom.svg',
    model: 'SR1008B-4A',
    price: 'S$138',
    desc: `Polygon frame with pudding-inspired accents, just like a Pudding À La Mode. Wearing it will fill you with as much joy as indulging in a delicious pudding made by Mom!`,
    product: {
      sp: '/SR1008B-4A_C1_02.webp',
      pc: '/SR1008B-4A_C1_02_pc.webp',
    },
    features: [
      {
        src: '/02/point-1.webp',
        desc: 'Pudding-inspired accents placed \non both sides \nof the frame front',
      },
      {
        src: '/02/point-2.webp',
        desc: 'Temples resembling an accessory chain',
      },
      {
        src: '/02/point-3.webp',
        desc: 'Special mark \non the temple tips',
      },
    ],
    colors: [
      {
        key: 'C1',
        title: '#product-SR1008B-4A-C1',
      },
      {
        key: 'C2',
        title: '#product-SR1008B-4A-C2',
      },
    ],
  },
  {
    id: 'model-03',
    title: 'Soft Pudding',
    character: '/03/char-2.svg',
    model: 'SR2003J-4A',
    price: 'S$138',
    desc: `Large Wellington frame inspired by Pompompurin’s soft, delightfully plump form. The standout feature is the colour combination inspired by Pompompurin and Muffin. Put it on and you might just feel bigger and bolder!`,
    product: {
      sp: '/SR2003J-4A_C1_02.webp',
      pc: '/SR2003J-4A_C1_02_pc.webp',
    },
    features: [
      {
        src: '/03/point-1.webp',
        desc: 'Colour combination inspired \nby Pompompurin and Muffin',
      },
      {
        src: '/03/point-2.webp',
        desc: 'Pompompurin and Muffin \npeeking out from \ninside the temples',
      },
      {
        src: '/03/point-3.webp',
        desc: 'Special mark \non the temple tips',
      },
    ],
    colors: [
      {
        key: 'C1',
        title: '#product-SR2003J-4A-C1',
      },
      {
        key: 'C2',
        title: '#product-SR2003J-4A-C2',
      },
    ],
  },
  {
    id: 'model-04',
    title: 'Laid-Back',
    character: '/04/char-2.svg',
    model: 'SR2004N-4A',
    price: 'S$138',
    desc: `Enjoy a relaxing time with pudding in this lightweight, comfortable resin frame. The temples are adorned with a fun-filled design featuring Pompompurin and friends!`,
    product: {
      sp: '/SR2004N-4A_C1_02.webp',
      pc: '/SR2004N-4A_C1_02_pc.webp',
    },
    features: [
      {
        src: '/04/point-1.webp',
        desc: 'Lightweight frame \nthat fits perfectly',
      },
      {
        src: '/04/point-2.webp',
        desc: 'Pompompurin \nand friends featured \non the temples',
      },
      {
        src: '/04/point-3.webp',
        desc: 'Special mark \non the temple tips',
      },
    ],
    colors: [
      {
        key: 'C1',
        title: '#product-SR2004N-4A-C1',
      },
      {
        key: 'C2',
        title: '#product-SR2004N-4A-C2',
      },
    ],
  },
  {
    id: 'model-05',
    title: 'Going Out',
    character: '/05/character-pom.svg',
    model: 'SR2005N-4A',
    price: 'S$178',
    desc: `2-in-1 Boston frame that can double up as sunglasses for your favourite outings. Say hello to Pompompurin and Muffin printed on the attachment lens!`,
    product: {
      sp: '/SR2005N-4A_C1_05.webp',
      pc: '/SR2005N-4A_C1_05_pc.webp',
    },
    features: [
      {
        src: '/05/point-1.webp',
        desc: 'Pompompurin and Muffin \nprinted on \nthe attachment lens',
      },
      {
        src: '/05/point-2.webp',
        desc: 'Pompompurin and friends \nfeatured on the temples',
      },
      {
        src: '/05/point-3.webp',
        desc: 'Special mark \non the temple tips',
      },
    ],
    colors: [
      {
        key: 'C1',
        title: '#product-SR2005N-4A-C1',
      },
      {
        key: 'C2',
        title: '#product-SR2005N-4A-C2',
      },
    ],
  },
];

export const ACCESSORIES_POMPOM = [
  {
    title: 'Lying Down Plush Cleaner',
    price: 'S$10',
    link: '/sg/en/owndays-pompompurin-SR-CLEANER001-4A-accessories.html',
    desc: `Featuring an adorable lying-down pose, this fluffy plush cleaner is impossible to resist! The bottom is made of a material perfect for cleaning your lenses and it comes with a chain for you to hang up and bring around.`,
  },
  {
    title: 'Fluffy Plush Case',
    price: 'S$20',
    link: '/sg/en/owndays-pompompurin-SR-CASE001-4A-accessories.html',
    desc: `Pompompurin shows off his playful side with this plush ‘bottom’ case, featuring his friend Muffin on the zipper. It comes with a strap, so you can easily attach it to your bag and bring it on your outings.`,
  },
];

export const SNS_POMPOM = [
  {
    link: 'https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/jp/ja/news/pompompurin',
    className: 'fa-facebook',
  },
  {
    link: 'https://twitter.com/intent/tweet?url=https://www.owndays.com/jp/ja/news/pompompurin',
    className: 'fa-x-twitter',
  },
  {
    link: 'https://line.me/R/msg/text/?https://www.owndays.com/jp/ja/news/pompompurin',
    className: 'fa-line',
  },
];
