import React from 'react';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';
import { TermsAndConditions } from '../../../CommonComponents/TermsAndCondition';
const TermsConditionsPage = () => {
  return (
    <main>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'Discover OWNDAYS Singapore and understand our terms & conditions for a smooth shopping journey. Ensuring transparency and trust in your eyewear purchases.',
          },
          {
            name: 'twitter:description',
            content:
              'Discover OWNDAYS Singapore and understand our terms & conditions for a smooth shopping journey. Ensuring transparency and trust in your eyewear purchases.',
          },
          {
            name: 'og:title',
            content: 'Terms & Conditions | OWNDAYS Singapore',
          },
          {
            name: 'twitter:title',
            content: 'Terms & Conditions | OWNDAYS Singapore',
          },
        ]}
        description="Discover OWNDAYS Singapore and understand our terms & conditions for a smooth shopping journey. Ensuring transparency and trust in your eyewear purchases."
        title="Terms & Conditions | OWNDAYS Singapore"
      />
      <TermsAndConditions />
    </main>
  );
};

export default TermsConditionsPage;
