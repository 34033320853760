import React, { Fragment, useState, useEffect } from 'react';

import Breadcrumbs from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';
import { SwiftUpText } from './SwiftUpText';

import { LINEUP_MENU } from './constants';
import './HeaderAnniversary10thCollection.scss';

export const HeaderAnniversary10thCollection = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: '10th Anniversary Collection' },
  ];

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const scrollToSection = id => {
    if (!id) return;

    const elementId = id.startsWith('#') ? id.substring(1) : id;
    const element = document.getElementById(elementId);

    window.location.hash = elementId;

    if (element) {
      const headerOffset = document.querySelector('header')?.offsetHeight || 0;
      const elementTop = element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementTop - headerOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scrollToSection(window.location.hash);
      }, 0);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-anniversary-10th-collection__main">
        <picture>
          <source
            media="(min-width:768px)"
            srcSet={`${baseImgUrl}/main-pc.webp`}
            type="image/webp"
          />
          <img alt="10th Anniversary Collection" src={`${baseImgUrl}/main-sp.webp`} />
        </picture>

        <div className="header-anniversary-10th-collection__main__text">
          <h1 style={isDesktop ? { display: 'inline' } : {}}>
            {isDesktop ? (
              <>
                <SwiftUpText delay={0} tag="strong" text="10th Anniversary Collection" />
                <SwiftUpText delay={1} tag="small" text="In Singapore Since 07.13" />
              </>
            ) : (
              <>
                <SwiftUpText delay={0} tag="strong" text="10th Anniversary" />
                <SwiftUpText delay={0.7} tag="strong" text=" Collection" />
                <SwiftUpText delay={1} tag="small" text="In Singapore Since 07.13" />
              </>
            )}
          </h1>
        </div>
      </section>

      <section className="header-anniversary-10th-collection__concept">
        <div className="base-anniversary-10th-collection__container">
          <p data-aos="fade-up">
            A commemorative collection to mark 10 amazing years in Singapore, featuring frame
            designs created specifically for the discerning local customers who manifest in the love
            for tasteful yet functional eyewear. The lineup, consisting of 2 metal frames and 2
            plastic frames, embodies our aesthetic interpretation and creative vision to the classic
            eyewear perfect for Singaporeans – minimalist with a bit of nostalgia to channel the art
            of quiet confidence.
          </p>

          <a data-fancybox="etc" href="https://youtu.be/oLblIgUWD3U">
            <div data-aos className="base-anniversary-10th-collection__frame">
              <img
                alt="10th Anniversary Collection"
                loading="lazy"
                src={`${baseImgUrl}/video-thumbnail.webp`}
              />
            </div>
          </a>
        </div>
      </section>

      <section className="header-anniversary-10th-collection__lineup-menu">
        <div className="base-anniversary-10th-collection__container">
          <div className="header-anniversary-10th-collection__lineup-menu__layout">
            <div className="header-anniversary-10th-collection__lineup-menu__title header-anniversary-10th-collection__lineup-menu__menu--div1">
              <h2>Line up</h2>
            </div>

            <div className="header-anniversary-10th-collection__lineup-menu__banner__text header-anniversary-10th-collection__lineup-menu__menu--div2">
              <div className="header-anniversary-10th-collection__lineup-menu__banner__text__layout">
                <p>
                  4 designs with 2 colour <br />
                  variations each
                </p>
              </div>

              <div className="header-anniversary-10th-collection__lineup-menu__banner__text__layout">
                <p>S$100</p>
              </div>
            </div>

            <div data-aos className="header-anniversary-10th-collection__lineup-menu__menu--div3">
              <img
                alt="10th Anniversary Collection"
                loading="lazy"
                src={`${baseImgUrl}/line-up-header.webp`}
              />
            </div>

            <div className="base-anniversary-10th-collection__container header-anniversary-10th-collection__lineup-menu__menu--div4">
              <div className="header-anniversary-10th-collection__lineup-menu__menu">
                <ul>
                  {LINEUP_MENU.map(({ id, title, img }) => (
                    <li key={id} onClick={() => scrollToSection(`#${id}`)}>
                      <div className="header-anniversary-10th-collection__lineup-menu__menu__title">
                        {title}
                      </div>
                      <div className="header-anniversary-10th-collection__lineup-menu__menu__image">
                        <img alt={title} loading="lazy" src={`${baseImgUrl}${img}`} />
                      </div>
                      <div className="header-anniversary-10th-collection__lineup-menu__menu__arrow">
                        <img alt={title} loading="lazy" src={`${baseImgUrl}/arrow-down.webp`} />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
