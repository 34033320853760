import React, { useEffect, useState } from 'react';
import AOS from 'aos';

import { HeaderAnniversary10thCollection } from './HeaderAnniversary10thCollection';
import { BodyAnniversary10thCollection } from './BodyAnniversary10thCollection';
import { FooterAnniversary10thCollection } from './FooterAnniversary10thCollection';

import './BaseAnniversary10thCollection.scss';

const Anniversary10thCollectionLP = () => {
  const [loaded, setLoaded] = useState(false);
  const [bg, setBg] = useState(false);

  const baseImgUrl = 'https://static.lenskart.com/owndays/img/anniversary-10th-collection';

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setLoaded(true);
    const timer = setTimeout(() => {
      setBg(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`base-anniversary-10th-collection ${loaded ? 'loaded' : ''} ${bg ? 'bg' : ''}`}>
      <HeaderAnniversary10thCollection baseImgUrl={baseImgUrl} />
      <BodyAnniversary10thCollection baseImgUrl={baseImgUrl} />
      <FooterAnniversary10thCollection baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default Anniversary10thCollectionLP;
