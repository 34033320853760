import React from 'react';

const Footer = () => {
  return (
    <div className="information-details__footer">
      <div className="information-details__container information-details__container--lg">
        <div className="information-details__footer-inner">
          <a href="/sg/en/information">
            <svg
              fill="#999"
              height={18}
              viewBox="0 0 320 512"
              width={18}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
            Back to list
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
