import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="og:title" content="#17 FUJIWARA KATSUAKI - OWNDAYS MEETS | OWNDAYS ONLINE STORE - OPTICAL SHOP)" />
    <meta name="description" content="Our guest in Part 17 of OWNDAYS MEETS is Fujiwara Katsuaki, a former professional motorcycle racer who is currently the coach of the Kawasaki Racing Team." />
    <meta name="keywords" content="FUJIWARA KATSUAKI,Coach of Kawasaki Racing Team,OWNDAYS MEETS,spectacles,glasses,optical shop,eye glasses,sunglasses,eyewear,pc lenses,frame" />
    <meta name="og:description" content="Our guest in Part 17 of OWNDAYS MEETS is Fujiwara Katsuaki, a former professional motorcycle racer who is currently the coach of the Kawasaki Racing Team." />
    <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/owndays-meets-201807">
          <meta property="og:image" content="https://www.owndays.com/images/specials/owndays-meets/201807/ogp_1200x630.jpg?1553872848">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
        <link href="https://www.owndays.com/web/css/owndays-meets.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title"> #17 FUJIWARA KATSUAKI | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            
        <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            #17 FUJIWARA KATSUAKI                    </li>
        
            </ul>
</div>

    <div class="l-owndays-meets">
            <section class="p-main">
        <div class="p-main__bg">
            <picture>
                <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/owndays-meets/201807/01_imgcnv-2880x1620.webp">
                <img src="https://www.owndays.com/images/specials/owndays-meets/201807/01_imgcnv-768x615.webp" alt="">
            </picture>
        </div>
        <div class="p-main__title">
            <h1 class="p-main__title-default meets-title">
                <img src="https://www.owndays.com/images/specials/owndays-meets/owndays-meets.png" alt="OWNDAYS MEETS">
                <span class="meets-title__vol">vol. 17</span>
                <span class="meets-title__name"><span><span>FUJIWARA KATSUAKI<span>Coach of Kawasaki Racing Team</span></span></span></span>
            </h1>
        </div>
    </section>
        <div class="l-content--inner">
            <p class="l-owndays-meets__information">    Our guest in Part 17 of OWNDAYS MEETS is Fujiwara Katsuaki, a former professional motorcycle racer who is currently the coach of the Kawasaki Racing Team. We speak to him about his journey moving from motorcycle racing into coaching and of his plans for the future.</p>
        </div>
        <section class="l-owndays-meets__profile">
                <div>
        <figure><img src="https://www.owndays.com/images/specials/owndays-meets/201807/profile_imgcnv.webp" alt="FUJIWARA KATSUAKI"></figure>
        <div>
            <div>
                <h2 class="profile__name">FUJIWARA KATSUAKI<span></span></h2>
                <p class="profile__job">Coach of Kawasaki Racing Team</p>
            </div>
        </div>
    </div>
    <p class="profile__text">    Born on 27 March 1975 in Shimonoseki, Yamaguchi Prefecture of Japan, Fujiwara Katsuaki is currently the team manager of the Kawasaki Asia Team which is competing in the FIM Asia Road Racing Championship and the coach of the Kawasaki Team Green in Japan. Both teams are owned by Kawasaki Heavy Industries Ltd.<br>Influenced by his father, Fujiwara started riding pocket bike at the age of 8. In 1991, he became a professional motorcycle racer after making his debut in the All Japan Road Race Championship that year. In 1998, Fujiwara began racing in world championships for a span of 13 years. In 2011, he shifted his focus to Asia races and had achieved stellar results with the international experience under his belt. Fujiwara retired from racing in 2014 and is currently the coach of the Kawasaki Racing Team.<br>Fujiwara had recorded 88 wins out of 91 pocket bike races and 191 wins out of 201 mini bike races.<br>- Career -<br>1992 Suzuka 4 Hours Endurance Race, Rank: 1st<br>1994 All Japan Road Race Championship (GP250), Rank: 3rd<br>1995 All Japan Road Race Championship (Superbike), Rank: 3rd<br>1995 Suzuka 8 Hours Endurance Race, Rank: 3rd<br>1997 All Japan Road Race Championship (Superbike), Rank: 2nd<br>2002 Supersport World Championship, Rank: 2nd<br>2005 Supersport World Championship, Rank: 3rd<br>2005 Suzuka 8 Hours Endurance Race, Rank: 2nd<br>2011 Asia Road Racing Championship (Supersports 600), Rank: 1st<br>2012 Asia Road Racing Championship (Supersports 600), Rank: 2nd<br>2013 Asia Road Racing Championship (Supersports 600), Rank: 2nd</p>
    <p class="profile__link"></p>
        </section>
        <section class="l-owndays-meets__interview">
            <div class="l-content--inner">
                <div class="interview__movie">
                    <a href="" data-video="https://www.youtube.com/embed/pp3ttaknnfY?loop=1&playlist=pp3ttaknnfY" data-toggle="modal" data-target="#videoModal">
                        <img src="https://www.owndays.com/images/specials/owndays-meets/201807/movie_imgcnv.webp" alt="OWNDAYS MEETS FUJIWARA KATSUAKI()">
                    </a>
                    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModal" area-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe width="560" height="315" src="" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                    </div>
                    <h3 class="interview__question"><span><span>Why led you into motorcycle racing?</span></span></h3>
    <p class="interview__answer">Actually, I wasn’t interested in motorcycles at the beginning. When I was 8 years old, a new pocket bike circuit was built near my home. My dad who was into motorcycles brought me to the circuit but I didn’t like it at all. I remember being made to ride in jeans and hoodie whereas the other people all wore racing suits.<br>My mom used to pick me up after school and I would practise until it got dark. That was basically how I spent my childhood. I got faster with more practice and became engrossed in it unknowingly. When I came to realise it, I had already become a professional racer.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201807/02_imgcnv.webp" alt="">
    </div>
    <h3 class="interview__question"><span><span>What has been the most memorable episode as a motorcycle racer?</span></span></h3>
    <p class="interview__answer">It has to be the opening race of the 2005 Losail Superbike World Championship in Qatar. The motorcycle I rode didn’t have a tachometer on its instrument panel and I had never practised starting that type of motorcycles. Usually when I start the engine, I’ll check the tachometer to determine when to engage the clutch. So at that time, I started the engine by guessing the number of revolutions per minute based on my gut feeling and the motorcycle stalled. I became the last one in the race.<br>However, I tried to catch up right after that and eventually made it to the first with just 3 laps left to go. In the end, I won the race and was 7 seconds ahead of the next rider. I had many different races after that but this was such an intense race that I can still remember vividly even now.</p>
    <h3 class="interview__question"><span><span>What made you decide retire from racing in 2014?</span></span></h3>
    <p class="interview__answer">I’ve hardly talked about it actually. 4 years ago after winning the Suzuka race, I went to Kawasaki Heavy Industries’ head office to discuss the contract for the next season. When I was in the meeting room with the executives, I sensed that the atmosphere in the room was a bit different from the usual. And it was then when they asked me to be the coach of the Kawasaki Racing Team.<br>I had, of course, wanted to continue being the top racer but I thought it would be a nice retirement for me after winning the last race. I wanted to maintain my image as a fast rider instead of moving into coaching only when I was going downhill. Offering me the position as a coach to lead the team was a huge recognition and that was one of the biggest reasons I decided to retire and move into coaching.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201807/03_imgcnv.webp" alt="">
    </div>
    <h3 class="interview__question"><span><span>What advantage do you have?</span></span></h3>
    <p class="interview__answer">It is becoming a coach right after my racing career and having collected data on the good and bad as well as the setup of motorcycles after working on motorcycle development in the last 10 years.<br>I’ll be able to know the situation the motorcycle is in just by looking at the data, even if the circuit has changed or if a rider has any feedback. The motorcycle currently used in races is also developed and used by me. That’s why, I do understand the rider’s feedback and can resolve any problem with the know-how I’ve acquired during my time as a racer. I think this is my advantage for having worked on motorcycle development all this while and for moving into coaching right after my racing career.</p>
    <h3 class="interview__question"><span><span>What is your next goal?</span></span></h3>
    <p class="interview__answer">I coach the riders on various things and I hope to claim the world title with the young riders in the Kawasaki team. I had raced in world championships during my 13-year racing career but I had never clinched any titles. I had only clinched titles as a rider and as a coach in the Asia races so I really hope to help the young riders shine on the world stage moving forward.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201807/04_imgcnv.webp" alt="">
    </div>
            </div>
        </section>
        <section class="l-owndays-meets__guestselect">
                <div class="l-content--inner">
        <h2>
            Guest Select
            <a href="/sg/en/products?productLines[]=29">OWNDAYS</a>
        </h2>
        <ul class="guestselect__photos">
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201807/glasses_imgcnv.webp" alt="OWNDAYS/SUN1025-T"></div></li>
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201807/05_imgcnv.webp"></div></li>
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201807/06_imgcnv.webp"></div></li>
        </ul>
        <p>    I’m a sunglass maniac so I own hundreds of sunglasses. The key factor when it comes to picking a pair of sunglasses is that it must look cool. Different designs create different impressions. Sometimes I’ll choose an outfit just to match the sunglasses.<br>The pair of sunglasses that I’ve chosen this time is really cool. It’s comfortable to wear and the design is great. You’ll know how cool it is when you actually wear it.</p>
        <div class="guestselect__product">
            <dl>
                <dt>P/No.</dt><dd>SUN1025-T</dd>
                <dt>Price</dt><dd>S$78.00</dd>
                <dt>Colour</dt><dd>C1 Gun</dd>
            </dl>
        </div>
        <div class="c-btn c-btn--primary c-btn--primary-center u-mt__30">
            <a href="/sg/en/products/SUN1025-T">More Details</a>
        </div>
    </div>
        </section>
        <section id="latestpost" class="l-owndays-meets__latestpost">
    <div class="l-content--inner">
        <h2>Latest post</h2>
        <!-- 最新の記事2つ -->
                <div class="latestpost__list">
            <a href="/sg/en/news/owndays-meets-201905">
                <img src="https://www.owndays.com/images/specials/owndays-meets/201905/list-main.webp" alt="HIROTADA OTOTAKE / Writer">
                <div>
                    <p class="latestpost__list-vol">vol. 19</p>
                    <p class="latestpost__list-name">HIROTADA OTOTAKE / Writer</p>
                </div>
            </a>
            <a>
                <img src="https://www.owndays.com/images/specials/owndays-meets/201901/list-main.webp" alt="KOMATSU MIWA / Contemporary Artist">
                <div>
                    <p class="latestpost__list-vol">vol. 18</p>
                    <p class="latestpost__list-name">KOMATSU MIWA / Contemporary Artist</p>
                </div>
            </a>
        </div>
                <div class="c-btn c-btn--primary c-btn--primary-center u-mt__30">
            <a href="/sg/en/news#owndays-meets">Back to list</a>
        </div>
    </div>
</section>
    </div>
        </main>
            </div>

    
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>


<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
    <script src="https://www.owndays.com/web/js/movie-modal.js" defer></script>
    <script>
        $(document).ready(function () {
            autoPlayYouTubeModal();
        });
    </script>
 
</body>

</html>`;

const OwndaysMeetsFujiwaraKatsuakiHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        canonical="news/owndays-meets-201807"
        description="Step into the intriguing world of FUJIWARA KATSUAKI in the 17th edition of 'OWNDAYS MEETS'. Discover his journey, inspirations, and the eyewear that reflects his distinct style. | OWNDAYS Singapore"
        title="OWNDAYS MEETS: #17 FUJIWARA KATSUAKI | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default OwndaysMeetsFujiwaraKatsuakiHTML;
