import React from 'react';

const Genre = ({ genres, selectedGenre, setSelectedGenre, setCurrentPage }) => {
  const handleGenreChange = genre => {
    setSelectedGenre(genre);
    setCurrentPage(1);
    const url = new URL(window.location);
    if (genre === 'ALL') {
      url.searchParams.delete('category');
    } else {
      url.searchParams.set('category', genre);
    }
    window.history.pushState({}, '', url);
  };
  return (
    <ul className="information__genre">
      <li className="information__genre-item">
        <button
          className={`information__genre-btn ${selectedGenre === 'ALL' ? 'active' : ''}`}
          onClick={() => handleGenreChange('ALL')}
        >
          ALL
        </button>
      </li>
      {genres?.map(genre => (
        <li key={genre?.id} className="information__genre-item">
          <button
            className={`information__genre-btn ${selectedGenre === genre?.id ? 'active' : ''}`}
            onClick={() => handleGenreChange(genre?.id)}
          >
            {genre?.label}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default Genre;
