const baseImgUrl =
  'https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses';
const baseImgUrl2 = 'https://static1.lenskart.com/owndays/img/news/free-myopia-control-2-lenses';

export const MAIN_LIST = [
  {
    iconPath: `${baseImgUrl}/icon-1.svg`,
    text: 'Clinically proven to slow down <br/>myopia progression by up to 50%<sup>1</sup>',
  },
  {
    iconPath: `${baseImgUrl}/icon-2.svg`,
    text: 'For Children Aged 14 and Below <br/>With Spectacles Purchase',
  },
];

export const UNDERSTANDING = {
  features: [
    {
      iconSrc: `${baseImgUrl}/icon-5.svg`,
      iconAlt: 'Icon check',
      text: 'Hard multi-coat',
    },
    {
      iconSrc: `${baseImgUrl}/icon-5.svg`,
      iconAlt: 'Icon check',
      text: '99% UV protection',
    },
    {
      iconSrc: `${baseImgUrl}/icon-5.svg`,
      iconAlt: 'Icon check',
      text: 'High index aspheric lenses',
    },
  ],
  benefits: [
    {
      iconSrc: `${baseImgUrl}/icon-1.svg`,
      iconAlt: 'Icon plus',
      text: 'Clinically proven to slow down myopia progression by up to 50%<sup>1</sup>',
    },
    {
      iconSrc: `${baseImgUrl}/icon-3.svg`,
      iconAlt: 'Icon eye side',
      text: 'Minimise axial elongation',
    },
    {
      iconSrc: `${baseImgUrl}/icon-4.svg`,
      iconAlt: 'Icon eye',
      text: 'Quick and easy adaptation for wearers to adjust to wearing the lenses quickly and smoothly',
    },
  ],
  contents: [
    {
      imgSrc: `${baseImgUrl2}/MCL-IMG-1.webp`,
      imgAlt: 'Importance of Managing Myopia Early',
      title: 'Importance <br class="md-none"/>of Managing Myopia Early',
      description:
        'As myopia cannot be reversed, early intervention using Myopia Control 2 lens can help slow down its progression. Studies have shown that myopia increases faster in children<sup>2</sup>, making myopia control particularly important for them. High myopia may elevate the risk of ocular pathology that might lead to irreversible vision loss and blindness.',
    },
    {
      imgSrc: `${baseImgUrl2}/MCL-IMG-2.webp`,
      imgAlt: 'How OWNDAYS Myopia Control 2 Works',
      title: 'How OWNDAYS Myopia <br class="md-none"/>Control 2 Works',
      description:
        'When light enters our eyes, it may not be focused evenly across the retina. The central light rays will be focused centrally on the retina while the peripheral light rays might be “focused” behind the retina. This phenomenon, known as hyperopic defocus, acts as a stimulus for the eyes to elongate which results in myopia growth.<br/><br/>Myopia Control 2 lens is engineered to reduce peripheral hyperopic defocus, thereby minimising eye growth signal which potentially controls the axial elongation of the eyeball. This effectively slows down myopia progression by up to 50%<sup>1</sup>.',
    },
  ],
};

export const CMMP_LIST = [
  {
    imgSrc: `${baseImgUrl}/icon-6.svg`,
    imgAlt: 'Regular Monitoring',
    title: 'Regular Monitoring',
    description: `We ensure your child's vision is closely monitored with a complimentary review
                  every 6 months. This helps us track the progression of myopia and adjust treatment
                  as needed.`,
  },
  {
    imgSrc: `${baseImgUrl}/icon-7.svg`,
    imgAlt: 'Complimentary One Time Change of Lens Degree',
    title: 'Complimentary One Time Change of Lens Degree',
    description: `If your child's myopia increases by 0.50 diopter or more within the first 6 months of purchase, we will change their lens degree at no extra cost.`,
  },
  {
    imgSrc: `${baseImgUrl}/icon-8.svg`,
    imgAlt: 'Expert Consultation',
    title: 'Expert Consultation',
    description: `We provide consultations on alternative myopia management options, in the event Myopia Control 2 has not achieved the intended results discovered through the regular reviews. This includes exploring advanced solutions like Ortho-K lenses to effectively manage myopia progression.`,
  },
];

export const RS_PRODUCTS = [
  {
    imgSrc: `${baseImgUrl}/JU2031N-1S_C2.webp`,
    imgAlt: 'JU2031N-1S C2',
    no: 'JU2031N-1S C2',
    colorCode: '#865928',
    url: '/sg/en/junni-ju2031n-1s-c2-eyeglasses.html',
  },
  {
    imgSrc: `${baseImgUrl}/JU1022G-1A_C3.webp`,
    imgAlt: 'JU1022G-1A C3',
    no: 'JU1022G-1A C3',
    colorCode: '#06348B',
    url: '/sg/en/junni-ju1022g-1a-c3-eyeglasses.html',
  },
  {
    imgSrc: `${baseImgUrl}/JU2030N-1S_C1.webp`,
    imgAlt: 'JU2030N-1S C1',
    no: 'JU2030N-1S C1',
    colorCode: '#000',
    url: '/sg/en/junni-ju2030n-1s-c1-eyeglasses.html',
  },
];

export const RS_NOTES = [
  'Children wearing myopic peripheral defocus lenses (MPDL) showed reductions in absolute growth of axial length by 50% and 39% after 6 and 12 months of treatment respectively. Sánchez-Tena MÁ, Cleva JM, Villa-Collar C, et al. Effectiveness of a Spectacle Lens with a Specific Asymmetric Myopic Peripheral Defocus: 12-Month Results in a Spanish Population. Children. 2024; 11(2):177.<a href="https://doi.org/10.3390/children11020177" target="_blank">&nbsp</a>',
  'Verkicharla PK, Kammari P, Das AV (2020) Myopia progression varies with age and severity of myopia. PLoS ONE 15(11): e0241759.<a href="https://doi.org/10.1371/journal.pone.0241759" target="_blank">&nbsp</a>',
];

export const TERMS = [
  'Offer is valid with the purchase of prescription glasses only and excludes the lens replacement service',
  'Offer is valid for customers aged 14 years old and below only',
  'Children under 8 years old have to book an appointment at selected OWNDAYS stores',
  'Lens sold will not be covered by the OWNDAYS Customer Guarantees warranty programme',
  'There will be a one-time exceptional warranty on lens visual performance within 6 months of purchase; lens power based on doctor’s prescription (Dr Rx) will be excluded from this warranty coverage',
  'Regular top up fee applies for additional lens upgrades in which the standard 1-year warranty on lens visual performance will be valid',
  'Offer is valid at OWNDAYS retail shops in Singapore only and is subject to stock availability of Myopia Control Lens',
  'A two-week processing time is required for Myopia Control Lens made during the campaign period',
  'Offer cannot be used in conjunction with other discounts, vouchers and/ or promotions',
  'The management reserves the right to amend the offer and the terms and conditions without prior notice',
];
