import { fork, call, put, take, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/header';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;
function* getResource(resource, successAction, errorAction) {
  try {
    const data = yield call(async () => _client.get(resource));
    console.log('naviagtion menu =====>', resource, data);
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getMainNav() {
  console.log('navigation.json', config.apiPath.mainNavigation);
  yield take(actionTypes.LOAD);
  yield call(
    getResource,
    config.apiPath.mainNavigation,
    { type: actionTypes.LOAD_NAVIGATION },
    { type: actionTypes.FAIL_NAVIGATION }
  );
}

function* getMobileNavigationData() {
  console.log('navigation_menu.json', config.apiPath.mobileNavigationJsonUrl);
  yield take(actionTypes.GET_MSITE_NAV);
  yield call(
    getResource,
    config.apiPath.mobileNavigationJsonUrl,
    { type: actionTypes.GET_MSITE_NAV_SUCCESS },
    { type: actionTypes.GET_MSITE_NAV_FAIL }
  );
}

export function* runHeaderCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([fork(getMainNav), fork(getMobileNavigationData)]);
}
