import React from 'react';

import { STAFF_CONTENTS } from './constants';

import './StaffSection.scss';

const StaffSection = () => {
  return (
    <>
      {STAFF_CONTENTS.map((content, index) => (
        <section key={index} className="staff-section__content">
          <div className="staff-section__content-inner">
            <h2 className="staff-section__content-title">{content.title}</h2>
            <p className="staff-section__content-description">
              {index === 0
                ? content.description.split('usage and more.').map((sentence, idx, arr) => (
                    <React.Fragment key={idx}>
                      {sentence}
                      {idx < arr.length - 1 && (
                        <React.Fragment key={`${idx}-br`}>
                          usage and more.
                          <br />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ))
                : content.description}
            </p>
            {content.table && (
              <div className="staff-section__content-table">
                <table>
                  <tbody>
                    {content.table.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        <th className={rowIndex === content.table.length - 1 ? '-last' : ''}>
                          <picture>
                            <img alt={row.grade} src={row.imgSrc} />
                          </picture>
                        </th>
                        <td className={rowIndex === content.table.length - 1 ? '-last' : ''}>
                          <h3 className="staff-section__content-table td-title">{row.grade}</h3>
                          <p className="staff-section__content-table td-text">{row.description}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      ))}
    </>
  );
};

export default StaffSection;
