export const WARRANTY_CONTENTS = [
  {
    section: "Visual Performance",
    items: [
      {
        number: "WARRANTY 01",
        term: "1year / 1times",
        title: "1-Year warranty on lens visual performance",
        description:
          "We provide a one-time complimentary change of lens degree if you experience discomfort in your vision within the first year of purchase.",
        notes: [
          "Degree based on power specified by the customer or a doctor’s prescription (Dr Rx) is not covered under this warranty.",
        ],
      },
      {
        number: "WARRANTY 02",
        term: "2months",
        title:
          "Free exchange to regular lenses if you are unable to get used to progressive glasses",
        description:
          "If you are unable to get used to progressive glasses, you may exchange them to regular lenses at no charge within two months of purchase.",
      },
      {
        number: "WARRANTY 03",
        term: "2months",
        title: "Free exchange to regular lenses if colour lenses are not suitable",
        description:
          "If the colour lenses you have picked are not suitable for you whether in terms of style or visual performance, you may exchange them to regular lenses at no charge within two months of purchase.",
        notes: [
          "Change of lens colour or tint density is excluded from this warranty.",
        ],
      },
      {
        number: "WARRANTY 04",
        title: "We provide complimentary visual acuity test and auto-refractor scanning",
      },
    ],
  },
  {
    section: "Product Quality",
    items: [
      {
        number: "WARRANTY 05",
        term: "1year",
        title: "1-Year warranty on frames",
        description:
          "We provide complimentary repair or exchange of your frames should there be any manufacturing defects within one year of purchase.",
        additionalInfo:
          "We will offer you a replacement item of the same value if we are unable to repair your current frame and it is no longer in stock.",
        notes: [
          "The following are not covered under frame warranty:",
          "Damage due to deliberate misuse, negligence or lack of care.",
          "Sale items.",
          "Warranty card must be presented.",
        ],
      },
      {
        number: "WARRANTY 06",
        title:
          "Free lifetime fitting, cleaning and maintenance services for your OWNDAYS glasses",
        description:
          "We provide complimentary fitting, cleaning and maintenance services for your OWNDAYS glasses in all OWNDAYS shops around the world. Simply bring your OWNDAYS glasses to any OWNDAYS shop near you, wherever you may be.",
      },
    ],
  },
  {
    section: "Damage Protection",
    items: [
      {
        number: "WARRANTY 07",
        term: "1year",
        title: "50% discount for your replacement pair",
        description:
          "If your glasses are damaged within one year of purchase for reasons not covered by our warranty, we will offer you 50% discount when you purchase a replacement pair.",
        notes: [
          "The damaged frame must be returned to OWNDAYS.",
          "You may utilise the 50% discount on other items of the same value if your current frame is no longer in stock.",
          "Limited to one-time discount only.",
        ],
      },
    ],
  },
  {
    section: "Others",
    items: [
      {
        number: "WARRANTY 08",
        title: "Warranties are valid in OWNDAYS shops worldwide",
        description:
          "As OWNDAYS continues to expand our global retail network, we endeavour to provide you the most comprehensive after-sales support. Simply approach any OWNDAYS shop near you, wherever you may be.",
      },
    ],
  },
];
