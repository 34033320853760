import React, { Fragment, useState, useRef, useEffect, useMemo } from 'react';
import AOS from 'aos';

import { COOKIES, DEFAULT_COOKIES_SETTINGS } from './constants';
import './CookiesModal.scss';

export const CookiesModal = () => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [expandList, setExpandList] = useState({
    essential: false,
    performance: false,
    advertising: false,
  });
  const settingModalRef = useRef(null);
  const [settings, setSettings] = useState(DEFAULT_COOKIES_SETTINGS);
  const [aosType, setAosType] = useState('fade-up');

  const settingsList = useMemo(
    () => [
      {
        ...COOKIES[0],
        value: settings.necessaryServices,
      },
      {
        ...COOKIES[1],
        value: settings.improvedSettings,
      },
      {
        ...COOKIES[2],
        value: settings.marketingSettings,
      },
    ],
    [settings]
  );

  const handleToggleSettingModal = () => {
    setOpenModal(!isOpenModal);
  };

  const handleSetExpandList = name => {
    const keyMap = {
      [COOKIES[0].name]: 'essential',
      [COOKIES[1].name]: 'performance',
      [COOKIES[2].name]: 'advertising',
    };

    if (keyMap[name]) {
      setExpandList(prev => ({ ...prev, [keyMap[name]]: !prev[keyMap[name]] }));
    }
  };

  const isExpanded = name =>
    (expandList.essential && name === 'Essential Cookies') ||
    (expandList.performance && name === 'Performance Cookies') ||
    (expandList.advertising && name === 'Advertising Cookies');

  const privacyLink = '/sg/en/privacy';

  const handleClickOutside = event => {
    if (settingModalRef.current && !settingModalRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  const handleToggleSetting = key => {
    if (!key) return;

    setSettings(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleSaveSettings = () => {
    localStorage.setItem('cookies-user-settings', JSON.stringify(settings));

    setOpenPopup(false);
    setOpenModal(false);
  };

  const handleAcceptAll = () => {
    const updatedSettings = {
      necessaryServices: true,
      functionalSettings: true,
      marketingSettings: true,
    };

    setSettings(updatedSettings);
    localStorage.setItem('cookies-user-settings', JSON.stringify(updatedSettings));

    setOpenPopup(false);
  };

  useEffect(() => {
    if (isOpenModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpenModal]);

  useEffect(() => {
    if (!localStorage.getItem('cookies-user-settings')) setOpenPopup(true);
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
    setTimeout(() => {
      setAosType('none');
      AOS.refresh();
    }, 200);
  }, []);

  const renderPopup = () => {
    return (
      <section className="cookies-modal__popup">
        <div className="cookies-modal__popup__container" data-aos={aosType} data-aos-duration="500">
          <div className="cookies-modal__popup__row">
            <div>
              <h5 className="cookies-modal__popup__title">
                We use cookies to provide better user experience
              </h5>

              <p className="cookies-modal__popup__desc">
                For more details on how cookies are used, please refer to our{' '}
                <a href={privacyLink}>Privacy Policy</a>.
              </p>
            </div>

            <div className="cookies-modal__popup__buttons">
              <button
                className="cookies-modal__button cookies-modal__button--white"
                onClick={handleToggleSettingModal}
              >
                Cookie settings
              </button>
              <button
                className="cookies-modal__button cookies-modal__button--gray"
                onClick={handleAcceptAll}
              >
                Accept all cookies
              </button>
            </div>
          </div>

          <button
            className="cookies-modal__popup__close"
            onClick={() => setOpenPopup(false)}
          ></button>
        </div>
      </section>
    );
  };

  const renderSettingModal = () => {
    return (
      <section className="cookies-modal__settings">
        <div className="cookies-modal__settings__layout" data-aos="fade-up">
          <div ref={settingModalRef} className="cookies-modal__settings__content">
            <div className="cookies-modal__settings__header">
              <h2 className="cookies-modal__settings__title">Cookie settings</h2>
              <button
                className="cookies-modal__settings__close"
                onClick={() => setOpenModal(false)}
              ></button>
            </div>

            <div className="cookies-modal__settings__body">
              <div className="cookies-modal__settings__desc">
                <p>
                  Cookies are small pieces of data that are added to the web browser when you visit
                  a website. We use cookies to enhance user experience on the OWNDAYS website.
                </p>
                <p>
                  Click to see our <a href={privacyLink}>Privacy Policy</a>.
                </p>
              </div>

              <ul className="cookies-modal__settings__list">
                {settingsList.map(item => (
                  <li key={item.name} className="cookies-modal__settings__item">
                    <div
                      className={`cookies-modal__settings__item__header ${
                        isExpanded(item.name)
                          ? 'cookies-modal__remove-border-bottom-radius cookies-modal__expand'
                          : ''
                      }`}
                    >
                      <div
                        className="cookies-modal__settings__item__name"
                        onClick={() => handleSetExpandList(item.name)}
                      >
                        <h3>{item.name}</h3>
                      </div>

                      <label className="cookies-modal__settings__item__toggle">
                        <input
                          checked={!!item.value}
                          disabled={item.disabled}
                          type="checkbox"
                          value={item.name}
                          onChange={() => handleToggleSetting(item.key)}
                        />
                        <span className="cookies-modal__settings__item__toggle--slider">
                          <span className="cookies-modal__settings__item__toggle--on"></span>
                          <span className="cookies-modal__settings__item__toggle--off"></span>
                        </span>
                      </label>
                    </div>

                    {isExpanded(item.name) && (
                      <div className="cookies-modal__settings__item__desc">
                        <p>{item.desc}</p>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="cookies-modal__settings__footer">
              <button
                className="cookies-modal__button cookies-modal__button--white"
                onClick={handleSaveSettings}
              >
                Save settings
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <Fragment>
      {isOpenPopup && renderPopup()}
      {isOpenModal && renderSettingModal()}
    </Fragment>
  );
};
