import React, { Fragment, useState } from 'react';
import Slider from 'react-slick';

import {
  SUNGLASSES,
  SUNGLASSES_LINEUP,
  SNAP_SUNGLASSES,
  SNAP_SUNGLASSES_LINEUP,
  CLEAR_SUNGLASSES,
  CLEAR_SUNGLASSES_LINEUP,
  OPTIONAL_LENSES_SUNGLASSES,
} from './constants';

import './BodySun.scss';

export const BodySun = ({ baseImgUrl }) => {
  // SUNGLASS STATES
  const [linkSUN2104B_4S, setLinkSUN2104B_4S] = useState(
    '/sg/en/sun-sun2104b-4s-c1-eyeglasses.html'
  );
  const [linkSUN1074B_4S, setLinkSUN1074B_4S] = useState(
    '/sg/en/sun-sun1074b-4s-c1-eyeglasses.html'
  );
  const [linkSUN2107N_4S, setLinkSUN2107N_4S] = useState(
    '/sg/en/sun-sun2107n-4s-c1-eyeglasses.html'
  );
  const [linkSUN1073B_4S, setLinkSUN1073B_4S] = useState(
    '/sg/en/sun-sun1073b-4s-c1-eyeglasses.html'
  );
  const [linkSUN2105B_4S, setLinkSUN2105B_4S] = useState(
    '/sg/en/sun-sun2105b-4s-c1-eyeglasses.html'
  );
  const [linkOB1006G_4A, setLinkOB1006G_4A] = useState('/sg/en/sun-ob1006g-4a-c1-eyeglasses.html');

  const [indexSUN2104B_4S, setIndexSUN2104B_4S] = useState(1);
  const [indexSUN1074B_4S, setIndexSUN1074B_4S] = useState(1);
  const [indexSUN2107N_4S, setIndexSUN2107N_4S] = useState(1);
  const [indexSUN1073B_4S, setIndexSUN1073B_4S] = useState(1);
  const [indexSUN2105B_4S, setIndexSUN2105B_4S] = useState(1);
  const [indexOB1006G_4A, setIndexOB1006G_4A] = useState(1);

  // SNAP SUNGLASS STATES
  const [linkSNP1022X_4S, setLinkSNP1022X_4S] = useState(
    '/sg/en/sun-snp1022x-4s-c1-eyeglasses.html'
  );
  const [linkSNP1024N_4S, setLinkSNP1024N_4S] = useState(
    '/sg/en/sun-snp1024n-4s-c1-eyeglasses.html'
  );
  const [linkSNP1021X_4S, setLinkSNP1021X_4S] = useState(
    '/sg/en/sun-snp1021x-4s-c1-eyeglasses.html'
  );

  const [indexSNP1022X_4S, setIndexSNP1022X_4S] = useState(1);
  const [indexSNP1024N_4S, setIndexSNP1024N_4S] = useState(1);
  const [indexSNP1021X_4S, setIndexSNP1021X_4S] = useState(1);

  // CLEAR SUNGLASS STATES
  const [linkCSU1004B_4S, setLinkCSU1004B_4S] = useState(
    '/sg/en/sun-csu1004b-4s-c1-eyeglasses.html'
  );
  const [linkCSU2003B_4S, setLinkCSU2003B_4S] = useState(
    '/sg/en/sun-csu2003b-4s-c1-eyeglasses.html'
  );
  const [linkCSU1002B_2S, setLinkCSU1002B_2S] = useState(
    '/sg/en/sun-csu1002b-2s-c1-eyeglasses.html'
  );

  const [indexSU1004B_4S, setIndexSU1004B_4S] = useState(1);
  const [indexCSU2003B_4S, setIndexCSU2003B_4S] = useState(1);
  const [indexSU1002B_2S, setIndexSU1002B_2S] = useState(1);

  const stylesSetting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    draggable: false,
    infinite: true,
    swipe: false,
    touchMove: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 800,
    pauseOnFocus: false,
    pauseOnHover: false,
  };

  const defaultLineupSetting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: false,
    draggable: false,
    infinite: false,
    swipe: true,
    touchMove: true,
  };

  const renderLineupPagination = index => {
    return (
      <button>
        <img alt="C1" loading="lazy" src={`${baseImgUrl}/colors/color-${index + 1}.webp`} />
      </button>
    );
  };

  const settingSUN2104B_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 1,
        2: 3,
        3: 6,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkSUN2104B_4S(`/sg/en/sun-sun2104b-4s-c${current + 1}-eyeglasses.html`);
      setIndexSUN2104B_4S(current + 1);
    },
  };

  const settingSUN1074B_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 1,
        2: 4,
        3: 7,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkSUN1074B_4S(`/sg/en/sun-sun1074b-4s-c${current + 1}-eyeglasses.html`);
      setIndexSUN1074B_4S(current + 1);
    },
  };

  const settingSUN2107N_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 2,
        2: 5,
        3: 8,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkSUN2107N_4S(`/sg/en/sun-sun2107n-4s-c${current + 1}-eyeglasses.html`);
      setIndexSUN2107N_4S(current + 1);
    },
  };

  const settingSUN1073B_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 9,
        2: 11,
        3: 14,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkSUN1073B_4S(`/sg/en/sun-sun1073b-4s-c${current + 1}-eyeglasses.html`);
      setIndexSUN1073B_4S(current + 1);
    },
  };

  const settingSUN2105B_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 1,
        2: 12,
        3: 15,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkSUN2105B_4S(`/sg/en/sun-sun2105b-4s-c${current + 1}-eyeglasses.html`);
      setIndexSUN2105B_4S(current + 1);
    },
  };

  const settingOB1006G_4A = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 10,
        2: 13,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkOB1006G_4A(`/sg/en/sun-ob1006g-4a-c${current + 1}-eyeglasses.html`);
      setIndexOB1006G_4A(current + 1);
    },
  };

  const settingSNP1022X_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 16,
        1: 5,
        2: 17,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkSNP1022X_4S(`/sg/en/sun-snp1022x-4s-c${current + 1}-eyeglasses.html`);
      setIndexSNP1022X_4S(current + 1);
    },
  };

  const settingSNP1024N_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 16,
        1: 18,
        2: 17,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkSNP1024N_4S(`/sg/en/sun-snp1024n-4s-c${current + 1}-eyeglasses.html`);
      setIndexSNP1024N_4S(current + 1);
    },
  };

  const settingSNP1021X_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 16,
        1: 19,
        2: 20,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkSNP1021X_4S(`/sg/en/sun-snp1021x-4s-c${current + 1}-eyeglasses.html`);
      setIndexSNP1021X_4S(current + 1);
    },
  };

  const settingSU1004B_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 0,
        1: 9,
        2: 21,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkCSU1004B_4S(`/sg/en/sun-csu1004b-4s-c${current + 1}-eyeglasses.html`);
      setIndexSU1004B_4S(current + 1);
    },
  };

  const settingCSU2003B_4S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 22,
        1: 1,
        2: 6,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkCSU2003B_4S(`/sg/en/sun-csu2003b-4s-c${current + 1}-eyeglasses.html`);
      setIndexCSU2003B_4S(current + 1);
    },
  };

  const settingSU1002B_2S = {
    ...defaultLineupSetting,
    customPaging: i => {
      const indexMapping = {
        0: 25,
        1: 23,
        2: 24,
      };

      const index = indexMapping[i] ?? i;
      return renderLineupPagination(index);
    },
    afterChange: async current => {
      await setLinkCSU1002B_2S(`/sg/en/sun-csu1002b-2s-c${current + 1}-eyeglasses.html`);
      setIndexSU1002B_2S(current + 1);
    },
  };

  const renderStyleTopAndFeature = item => {
    return (
      <div className="body-sun__styles__item">
        <div className="body-sun__styles__top">
          <div className="body-sun__styles__top-img">
            <Slider pa {...stylesSetting} className="body-sun__styles__top-img-slider">
              {item.images?.map(({ sp, pc, alt }, index) => (
                <picture key={index}>
                  <source media="(min-width: 768px)" srcSet={`${baseImgUrl}${pc}`} />
                  <img alt={alt} loading="lazy" src={`${baseImgUrl}${sp}`} />
                </picture>
              ))}
            </Slider>
          </div>

          <div className="body-sun__styles__top-text">
            <div className="base-sun__container">
              <h2 data-aos>
                {item.header.map(({ title }) => (
                  <span key={title}>{title}</span>
                ))}
              </h2>
              <div className="body-sun__styles__top-text-details">
                <h3 data-aos data-aos-delay="100">
                  <span>{item.frameType}</span>
                </h3>
                <p>{item.desc}</p>

                {item.detail && (
                  <div className="body-sun__styles__top-text-details-img">
                    <span></span>
                    <img
                      alt={item.detail.alt}
                      loading="lazy"
                      src={`${baseImgUrl}${item.detail.src}`}
                    />
                  </div>
                )}

                {item.frameType === 'SUNGLASSES' && (
                  <a className="base-sun__btn base-sun__btn--primary" href={item.descSection.link}>
                    See All Products
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        {item.features && (
          <div className="body-sun__features">
            <div className="base-sun__container">
              <h3>FEATURES</h3>
              <ul>
                {item.features.map(({ src, desc }, index) => (
                  <li key={index} data-aos="fade-up" data-aos-delay="0">
                    <span>
                      <img alt={desc} loading="lazy" src={`${baseImgUrl}${src}`} />
                    </span>
                    <small>#0{index + 1}</small>
                    <p>{desc}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {item.isShowFooter && (
          <div className="body-sun__description">
            <div className="base-sun__container">
              <a className="base-sun__btn base-sun__btn--primary" href={item.descSection.link}>
                See All Products
              </a>

              {item.descSection.title && (
                <h3 data-aos="fade-up">
                  {item.descSection.title.split('\n').map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </h3>
              )}

              {item.descSection.desc && (
                <p data-aos data-aos-delay="600">
                  {item.descSection.desc}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderLineupSlide = model => {
    let array = [1, 2, 3, 4];

    const modelsToCheck = [
      'OB1006G-4A',
      'SNP1022X-4S',
      'SNP1024N-4S',
      'SNP1021X-4S',
      'CSU1004B-4S',
      'CSU2003B-4S',
      'CSU1002B-2S',
    ];

    if (modelsToCheck.includes(model)) {
      array = [1, 2, 3];
    }

    const products = array.map((_, i) => (
      <img
        key={i}
        alt={`${model} C${i + 1}`}
        src={`${baseImgUrl}/products/${model}_C${i + 1}.webp`}
      />
    ));

    const lineupSettingsMap = {
      'SUN2104B-4S': settingSUN2104B_4S,
      'SUN1074B-4S': settingSUN1074B_4S,
      'SUN2107N-4S': settingSUN2107N_4S,
      'SUN1073B-4S': settingSUN1073B_4S,
      'SUN2105B-4S': settingSUN2105B_4S,
      'OB1006G-4A': settingOB1006G_4A,
      'SNP1022X-4S': settingSNP1022X_4S,
      'SNP1024N-4S': settingSNP1024N_4S,
      'SNP1021X-4S': settingSNP1021X_4S,
      'CSU1004B-4S': settingSU1004B_4S,
      'CSU2003B-4S': settingCSU2003B_4S,
      'CSU1002B-2S': settingSU1002B_2S,
    };

    const lineupSetting = lineupSettingsMap[model] || defaultLineupSetting;

    return <Slider {...lineupSetting}>{products}</Slider>;
  };

  const renderLineupCards = list => {
    return list.map(({ model, delay }) => {
      const linkMap = {
        'SUN2104B-4S': linkSUN2104B_4S,
        'SUN1074B-4S': linkSUN1074B_4S,
        'SUN2107N-4S': linkSUN2107N_4S,
        'SUN1073B-4S': linkSUN1073B_4S,
        'SUN2105B-4S': linkSUN2105B_4S,
        'OB1006G-4A': linkOB1006G_4A,
        'SNP1022X-4S': linkSNP1022X_4S,
        'SNP1024N-4S': linkSNP1024N_4S,
        'SNP1021X-4S': linkSNP1021X_4S,
        'CSU1004B-4S': linkCSU1004B_4S,
        'CSU2003B-4S': linkCSU2003B_4S,
        'CSU1002B-2S': linkCSU1002B_2S,
      };

      const newLink = linkMap[model] || '';

      const indexMap = {
        'SUN2104B-4S': indexSUN2104B_4S,
        'SUN1074B-4S': indexSUN1074B_4S,
        'SUN2107N-4S': indexSUN2107N_4S,
        'SUN1073B-4S': indexSUN1073B_4S,
        'SUN2105B-4S': indexSUN2105B_4S,
        'OB1006G-4A': indexOB1006G_4A,
        'SNP1022X-4S': indexSNP1022X_4S,
        'SNP1024N-4S': indexSNP1024N_4S,
        'SNP1021X-4S': indexSNP1021X_4S,
        'CSU1004B-4S': indexSU1004B_4S,
        'CSU2003B-4S': indexCSU2003B_4S,
        'CSU1002B-2S': indexSU1002B_2S,
      };

      const newIndex = indexMap[model] || '';

      return (
        <li
          key={model}
          className="body-sun__lineup__item"
          data-aos="fade-left"
          data-aos-delay={delay}
          data-model={`${model} C${newIndex}`}
        >
          {renderLineupSlide(model)}
          {/* 
          Show later
          <a className="base-sun__btn base-sun__btn--primary" href={newLink}>
            More Details
          </a> */}
        </li>
      );
    });
  };

  const renderLineup = cards => {
    return (
      <section className="body-sun__lineup">
        <div className="base-sun__container">
          <h3>LINEUP</h3>
          <ul className="body-sun__lineup__list">{renderLineupCards(cards)}</ul>
        </div>
      </section>
    );
  };

  const renderOptionalLensesSunglasses = () => {
    const option = OPTIONAL_LENSES_SUNGLASSES;
    const plusIcon = (
      <li>
        <i className="icon-plus">
          <svg
            fill="none"
            height="13"
            viewBox="0 0 13 13"
            width="13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.347 7.443v5.171h-1.49V7.443H.704V5.989h5.153V.84h1.49v5.15h5.132v1.454H7.347Z"
              fill="#000"
            ></path>
          </svg>
        </i>
      </li>
    );

    return (
      <div className="base-sun__container">
        <div className="body-sun__styles__option">
          <h3 className="body-sun__styles__option-title">OPTIONAL LENSES</h3>

          <div className="body-sun__styles__option-top">
            <div className="body-sun__styles__option-top-detail">
              {option.subTitle}
              {option.desc}
              {option.remark}
            </div>

            <ul className="body-sun__styles__option-step styles__option-step--3col">
              {option.lens.map((len, index) => (
                <Fragment key={len.alt}>
                  <li>
                    <div>
                      <div className="body-sun__styles__option-step-img-wrap">
                        <img alt={len.alt} loading="lazy" src={`${baseImgUrl}${len.src}`} />
                      </div>
                      {len.text}
                    </div>
                  </li>

                  {index < option.lens.length - 1 && plusIcon}
                </Fragment>
              ))}
            </ul>
          </div>

          <h4 className="body-sun__styles__option-recommended-title">
            Recommended Optional Lenses
          </h4>

          <ul className="body-sun__styles__option-recommended">
            {option.recommended.list.map(item => (
              <li key={item.title}>
                <a href={item.link}>
                  <img alt={item.title} loading="lazy" src={`${baseImgUrl}${item.src}`} />
                  <div className="body-sun__styles__option-recommended-footer">
                    <div>
                      <h5 className="body-sun__styles__option-recommended-footer-title">
                        {item.title}
                      </h5>
                      <span className="body-sun__styles__option-recommended-footer-more">
                        More Details
                      </span>
                    </div>
                    <div>
                      <strong className="body-sun__styles__option-recommended-footer-price">
                        {item.price}
                      </strong>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>

          <ul className="body-sun__styles__option-note">
            {option.recommended.remarks.map(({ text }, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <section className="body-sun__styles" id="sunglasses">
        {renderStyleTopAndFeature(SUNGLASSES)}
      </section>

      {renderLineup(SUNGLASSES_LINEUP)}

      {renderOptionalLensesSunglasses()}

      <section className="body-sun__styles body-sun__styles--reverse" id="snap-sunglasses">
        {renderStyleTopAndFeature(SNAP_SUNGLASSES)}
      </section>

      {renderLineup(SNAP_SUNGLASSES_LINEUP)}

      <section
        className="body-sun__styles body-sun__styles__clear-sunglasses"
        id="clear-sunglasses"
      >
        {renderStyleTopAndFeature(CLEAR_SUNGLASSES)}
      </section>

      {renderLineup(CLEAR_SUNGLASSES_LINEUP)}
    </Fragment>
  );
};
