import React from 'react';
import WarrantySection from '../views/services/Warranty/WarrantySection';
import Breadcrumbs from '../CommonComponents/Breadcrumbs/Breadcrumbs';
import Header from '../CommonComponents/Services/Header/Header';
import Navigation from '../CommonComponents/Services/Navigation/Navigation';
import '../views/services/Top/BaseService.scss';
import {
  getBreadcrumbItems,
  getCurrentPath,
} from '../CommonComponents/Breadcrumbs/breadcrumbUtils';

const ServicesWarrantyHTML = () => {
  const crumbs = getBreadcrumbItems([
    { path: '/sg/en/services/warranty', label: 'Customer Guarantees' },
  ]);
  const currentPath = getCurrentPath('/warranty');

  const headerContent = {
    title: 'OWNDAYS Customer Guarantees',
    description:
      'Proper care and regular maintenance can extend the lifespan of your spectacles. OWNDAYS offers you the most comprehensive after-sales service in the optical industry so you can enjoy your OWNDAYS spectacles at all times.',
    bgSp: 'https://static.lenskart.com/owndays/img/services/warranty/main-1534x1023.webp',
    bgPc: 'https://static.lenskart.com/owndays/img/services/warranty/main-1536x480.webp',
    bgAlt: 'OWNDAYS Customer Guarantees',
    align: 'center',
    theme: 'light',
    id: 'warranty',
  };

  return (
    <main className="service_l">
      <Breadcrumbs crumbs={crumbs} />
      <div className="service_l__section">
        <Navigation currentPath={currentPath} size="desktop" />
        <Header headerContent={headerContent} />
        <div className="service_l__container-warranty">
          <WarrantySection />
        </div>
        <div className="service_l__container">
          <Navigation currentPath={currentPath} size="mobile" />
        </div>
      </div>
    </main>
  );
};

export default ServicesWarrantyHTML;
