import React, { Fragment } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import { NAVS_CONTACT_US } from './constants';
import './HeaderContactUs.scss';

export const HeaderContactUs = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '#', label: 'Contact Us' },
  ];

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <div className="header-contact-us__container">
        <section className="header-contact-us__main">
          <h1 className="header-contact-us__main__title">
            CONTACT<span>Need help? Reach us via the contact information provided below!</span>
          </h1>

          <nav>
            {NAVS_CONTACT_US.map(({ id, link, src, name }) => (
              <a key={id} href={`#${link}`} id={id}>
                <div>
                  <span>
                    <img alt={name} src={`${baseImgUrl}${src}`} />
                  </span>
                  <p>
                    {name.split('\n').map((line, index) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                  </p>
                </div>
              </a>
            ))}
          </nav>

          <div className="header-contact-us__main__tel">
            <p className="header-contact-us__main__tel__title">
              Please call our hotline if you wish to speak to us.
            </p>
            <div>
              <p className="header-contact-us__main__tel--number">6222-0588</p>
              <p className="header-contact-us__main__tel--time">
                <span>10:00 - 19:00</span>
              </p>
            </div>
          </div>

          <ul className="header-contact-us__main__list">
            <li>Operating during Saturday, Sunday and Public holiday</li>
          </ul>
        </section>
      </div>
    </Fragment>
  );
};
