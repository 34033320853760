import React, { Fragment, useEffect } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { NAVS_POMPOM } from './constants';

import './HeaderPompompurin.scss';

export const HeaderPompompurin = ({ baseImgUrl }) => {
  const title = 'OWNDAYS & POMPOMPURIN';
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news#all', label: 'News' },
    { path: '#', label: title },
  ];

  const scrollToSection = id => {
    if (!id) return;

    const elementId = id.startsWith('#') ? id.substring(1) : id;
    const element = document.getElementById(elementId);

    if (element) {
      const headerOffset = document.querySelector('header')?.offsetHeight || 0;
      const elementTop = element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementTop - headerOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scrollToSection(window.location.hash);
      }, 0);
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-pompompurin__main">
        <img
          alt={title}
          className="header-pompompurin__main__fg header-pompompurin__main__fg--left"
          src={`${baseImgUrl}/main-fg-left-2.svg`}
        />
        <img
          alt={title}
          className="header-pompompurin__main__fg header-pompompurin__main__fg--right"
          src={`${baseImgUrl}/main-fg-right-2.svg`}
        />

        <div className="base-pompompurin__container">
          <div className="header-pompompurin__main__grid">
            <h1 className="header-pompompurin__main__logo">
              <img alt={title} src={`${baseImgUrl}/logo.svg`} />
            </h1>
            <img alt={title} src={`${baseImgUrl}/main-img_preload.webp`} />
          </div>
        </div>
      </section>

      <section className="header-pompompurin__about">
        <div className="header-pompompurin__about__content">
          <img
            alt={title}
            className="header-pompompurin__about__fg header-pompompurin__about__fg--char-1"
            data-aos="fade-up"
            src={`${baseImgUrl}/about-char-1.svg`}
          />
          <picture className="header-pompompurin__about__fg header-pompompurin__about__fg--char-2">
            <img alt={title} data-aos="fade-up" src={`${baseImgUrl}/about-char-2.svg`} />
          </picture>
          <img
            alt={title}
            className="header-pompompurin__about__fg header-pompompurin__about__fg--footprints-1"
            src={`${baseImgUrl}/footprints.svg`}
          />
          <img
            alt={title}
            className="header-pompompurin__about__fg header-pompompurin__about__fg--footprints-2"
            src={`${baseImgUrl}/footprints.svg`}
          />
          <img
            alt={title}
            className="header-pompompurin__about__fg header-pompompurin__about__fg--pudding"
            src={`${baseImgUrl}/pudding.svg`}
          />
          <img
            alt={title}
            className="header-pompompurin__about__fg header-pompompurin__about__fg--bone"
            src={`${baseImgUrl}/bone.svg`}
          />
          <img
            alt={title}
            className="header-pompompurin__about__fg header-pompompurin__about__fg--cherry"
            src={`${baseImgUrl}/cherry.svg`}
          />
          <img
            alt={title}
            className="header-pompompurin__about__fg header-pompompurin__about__fg--mike"
            src={`${baseImgUrl}/mike.svg`}
          />
          <div className="base-pompompurin__container">
            <h2>
              <img alt={title} src={`${baseImgUrl}/about-heading.svg`} />
            </h2>
            <p className="header-pompompurin__about-desc">
              It’s cuteness overload with these spectacle frames featuring Pompompurin, the boy
              golden retriever with his trademark brown beret. <br />
              Whether you’re out and about or relaxing at home, pick your favourable pair and keep
              Pompompurin by your side at all time!
            </p>
            <a className="base-pompompurin__btn" href="/sg/en/eyeglasses/brands/pompompurin.html">
              ALL ITEMS<span className="base-pompompurin__btn-arrow">
                <svg
                  fill="none"
                  height="13"
                  viewBox="0 0 14 13"
                  width="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.05527 1.07825L7.05527 1.07825C6.68429 1.44925 6.68428 2.05075 7.05527 2.42175L7.05527 2.42175L10.2065 5.57297L2.25 5.57297H2L1.75 5.57297C1.22533 5.57297 0.8 5.9983 0.8 6.52297C0.8 7.04765 1.22533 7.47297 1.75 7.47297L2 7.47297H2.25L10.2065 7.47297L7.05527 10.6242L7.19669 10.7656L7.05527 10.6242C6.68428 10.9952 6.68429 11.5967 7.05526 11.9677L7.19669 11.8263L7.05527 11.9677C7.42627 12.3387 8.02779 12.3387 8.39877 11.9677C8.39877 11.9677 8.39878 11.9677 8.39878 11.9677L13.1717 7.19472C13.5427 6.82373 13.5427 6.22221 13.1717 5.85122L8.39878 1.07826C8.0278 0.707244 7.42627 0.707255 7.05527 1.07825Z"
                    fill="#FFCB14"
                    stroke="#FFCB14"
                    strokeWidth="0.4"
                  ></path>
                </svg>
              </span>
            </a>
          </div>
        </div>
      </section>

      <section className="header-pompompurin__lineup" id="header-pompompurin__lineup">
        <img alt="" className="header-pompompurin__lineup__fg" src={`${baseImgUrl}/cloud.svg`} />

        <div className="base-pompompurin__container">
          <h2 className="header-pompompurin__lineup__title">LINEUP</h2>
          <nav className="header-pompompurin__lineup__nav">
            {NAVS_POMPOM.map(({ id, model, title, navImg, productImg }) => (
              <li
                key={model}
                className="header-pompompurin__lineup__nav-item"
                onClick={() => scrollToSection(id)}
              >
                <img
                  alt={model}
                  className="header-pompompurin__lineup__nav-img"
                  src={`${baseImgUrl}/navs${navImg}`}
                />
                <div className="header-pompompurin__lineup__nav-content">
                  <img
                    alt={model}
                    className="header-pompompurin__lineup__nav-product"
                    src={`${baseImgUrl}/products${productImg}`}
                  />
                  <span className="header-pompompurin__lineup__nav-text">
                    {title}
                    <strong>MODEL</strong>
                  </span>
                </div>
              </li>
            ))}
          </nav>
        </div>
      </section>
    </Fragment>
  );
};
