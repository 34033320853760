import React, { useEffect } from 'react';
import AOS from 'aos';

import { HeaderSun } from './HeaderSun';
import { BodySun } from './BodySun';
import { FooterSun } from './FooterSun';

import './BaseSun.scss';

const SunLP = () => {
  const baseImgUrl = 'https://static.lenskart.com/owndays/img/sun';

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="base-sun base-sun__scroll-hidden">
      <HeaderSun baseImgUrl={baseImgUrl} />
      <BodySun baseImgUrl={baseImgUrl} />
      <FooterSun />
    </div>
  );
};

export default SunLP;
