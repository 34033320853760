import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/home';
import config from 'config';
import apiClient from '../helpers/apiClient';
import { getRawHomeData, getMergedHomeData } from '../selectors/home';

let _client = null;
// let _location = null;

function* getHomepageData(pageContext, usePristineData) {
  console.log('this is page context ===>', pageContext);
  if (__CLIENT__) sessionStorage.removeItem('isReOrderProduct');
  const successAction = { type: actionTypes.HOMEPAGE_DATA_SUCCESS };
  const errorAction = { type: actionTypes.HOMEPAGE_DATA_FAIL };
  const client = Object.assign({}, _client);
  client.setHeader();

  if (
    [
      'premium-eyeglasses',
      'premium-sunglasses',
      'eyeglasses',
      'sunglasses',
      'home',
      'sg-en',
    ].includes(pageContext) ||
    pageContext?.indexOf('promo-') > -1
  ) {
    try {
      let configPath;
      if (pageContext === 'home' || pageContext === 'sg-en') {
        configPath = config.apiPath.homePageJson;
        console.log('revamp_home.json', config.apiPath.homePageJson);
      } else {
        configPath = `${config.jsonPath}/${pageContext}.json`;
      }
      let rawHomeData;
      if (usePristineData) {
        rawHomeData = yield select(getRawHomeData);
      } else {
        rawHomeData = yield call(async () => client.get(`${configPath}?v=${new Date().getTime()}`));
        const categoryCarouselPositions = [];
        const categoryCarouselIds = rawHomeData.homeData.sections.reduce(
          (filtered, section, index) => {
            if (__SERVER__ && filtered.length > 2) {
              return filtered;
            }
            if (section.data[0].type === 'TYPE_CATEGORY_CAROUSEL') {
              const catId = section.data[0].data.catId;
              filtered.push(catId);
              categoryCarouselPositions.push(index);
            }
            return filtered;
          },
          []
        );
        if (categoryCarouselIds && categoryCarouselIds.length) {
          const carouselIds = __SERVER__
            ? categoryCarouselIds.slice(0, 2).join(',')
            : categoryCarouselIds.join(',');
          const categoryCarouselsData = yield call(async () =>
            client.get(config.apiPath.categoryCarouselUrl + carouselIds)
          );
          categoryCarouselPositions.forEach(carouselPosition => {
            rawHomeData.homeData.sections[carouselPosition].data[0].productData =
              categoryCarouselsData.result.filter(
                carouselData =>
                  parseInt(carouselData.cat_id, 10) ===
                  rawHomeData.homeData.sections[carouselPosition].data[0].data.catId
              );
          });
        }
      }

      const rawDataClone = JSON.parse(JSON.stringify(rawHomeData));
      successAction.data = rawDataClone;
      successAction.rawData = rawHomeData;
      yield put(successAction);
    } catch (e) {
      errorAction.error = e;
      yield put(errorAction);
    }
  }
}
function* getCarousalsData() {
  const successAction = { type: actionTypes.HOMEPAGE_CAROUSALS_DATA_SUCCESS };
  const errorAction = { type: actionTypes.HOMEPAGE_CAROUSALS_DATA_FAIL };
  const client = Object.assign({}, _client);
  client.setHeader();
  try {
    const rawHomeData = yield select(getRawHomeData);
    const mergedHomeData = yield select(getMergedHomeData);
    const categoryCarouselPositions = [];
    const categoryCarouselIds = rawHomeData.homeData.sections.reduce((filtered, section, index) => {
      if (section.data[0].type === 'TYPE_CATEGORY_CAROUSEL') {
        const catId = section.data[0].data.catId;
        filtered.push(catId);
        categoryCarouselPositions.push(index);
      }
      return filtered;
    }, []);
    if (categoryCarouselIds && categoryCarouselIds.length) {
      const categoryCarouselsData = yield call(async () =>
        client.get(config.apiPath.categoryCarouselUrl + categoryCarouselIds.join(','))
      );
      categoryCarouselPositions.forEach(carouselPosition => {
        rawHomeData.homeData.sections[carouselPosition].data[0].productData =
          categoryCarouselsData.result.filter(
            carouselData =>
              parseInt(carouselData.cat_id, 10) ===
              rawHomeData.homeData.sections[carouselPosition].data[0].data.catId
          );
        mergedHomeData.sections[carouselPosition].data[0].productData =
          categoryCarouselsData.result.filter(
            carouselData =>
              parseInt(carouselData.cat_id, 10) ===
              rawHomeData.homeData.sections[carouselPosition].data[0].data.catId
          );
      });
    }
    successAction.data = rawHomeData;
    successAction.mergedHomeData = mergedHomeData;
    yield put(successAction);
  } catch (e) {
    errorAction.error = e;
    yield put(errorAction);
  }
}
export function* getHome({ pageContext }) {
  yield call(getHomepageData, pageContext);
}

function* getMobileHomepageData({ isBot }) {
  const successAction = { type: actionTypes.MOBILE_HOMEPAGE_DATA_SUCCESS };
  const errorAction = { type: actionTypes.HOMEPAGE_DATA_FAIL };
  const timestamp = Math.floor(new Date().getTime() / (60000 * 3)).toString();
  const client = Object.assign({}, _client);

  client.setHeader();

  console.log('home.json', config.apiPath.homePageJson);

  try {
    const rawHomeData = yield call(async () =>
      client.get(`${config.apiPath.homePageJson}?v=${timestamp}`)
    );

    let categoryPreviewConfig = rawHomeData.result.reduce((acc, { data, dataType }, index) => {
      if (__SERVER__ && acc.length > 2) {
        return acc;
      }

      if (dataType === 'TYPE_CATEGORY_PREVIEW') {
        acc.push({ categoryId: data.catId, categoryPreviewPosition: index });
      }

      return acc;
    }, []);

    if (categoryPreviewConfig.length) {
      categoryPreviewConfig = __SERVER__
        ? categoryPreviewConfig.slice(0, 2)
        : categoryPreviewConfig;

      const categoriesData = yield all(
        categoryPreviewConfig.map(({ categoryId }) =>
          client.getCategoryData(categoryId, null, 4, 1, false)
        )
      );

      categoryPreviewConfig.forEach(({ categoryPreviewPosition }, index) => {
        rawHomeData.result[categoryPreviewPosition].data.products =
          categoriesData[index].result.product_list;
      });
    }

    const rawDataClone = JSON.parse(JSON.stringify(rawHomeData));

    successAction.data = rawDataClone;
    successAction.rawData = rawHomeData;
    successAction.isBot = isBot;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getHomePageProducts({ catId }) {
  const successAction = { type: actionTypes.HOME_PAGE_PRODUCTS_SUCCESS };
  const errorAction = { type: actionTypes.HOME_PAGE_PRODUCTS_FAILURE };
  const client = { ..._client };
  client.setHeader();

  try {
    const data = yield call(async () =>
      _client.get(`${config.apiPath.dataCategory}${catId}?withMainProductColorOption=true&oos=true`)
    );

    successAction.data = data.result.product_list;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error.message;
    yield put(errorAction);
  }
}

function* getClickCategory({ catId }) {
  const successAction = { type: actionTypes.GET_CLICK_CATEGORY_SUCCESS };
  const errorAction = { type: actionTypes.GET_CLICK_CATEGORY_FAILURE };
  const client = { ..._client };
  client.setHeader();

  try {
    const data = yield call(async () =>
      _client.get(`${config.apiPath.dataCategory}${catId}?withMainProductColorOption=true&oos=true`)
    );

    successAction.data = data.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error.message;
    yield put(errorAction);
  }
}

export function* runHomeCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.MOBILE_HOMEPAGE_DATA_LOAD, getMobileHomepageData),
    takeLatest(actionTypes.HOME_PAGE_PRODUCTS_LOAD, getHomePageProducts),
    takeLatest(actionTypes.GET_CLICK_CATEGORY_LOAD, getClickCategory),
    takeLatest(actionTypes.HOMEPAGE_CAROUSALS_DATA_LOAD, getCarousalsData),
    takeLatest(actionTypes.HOMEPAGE_DATA_LOAD, getHome),
  ]);
}
