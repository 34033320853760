import React, { Fragment } from 'react';

import Breadcrumbs from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';
import Navigation from '../../../CommonComponents/Services/Navigation/Navigation';

import './HeaderLensReplacement.scss';

export const HeaderLensReplacement = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/services', label: 'Services' },
    { path: '#', label: 'Lens Replacement' },
  ];
  const currentPath = '/sg/en/services/lens-replacement';

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />
      <Navigation currentPath={currentPath} size="desktop" />

      <section className="header-lens-replacement">
        <div className="header-lens-replacement__bg">
          <picture>
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}/main-pc.webp`} />
            <img alt="Lens Replacement" src={`${baseImgUrl}/main-sp.webp`} />
          </picture>
        </div>

        <div className="header-lens-replacement__title">
          <h1 className="header-lens-replacement__title-default">Lens Replacement</h1>
          <p className="header-lens-replacement__title-description">
            Extend the lifespan of spectacles that you cherish with regular lens replacement
          </p>
        </div>
      </section>
    </Fragment>
  );
};
