import React from 'react';

const RenderWarrantyContent = ({ warranty }) => (
  <div key={warranty.number} className="l-services-warranty--l-warranty__content-block">
    <div className="l-services-warranty--l-warranty__content-block--l-warranty-no">
      <p>{warranty.number}</p>
    </div>
    <div className="l-services-warranty--l-warranty__content-block--l-warranty-service">
      <div className="l-services-warranty--l-warranty__content-block--l-warranty-service-inner">
        {warranty.term && <p className="l-services-warranty--c-term">{warranty.term}</p>}
        <h3 className="l-services-warranty--c-attention-title">{warranty.title}</h3>
        {warranty.description && (
          <p className="l-services-warranty--c-attention">{warranty.description}</p>
        )}
        {warranty.additionalInfo && (
          <p className="l-services-warranty--c-attention">
            <span>{warranty.additionalInfo}</span>
          </p>
        )}
        {warranty.notes && (
          <ul className="l-services-warranty--p-attention-list">
            {warranty.notes.map((note, index) => (
              <li key={index}>
                <p>{note}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  </div>
);

export default RenderWarrantyContent;
