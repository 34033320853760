import React, { useEffect, useRef, useState } from 'react';
import './Header.scss';
import { useSelector } from 'react-redux';
import MainNav from '../../components/MainNav/MainNav';
import DiscountBanner from '../../../CommonComponents/DiscountBanner/DiscountBanner';

const Header = props => {
  const plpBanner = useSelector(state => state.plpBanner.showBanner);

  const [headerHeight, setHeaderHeight] = useState(79);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [bannerScrolled, setBannerScrolled] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    if (headerRef.current && headerRef.current.clientHeight !== headerHeight) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  });

  useEffect(() => {
    if (plpBanner) {
      const bannerElement = document.querySelector('.discount-banner');
      if (bannerElement) {
        const bannerHeight = bannerElement.offsetHeight;
        setBannerHeight(bannerHeight);
      }

      const handleScroll = () => {
        if (window.scrollY === 0) {
          setBannerScrolled(false);
        } else {
          setBannerScrolled(window.scrollY >= bannerHeight);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    setBannerHeight(0);
  }, [bannerHeight, plpBanner]);

  return (
    <>
      {plpBanner && <DiscountBanner />}
      <header
        ref={headerRef}
        className="header"
        id="header"
        style={!bannerScrolled ? { top: bannerHeight } : {}}
      >
        <MainNav {...props} />
      </header>
      <div className="header-placeholder" style={{ height: `${headerHeight}px` }}></div>
    </>
  );
};

export default Header;
