import * as actionTypes from '../../actionTypes/category';
import localStorageHelper from '../../utils/localStorageHelper';
const initialState = {
  categoryData: {},
  filterData: undefined,
  initialFilters: null,
  categoryScrollTo: 0,
  filterDrop: null,
  reset: false,
  order: '',
  counter: 1,
  catImgLink: '',
  dittoEnable: false,
  totalCountProd: null,
  isFilterEnable: false,
  filterFail: false,
  dataCategoryList: {},
  loadSwitchBarSection: false,
  reload: { reload: false, data: {} },
  FilterDataObj: {},
  handleFilterFunction: '',
  breadcrumb: '',
  prevUrl: '',
  categoryType: '',
  powerUrls: '',
  query: '',
  pathname: '',
  visibleItemCounter: 1,
  subCategories: {},
  isCachedData: false,
  searchDataLoading: false,
  categoryDataLoading: false,
  currentScrollDataLength: null,
};

export default function category(
  prevState = initialState,
  { data, error, type, filterObj, func, value }
) {
  switch (type) {
    case actionTypes.CATEGORY_AND_FILTER_DATA_LOAD:
      return Object.assign({}, prevState, {
        categoryData: {},
        // filterData: undefined
      });
    case actionTypes.CATEGORY_DATA_LOAD:
      return Object.assign({}, prevState, {
        counter: 0,
        categoryData: {},
        isCachedData: false,
        searchDataLoading: true,
        categoryDataLoading: true,
      });
    case actionTypes.SEARCH_CATEGORY_DATA_LOAD:
      return Object.assign({}, prevState, {
        counter: 0,
        categoryData: {},
        isCachedData: false,
        searchDataLoading: true,
      });
    case actionTypes.CATEGORY_DATA_SUCCESS:
      if (data.pathname === '/search') {
        data.result.category_name = decodeURI(data.query);
      }
      return Object.assign({}, prevState, {
        categoryData: data,
        totalCountProd: data.result.product_list.length,
        loading: true,
        counter:
          data.counter !== null && data.counter !== undefined && data.counter !== ''
            ? Number(data.counter) + 1
            : prevState.counter,
        breadcrumb: data.result.breadcrumb,
        catImgLink: data.result.cat_img_link,
        categoryType: data.result.categoryType,
        powerUrls: data.result.category_power_urls,
        isCachedData: false,
        searchDataLoading: false,
        categoryDataLoading: false,
      });
    case actionTypes.CATEGORY_DATA_FAIL:
      return Object.assign({}, prevState, {
        isFilterEnable: false,
        categoryData: error,
        searchDataLoading: false,
        categoryDataLoading: false,
      });
    case actionTypes.DELETE_CATEGORYDATA:
      return Object.assign({}, prevState, {
        categoryData: {},
      });
    case actionTypes.SUBCATEGORY_DATA_LOAD:
      return Object.assign({}, prevState, {
        subCategories: {},
      });
    case actionTypes.SUBCATEGORY_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        subCategories: data,
      });
    case actionTypes.SUBCATEGORY_DATA_FAIL:
      return Object.assign({}, prevState, {
        subCategories: { error: true },
      });
    case actionTypes.CATEGORY_CONFIG_JSON_LOAD:
      return Object.assign({}, prevState, {
        categoryConfigJson: {},
      });
    case actionTypes.CATEGORY_CONFIG_JSON_SUCCESS:
      return Object.assign({}, prevState, {
        categoryConfigJson: data,
      });
    case actionTypes.CATEGORY_CONFIG_JSON_FAIL:
      return Object.assign({}, prevState, {
        categoryConfigJson: error,
      });
    case actionTypes.FILTER_DATA_LOAD:
      return Object.assign({}, prevState, {
        filterData: undefined,
        catFilterLoaded: true,
        isFilterEnable: false,
        filterFail: false,
      });
    case actionTypes.FILTER_DATA_SUCCESS:
      const savedCat = prevState.dataCategoryList;
      if (savedCat[(window.location.pathname + window.location.hash).replace('/', '')]) {
        savedCat[(window.location.pathname + window.location.hash).replace('/', '')].filterData =
          data;
      }
      return Object.assign({}, prevState, {
        isFilterEnable: data.result.filters.length,
        filterData: data,
        catFilterLoaded: false,
        prevUrl: '',
        filterFail: false,
        dataCategoryList: savedCat,
      });
    case actionTypes.FILTER_DATA_FAIL:
      return Object.assign({}, prevState, {
        isFilterEnable: false,
        filterData: { error: true },
        filterFail: true,
        catFilterLoaded: true,
        prevUrl: '',
        error,
      });
    case actionTypes.SEARCH_FILTER_LOAD:
      return Object.assign({}, prevState, {
        filterData: undefined,
        isFilterEnable: false,
        prevUrl: '/search',
        filterFail: false,
      });
    case actionTypes.SEARCH_FILTER_SUCCESS:
      const filterData = Array.isArray(data.result)
        ? { result: { filters: data.result } }
        : { result: { filters: [data.result] } };
      return Object.assign({}, prevState, {
        isFilterEnable: data.result.length,
        filterData,
        prevUrl: '/search',
        filterFail: false,
      });
    case actionTypes.SEARCH_FILTER_FAIL:
      return Object.assign({}, prevState, {
        isFilterEnable: false,
        filterData: { error: true },
        filterFail: true,
        prevUrl: '/search',
        error,
      });
    case actionTypes.ADD_FILTERDATA:
      return Object.assign({}, prevState, {
        FilterDataObj: filterObj,
        error: null,
      });
    case actionTypes.HANDLE_FILTER_FUNC:
      return Object.assign({}, prevState, {
        handleFilterFunction: func,
      });
    case actionTypes.MODIFY_DATA:
      return Object.assign({}, prevState, {
        ...data,
      });
    case actionTypes.FILTER_DROPDOWN:
      return Object.assign({}, prevState, {
        filterDrop: value,
      });
    case actionTypes.APPEND_DATA_LOAD:
      return Object.assign({}, prevState, {
        redisLoader: true,
        counter: prevState.counter + 1,
        isCachedData: false,
      });
    case actionTypes.APPEND_DATA_AFTER: {
      const dataArray = prevState.categoryData;
      const updatedData = data.result.product_list;
      // const productCount = data.result.num_of_products;
      const dataConcat = dataArray.result.product_list.concat(updatedData);
      dataArray.result.product_list = dataConcat;
      // dataArray.result.num_of_products = productCount;
      return Object.assign({}, prevState, {
        categoryData: dataArray,
        error: null,
        redisLoader: false,
        counter: prevState.counter,
        totalCountProd: dataArray.result.product_list.length,
        currentScrollDataLength: updatedData.length, // for stopping infinite loading gif
      });
    }
    case actionTypes.FAIL_APPEND_DATA:
      return Object.assign({}, prevState, {
        redisLoader: false,
      });
    case actionTypes.SAVE_CATEGORY_DATA_ON_SCROLL: {
      const catData = prevState.dataCategoryList;
      const currentURL = data.location ? data.location : window.location.pathname.slice(1);
      catData[currentURL] = { ...catData[currentURL], ...data };
      return Object.assign({}, prevState, {
        categoryScrollTo: data.scrollTo,
        isCachedData: false,
      });
    }
    case actionTypes.UPDATE_DITTO_ENABLE:
      return Object.assign({}, prevState, {
        dittoEnable: value,
      });
    case actionTypes.SHOW_CACHED_DATA:
      localStorageHelper.setItem('filters', {
        result: { filters: data.filterData && data.filterData.result },
      });
      return Object.assign({}, prevState, {
        categoryData: data.catData,
        totalCountProd: data.catData.result.product_list.length,
        breadcrumb: data.catData.result.breadcrumb,
        catImgLink: data.catData.result.cat_img_link,
        categoryType: data.catData.result.categoryType,
        powerUrls: data.catData.result.category_power_urls,
        categoryName: data.catData.result.category_name,
        categoryId: data.catData.result.categoryId,
        counter: data.counter,
        categoryScrollTo: data.scrollTo,
        filterData: data.filterData,
        loading: true,
        filterFail: false,
        isCachedData: true,
      });
    case actionTypes.SAVE_CATEGORYDATA_TO_REDUX:
      let catDataList = null;
      const currentURL = __SERVER__
        ? data.location
        : window.location.pathname.replace('/', '') + window.location.hash;
      catDataList = prevState.dataCategoryList || {};
      if (!catDataList[currentURL]) {
        if (Object.keys(catDataList).length === 5) {
          delete catDataList[Object.keys(catDataList)[0]];
        }
        catDataList[currentURL] = {
          catData: data,
          catid: data.result.categoryId,
          counter: prevState.counter,
          scrollTo: 0,
          filterData: prevState.filterData,
        };
      } else {
        const currentURL = __SERVER__
          ? data.location
          : window.location.pathname.slice(1) + window.location.hash;
        const catDataList = prevState.dataCategoryList;
        catDataList[currentURL] = {
          catData: prevState.categoryData,
          catid: prevState.categoryData.result.categoryId,
          counter: prevState.counter,
          scrollTo: 0,
          filterData: prevState.filterData,
        };
      }
      return Object.assign({}, prevState, {
        dataCategoryList: catDataList || {},
      });
    case actionTypes.FILTER_STATE_UNSET: {
      return Object.assign({}, prevState, {
        isFilterEnable: false,
      });
    }
    default:
      return prevState;
  }
}
