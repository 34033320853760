import React, { useEffect, useState } from 'react';
import ModalBody from './ModalBody';
import ModalTitle from './ModalTitle';
import ModalHeader from './ModalHeader';
import './Modal.scss';

const Modal = props => {
  const {
    dialogClassName,
    children,
    /* backdrop, */ backdropClassName,
    keyboard,
    bsSize,
    sgBookAppointment,
    thankYouHeight,
    onHide,
    outSideContentClick,
  } = props;

  const [show, setShow] = useState(props.show || false);

  useEffect(() => {
    setShow(props.show || false);
  }, [props.show]);

  useEffect(() => {
    const handleKeyClose = e => {
      if (e.key === 'Escape' && keyboard) {
        onHide();
      }
    };

    const handleOutsideClick = e => {
      if (e.target.classList.contains('modal') && show) {
        outSideContentClick();
      }
    };

    if (keyboard) {
      window.addEventListener('keydown', handleKeyClose);
    }
    if (outSideContentClick) window.addEventListener('mousedown', handleOutsideClick);

    return () => {
      window.removeEventListener('keydown', handleKeyClose);
      if (outSideContentClick) window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [show, keyboard, onHide, outSideContentClick]);

  const subComponents =
    children && Array.isArray(children)
      ? children.map(child => {
          if (child) {
            const clonedChild = {
              ...child,
              props: {
                ...props,
                ...child.props,
              },
            };
            return clonedChild;
          }
          return null;
        })
      : [children];
  let bsSizeLargeAvailable;
  if (bsSize === 'large' || bsSize === 'lg') bsSizeLargeAvailable = true;
  else bsSizeLargeAvailable = false;

  if (show) {
    return (
      <>
        <div className={`fade modal-backdrop in ${backdropClassName || ''}`}></div>
        <div
          className={`fade in modal ${
            dialogClassName === 'cart-popup' ? 'animate-left' : 'animate-top'
          }`}
          data-testid="modal"
          style={{ display: 'block' }}
          tabIndex="-1"
        >
          <div
            className={`${
              bsSizeLargeAvailable ? 'modal-lg' : 'modal'
            } modal-dialog ${dialogClassName}`}
          >
            <div
              className={`modal-content ${sgBookAppointment ? 'sgBookAppointment-height' : ''} ${
                thankYouHeight && 'thank-you-height'
              }`}
            >
              {subComponents}
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
};

Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Body = ModalBody;

export default Modal;
