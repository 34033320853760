import React from 'react';
import { getGenreLabel } from '../../../constants/information';

const Item = ({ item }) => {
  const formatDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  return (
    <li className="information__list-item">
      <a className="information__card" href={`/sg/en/information/${item?.id}`}>
        <img
          alt={item?.storeName ? item?.storeName : item?.title}
          className="information__card-cover"
          loading="lazy"
          src={
            item?.cover
              ? item?.cover
              : 'https://static.lenskart.com/media/owndays/img/information/no-image.webp'
          }
        />
        <div className="information__card-details">
          <p className="information__card-date">
            {formatDate(item?.releaseDate)} ｜ {getGenreLabel(item?.genre)}
          </p>
          <h2 className="information__card-title">{item?.title}</h2>
        </div>
      </a>
    </li>
  );
};

export default Item;
