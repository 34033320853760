import React from 'react';

import { QR_LIST } from './constants';
import './FooterContactUs.scss';

export const FooterContactUs = ({ baseImgUrl }) => {
  return (
    <div className="footer-contact-us__container">
      <section className="footer-contact-us" id="section_pr">
        <h2><span><img alt="Public Relations and Media Enquiries" src={`${baseImgUrl}/contact_pr.svg`} /></span>Public Relations and Media Enquiries</h2>

        <ul>
          {QR_LIST.map(({ title, desc }) => (
            <li key={title}>
              <h4>{title}</h4>
              <p>{desc}</p>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};
