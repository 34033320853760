import React, { Fragment, useEffect } from 'react';

import Breadcrumbs from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';

import { LINEUP_MENU } from './constants';
import './HeaderTomAndJerry.scss';

export const HeaderTomAndJerry = ({ baseImgUrl }) => {
  const title = 'TOM and JERRY × OWNDAYS';
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: title },
  ];

  const scrollToSection = id => {
    if (!id) return;

    const elementId = id.startsWith('#') ? id.substring(1) : id;
    const element = document.getElementById(elementId);

    window.location.hash = elementId;

    if (element) {
      const headerOffset = document.querySelector('header')?.offsetHeight || 0;
      const elementTop = element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementTop - headerOffset,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scrollToSection(window.location.hash);
      }, 0);
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-tom-and-jerry__main">
        <picture className="header-tom-and-jerry__main__top">
          <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/main-bg-top_pc.svg`} />
          <img alt={title} loading="lazy" src={`${baseImgUrl}/main-bg-top_sp.svg`} />
        </picture>

        <div className="base-tom-and-jerry__container">
          <h1 className="header-tom-and-jerry__main__logo">
            {title}
            <img alt={title} loading="lazy" src={`${baseImgUrl}/TOM_and_JERRY_x_OWNDAYS.svg`} />
          </h1>

          <div className="header-tom-and-jerry__main__content">
            <div className="header-tom-and-jerry__main__img header-tom-and-jerry__main__img--jn">
              <img
                alt="JERRY & Nibbles"
                data-aos="fade-right"
                loading="lazy"
                src={`${baseImgUrl}/main-jerry-nibbles.svg`}
              />
            </div>

            <div className="header-tom-and-jerry__main__img header-tom-and-jerry__main__img--tom">
              <img
                alt="TOM"
                data-aos="fade-left"
                data-aos-delay="200"
                loading="lazy"
                src={`${baseImgUrl}/main-tom.svg`}
              />
            </div>
          </div>

          <div className="header-tom-and-jerry__main__img header-tom-and-jerry__main__img--85y">
            <img
              alt="TOM and JERRY 85th"
              loading="lazy"
              src={`${baseImgUrl}/TOM_and_JERRY_85years.svg`}
            />
          </div>
        </div>
      </section>

      <section className="header-tom-and-jerry__about">
        <div className="base-tom-and-jerry__container">
          <div className="header-tom-and-jerry__about__content">
            <p data-aos="fade-up" data-aos-delay="200">
              Discover the collection inspired by <br />
              <em>Tom and Jerry</em> , the beloved slapstick <br />
              cartoon comedy starring the iconic duo <br />
              in an endless game of cat-and-mouse! <br />
              This collaboration captures their playful <br />
              dynamic - a true friendship wrapped in <br />
              constant mischief. Packed with hidden <br />
              surprises, they are sure to leave you smiling.
            </p>
            <img
              alt="TOM and JERRY"
              data-aos="zoom-in"
              loading="lazy"
              src={`${baseImgUrl}/about-tom-jerry.svg`}
            />
          </div>
        </div>
      </section>

      <section className="header-tom-and-jerry__lineup-menu">
        <div className="base-tom-and-jerry__container">
          <div className="header-tom-and-jerry__lineup-menu__layout">
            <div className="header-tom-and-jerry__lineup-menu__header">
              <h2>
                <img
                  alt="LINEUP"
                  className="header-tom-and-jerry__lineup-menu__header-logo"
                  loading="lazy"
                  src={`${baseImgUrl}/lineup_logo.svg`}
                />
              </h2>
              <p>6 MODELS / 2 COLOURS</p>
            </div>

            <ul>
              {LINEUP_MENU.map(({ id, text, img }, index) => {
                const isAccessories = LINEUP_MENU.length - 1 === index;
                const label = isAccessories ? 'ACCESSORIES' : 'MODEL';
                const title = `${text} ${label}`;

                return (
                  <li key={title} onClick={() => scrollToSection(`#${id}`)}>
                    <span></span>
                    <div className="header-tom-and-jerry__lineup-menu__title">
                      <h3>{text}</h3>
                      <img
                        alt={title}
                        loading="lazy"
                        src={`${baseImgUrl}/lineup_${isAccessories ? 'accessories' : 'model'}.svg`}
                      />
                    </div>

                    <img
                      alt={title}
                      className="header-tom-and-jerry__lineup-menu__character"
                      loading="lazy"
                      src={`${baseImgUrl}${img}`}
                    />

                    <div className="header-tom-and-jerry__lineup-menu__arrow"></div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
