const IMG_URL = 'https://static.lenskart.com/media/owndays/img/brands';
const NEW_IMG_URL = 'https://static.lenskart.com/owndays/img/brands';

export const BRANDS = [
  {
    title: 'OWNDAYS | AIR',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: 'Incredibly Lightweight',
    brandTitle2: 'The Durability You Can Rely On',
    description:
      'Engineered with ultra-lightweight and highly durable materials to deliver a wearing comfort that feels like air, these metal frames offer an impeccable fit and can be worn comfortably for long hours.',
    alt: 'OWNDAYS | AIR',
    productList: '/sg/en/eyeglasses/brands/air.html',
    productDetails: '/sg/en/news/air',
    logo: `${IMG_URL}/air/logo.webp`,
    thumbnail: `${IMG_URL}/air/air.webp`,
    gallery: [
      `${IMG_URL}/air/img-1.webp`,
      `${IMG_URL}/air/img-2.webp`,
      `${IMG_URL}/air/img-3.webp`,
      `${IMG_URL}/air/img-4.webp`,
    ],
    show: true,
  },
  {
    title: 'OWNDAYS | ESSENTIAL',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: 'The New Norm',
    brandTitle2: 'Finding Joy In Your Eyewear',
    description:
      'A collection that is impeccably tasteful, featuring frames in sleek silhouettes with delicate accents.',
    alt: 'OWNDAYS | ESSENTIAL',
    productList: '/sg/en/eyeglasses/brands/essential.html',
    productDetails: null,
    logo: `${IMG_URL}/essential/logo.webp`,
    thumbnail: `${IMG_URL}/essential/essential.webp`,
    gallery: [
      `${IMG_URL}/essential/img-1.webp`,
      `${IMG_URL}/essential/img-2.webp`,
      `${IMG_URL}/essential/img-3.webp`,
    ],
    show: true,
  },
  {
    title: 'OWNDAYS | SUN',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: "Heart's Leaping",
    brandTitle2: 'Under The Sun',
    description:
      'Where fashion meets function. Coming in an array of vibrant designs, these sunglasses not only reduce glare and protect your eyes from UV rays but also enable you to see the world more vividly. With SUN by your side, every moment becomes extra special.',
    alt: 'OWNDAYS | SUN',
    productList: '/sg/en/eyeglasses/brands/sun.html',
    productDetails: '/sg/en/news/sun',
    logo: `${IMG_URL}/sun/logo.webp`,
    thumbnail: `${IMG_URL}/sun/sun.webp`,
    gallery: [
      `${IMG_URL}/sun/img-1.webp`,
      `${IMG_URL}/sun/img-2.webp`,
      `${IMG_URL}/sun/img-3.webp`,
    ],
    show: true,
  },
  {
    title: 'Graph Belle',
    recommended: ['WOMEN'],
    brandTitle1: 'The Definition Of Me',
    brandTitle2: 'Composed And Elegant',
    description:
      'A collection that embodies the beauty of minimalism, featuring frames in simple yet sophisticated designs that complement any style.',
    alt: 'graphbelle',
    productList: '/sg/en/eyeglasses/brands/graphbelle.html',
    productDetails: null,
    logo: `${IMG_URL}/grahpbelle-new/logo-s.webp`,
    thumbnail: `${IMG_URL}/grahpbelle/grahpbelle.webp`,
    gallery: [
      `${IMG_URL}/graphbelle/img-1.webp`,
      `${IMG_URL}/graphbelle/img-2.webp`,
      `${IMG_URL}/graphbelle/img-3.webp`,
    ],
    show: true,
  },
  {
    title: 'John Dillinger',
    recommended: ['MEN'],
    brandTitle1: 'Vintage',
    brandTitle2: 'Reinterpreted',
    description:
      'Inspired by vintage eyewear designs with a contemporary twist, this collection features frames that exude sophistication and character.',
    alt: 'johndillinger',
    productList: '/sg/en/eyeglasses/brands/JohnDillinger.html',
    productDetails: null,
    logo: `${IMG_URL}/johndillinger-new/logo.webp`,
    thumbnail: `${IMG_URL}/johndillinger-new/johndillinger.webp`,
    gallery: [
      `${IMG_URL}/johndillinger/img-1.webp`,
      `${IMG_URL}/johndillinger/img-2.webp`,
      `${IMG_URL}/johndillinger/img-3.webp`,
      `${IMG_URL}/johndillinger/img-4.webp`,
      `${IMG_URL}/johndillinger/img-5.webp`,
    ],
    show: true,
  },
  {
    title: 'Senichisaku',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: 'Japanese Craftsmanship',
    brandTitle2: 'The Epitome Of Sublime Beauty',
    description:
      'A premium collection that showcases the finest Japanese craftsmanship, featuring frames made with exceptional attention to detail.',
    alt: 'senichi',
    productList: '/sg/en/eyeglasses/brands/Senichisaku.html',
    productDetails: '/sg/en/news/senichisaku',
    logo: `${IMG_URL}/senichi-new/logo-s.webp`,
    thumbnail: `${IMG_URL}/senichi-new/senichisaku.webp`,
    gallery: [
      `${IMG_URL}/senichi/img-1.webp`,
      `${IMG_URL}/senichi/img-2.webp`,
      `${IMG_URL}/senichi/img-3.webp`,
      `${IMG_URL}/senichi/img-4.webp`,
      `${IMG_URL}/senichi/img-5.webp`,
    ],
    show: true,
  },
  {
    title: 'NICHE',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: 'Ride The Latest Wave',
    brandTitle2: 'For A Standout Style',
    description:
      'A collection for those who appreciate unique design elements and superior quality, featuring frames that make a subtle yet memorable statement.',
    alt: 'niche',
    productList: '/sg/en/eyeglasses/brands/niche.html',
    productDetails: null,
    logo: `${IMG_URL}/niche-new/logo.webp`,
    thumbnail: `${IMG_URL}/niche-new/niche.webp`,
    gallery: [
      `${IMG_URL}/niche-new/img-1.webp`,
      `${IMG_URL}/niche-new/img-2.webp`,
      `${IMG_URL}/niche-new/img-3.webp`,
    ],
    show: true,
  },
  {
    title: 'Junni',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: 'Levelling Up To The Adults',
    brandTitle2: 'In Style And Durability',
    description:
      'A collection that embraces clean lines and contemporary aesthetics, featuring frames that are both stylish and versatile.',
    alt: 'junni',
    productList: '/sg/en/eyeglasses/brands/Junni.html',
    productDetails: null,
    logo: `${IMG_URL}/junni-new/logo.webp`,
    thumbnail: `${IMG_URL}/junni-new/junni.webp`,
    gallery: [
      `${IMG_URL}/junni/img-1.webp`,
      `${IMG_URL}/junni/img-2.webp`,
      `${IMG_URL}/junni/img-3.webp`,
    ],
    show: true,
  },
  {
    title: 'K.moriyama',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: 'Creating Fluid Strokes',
    brandTitle2: 'Always Radiating In Elegance',
    description:
      'A collection that combines traditional Japanese aesthetics with modern design sensibilities, featuring frames of exceptional quality.',
    alt: 'k-moriyama',
    productList: '/sg/en/eyeglasses/brands/kmoriyama.html',
    productDetails: null,
    logo: `${IMG_URL}/kmoriyama-new/logo.webp`,
    thumbnail: `${IMG_URL}/kmoriyama-new/kmoriyama.webp`,
    gallery: [`${IMG_URL}/kmoriyama-new/img-1.webp`, `${IMG_URL}/kmoriyama-new/img-2.webp`],
    show: true,
  },
  {
    title: 'marcus raw',
    recommended: ['MEN'],
    brandTitle1: 'An Unrivalled Presence',
    brandTitle2: 'Defying Stereotypes',
    description:
      'A collection that pushes boundaries with its avant-garde designs, featuring frames that make a strong fashion statement.',
    alt: 'marcusraw',
    productList: null,
    productDetails: null,
    logo: `${IMG_URL}/marcusraw-new/logo.webp`,
    thumbnail: `${IMG_URL}/marcusraw-new/marcusraw.webp`,
    gallery: [`${IMG_URL}/marcusraw-new/img-1.webp`, `${IMG_URL}/marcusraw-new/img-2.webp`],
    show: true,
  },
  {
    title: 'BUTTERFLY EFFECT',
    recommended: ['WOMEN'],
    brandTitle1: 'Unleash Your Individuality',
    brandTitle2: 'Through New Challenges',
    description:
      'A collection that celebrates feminine beauty and grace, featuring frames with delicate details and sophisticated designs.',
    alt: 'butterflyeffect',
    productList: null,
    productDetails: null,
    logo: `${IMG_URL}/butterflyeffect-new/logo.webp`,
    thumbnail: `${IMG_URL}/butterflyeffect-new/butterflyeffect.webp`,
    gallery: [
      `${IMG_URL}/butterflyeffect-new/img-1.webp`,
      `${IMG_URL}/butterflyeffect-new/img-2.webp`,
      `${IMG_URL}/butterflyeffect-new/img-3.webp`,
    ],
    show: true,
  },
  {
    title: 'TOM and JERRY × OWNDAYS',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'Discover the collection inspired by Tom and Jerry, the beloved slapstick cartoon comedy starring the iconic duo in an endless game of cat-and-mouse! This collaboration captures their playful dynamic - a true friendship wrapped in constant mischief. Packed with hidden surprises, they are sure to leave you smiling.',
    alt: 'TOM and JERRY × OWNDAYS',
    productList: '/sg/en/eyeglasses/brands/tomandjerry.html',
    productDetails: '/sg/en/news/tomandjerry',
    logo: `${NEW_IMG_URL}/tomandjerry/brand_logo.webp`,
    isLogoExpanded: true,
    thumbnail: `${NEW_IMG_URL}/tomandjerry/thumbnail.webp`,
    gallery: [
      `${NEW_IMG_URL}/tomandjerry/brand_slider1.webp`,
      `${NEW_IMG_URL}/tomandjerry/brand_slider2.webp`,
      `${NEW_IMG_URL}/tomandjerry/brand_slider3.webp`,
      `${NEW_IMG_URL}/tomandjerry/brand_slider4.webp`,
      `${NEW_IMG_URL}/tomandjerry/brand_slider6.webp`,
    ],
    show: true,
  },
  {
    title: 'OWNDAYS & POMPOMPURIN',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'Presenting the spectacle frame collaboration with POMPOMPURIN. It’s cuteness overload with these spectacle frames featuring Pompompurin, the boy golden retriever with his trademark brown beret. Whether you’re out and about or relaxing at home, pick your favourable pair and keep Pompompurin by your side at all time!',
    alt: 'OWNDAYS & POMPOMPURIN',
    productList: '/sg/en/eyeglasses/brands/pompompurin.html',
    productDetails: '/sg/en/news/pompompurin',
    logo: `${IMG_URL}/pompompurin/brand_logo.webp`,
    isLogoExpanded: true,
    thumbnail: `${IMG_URL}/pompompurin/thumbnail.webp`,
    gallery: [
      `${IMG_URL}/pompompurin/brand_slider1.webp`,
      `${IMG_URL}/pompompurin/brand_slider2.webp`,
      `${IMG_URL}/pompompurin/brand_slider3.webp`,
      `${IMG_URL}/pompompurin/brand_slider4.webp`,
      `${IMG_URL}/pompompurin/brand_slider5.webp`,
      `${IMG_URL}/pompompurin/brand_slider6.webp`,
    ],
    show: true,
  },
  {
    title: 'OWNDAYS × HUAWEI Eyewear 2',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'Introducing the Titanium Edition of the OWNDAYS × HUAWEI Eyewear 2 Smart Audio Glasses. With this addition, the collection now features six models, each available in two colour variations.',
    alt: 'OWNDAYS × HUAWEI Eyewear 2',
    productList: '/sg/en/eyeglasses/brands/huawei2.html',
    productDetails: '/sg/en/news/huawei2',
    logo: `${NEW_IMG_URL}/huawei2/brand_logo.webp`,
    thumbnail: `${NEW_IMG_URL}/huawei2/Brands.webp`,
    gallery: [
      `${NEW_IMG_URL}/huawei2/brand_slider1.webp`,
      `${NEW_IMG_URL}/huawei2/brand_slider2.webp`,
      `${NEW_IMG_URL}/huawei2/brand_slider3.webp`,
      `${NEW_IMG_URL}/huawei2/brand_slider4.webp`,
      `${NEW_IMG_URL}/huawei2/brand_slider5.webp`,
      `${NEW_IMG_URL}/huawei2/brand_slider6.webp`,
      `${NEW_IMG_URL}/huawei2/brand_slider7.webp`,
      `${NEW_IMG_URL}/huawei2/brand_slider8.webp`,
      `${NEW_IMG_URL}/huawei2/brand_slider9.webp`,
    ],
    show: true,
    isLogoExpanded: true,
  },
  {
    title: 'OWNDAYS × DITA Lancier',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'A collaborative collection with luxury eyewear brand DITA, featuring frames that combine premium materials with sophisticated design.',
    alt: 'dita-lancier',
    productList: null,
    productDetails: null,
    logo: `${IMG_URL}/dita-lancier-new/logo.webp`,
    thumbnail: `${IMG_URL}/dita-lancier/OWNDAYS_×_DITA_Lancier.webp`,
    gallery: [
      `${IMG_URL}/dita-lancier/img-1.webp`,
      `${IMG_URL}/dita-lancier/img-2.webp`,
      `${IMG_URL}/dita-lancier/img-3.webp`,
      `${IMG_URL}/dita-lancier/img-4.webp`,
    ],
    show: true,
  },
  {
    title: 'ZEON EXCLUSIVE EYEGLASSES & SUNGLASSES',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'A collection that combines advanced technology with stylish design, featuring frames that offer both comfort and durability.',
    alt: 'gundam5-zeon',
    productList: '/sg/en/eyeglasses/brands/zeon.html',
    productDetails: '/sg/en/news/gundam5-zeon',
    logo: `${IMG_URL}/gundam5-zeon-new/logo.webp`,
    thumbnail: `${IMG_URL}/gundam5-zeon/zeon.webp`,
    gallery: [
      `${IMG_URL}/gundam5-zeon/img-1.webp`,
      `${IMG_URL}/gundam5-zeon/img-2.webp`,
      `${IMG_URL}/gundam5-zeon/img-3.webp`,
      `${IMG_URL}/gundam5-zeon/img-4.webp`,
    ],
    show: true,
  },
  {
    title: 'Harry Potter × OWNDAYS',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'A special collaboration featuring frames inspired by the magical world of Harry Potter, perfect for fans of the beloved series.',
    alt: 'harry-potter',
    productList: '/sg/en/eyeglasses/brands/harry-potter.html',
    productDetails: '/sg/en/news/harry-potter',
    logo: `${IMG_URL}/harry-potter-new/logo.webp`,
    thumbnail: `${IMG_URL}/harry-potter/Harry_Potter_×_OWNDAYS.webp`,
    gallery: [
      `${IMG_URL}/harry-potter/img-1.webp`,
      `${IMG_URL}/harry-potter/img-2.webp`,
      `${IMG_URL}/harry-potter/img-3.webp`,
      `${IMG_URL}/harry-potter/img-4.webp`,
      `${IMG_URL}/harry-potter/img-5.webp`,
    ],
    show: true,
  },
  {
    title: `OWNDAYS × FREAK'S STORE`,
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'A collaboration that brings street fashion to eyewear, featuring frames with urban appeal and contemporary design.',
    alt: 'freaks-store',
    productList: null,
    productDetails: '/sg/en/news/freaks-store',
    logo: `${IMG_URL}/freaks-store-new/logo.webp`,
    thumbnail: `${IMG_URL}/freaks-store/OWNDAYS_×_FREAK_S_STORE.webp`,
    gallery: [
      `${IMG_URL}/freaks-store/img-1.webp`,
      `${IMG_URL}/freaks-store/img-2.webp`,
      `${IMG_URL}/freaks-store/img-3.webp`,
      `${IMG_URL}/freaks-store/img-4.webp`,
      `${IMG_URL}/freaks-store/img-5.webp`,
      `${IMG_URL}/freaks-store/img-6.webp`,
    ],
    show: true,
  },
  {
    title: 'SHINGO AIBA × OWNDAYS EYEWEAR COLLECTION',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'A collaborative collection with renowned artist Shingo Aiba, featuring frames that blend artistic expression with practical design.',
    alt: 'shingo-aiba',
    productList: null,
    productDetails: 'news/shingo-aiba',
    logo: `${IMG_URL}/shingo-aiba/logo.webp`,
    thumbnail: `${IMG_URL}/shingo-aiba/SHINGO_AIBA_×_OWNDAYS_EYEWEAR_COLLECTION.webp`,
    gallery: [
      `${IMG_URL}/shingo-aiba/img-1.webp`,
      `${IMG_URL}/shingo-aiba/img-2.webp`,
      `${IMG_URL}/shingo-aiba/img-3.webp`,
    ],
    show: true,
  },
  {
    title: 'Kuromi × OWNDAYS',
    recommended: ['WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'A delightful collaboration featuring the beloved Sanrio character Kuromi, bringing fun and personality to eyewear.',
    alt: 'kuromi',
    productList: null,
    productDetails: '/sg/en/news/kuromi',
    logo: `${IMG_URL}/kuromi-new/logo.webp`,
    thumbnail: `${IMG_URL}/kuromi/Kuromi_×_OWNDAYS.webp`,
    gallery: [
      `${IMG_URL}/kuromi/img-1.webp`,
      `${IMG_URL}/kuromi/img-2.webp`,
      `${IMG_URL}/kuromi/img-3.webp`,
    ],
    show: true,
  },
  {
    title: 'DEMON SLAYER -KIMETSU NO YAIBA',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: '',
    brandTitle2: '',
    description:
      'A special collection inspired by the hit anime series, featuring frames that capture the spirit of Demon Slayer characters.',
    alt: 'kimetsu',
    productList: null,
    productDetails: '/sg/en/news/kimetsu',
    logo: `${IMG_URL}/kimetsu/logo-s.webp`,
    thumbnail: `${IMG_URL}/kimetsu/kimetsu.webp`,
    gallery: [`${IMG_URL}/kimetsu/img-1.webp`, `${IMG_URL}/kimetsu/img-2.webp`],
    show: true,
  },
  {
    title: 'OWNDAYS PC',
    recommended: ['MEN', 'WOMEN'],
    brandTitle1: 'The Smart Choice',
    brandTitle2: 'For Digital-Age Consumers',
    description:
      'A collection designed for the digital age, featuring frames with blue light protection for comfortable screen time.',
    alt: 'owndayspc',
    productList: '/sg/en/eyeglasses/brands/owndayspc.html',
    productDetails: '/sg/en/news/owndayspc',
    logo: `${IMG_URL}/owndayspc/logo.webp`,
    thumbnail: `${IMG_URL}/owndayspc/OWNDAYS_PC.webp`,
    gallery: [`${IMG_URL}/owndayspc/img-1.webp`],
    show: true,
  },
];

export const BRANDS_LIST_FILTER = BRANDS.filter(item => {
  const { startDeactivate, endDeactivate } = item;
  const currentDate = new Date().toISOString();

  if (!startDeactivate && !endDeactivate) {
    return true;
  } else if (startDeactivate && endDeactivate) {
    if (startDeactivate < currentDate && endDeactivate > currentDate) {
      return false;
    }
    return true;
  } else if (startDeactivate && !endDeactivate) {
    if (startDeactivate < currentDate) {
      return false;
    }
    return true;
  }

  return true;
});
