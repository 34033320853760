import * as actionTypes from '../../actionTypes/locale';

const initialState = {};

export default function localeConfigs(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_LOCALE_CONFIG:
      return Object.assign({}, prevState, {});
    case actionTypes.LOAD_LOCALE_CONFIG_SUCCESS:
      return Object.assign({}, prevState, { ...action.data });
    case actionTypes.LOAD_LOCALE_CONFIG_FAIL:
      return Object.assign({}, prevState, {});
    default:
      return prevState;
  }
}
