import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';

import {
  BENEFITS,
  RECOMMENDED_LIST,
  TREATMENT_STEPS,
  DISCOUNTS,
  TERM_AND_CONDITION,
  STORES,
  INSERTION_SLIDES,
  REMOVAL_SUCTION_PUMP_SLIDES,
  REMOVAL_FINGERS_SLIDES,
  CLEANING_CARE,
} from './constants';

import './BodyOrthoKLens.scss';

const pdfUrl = 'https://static1.lenskart.com/owndays/pdf';

const TreatmentStep = ({ step, answer, note, isOpen, onClick }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
      } else {
        contentRef.current.style.maxHeight = '0';
      }
    }
  }, [isOpen]);

  return (
    <li>
      <div
        className={`body-ortho-k-lens__treatment-process__list-step ${
          isOpen ? 'body-ortho-k-lens__treatment-process__list-step__active' : ''
        }`}
      >
        <p className="body-ortho-k-lens__treatment-process__list-step-inner"></p>
      </div>
      <div className="body-ortho-k-lens__treatment-process__list-content">
        <div
          className={`body-ortho-k-lens__treatment-process__list--question ${
            isOpen ? 'body-ortho-k-lens__treatment-process__list--question--opened' : ''
          }`}
          onClick={onClick}
        >
          <span>{step}</span>
          <span className="body-ortho-k-lens__treatment-process__list--question__plus"></span>
        </div>
        <div
          ref={contentRef}
          className={`body-ortho-k-lens__treatment-process__list--answer ${
            isOpen ? 'expanded' : ''
          }`}
          style={{ maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0' }}
        >
          <div>
            <p>{answer}</p>
            {note && <span className="base-ortho-k-lens__note">{note}</span>}
          </div>
        </div>
      </div>
    </li>
  );
};

const StoreItem = ({ img, altText, storeName, storeLink }) => (
  <li>
    <img alt={altText} className="body-ortho-k-lens__stores__list-img lazyload" src={img} />
    <h3 className="body-ortho-k-lens__stores__title">{storeName}</h3>
    <a className="base-ortho-k-lens__button base-ortho-k-lens__button--right" href={storeLink}>
      Book an appointment
    </a>
  </li>
);

const SlideGuideItem = ({ step, imgSrc, description }) => (
  <li className="body-ortho-k-lens__guide__slide__item">
    <div className="body-ortho-k-lens__guide__slide__img-box">
      <div className="body-ortho-k-lens__guide__slide__order">
        <span>{step}</span>
      </div>
      <img alt={`STEP${step}`} className="lazyload" src={imgSrc} />
    </div>
    <div className="body-ortho-k-lens__guide__slide__detail">
      <p>{description}</p>
    </div>
  </li>
);

const guideSetting = {
  slidesToShow: 4,
  slidesToScroll: 4,
  infinite: false,
  swipe: true,
  touchMove: true,
  dots: false,
  draggable: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1441,
      settings: {
        slidesToShow: 3.68,
        slidesToScroll: 3.68,
      },
    },
    {
      breakpoint: 1296,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 3.2,
      },
    },
    {
      breakpoint: 1083,
      settings: {
        slidesToShow: 2.8,
        slidesToScroll: 2.8,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2.85,
        slidesToScroll: 2.85,
      },
    },
    {
      breakpoint: 930,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2.5,
      },
    },
    {
      breakpoint: 845,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 2.2,
      },
    },
    {
      breakpoint: 782,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 2.17,
        slidesToScroll: 2.17,
      },
    },
    {
      breakpoint: 730,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2.5,
      },
    },
    {
      breakpoint: 624,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 492,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1.8,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1.72,
        slidesToScroll: 1.72,
      },
    },
    {
      breakpoint: 390,
      settings: {
        slidesToShow: 1.55,
        slidesToScroll: 1.55,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1.4,
        slidesToScroll: 1.4,
      },
    },
  ],
};

export const BodyOrthoKLens = ({ baseImgUrl }) => {
  const [openSteps, setOpenSteps] = useState([0]);

  const storeRef = useRef(null);

  const handleToggle = index => {
    if (openSteps.includes(index)) {
      setOpenSteps(openSteps.filter(step => step !== index));
    } else {
      setOpenSteps([...openSteps, index]);
    }
  };

  return (
    <div className="base-ortho-k-lens__container">
      <section className="body-ortho-k-lens__key-benefits">
        <h2 className="base-ortho-k-lens__title base-ortho-k-lens__title--center">Key Benefits</h2>
        <ul className="body-ortho-k-lens__key-benefits__list">
          {BENEFITS.map((benefit, index) => (
            <li key={index}>
              <div className="body-ortho-k-lens__key-benefits__list-top">
                <img
                  alt={benefit.alt}
                  className="body-ortho-k-lens__key-benefits__list-img lazyload"
                  src={`${baseImgUrl}/${benefit.src}`}
                />
                <h3 className="body-ortho-k-lens__key-benefits__list-title">{benefit.title}</h3>
              </div>
              <p>{benefit.description}</p>
            </li>
          ))}
        </ul>
      </section>

      <section className="body-ortho-k-lens__recommended">
        <div className="body-ortho-k-lens__recommended__content">
          <div className="body-ortho-k-lens__recommended__left-container">
            <h2 className="base-ortho-k-lens__title">
              Who is Ortho-K
              <br />
              recommended for?
            </h2>
            <a
              className="body-ortho-k-lens__recommended__link"
              href={`${pdfUrl}/23_Annex_C-IFU-v2.pdf`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Detailed instructions for use
            </a>
          </div>
          <ul className="body-ortho-k-lens__recommended__list">
            {RECOMMENDED_LIST.map((item, index) => (
              <li key={`recommend-ortho-lens-${index}`}>{item.name}</li>
            ))}
          </ul>
        </div>
        <a
          className="base-ortho-k-lens__button base-ortho-k-lens__button--right"
          href="/stores/sg?stores=Ortho-k"
        >
          Which stores is Ortho-K available at?
        </a>
      </section>

      <section className="body-ortho-k-lens__treatment-process">
        <h2 className="base-ortho-k-lens__title base-ortho-k-lens__title--center">
          Treatment Process
        </h2>
        <div className="base-ortho-k-lens__container--sub">
          <ul className="body-ortho-k-lens__treatment-process__list">
            {TREATMENT_STEPS.map((stepData, index) => (
              <TreatmentStep
                key={index}
                answer={stepData.answer}
                isOpen={openSteps.includes(index)}
                note={stepData.note}
                step={stepData.step}
                onClick={() => handleToggle(index)}
              />
            ))}
          </ul>
        </div>
      </section>

      <section className="body-ortho-k-lens__price">
        <div>
          <h2 className="base-ortho-k-lens__title">Price</h2>
          <p>
            From $1,688 depending on complexity
            <br />
            Includes: 1 set of solutions and 1 pair of spectacles worth up to $198
          </p>
        </div>

        <div className="body-ortho-k-lens__price__content">
          <p>Enjoy exclusive discount on repeat purchases:</p>
          <ul>
            {DISCOUNTS.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <br />

          <p>Repeat Purchase Terms and Conditions</p>
          <ul>
            {TERM_AND_CONDITION.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </section>

      <section ref={storeRef} className="body-ortho-k-lens__stores" id="stores">
        <div>
          <h2 className="base-ortho-k-lens__title">Stores</h2>
          <p>
            Ortho-K is available exclusively at the following premium concept stores by appointment
            basis:
          </p>
        </div>

        <div>
          <ul className="body-ortho-k-lens__stores__list">
            {STORES.map((store, index) => (
              <StoreItem key={index} {...store} />
            ))}
          </ul>
          <p className="base-ortho-k-lens__note">
            Appointments are available for online booking only, be it for pre-assessment or simply
            to meet our qualified optometrists in-store to find out more about Ortho-K.{' '}
          </p>
        </div>
      </section>

      <section className="body-ortho-k-lens__guide">
        <div className="body-ortho-k-lens__guide__title">
          <h2 className="base-ortho-k-lens__title base-ortho-k-lens__title--center">
            Beginner’s Guide
            <br />
            to Wearing Ortho-K
          </h2>
          <h3>[ Inserting & Removing Lenses ]</h3>
        </div>

        <div className="body-ortho-k-lens__guide__frame__slide">
          <div className="body-ortho-k-lens__guide__row">
            <div className="body-ortho-k-lens__guide__row__title">
              <div className="body-ortho-k-lens__guide__row__title__group">
                <div className="body-ortho-k-lens__guide__row__title__main-title">
                  <h4>Insertion</h4>
                </div>
              </div>
            </div>

            <Slider {...guideSetting} className="body-ortho-k-lens__guide__slide">
              {INSERTION_SLIDES.map(item => (
                <SlideGuideItem key={item.step} {...item} />
              ))}
            </Slider>
          </div>

          <div className="body-ortho-k-lens__guide__row">
            <div className="body-ortho-k-lens__guide__row__title">
              <div className="body-ortho-k-lens__guide__row__title__group">
                <div className="body-ortho-k-lens__guide__row__title__main-title">
                  <h4>Removal</h4>
                </div>
                <div className="body-ortho-k-lens__guide__row__title__sub-title">
                  <p>with suction pump</p>
                </div>
              </div>
            </div>

            <Slider {...guideSetting} className="body-ortho-k-lens__guide__slide">
              {REMOVAL_SUCTION_PUMP_SLIDES.map(item => (
                <SlideGuideItem key={item.step} {...item} />
              ))}
            </Slider>
          </div>

          <div className="body-ortho-k-lens__guide__row">
            <div className="body-ortho-k-lens__guide__row__title">
              <div className="body-ortho-k-lens__guide__row__title__group">
                <div className="body-ortho-k-lens__guide__row__title__main-title">
                  <h4>Removal</h4>
                </div>
                <div className="body-ortho-k-lens__guide__row__title__sub-title">
                  <p>with fingers</p>
                </div>
              </div>
            </div>

            <Slider {...guideSetting} className="body-ortho-k-lens__guide__slide">
              {REMOVAL_FINGERS_SLIDES.map(item => (
                <SlideGuideItem key={item.step} {...item} />
              ))}
            </Slider>
          </div>
        </div>

        <div className="body-ortho-k-lens__guide__title">
          <h3>[ Cleaning & Care ]</h3>
        </div>

        <div className="body-ortho-k-lens__guide__clean">
          <div className="body-ortho-k-lens__guide__clean__row">
            <ul className="body-ortho-k-lens__guide__clean__list">
              {CLEANING_CARE.map((item, index) => (
                <li key={index} className="body-ortho-k-lens__guide__clean__item">
                  <h4 className="body-ortho-k-lens__guide__clean__item__main-title-card">
                    {item.title}
                  </h4>
                  <div className="body-ortho-k-lens__guide__clean__item__img-clean">
                    <img alt={item.altText} className="lazyload" src={item.imgSrc} />
                  </div>
                  <div className="body-ortho-k-lens__guide__clean__item__detail">
                    <p>{item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};
