import React from 'react';

import './RetailSection.scss';

const RetailSection = () => {
  return (
    <>
      <div className="l-account__price">
        <div className="l-account__price-container" id="price">
          <div className="l-account__price-container-title">SIMPLE PRICE</div>
          <p className="l-account__price-container-main l-account__price-container-main--center">
            <strong>No additional charge </strong>
            <br className="u-sp-only" />
            <span>for standard lenses of any power</span>
          </p>
          <p className="l-account__price-container-text">
            Pay only the price indicated on the frame
          </p>
          <div className="l-account__price-container-table">
            <img
              alt="Frame"
              className="u-svg__icon-frame"
              src="https://static.lenskart.com/owndays/img/services/top/icon-frame.svg"
            />
            <div className="u-mark u-mark__plus"></div>
            <img
              alt="Lens"
              className="u-svg__icon-lens"
              src="https://static.lenskart.com/owndays/img/services/top/icon-lens.svg"
            />
            <div className="u-mark u-mark__equal"></div>
            <div className="l-account__price-container-table__c-price">
              S$98<small></small>〜
            </div>
          </div>
          <p className="l-account__price-container-text-description">
            In OWNDAYS, we pride ourselves in our simple price system. <br className="u-pc-only" />
            All you need to pay for a pair of spectacles is the price indicated on the frame.
            <br className="u-pc-only" />
            There is no additional charge for standard high index aspheric lenses of any power.
          </p>
        </div>
      </div>
      <div className="l-account__inner">
        <div className="l-account__inner--content" id="pear-discount">
          <div className="l-account__inner--title">
            2nd Pair at Half Price
            <br />
            for Same Day Purchase
          </div>
          <div className="l-account__inner--terms-conditions">
            <img
              alt="2nd Pair Discount"
              className="l-account__inner--image"
              src="https://static.lenskart.com/owndays/img/services/price/pear.webp"
            />
            <div className="l-account__inner--subtitle">Terms and Conditions</div>
            <ul className="l-account__inner--list">
              <li className="l-account__inner--list-item">
                Offer is applicable to the purchase of all prescription glasses (frames with
                standard lenses) only and applies to the lower priced item for every bundle purchase
                of 2 pairs of prescription glasses per transaction.
              </li>
              <li className="l-account__inner--list-item">
                Offer is not applicable for collaboration merchandise, limited edition merchandise,
                sale items, sunglasses, OWNDAYS PC glasses, other ready-to-wear merchandise and all
                lens upgrades.
              </li>
              <li className="l-account__inner--list-item">
                Offer is valid for prescription glasses purchased for different wearers so long the
                purchase is made in the same transaction.
              </li>
              <li className="l-account__inner--list-item">
                Offer is valid for same day purchase only.
              </li>
              <li className="l-account__inner--list-item">
                Offer cannot be used in conjunction with other discounts, vouchers and/or
                promotions.
              </li>
              <li className="l-account__inner--list-item">
                The management reserves the right to amend the offer and the terms and conditions
                without prior notice.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RetailSection;
