import React from 'react';
import './Header.scss';

const Header = ({ headerContent }) => {
  const { bgPc, bgSp, bgAlt, align, theme, id, title, description } = headerContent;

  return (
    <div className="services__header">
      <div className="services__header-bg">
        <picture>
          <source media="(min-width:768px)" srcSet={bgPc} />
          <img alt={bgAlt} src={bgSp} />
        </picture>
      </div>
      <div
        className={`services__header-content services__header-content--${align} services__header-content--${theme} ${
          ['warranty', 'staff'].includes(id) ? 'services__header-content--sp-bg-light' : ''
        }`}
      >
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
};

export default Header;
