import React, { Fragment } from 'react';
import Sheet from './Sheet';

const Contents = ({ contents, isTemporaryClosure }) => {
  return (
    <div
      className={`information-details__contents ${isTemporaryClosure ? 'temporary-closure' : ''}`}
    >
      {contents?.map((content, index) => (
        <Fragment key={index}>
          {content?.title && <h2>{content?.title}</h2>}
          <div
            dangerouslySetInnerHTML={{ __html: content?.html }}
            className={`${isTemporaryClosure ? 'mb-20' : 'mb-50'}`}
          />
          {content?.sheet && (
            <Sheet sheet={content?.sheet} isTemporaryClosure={isTemporaryClosure} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Contents;
