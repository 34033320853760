import React from 'react';

export const SUNGLASSES = {
  header: [
    {
      title: 'Basking In The Sun',
    },
    {
      title: 'Embracing Greater Freedom',
    },
  ],
  frameType: 'SUNGLASSES',
  desc: `From neutral earth tones to transition sunglasses, discover a curated selection designed to rejuvenate your everyday look while offering over 99% UV protection. Slip on a pair that resonates with your style and make it an extension of your personality.`,
  images: [
    {
      sp: '/sunglasses-sp-01.webp',
      pc: '/sunglasses-pc-01.webp',
      alt: 'Sunglasses',
    },
    {
      sp: '/sunglasses-sp-02.webp',
      pc: '/sunglasses-pc-02.webp',
      alt: 'Sunglasses',
    },
  ],
  descSection: {
    link: '/sg/en/eyeglasses/brands/sun.html',
  },
};

export const SNAP_SUNGLASSES = {
  header: [
    {
      title: 'Just A Simple SNAP',
    },
    {
      title: 'Be It Indoors Or Outdoors',
    },
  ],
  frameType: 'SNAP SUNGLASSES',
  desc: `Designed with built-in magnets that allow you to transform your spectacles into sunglasses in a split second! Additionally, SNAP LENS is fitted with polarised lenses that can reduce glare from shiny surfaces such as the road and water surface, giving you more comfortable vision.`,
  images: [
    {
      sp: '/snap-sp-01.webp',
      pc: '/snap-pc-01.webp',
      alt: 'Snap Sunglasses',
    },
    {
      sp: '/snap-sp-02.webp',
      pc: '/snap-pc-02.webp',
      alt: 'Snap Sunglasses',
    },
  ],
  detail: {
    alt: 'Glasses made of resin',
    src: '/snap-frame.webp',
  },
  features: [
    {
      src: '/feature-1.svg',
      desc: '2-in-1 spectacles that double up as sunglasses',
    },
    {
      src: '/feature-2_r.svg',
      desc: 'With built-in magnets for quick attaching/detaching',
    },
    {
      src: '/feature-3.svg',
      desc: 'Polarised lenses reduce glare from shiny surfaces',
    },
  ],
  descSection: {
    link: '/sg/en/eyeglasses/brands/sun.html',
  },
  isShowFooter: true,
};

export const CLEAR_SUNGLASSES = {
  header: [
    {
      title: 'Keep UV At Bay',
    },
    {
      title: 'Wear Non-Tinted Sunglasses',
    },
  ],
  frameType: 'CLEAR SUNGLASSES',
  desc: `Fitted with transparent, non-tinted anti-UV blue light lenses, these sunglasses offer you double protection against UV rays and harmful blue light! They are the ideal eyewear for all occasions, be it your daily commute, weekend outings or holiday trips.`,
  images: [
    {
      sp: '/clear-sunglasses-sp-01.webp',
      pc: '/clear-sunglasses-pc-01.webp',
      alt: 'Clear Sunglasses',
    },
    {
      sp: '/clear-sunglasses-sp-02.webp',
      pc: '/clear-sunglasses-pc-02.webp',
      alt: 'Clear Sunglasses',
    },
  ],
  detail: {
    alt: 'Glasses made of resin',
    src: '/clear-sunglasses-frame.webp',
  },
  descSection: {
    link: '/sg/en/eyeglasses/brands/sun.html',
  },
  isShowFooter: true,
};

export const SUNGLASSES_LINEUP = [
  { model: 'SUN2104B-4S', delay: 0 },
  { model: 'SUN1074B-4S', delay: 100 },
  { model: 'SUN2107N-4S', delay: 200 },
  { model: 'SUN1073B-4S', delay: 300 },
  { model: 'SUN2105B-4S', delay: 400 },
  { model: 'OB1006G-4A', delay: 500 },
];

export const SNAP_SUNGLASSES_LINEUP = [
  { model: 'SNP1022X-4S', delay: 0 },
  { model: 'SNP1024N-4S', delay: 100 },
  { model: 'SNP1021X-4S', delay: 200 },
];

export const CLEAR_SUNGLASSES_LINEUP = [
  { model: 'CSU1004B-4S', delay: 0 },
  { model: 'CSU2003B-4S', delay: 100 },
  { model: 'CSU1002B-2S', delay: 200 },
];

export const OPTIONAL_LENSES_SUNGLASSES = {
  subTitle: (
    <p className="body-sun__styles__option-sub-title">
      Adding prescription with <br />
      optional lenses
    </p>
  ),
  desc: (
    <p className="body-sun__styles__option-desc">
      Upgrade to prescription sunglasses from $198, which includes a lens replacement fee of $98 and
      an optional lens upgrade for $100*. You can also customize your sunglasses by swapping the
      original lenses for lenses in a different colour.
    </p>
  ),
  remark: (
    <p className="body-sun__styles__option-desc body-sun__styles__option-desc--remark">
      Additional fees apply for other lens upgrade options
    </p>
  ),
  lens: [
    {
      alt: 'Price of Sunglasses',
      src: '/icon-sunglasses-prices.svg',
      text: (
        <p>
          Price of <br />
          Sunglasses
        </p>
      ),
    },
    {
      alt: 'Lens Replacement Fee',
      src: '/icon-lens-processing-fee.svg',
      text: (
        <p>
          Lens Replacement <br />
          Fee
        </p>
      ),
    },
    {
      alt: 'Optional Lens Upgrade Fee',
      src: '/icon-optional-lens-price.svg',
      text: (
        <p>
          Optional Lens <br />
          Upgrade Fee
        </p>
      ),
    },
  ],
  recommended: {
    list: [
      {
        src: '/banner-lens-color.webp',
        title: 'Colour Lenses',
        link: '/sg/en/services/lens#color',
        price: 'S$100',
      },
      {
        src: '/banner-lens-transition.webp',
        title: 'Transitions Lenses',
        link: '/sg/en/services/lens#transitions-light',
        price: 'S$200',
      },
      {
        src: '/banner-lens-polarised.webp',
        title: 'Polarised Lenses',
        link: '/sg/en/services/lens#polarised',
        price: 'S$100',
      },
    ],
    remarks: [
      {
        text: 'This service is only available for sunglasses purchased from physical retail stores only',
      },
      { text: 'Lens replacement is subject to lens power and the type of sunglasses' },
      {
        text: 'Slight variations in colour may occur when replacing with prescription lenses of the same colour',
      },
    ],
  },
};
