import React from 'react';
import ServiceSection from '../views/services/Top/ServiceSection';
import Breadcrumbs from '../CommonComponents/Breadcrumbs/Breadcrumbs';
import Header from '../CommonComponents/Services/Header/Header';
import Navigation from '../CommonComponents/Services/Navigation/Navigation';
import '../views/services/Top/BaseService.scss';
import {
  getBreadcrumbItems,
  getCurrentPath,
} from '../CommonComponents/Breadcrumbs/breadcrumbUtils';

const ServicesHTML = () => {
  const crumbs = getBreadcrumbItems();
  const currentPath = getCurrentPath();
  const headerContent = {
    title: 'Services',
    description:
      'At OWNDAYS, we are dedicated to providing the best service to our customers. Using the concept of OWNDAYS Standard, we strive to offer a consistent level of excellent customer service across all our retail outlets.',
    bgSp: 'https://static.lenskart.com/owndays/img/services/top/main-768x500.webp',
    bgPc: 'https://static.lenskart.com/owndays/img/services/top/main-1536x480.webp',
    bgAlt: 'OWNDAYS Services',
    align: 'left',
    theme: 'dark',
  };

  return (
    <main className="service_l">
      <Breadcrumbs crumbs={crumbs} />
      <div className="service_l__section">
        <Navigation currentPath={currentPath} size="desktop" />
        <Header headerContent={headerContent} />
        <div className="service_l__container">
          <ServiceSection />
        </div>
        <div className="service_l__container">
          <Navigation currentPath={currentPath} size="mobile" />
        </div>
      </div>
    </main>
  );
};

export default ServicesHTML;
