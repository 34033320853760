import React from 'react';

const StoreDetails = ({ storeDetails }) => {
  return (
    <div className="information-details__store-details">
      <h2>Store Details</h2>
      <ul>
        <li>
          <strong>Address</strong>
          {storeDetails?.address}
        </li>
        <li>
          <strong>Operating Hours</strong>
          {storeDetails?.operatingHours}
        </li>
      </ul>
    </div>
  );
};

export default StoreDetails;
