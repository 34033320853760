const SCHEDULE_10AM = ['10:00 - 18:00', 'Closed', 'Closed', 'Business as usual'];
const SCHEDULE_11AM = ['11:00 - 18:00', 'Closed', 'Closed', 'Business as usual'];
const SCHEDULE_COMMON_SPECIAL = [
  '11:00 - 18:00',
  '12:00 - 21:00',
  '12:00 - 22:00',
  'Business as usual',
];
const SCHEDULE_MBS_2021_2022 = [
  '11:00 - 22:00',
  '11:00 - 22:00',
  '11:00 - 22:00',
  'Business as usual',
];
const SCHEDULE_MBS_2023 = ['11:00 - 23:00', '11:00 - 23:00', '11:00 - 23:00', 'Business as usual'];
const SCHEDULE_JEWEL_2021 = [
  '11:00 - 18:00',
  '10:00 - 22:00',
  '10:00 - 22:00',
  'Business as usual',
];
const SCHEDULE_JEWEL_2022 = [
  '11:00 - 18:00',
  '12:00 - 21:00',
  '12:00 - 21:00',
  'Business as usual',
];
const SCHEDULE_JEWEL_2023 = [
  '10:00 - 18:00',
  '10:00 - 22:00',
  '10:00 - 22:00',
  'Business as usual',
];
const TEMP_SCHEDULE_1030_TO_1700 = ['10:30am - 5:00pm'];
const TEMP_SCHEDULE_1000_TO_1700 = ['10:00am - 5:00pm'];
const TEMP_SCHEDULE_1000_TO_1630 = ['10:00am - 4:30pm'];
const TEMP_SCHEDULE_1100_TO_1630 = ['11:00am - 4:30pm'];
const TEMP_SCHEDULE_1100_TO_1700 = ['11:00am - 5:00pm'];

const createStore = (store, shedules = SCHEDULE_11AM) => ({ store, shedules });

const STORE_COMMON = [
  createStore('OWNDAYS Plaza Singapura'),
  createStore('OWNDAYS 313@somerset'),
  createStore('OWNDAYS Bedok Mall'),
  createStore('OWNDAYS nex'),
  createStore('OWNDAYS Raffles City'),
  createStore('OWNDAYS Bugis+'),
  createStore('OWNDAYS IMM Outlet'),
  createStore('OWNDAYS Suntec City'),
  createStore('OWNDAYS JCube'),
  createStore('OWNDAYS White Sands'),
  createStore('OWNDAYS Tiong Bahru Plaza'),
  createStore('OWNDAYS Compass One'),
  createStore('OWNDAYS City Square Mall'),
  createStore('OWNDAYS Westgate'),
  createStore('OWNDAYS 100AM'),
  createStore('OWNDAYS Clementi Mall'),
  createStore('OWNDAYS Northpoint City'),
  createStore('OWNDAYS SingPost Centre'),
  createStore('OWNDAYS Seletar Mall'),
  createStore('OWNDAYS AMK Hub'),
  createStore('OWNDAYS Bukit Panjang Plaza'),
  createStore('OWNDAYS Century Square'),
  createStore('OWNDAYS PLQ Mall'),
  createStore('OWNDAYS Causeway Point'),
  createStore('OWNDAYS Parkway Parade'),
  createStore('OWNDAYS BUGIS JUNCTION'),
  createStore('OWNDAYS ION Orchard', SCHEDULE_10AM),
  createStore('OWNDAYS Takashimaya S.C Premium Concept Store', SCHEDULE_10AM),
];

// stores list 2021 - 2023
const COMMON_SPECIAL_STORE_NAMES = [
  'OWNDAYS Jurong Point',
  'OWNDAYS Vivo City',
  'OWNDAYS Waterway Point',
  'OWNDAYS MBS Premium Concept Store',
  'OWNDAYS Jewel Changi',
];

function getSchedule(store, year) {
  if (store === 'OWNDAYS MBS Premium Concept Store') {
    return year === 2023 ? SCHEDULE_MBS_2023 : SCHEDULE_MBS_2021_2022;
  }
  if (store === 'OWNDAYS Jewel Changi') {
    if (year === 2021) return SCHEDULE_JEWEL_2021;
    if (year === 2022) return SCHEDULE_JEWEL_2022;
    return SCHEDULE_JEWEL_2023;
  }
  return SCHEDULE_COMMON_SPECIAL;
}

function createCommonSpecialStores(year) {
  return COMMON_SPECIAL_STORE_NAMES.map(store => createStore(store, getSchedule(store, year)));
}

const STORE_LIST_2021 = [...STORE_COMMON, ...createCommonSpecialStores(2021)];
const STORE_LIST_2022 = [...STORE_COMMON, ...createCommonSpecialStores(2022)];
const STORE_LIST_2023 = [...STORE_COMMON, ...createCommonSpecialStores(2023)];

// stores list temporary closure notice
const STORE_LIST_TEMPORARY_CLOSURE_NOTICE = [
  { store: 'OWNDAYS The Woodleigh Mall', shedules: TEMP_SCHEDULE_1030_TO_1700 },
  { store: 'OWNDAYS MBS Premium Concept Store', shedules: TEMP_SCHEDULE_1030_TO_1700 },
  { store: 'OWNDAYS Jewel Changi', shedules: TEMP_SCHEDULE_1000_TO_1630 },
  { store: 'OWNDAYS ION Orchard', shedules: TEMP_SCHEDULE_1000_TO_1700 },
  { store: 'OWNDAYS Takashimaya S.C Premium Concept Store', shedules: TEMP_SCHEDULE_1000_TO_1700 },
  { store: 'OWNDAYS Raffles City', shedules: TEMP_SCHEDULE_1000_TO_1700 },

  'OWNDAYS Bedok Mall',
  'OWNDAYS Tiong Bahru Plaza',
  'OWNDAYS SingPost Centre',
  'OWNDAYS PLQ Mall',
  'OWNDAYS Parkway Parade',
  'OWNDAYS White Sands',
  'OWNDAYS Century Square',
  'OWNDAYS Pasir Ris Mall',
  'OWNDAYS nex',
  'OWNDAYS Waterway Point',
  'OWNDAYS Compass One',
  'OWNDAYS City Square Mall',
  'OWNDAYS 100AM',
  'OWNDAYS Seletar Mall',
  'OWNDAYS PUNGGOL COAST MALL',
  'OWNDAYS IMM Outlet',
  'OWNDAYS Jurong Point',
  'OWNDAYS Westgate',
  'OWNDAYS Clementi Mall',
  'OWNDAYS Northpoint City',
  'OWNDAYS AMK Hub',
  'OWNDAYS Bukit Panjang Plaza',
  'OWNDAYS Causeway Point',
  'OWNDAYS LEQUEST',
].map(item =>
  typeof item === 'string' ? { store: item, shedules: TEMP_SCHEDULE_1100_TO_1630 } : item
);

const TEMP_CLOSURE_1100_TO_1700_STORES = [
  'OWNDAYS Vivo City',
  'OWNDAYS Plaza Singapura',
  'OWNDAYS 313@somerset',
  'OWNDAYS Bugis+',
  'OWNDAYS Suntec City',
  'OWNDAYS BUGIS JUNCTION',
  'OWNDAYS TOA PAYOH',
].map(store => ({
  store,
  shedules: TEMP_SCHEDULE_1100_TO_1700,
}));

const STORE_LIST_TEMPORARY_CLOSURE_NOTICE_FINAL = [
  ...STORE_LIST_TEMPORARY_CLOSURE_NOTICE,
  ...TEMP_CLOSURE_1100_TO_1700_STORES,
];

module.exports = {
  STORE_LIST_2021,
  STORE_LIST_2022,
  STORE_LIST_2023,
  STORE_LIST_TEMPORARY_CLOSURE_NOTICE: STORE_LIST_TEMPORARY_CLOSURE_NOTICE_FINAL,
};
