import React from 'react';
import './TermsAndConditions.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

export const TermsAndConditions = () => {
  const breadcrumb = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '#', label: 'Terms and Conditions' },
  ];
  return (
    <div className="od-terms">
      <Breadcrumbs crumbs={breadcrumb} />
      <div className="od-terms__title-container">
        <h1 className="od-terms__title">TERMS & CONDITIONS</h1>
      </div>
      <div className="od-terms__content">
        <div className="od-terms__content-section">
          <p>
            This website (which bears the domain name 'owndays.com') is a property of OWNDAYS
            SINGAPORE PTE LTD, a company registered in the Republic of Singapore under registration
            number 201308429G. Please read these Terms and Conditions carefully before using the
            website. By accessing to and using the website and the products and services available
            on the website (collectively referred to as "the Site"), you confirm that you have read,
            understood and agree to be bound by these Terms and Conditions. If you do not agree to
            these Terms and Conditions,
          </p>
          <p>
            please do not use the Site. If you are below the age of 13 years old, we require that
            you inform a parent or guardian of our Terms and Conditions and our Privacy Policy and
            seek their consent before registering or placing an order at the Site. The management
            reserves the right to only accept orders from customers who are 13 years and above.
          </p>
        </div>
        <div>
          <h3>Membership Registration</h3>
          <p>
            You are required to register a membership account with us before you can place an order
            or to use certain features of the Site, subject to the{' '}
            <a href="/sg/en/membership-terms">Terms and Conditions</a> of the OWNDAYS Membership
            Programme. During registration, you will need to provide personal information that is
            true, accurate and current. Should there be any change to this information, please sign
            in to your account immediately and update the relevant section. Alternatively, you may
            email us at <a href="mailto:inquirysg@owndays.com">inquirysg@owndays.com</a> to notify
            us of the change.
          </p>
          <p>
            We recommend that you keep your account password safely and log out after each use. If
            you suspect any breach or unauthorised use of your account, please notify us
            immediately. For more details on the use and security of your personal information,
            please refer to our <a href="/sg/en/privacy">Privacy Policy</a>. You may only register
            one account on the Site and the account cannot be shared nor transferred to other users.
          </p>
        </div>

        <div>
          <h3>Order and Payment</h3>
          <p>
            You must provide your name, valid email address, contact number and other information as
            indicated when you place an order. To complete and submit an order, you must follow the
            procedures shown on the Site. An order confirmation will be sent to your registered
            email when your order has been accepted.
          </p>
          <p>
            Payment must be cleared from your account before we process your order. You may make
            payment by credit/debit card bearing the MasterCard or VISA logo (collectively referred
            to as "credit card"). In particular, you are required to use your own credit card to pay
            for purchases on the Site. By placing an order, you confirm that you are authorised by
            the issuing bank or financial institution to use the credit card for purchases. We shall
            not be held liable for any fraudulent use of a credit card.
          </p>
          <p>
            Your order will be processed the next working day if it is made on the Site after 7:00pm
            SGT (GMT+8).
          </p>
        </div>

        <div>
          <h3>Published Prices</h3>
          <p>
            We make the best efforts to ensure that all prices, images, product descriptions and
            details listed on the Site are accurate. There may be instances where errors may occur.
            If there is any error on the price of the products you have ordered, we will contact you
            via email as soon as possible to re-confirm your order with the correct pricing or to
            cancel the order. In the event you are uncontactable despite our best efforts to reach
            out to you, your order will be cancelled.
          </p>
          <p>
            All prices in the Site are in Singapore Dollars (SGD) and are inclusive of the
            prevailing Goods and Services Tax (GST). If your credit card is not denominated in SGD,
            the final price charged to you will be subject to currency exchange rates and bank
            charges may apply.
          </p>
          <p>
            From time to time, some products may be marked down as sales. By placing an order, you
            accept that all prices published in the Site are final unless otherwise stated.
          </p>
        </div>

        <div>
          <h3>Promo Codes and Vouchers</h3>
          <p>
            We accept promo codes for your online order if the promo codes are valid for online
            purchases and subject to the terms and conditions of the respective promo codes. To
            redeem, promo codes must be entered during checkout. Store vouchers and coupons will not
            be valid for use on the Site unless otherwise stated.
          </p>
        </div>

        <div>
          <h3>Prescription Details</h3>
          <p>
            To purchase prescription glasses on the Site, you must provide a valid prescription
            consisting of the following details:
          </p>
          <ul>
            <li>
              Your full name, date of birth and unique identification number (e.g. NRIC number)
            </li>
            <li>Date eye examination was done</li>
            <li>Issue date and expiry date of the prescription</li>
            <li>
              Name, work/contact address and contact number of the eye-care professional who issued
              the prescription
            </li>
            <li>Refraction error with indication of spherical and cylindrical readings</li>
            <li>Inter-pupillary distance reading</li>
            <li>Back vertex distance (if applicable)</li>
            <li>
              A written assessment that you do not have any existing organic eye disease or systemic
              medical condition that will affect vision
            </li>
            <li>
              Your written acknowledgement of a statement of advice to undergo eye checks if the
              spectacles cause discomfort
            </li>
          </ul>
          <p>
            Prescription must be issued within ONE YEAR from the date you place order on the Site
            and is issued by a registered optometrist. Do inform the optometrist of your intention
            to purchase spectacles online and details of the Site.
          </p>
        </div>

        <div>
          <h3>Cancellation or Change of Order Details</h3>
          <p>We do not accept any cancellation once your order is placed and accepted by us.</p>
          <p>
            If you would like to change your order details after placing an order, please contact us
            via email at <a href="mailto:ecsg@owndays.com">ecsg@owndays.com</a> immediately. We will
            do our best to process your request. Please note that we are unable to accommodate any
            change request after your order has been processed and dispatched.
          </p>
        </div>

        <div>
          <h3>Non-Acceptance of Orders</h3>
          <p>
            We will do our best to process all orders made on the Site. In the event we are unable
            to fulfil your order due to incorrect or invalid prescription and/or discontinued
            products and/or other reasons, we reserve the right to reject and cancel your order at
            any time at our sole discretion even after an order confirmation has been sent to you.
            Should such non-acceptance of your order occur, we will inform you via email and the
            payment you have made will be fully refunded to you via the payment mode used in your
            order.
          </p>
        </div>

        <div>
          <h3>Local Delivery (within Singapore)</h3>
          <p>
            Your order will be sent to the shipping address you have specified when placing your
            order. Local delivery is complimentary with minimum order of SGD21. For orders below
            SGD21, delivery fee of SGD10 applies.
          </p>
          <p>
            Your order will be delivered in our standard packaging unless otherwise specified.
            Delivery of your order is subject to the availability of the products, accuracy of your
            information and prescription. Upon successful payment, we will do our best to deliver
            your order within 5 to 10 working days, excluding weekends and public holidays. The
            estimated delivery date indicated is for reference only and may vary according to the
            lens type and prescription. Should there be any delay in the delivery of your order, we
            will inform you via email or call.
          </p>
          <p>
            We engage an external service provider to deliver your order. Should you specifically
            instruct the delivery provider to leave your order unattended, whether outside the door
            of the shipping address or at any other location instructed by you, such delivery shall
            be at your own risk and you shall accept all liability and risk of loss, theft and
            damage.
          </p>
        </div>

        <div>
          <h3>Exchanges and Refunds</h3>
          <p>
            We do not accept any exchange, including but not limited to the change of frame model or
            lens type, once your order is placed on the Site and accepted by us.
          </p>
          <p>
            There is strictly no refund once your order is placed and accepted by us. In the event
            we are unable to fulfill your order subject to the terms and conditions outlined in the
            Non-Acceptance of Orders section above, we will process your refund based on the
            original payment mode used in your order. Please note that it will take up to 3 weeks
            for the refund to be reflected in your bank statement. Please contact your respective
            bank or credit card issuer for assistance thereafter.
          </p>
        </div>

        <div>
          <h3>Availability of Products for Online Orders</h3>
          <p>
            We offer a wide collection of both prescription and non-prescription eyewear for
            purchase on the Site. However, do note that some products, including contact lenses, are
            available only at the physical retail stores.
          </p>
          <p>
            You may also choose to upgrade the regular high index aspheric lenses to blue light
            lenses, colour lenses or Transitions lenses when you purchase spectacle frames on the
            Site. Do note that progressive lenses and certain other optional lenses are not
            available for purchase on the Site.
          </p>
        </div>

        <div>
          <h3>Product Quality and Warranty</h3>
          <p>
            All orders placed on the Site will undergo quality inspection before being shipped to
            you. If you receive any damaged or defective product, please email us at{' '}
            <a href="mailto:ecsg@owndays.com">ecsg@owndays.com</a> for assistance.
          </p>
          <p>
            We provide comprehensive warranty for products you have purchased from us, including but
            not limited to 1-year warranty against manufacturing defects for spectacle frames and
            1-year warranty on lens visual performance. * Degree based on power specified by the
            customer or a doctor's prescription (Dr Rx) is not covered under this warranty. For more
            details on our warranty programme and its terms and conditions, please refer to the{' '}
            <a href="/sg/en/services/warranty">OWNDAYS Customer Guarantees</a> page.
          </p>
        </div>

        <div>
          <h3>After-Sales Services</h3>
          <p>We provide the following after-sales services for orders made on the Site:</p>
          <ul>
            <li>Adjustment of frame fitting</li>
            <li>Re-checking of your vision</li>
            <li>Replacement of nose pads, screws and spare parts (subject to availability)</li>
          </ul>
          <p>
            These after-sales services are complimentary to you. Please visit any OWNDAYS store in
            Singapore if you require the above after-sales services after receiving your order.
          </p>
        </div>

        <div>
          <h3>General Use of the Site</h3>
          <p>
            The products and services available on the Site, including discount coupons, gift
            vouchers and product warranty services, are strictly for personal use only. You may not
            sell or resell any of the products and services that you purchase or receive from us. If
            any registered member does not comply with or violates these Terms and Conditions, we
            reserve the right to cancel the membership account and the orders without prior notice.
          </p>
          <p>You agree not to:</p>
          <ul>
            <li>Use the Site and our products and services for illegal and unethical purposes</li>
            <li>
              Use or upload software or materials that may contain viruses and/or harmful components
              which may corrupt the Site's data or interfere with the operation of another user's
              mobile or computer device
            </li>
            <li>Attempt to gain unauthorised access to or disrupt computer systems or network</li>
            <li>Impersonate other users or use the membership account belonging to other users</li>
          </ul>
        </div>

        <div>
          <h3>Feedback and Suggestions</h3>
          <p>
            At OWNDAYS, we are constantly reviewing and improving ourselves in order to deliver the
            best customer experience. We welcome your feedback and suggestions on how we can improve
            the Site and our products and services. Please email us at{' '}
            <a href="mailto:ecsg@owndays.com">ecsg@owndays.com</a> and we will respond to you within
            3 to 5 working days.
          </p>
          <p>
            For assistance with your order, please provide us the Order Number and state any concern
            or complaint clearly and as accurately as possible. The Order Number can be found in the
            order confirmation email or under the Order History section when you log in to your
            membership account from the Site.
          </p>
          <p>
            In some circumstances, your emails may not reach us and vice versa. If you do not
            receive any response from us after 5 working days, please contact us via our Customer
            Service Hotline at +65 6222 0588 (available between 10am to 7pm daily).
          </p>
        </div>

        <div>
          <h3>Amendments of Terms and Conditions</h3>
          <p>
            We reserve the right to amend these Terms and Conditions at any time without prior
            notice. All amendments to these Terms and Conditions will be reflected on the Site and
            are effective immediately. Your continued use of the Site will be deemed to constitute
            your acceptance to the amended Terms and Conditions.
          </p>
        </div>
      </div>
    </div>
  );
};
