export const NAVS_CONTACT_US = [
  {
    id: 'contact_products',
    link: 'section_products',
    src: '/contact_products.svg',
    name: 'About Our Products \nand Services',
  },
  {
    id: 'contact_recruit',
    link: 'section_recruit',
    src: '/contact_recruit.svg',
    name: 'Recruitment',
  },
  {
    id: 'contact_pr',
    link: 'section_pr',
    src: '/contact_pr.svg',
    name: 'Media Enquiries',
  },
];

export const ABOUT_LIST = [
  {
    link: '/sg/en/faq',
    name: 'Warranty & FAQs',
  },
  {
    link: 'https://v2.zopim.com/widget/livechat.html?api_calls=%5B%5D&hostname=www.owndays.com&key=2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS&lang=en',
    name: 'Contact us via online chat',
  },
  {
    link: 'https://api.whatsapp.com/send/?phone=6598214268&text&app_absent=0',
    name: 'WhatsApp',
  },
  {
    link: 'mailto:inquirysg@owndays.com',
    name: 'Contact us via email',
  },
];

export const RECRUIT_LIST = [
  {
    title: 'Contact Person',
    desc: 'Jane Lim',
  },
  {
    title: 'Address',
    desc: '21 Merchant Road, #07-01, Singapore 058267',
  },
  {
    title: 'TEL',
    desc: '6737-0980',
  },
  {
    title: 'MAIL',
    desc: 'hr.sin@owndays.co.jp',
  },
];

export const QR_LIST = [
  {
    title: 'Contact Person',
    desc: 'Rain Wong',
  },
  {
    title: 'Address',
    desc: '21 Merchant Road, #07-01, Singapore 058267',
  },
  {
    title: 'TEL',
    desc: '6737-0980',
  },
];
