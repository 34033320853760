import React from 'react';
import { LENS_DETAILS } from '../../../../../own-days/CommonComponents/Services/constants/lens';
import './LensDetails.scss';

const LensDetails = () => {
  return (
    <ul className="services__lens-details">
      {LENS_DETAILS.map(lens => (
        <li key={lens.id} className="services__lens-details-item" id={lens.id}>
          <div className="services__lens-details-header">
            <img className="services__lens-details-header-icon" src={lens.imgPath} />
            <div className="services__lens-details-header-details">
              <i className={lens.tag.toLowerCase()}>{lens.tag}</i>
              <div>
                <h3>
                  <span dangerouslySetInnerHTML={{ __html: lens.type }} />
                  {lens.typeAdditional && <small>{lens.typeAdditional}</small>}
                </h3>
                <p dangerouslySetInnerHTML={{ __html: lens.price }} />
              </div>
            </div>
          </div>
          <div className="services__lens-details-body">
            <h4 dangerouslySetInnerHTML={{ __html: lens.description }} />
            <p dangerouslySetInnerHTML={{ __html: lens.details }} />
            {lens.features && (
              <table className="services__lens-details-body-features">
                <tbody>
                  <tr>
                    <th>Features</th>
                    <td>
                      <ul>
                        {lens.features.map((feature, index) => (
                          <li key={index}>{feature}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Refractive Index</th>
                    <td>{lens.refractiveIndex}</td>
                  </tr>
                  {lens.recommendedFor && (
                    <tr>
                      <th>Recommended For</th>
                      <td>{lens.recommendedFor}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {lens.examples && (
              <div
                dangerouslySetInnerHTML={{ __html: lens.examples }}
                className="services__lens-details-body-examples"
              />
            )}
            {lens.lineup && (
              <ul className="services__lens-details-body-lineup">
                {lens.lineup.map(item => (
                  <li key={item.id} id={item.id}>
                    <h4>
                      <strong>
                        {item.type}
                        {item.typeAdditional && <small>{item.typeAdditional}</small>}
                      </strong>
                      <span>{item.price}</span>
                    </h4>
                    {item.details && <div dangerouslySetInnerHTML={{ __html: item.details }} />}
                    {item.features && (
                      <table className="services__lens-details-body-features">
                        <tbody>
                          <tr>
                            <th>Features</th>
                            <td>
                              <ul>
                                {item.features.map((feature, index) => (
                                  <li key={index}>{feature}</li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <th>Refractive Index</th>
                            <td dangerouslySetInnerHTML={{ __html: item.refractiveIndex }} />
                          </tr>
                          {item.recommendedFor && (
                            <tr>
                              <th>Recommended For</th>
                              <td>{item.recommendedFor}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                    {item.examples && (
                      <div
                        dangerouslySetInnerHTML={{ __html: item.examples }}
                        className="services__lens-details-body-examples"
                      />
                    )}
                  </li>
                ))}
              </ul>
            )}
            {lens.notes && (
              <div
                dangerouslySetInnerHTML={{ __html: lens.notes }}
                className="services__lens-details-body-notes"
              />
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default LensDetails;
