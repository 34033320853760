import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';
import ExternalResources from '../CommonComponents/ExternalResources/ExternalResources';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="og:title" content="Celebrating 500 stores worldwide | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="A commemorative collection to mark the 500-store milestone, featuring frames in lightweight resin material that makes them a great comfort to wear. These made-in-Japan frames come in the oversized rectangle style and the classic Boston style and are available at an unprecedented price so customers can enjoy quality eyewear at a fraction of the usual retail price. This is our way of saying Thank You to all our customers for making this 500-store milestone a dream come true." />
<meta name="keywords" content="Celebrating 500 stores worldwide,Commemorative frame,glasses,spectacles,optical shop,online store,eyeglasses,OWNDAYS,frame,sunglasses,pc lenses" />
<meta name="og:description" content="A commemorative collection to mark the 500-store milestone, featuring frames in lightweight resin material that makes them a great comfort to wear. These made-in-Japan frames come in the oversized rectangle style and the classic Boston style and are available at an unprecedented price so customers can enjoy quality eyewear at a fraction of the usual retail price. This is our way of saying Thank You to all our customers for making this 500-store milestone a dream come true." />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/500stores">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/500stores/ogp_1200x630.jpg?1685535514">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    
    
    <link rel="preload" href="/fonts/Avenir/Avenir-Black.woff2" as="font" type="font/woff2" crossorigin>
<link rel="preload" href="/fonts/Avenir/Avenir-LightOblique.woff2" as="font" type="font/woff2" crossorigin>
<link href="https://www.owndays.com/web/css/specials-500stores.css?v=1698406705" rel="stylesheet">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css" />
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    
    
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">Celebrating 500 stores worldwide | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>
 


<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            Celebrating 500 stores worldwide                    </li>
        
            </ul>
</div>


<section class="l-500stores">
    <section class="main">
        <div class="main__top">
            <img class="main__img" src="https://www.owndays.com/images/specials/products/500stores/main-500stores.svg" alt="Celebrating 500 stores worldwide">
            <a class="main__scroll" href="#link">Scroll</a>
        </div>
        <div class="main__des">
            <h1>We made it to <br class="u-sp-only"><strong>500</strong> stores globally!</h1>
            <p>With overwhelming support from our customers, <br>OWNDAYS made it to 500 stores globally!</p>
            <p>Moving forward,<br class="u-sp-only"> we will continue to bring quality eyewear<br> to as many people as possible.</p>
        </div>
    </section>
    <section class="link" id="link">
        <div class="l-500stores__container">
            <ul class="link__list">
                <li>
                    <a href="#anniversary">
                        <img src="https://www.owndays.com/images/specials/products/500stores/sg/icon-anniversary-gold.svg" alt="Celebrating 500 stores worldwide">
                        <span>Commemorative<br>frames</span>
                    </a>
                </li>
                <li>
                    <a href="#store">
                        <img src="https://www.owndays.com/images/specials/products/500stores/icon-store-gold.svg" alt="OWNDAYS stores">
                        <span>OWNDAYS stores</span>
                    </a>
                </li>
                <li>
                    <a href="#service">
                        <img src="https://www.owndays.com/images/specials/products/500stores/icon-service-gold.svg" alt="OWNDAYS services">
                        <span>OWNDAYS services</span>
                    </a>
                </li>
                <li>
                    <a href="#csr">
                        <img src="https://www.owndays.com/images/specials/products/500stores/icon-csr-gold.svg" alt="CSR activities">
                        <span>CSR activities</span>
                    </a>
                </li>
                            </ul>
        </div>
    </section>
    <section class="anniversary l-500stores__bg-text l-500stores__bg-text--reverse" data-title="MEMENTO" id="anniversary">
        <div class="l-500stores__container">
            <div class="anniversary__top">
                <picture class="anniversary__top-img">
                    <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1_02.webp" type="image/webp">
                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1_02.jpg" alt="OWSP2002L-3S C1">
                </picture>
                <div class="anniversary__top-des">
                    <h2>OWNDAYS commemorative collection celebrating<br>
                        <picture>
                            <source data-srcset="https://www.owndays.com/images/specials/products/500stores/500.webp" type="image/webp">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/500.jpg" alt="500">
                        </picture>
                        <span>stores worldwide</span>
                    </h2>
                    <p>A commemorative collection to mark the 500-store milestone, featuring frames in lightweight resin material that makes them a great comfort to wear. These made-in-Japan frames come in the oversized rectangle style and the classic Boston style and are available at an unprecedented price so customers can enjoy quality eyewear at a fraction of the usual retail price. This is our way of saying Thank You to all our customers for making this 500-store milestone a dream come true.</p>
                </div>
            </div>
            <div class="anniversary__made-in-jp">
                <h3>Balancing exemplary, “<span class="gold">Made-in-Japan</span>” quality at a valuable price point <br class="u-pc-only">of S$55 to attain excellent price-performance ratio.</h3>
                <ul>
                    <li>
                        <picture>
                            <source data-srcset="https://www.owndays.com/images/specials/products/500stores/light-and-flexible.webp" type="image/webp">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/light-and-flexible.jpg" alt="Using lightweight and flexible resin material">
                        </picture>
                        <p>Using lightweight and flexible<br>resin material</p>
                    </li>
                    <li>
                        <picture>
                            <source data-srcset="https://www.owndays.com/images/specials/products/500stores/made-in-a-domestic-factory.webp" type="image/webp">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/made-in-a-domestic-factory.jpg" alt="Designed and made in Japan">
                        </picture>
                        <p>Designed and<br>made in Japan</p>
                    </li>
                    <li>
                        <picture>
                            <source data-srcset="https://www.owndays.com/images/specials/products/500stores/affordable-price.webp" type="image/webp">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/affordable-price.jpg" alt="Available at an affordable price point">
                        </picture>
                        <p>Available at an<br>affordable price point</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="anniversary__special">
            <div class="l-500stores__container">
                <h2>
                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/sg/icon-anniversary-white.svg" alt="500 Anniversary">
                    SPECIAL PRICE <small>High quality <span class="gold">made-in-Japan</span> frames at an affordable price point</small>                </h2>
                <h3><small>2 types / 8 variations in total</small><strong>S$55</strong> each</h3>
                <div class="anniversary__special-products-container">
                    
                    <div class="anniversary__special-products">
                        <h5 class="anniversary__special-products-shape">RECTANGLE</h5>
                        <ul class="anniversary__special-products-list anniversary__special-products-list-gp-1">
                            
                            <li>
                                <div class="swiper swiper__products">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_01.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_01.jpg" alt="OWSP2001L-3S-C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_02.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_02.jpg" alt="OWSP2001L-3S-C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_03.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_03.jpg" alt="OWSP2001L-3S-C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_04.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_04.jpg" alt="OWSP2001L-3S-C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_05.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/OWSP2001L-3S-C1_05.jpg" alt="OWSP2001L-3S-C1">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="swiper-pagination"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--next"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--prev"></div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="swiper swiper__products">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_01.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_01.jpg" alt="OWSP2001L-3S-C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_02.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_02.jpg" alt="OWSP2001L-3S-C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_03.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_03.jpg" alt="OWSP2001L-3S-C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_04.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_04.jpg" alt="OWSP2001L-3S-C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_05.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C2/OWSP2001L-3S-C2_05.jpg" alt="OWSP2001L-3S-C2">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="swiper-pagination"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--next"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--prev"></div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="swiper swiper__products">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_01.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_01.jpg" alt="OWSP2001L-3S-C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_02.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_02.jpg" alt="OWSP2001L-3S-C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_03.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_03.jpg" alt="OWSP2001L-3S-C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_04.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_04.jpg" alt="OWSP2001L-3S-C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_05.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C3/OWSP2001L-3S-C3_05.jpg" alt="OWSP2001L-3S-C3">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="swiper-pagination"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--next"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--prev"></div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="swiper swiper__products">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_01.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_01.jpg" alt="OWSP2001L-3S-C4">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_02.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_02.jpg" alt="OWSP2001L-3S-C4">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_03.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_03.jpg" alt="OWSP2001L-3S-C4">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_04.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_04.jpg" alt="OWSP2001L-3S-C4">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_05.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C4/OWSP2001L-3S-C4_05.jpg" alt="OWSP2001L-3S-C4">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="swiper-pagination"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--next"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--prev"></div>
                                </div>
                            </li>
                        </ul>
                        <div class="anniversary__special-products-bottom">
                            <div>
                                <h6 class="anniversary__special-products-code">OWSP2001L-3S</h6>
                                <ul class="anniversary__special-products-color" uk-switcher="connect: .anniversary__special-products-list-gp-1; swiping: false">
                                    <li>
                                        <a href="#">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/color.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2001L-3S-C1/color.jpg" alt="OWSP2001L-3S-C1">
                                            </picture>
                                        </a>
                                    </li>
                                    <li><a href="#" style="--cl:#f1ddc4"></a></li>
                                    <li><a href="#" style="--cl:#F7F5F4"></a></li>
                                    <li><a href="#" style="--cl:#c7cad9"></a></li>
                                </ul>
                            </div>
                            <a href="/sg/en/products/OWSP2001L-3S" class="l-500stores__btn l-500stores__btn--gold l-500stores__btn--pc-left">More details</a>
                        </div>
                    </div>
                    
                    <div class="anniversary__special-products">
                        <h5 class="anniversary__special-products-shape">BOSTON</h5>
                        <ul class="anniversary__special-products-list anniversary__special-products-list-gp-2">
                            
                            <li>
                                <div class="swiper swiper__products">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_01.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_01.jpg" alt="OWSP2002L-3S-C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_02.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_02.jpg" alt="OWSP2002L-3S-C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_03.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_03.jpg" alt="OWSP2002L-3S-C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_04.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_04.jpg" alt="OWSP2002L-3S-C1">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_05.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C1/OWSP2002L-3S-C1_05.jpg" alt="OWSP2002L-3S-C1">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="swiper-pagination"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--next"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--prev"></div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="swiper swiper__products">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_01.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_01.jpg" alt="OWSP2002L-3S-C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_02.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_02.jpg" alt="OWSP2002L-3S-C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_03.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_03.jpg" alt="OWSP2002L-3S-C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_04.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_04.jpg" alt="OWSP2002L-3S-C2">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_05.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/OWSP2002L-3S-C2_05.jpg" alt="OWSP2002L-3S-C2">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="swiper-pagination"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--next"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--prev"></div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="swiper swiper__products">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_01.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_01.jpg" alt="OWSP2002L-3S-C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_02.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_02.jpg" alt="OWSP2002L-3S-C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_03.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_03.jpg" alt="OWSP2002L-3S-C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_04.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_04.jpg" alt="OWSP2002L-3S-C3">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_05.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C3/OWSP2002L-3S-C3_05.jpg" alt="OWSP2002L-3S-C3">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="swiper-pagination"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--next"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--prev"></div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="swiper swiper__products">
                                    <ul class="swiper-wrapper">
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_01.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_01.jpg" alt="OWSP2002L-3S-C4">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_02.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_02.jpg" alt="OWSP2002L-3S-C4">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_03.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_03.jpg" alt="OWSP2002L-3S-C4">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_04.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_04.jpg" alt="OWSP2002L-3S-C4">
                                            </picture>
                                        </li>
                                        <li class="swiper-slide">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_05.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C4/OWSP2002L-3S-C4_05.jpg" alt="OWSP2002L-3S-C4">
                                            </picture>
                                        </li>
                                    </ul>
                                    <div class="swiper-pagination"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--next"></div>
                                    <div class="anniversary__special-products-button anniversary__special-products-button--prev"></div>
                                </div>
                            </li>
                        </ul>
                        <div class="anniversary__special-products-bottom">
                            <div>
                                <h6 class="anniversary__special-products-code">OWSP2002L-3S</h6>
                                <ul class="anniversary__special-products-color" uk-switcher="connect: .anniversary__special-products-list-gp-2; swiping: false">
                                    <li><a href="#" style="--cl:#000"></a></li>
                                    <li>
                                        <a href="#">
                                            <picture>
                                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/color.webp" type="image/webp">
                                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/products/OWSP2002L-3S-C2/color.jpg" alt="OWSP2001L-3S-C1">
                                            </picture>
                                        </a>
                                    </li>
                                    <li><a href="#" style="--cl:#ecd5c0"></a></li>
                                    <li><a href="#" style="--cl:#fee4e4"></a></li>
                                </ul>
                            </div>
                            <a href="/sg/en/products/OWSP2002L-3S" class="l-500stores__btn l-500stores__btn--gold l-500stores__btn--pc-left">More details</a>
                        </div>
                    </div>
                </div>
                            </div>
        </div>
    </section>
        <section class="store" id="store">
        <div class="l-500stores__container">
            <div class="store__top l-500stores__bg-text" data-title="STORES">
                <h2 class="l-500stores__heading l-500stores__heading--pc-left">OWNDAYS STORES</h2>
                <p>With over 1,500 frame designs available as well as one of the most comprehensive after-sales services in the optical industry, you will be able to enjoy your OWNDAYS spectacles for a long time to come. This includes worldwide warranty on lens visual performance and frame quality.</p>
                <picture class="store__top-img">
                    <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-top.webp" type="image/webp">
                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-top.jpg" alt="OWNDAYS STORES">
                </picture>
                <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-500stores__btn l-500stores__btn--black l-500stores__btn--pc-left">Search For Nearby Stores</a>
            </div>
        </div>
        <div class="store__content">
            <div class="l-500stores__container">
                <div class="store__map">
                    <div class="store__map-des">
                        <h3 class="l-500stores__heading">Retail network in <strong>13 regions</strong></h3>
                        <p>We have been earnestly growing our retail network beyond Japan. As at April 2023, OWNDAYS stores can be found in 13 regions.</p>
                    </div>
                    <figure>
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-map.svg" alt="Retail network in 13 regions">
                    </figure>
                </div>
            </div>
            <div class="store__photos">
                <div class="l-500stores__container">
                    <h3 class="l-500stores__heading"><strong>500 stores</strong> worldwide</h3>
                    <p>As at April 2023, there are 533 OWNDAYS stores globally.<br class="u-pc-only"> Moving forward, we will continue our growth journey in order to bring quality eyewear to more people around the world.</p>
                </div>
                <div class="swiper swiper__store">
                    <ul class="swiper-wrapper">
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-photo-01.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-photo-01.jpg" alt="Shop image①">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-photo-02.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-photo-02.jpg" alt="Shop image②">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-photo-03.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-photo-03.jpg" alt="Shop image③">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-photo-04.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-photo-04.jpg" alt="Shop image④">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-photo-05.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-photo-05.jpg" alt="Shop image⑤">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-photo-06.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-photo-06.jpg" alt="Shop image⑥">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-photo-07.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-photo-07.jpg" alt="Shop image⑦">
                            </picture>
                        </li>
                        <li class="swiper-slide">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/store-photo-08.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/store-photo-08.jpg" alt="Shop image⑧">
                            </picture>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="history">
        <div class="l-500stores__container">
            <h2 class="l-500stores__heading"><span class="font-avenir">HISTORY</span><small>Our growth story</small></h2>
            <div class="history__content">
                <dl class="history__timeline">
                    <dt class="history__timeline-year">2001<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 10; --bar-mw: 30px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-flags">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/jp.webp" alt="Japan">
                            </span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2006<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 50; --bar-mw: 79px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">50<small>stores</small></span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2009<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 70; --bar-mw: 130px;"></div>
                    </dd>

                    <dt class="history__timeline-year">2011<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 100; --bar-mw: 243px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">100<small>stores</small></span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2013<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 110; --bar-mw: 268px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">110<small>stores</small></span>
                            <span class="history__timeline-flags">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/sg.webp" alt="Singapore">
                            </span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2014<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 117; --bar-mw: 283px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">117<small>stores</small></span>
                            <span class="history__timeline-flags">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/tw.webp" alt="Taiwan">
                            </span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2015<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 137; --bar-mw: 340px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">137<small>stores</small></span>
                            <span class="history__timeline-flags">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/th.webp" alt="Thailand">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/kh.webp" alt="Cambodia">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/ph.webp" alt="Philippines">
                            </span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2016<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 181; --bar-mw: 380px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">181<small>stores</small></span>
                            <span class="history__timeline-flags">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/my.webp" alt="Malaysia">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/au.webp" alt="Australia">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/vn.webp" alt="Vietnam">
                            </span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2018<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 266; --bar-mw: 455.48px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">266<small>stores</small></span>
                            <span class="history__timeline-flags">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/id.webp" alt="Indonesia">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/hk.webp" alt="Hong Kong">
                            </span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2019<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 335; --bar-mw: 546.38px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">335<small>stores</small></span>
                            <span class="history__timeline-flags">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/in.webp" alt="India">
                            </span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2021<small></small></dt>
                    <dd class="history__timeline-details">
                        <div class="history__timeline-bar" style="--bar-w: 430; --bar-mw: 721.11px;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text">430<small>stores</small></span>
                            <span class="history__timeline-flags">
                                <img class="lazyload" data-src="https://www.owndays.com/images/flags/ae.webp" alt="United Arab Emirates">
                            </span>
                        </div>
                    </dd>

                    <dt class="history__timeline-year">2023<small></small></dt>
                    <dd class="history__timeline-details history__timeline-details--latest">
                        <div class="history__timeline-bar history__timeline-bar--gold" style="--bar-w: 500; --bar-mw: 100%;"></div>
                        <div class="history__timeline-info">
                            <span class="history__timeline-text history__timeline-text--gold">500<small>stores</small></span>
                        </div>
                    </dd>
                </dl>
                <div class="history__sale-value">
                    <p class="history__sale-value-heading">
                        <span class="history__sale-value-heading-title">Surpassing</span>
                        <span class="history__sale-value-heading-value">
                            <strong class="number">2.5</strong>
                            <small class="text text--1">million <br class="u-pc-only">pairs</small>
                            <small class="text text--2">sold annually</small>
                        </span>
                    </p>
                    <p class="history__sale-value-note">*As at May 2023</p>
                </div>
            </div>
        </div>
    </section>
    <section class="service" id="service">
        <div class="l-500stores__container">
            <h2 class="l-500stores__heading"><span class="font-avenir">SERVICES</span><small>OWNDAYS SERVICES</small></h2>
            <p class="service__text">We offer an extensive warranty coverage so you will always enjoy complete peace of mind when using your OWNDAYS spectacles.</p>
            <ul class="service__list">
                <li class="service__item">
                    <div class="service__card">
                        <img class="service__card-icon lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/icon-tag.svg" alt="Simple price">
                        <p class="service__card-title">Simple price</p>
                        <a class="service__card-link" href="/sg/en/services">view more</a>
                    </div>
                </li>
                <li class="service__item">
                    <div class="service__card">
                        <img class="service__card-icon lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/icon-lens.svg" alt="Standard high index aspheric lenses included">
                        <p class="service__card-title">Standard high index aspheric lenses included</p>
                        <a class="service__card-link" href="/sg/en/services/lens">view more</a>
                    </div>
                </li>
                <li class="service__item">
                    <div class="service__card">
                        <img class="service__card-icon lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/icon-clock.svg" alt="Same day collection">
                        <p class="service__card-title">Same day collection</p>
                    </div>
                </li>
                <li class="service__item">
                    <div class="service__card">
                        <img class="service__card-icon lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/icon-change-glasses.svg" alt="Lens replacement">
                        <p class="service__card-title">Lens replacement</p>
                        <a class="service__card-link" href="/sg/en/services/lens-replacement">view more</a>
                    </div>
                </li>
                <li class="service__item">
                    <div class="service__card">
                        <img class="service__card-icon lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/icon-glasses.svg" alt="Frame">
                        <p class="service__card-title">Frame</p>
                        <a class="service__card-link" href="/sg/en/products?productTypes[]=1">view more</a>
                    </div>
                </li>
                <li class="service__item">
                    <div class="service__card">
                        <img class="service__card-icon lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/icon-heart-hand.svg" alt="OWNDAYS Customer Guarantees">
                        <p class="service__card-title">OWNDAYS Customer Guarantees</p>
                        <a class="service__card-link" href="/sg/en/services/warranty">view more</a>
                    </div>
                </li>
                                <li class="service__item">
                    <div class="service__card">
                        <img class="service__card-icon lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/icon-users.svg" alt="Staff">
                        <p class="service__card-title">Staff</p>
                        <a class="service__card-link" href="/sg/en/services/staff">view more</a>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <section class="csr" id="csr">
        <div class="l-500stores__container">
            <h2 class="l-500stores__heading"><span class="font-avenir">EYE CAMP</span><small>Aligned with our CSR activities</small></h2>
            <p class="csr__text">Proceeds from this commemorative collection will be used to fund the OWNDAYS Eye Camp—our key CSR initiative <br class="u-pc-only">in which we give out prescription glasses to the less-privileged people in developing nations and those affected by natural disasters.</p>
            <ul class="csr__list">
                <li class="csr__item">
                    <div class="csr__card">
                        <figure class="csr__card-img">
                            <picture>
                                <source data-srcset="https://www.owndays.com/images/specials/products/500stores/EYE_CAMP.webp" type="webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/500stores/EYE_CAMP.jpg" alt="EYE CAMP">
                            </picture>
                            <figcaption class="eye-camp">EYE CAMP</figcaption>
                        </figure>
                        <div class="csr__card-body">
                            <p class="csr__card-text"></p>
                            <a href="/sg/en/company/eyecamp#eye-camp" class="l-500stores__btn l-500stores__btn--black">Learn more about the initiative</a>
                        </div>
                    </div>
                </li>
                            </ul>
        </div>
    </section>
        <section class="bottom">
        <div class="l-500stores__container">
            <img class="bottom__logo lazyload" data-src="https://www.owndays.com/images/common/logo.svg" alt="OWNDAYS">
            <h2 class="bottom__title">OWNDAYS: Beyond the way of seeing</h2>
            <p class="bottom__text">“We hope our spectacles can help you look beyond what that lies ahead”<br><br>Beyond simply providing eyewear and the ability to see with crystal-clear vision,<br> we strive to enrich the lives of our customers by always remaining accessible,<br> constantly innovating and bringing new and improved products and services from Japan to the world.</p>
        </div>
    </section>
</section>
<section class="modal-500stores">
    <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalquestion">
    Open Modal
    </button> -->
    <!-- The Modal -->
    <div class="modal fade" id="modalquestion" data-modal="fixed" tabindex="-1" role="dialog" aria-labelledby="modalqLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <div class="modal-title">
                        <img src="https://www.owndays.com/images/specials/products/500stores/logo-500.svg" alt="OWNDAYS 500 STORES - THANK YOU">
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img src="https://www.owndays.com/images/specials/products/500stores/icon-close.svg" alt="">
                    </button>
                </div>

                <!-- Modal Body -->
                <div class="modal-body">
                    <form>
                        <div class="detail-modal">
                            <div class="list-q-section">
                                <div class="section-list" id="q_03_4">
                                    <div class="q-section">
                                        <p>[QUESTION 03]</p>
                                    </div>
                                    <div class="title-q-section">
                                        <h3>こんな機能があったら嬉しい</h3>
                                    </div>
                                    <ul class="ul-list-q-section">
                                        <li>
                                            <a>
                                                <div class="box-list">
                                                    <div class="img-box-list u-pc-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-black.svg" class="list-show" alt="">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-active.svg" class="list-hidden" alt="">
                                                    </div>
                                                    <div class="img-box-list u-sp-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-sp.svg" alt="">
                                                    </div>
                                                    <div class="txt-box-list">
                                                        <p class="f-16">水面やビルの<br>ギラつきを抑え、<br>目の負担を軽減</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <!-- <input type="radio" name="list-q-section03_04" value="1" hidden> -->
                                        </li>
                                        <li>
                                            <a>
                                                <div class="box-list">
                                                    <div class="img-box-list u-pc-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-black.svg" class="list-show" alt="">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-active.svg" class="list-hidden" alt="">
                                                    </div>
                                                    <div class="img-box-list u-sp-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-sp.svg" alt="">
                                                    </div>
                                                    <div class="txt-box-list">
                                                        <p class="f-16">移動中に<br>メガネとサングラスの<br>掛け替えが不要</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <!-- <input type="radio" name="list-q-section03_04" value="2" hidden> -->
                                        </li>
                                    </ul>
                                    <div class="btn-q-section">
                                        <a href="javascript:voide(0)" class="l-500stores__btn l-500stores__btn--black l-500stores__btn--arrow-left" data-back="">BACK</a>
                                    </div>
                                    <div class="page-number">
                                        <p>03</p><img src="https://www.owndays.com/images/specials/products/500stores/line-num-page.svg" style="width: 23px;" alt="">
                                        <p>03</p>
                                    </div>
                                </div>
                                <div class="section-list" id="q_03_3">
                                    <div class="q-section">
                                        <p>[QUESTION 03]</p>
                                    </div>
                                    <div class="title-q-section">
                                        <h3>こういう印象になりたい</h3>
                                    </div>
                                    <ul class="ul-list-q-section">
                                        <li>
                                            <a>
                                                <div class="box-list">
                                                    <div class="img-box-list u-pc-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-black.svg" class="list-show" alt="">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-active.svg" class="list-hidden" alt="">
                                                    </div>
                                                    <div class="img-box-list u-sp-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-sp.svg" alt="">
                                                    </div>
                                                    <div class="txt-box-list">
                                                        <p class="f-16">柔らかく、<br>優しげ</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <!-- <input type="radio" name="list-q-section03_03" value="1" hidden> -->
                                        </li>
                                        <li>
                                            <a>
                                                <div class="box-list">
                                                    <div class="img-box-list u-pc-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-black.svg" class="list-show" alt="">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-active.svg" class="list-hidden" alt="">
                                                    </div>
                                                    <div class="img-box-list u-sp-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-sp.svg" alt="">
                                                    </div>
                                                    <div class="txt-box-list">
                                                        <p class="f-16">クールに<br>キリッと</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <!-- <input type="radio" name="list-q-section03_03" value="2" hidden> -->
                                        </li>
                                    </ul>
                                    <div class="btn-q-section">
                                        <a href="javascript:voide(0)" class="l-500stores__btn l-500stores__btn--black l-500stores__btn--arrow-left" data-back="">BACK</a>
                                    </div>
                                    <div class="page-number">
                                        <p>03</p><img src="https://www.owndays.com/images/specials/products/500stores/line-num-page.svg" style="width: 23px;" alt="">
                                        <p>03</p>
                                    </div>
                                </div>
                                <div class="section-list" id="q_03_2">
                                    <div class="q-section">
                                        <p>[QUESTION 03]</p>
                                    </div>
                                    <div class="title-q-section">
                                        <h3>こだわるなら</h3>
                                    </div>
                                    <ul class="ul-list-q-section-click ul-list-q-section">
                                        <li>
                                            <a>
                                                <div class="box-list">
                                                    <div class="img-box-list u-pc-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-black.svg" class="list-show" alt="">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-active.svg" class="list-hidden" alt="">
                                                    </div>
                                                    <div class="img-box-list u-sp-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-sp.svg" alt="">
                                                    </div>
                                                    <div class="txt-box-list">
                                                        <p class="f-16">より自然な<br>見え方</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <!-- <input type="radio" name="list-q-section03_02" value="1" hidden> -->
                                        </li>
                                        <li>
                                            <div class="box-list">
                                                <div class="img-box-list u-pc-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-black.svg" class="list-show" alt="">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-active.svg" class="list-hidden" alt="">
                                                </div>
                                                <div class="img-box-list u-sp-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-sp.svg" alt="">
                                                </div>
                                                <div class="txt-box-list">
                                                    <p class="f-16">色付きレンズに<br>挑戦したい</p>
                                                </div>
                                            </div>
                                            <input type="radio" data-number="2" name="list-q-section03_02" value="3" hidden>
                                        </li>
                                    </ul>
                                    <div class="btn-q-section">
                                        <a href="javascript:voide(0)" class="l-500stores__btn l-500stores__btn--black l-500stores__btn--arrow-left" data-back="">BACK</a>
                                    </div>
                                    <div class="page-number">
                                        <p>03</p><img src="https://www.owndays.com/images/specials/products/500stores/line-num-page.svg" style="width: 23px;" alt="">
                                        <p>03</p>
                                    </div>
                                </div>
                                <div class="section-list" id="q_03_1">
                                    <div class="q-section">
                                        <p>[QUESTION 03]</p>
                                    </div>
                                    <div class="title-q-section">
                                        <h3>着用シーンはズバリ</h3>
                                    </div>
                                    <ul class="ul-list-q-section">
                                        <li>
                                            <a>
                                                <div class="box-list">
                                                    <div class="img-box-list u-pc-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-black.svg" class="list-show" alt="">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-active.svg" class="list-hidden" alt="">
                                                    </div>
                                                    <div class="img-box-list u-sp-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/a-list-sp.svg" alt="">
                                                    </div>
                                                    <div class="txt-box-list">
                                                        <p class="f-16">室内がメイン</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <!-- <input type="radio" name="list-q-section02" value="1" hidden> -->
                                        </li>
                                        <li>
                                            <a>
                                                <div class="box-list">
                                                    <div class="img-box-list u-pc-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-black.svg" class="list-show" alt="">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-active.svg" class="list-hidden" alt="">
                                                    </div>
                                                    <div class="img-box-list u-sp-only">
                                                        <img src="https://www.owndays.com/images/specials/products/500stores/b-list-sp.svg" alt="">
                                                    </div>
                                                    <div class="txt-box-list">
                                                        <p class="f-16">屋外がメイン</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <!-- <input type="radio" name="list-q-section02" value="2" hidden> -->
                                        </li>
                                    </ul>
                                    <div class="btn-q-section">
                                        <a href="javascript:voide(0)" class="l-500stores__btn l-500stores__btn--black l-500stores__btn--arrow-left" data-back="">BACK</a>
                                    </div>
                                    <div class="page-number">
                                        <p>03</p><img src="https://www.owndays.com/images/specials/products/500stores/line-num-page.svg" style="width: 23px;" alt="">
                                        <p>03</p>
                                    </div>
                                </div>
                                <div class="section-list" id="q_02_2">
                                    <div class="q-section">
                                        <p>[QUESTION 02]</p>
                                    </div>
                                    <div class="title-q-section">
                                        <h3>メガネに求めるのは</h3>
                                    </div>
                                    <ul class="ul-list-q-section-click">
                                        <li>
                                            <div class="box-list">
                                                <div class="img-box-list u-pc-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-black.svg" class="list-show" alt="">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-active.svg" class="list-hidden" alt="">
                                                </div>
                                                <div class="img-box-list u-sp-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-sp.svg" alt="">
                                                </div>
                                                <div class="txt-box-list">
                                                    <p class="f-16">ファッション性</p>
                                                </div>
                                            </div>
                                            <input type="radio" data-number="2" name="list-q-section02_2" value="3" hidden>
                                        </li>
                                        <li>
                                            <div class="box-list">
                                                <div class="img-box-list u-pc-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-black.svg" class="list-show" alt="">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-active.svg" class="list-hidden" alt="">
                                                </div>
                                                <div class="img-box-list u-sp-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-sp.svg" alt="">
                                                </div>
                                                <div class="txt-box-list">
                                                    <p class="f-16">機能性</p>
                                                </div>
                                            </div>
                                            <input type="radio" data-number="2" name="list-q-section02_2" value="4" hidden>
                                        </li>
                                    </ul>
                                    <div class="btn-q-section">
                                        <a href="javascript:voide(0)" class="l-500stores__btn l-500stores__btn--black l-500stores__btn--arrow-left" data-back="">BACK</a>
                                    </div>
                                    <div class="page-number">
                                        <p>02</p><img src="https://www.owndays.com/images/specials/products/500stores/line-num-page.svg" style="width: 23px;" alt="">
                                        <p>03</p>
                                    </div>
                                </div>
                                <div class="section-list" id="q_02_1">
                                    <div class="q-section">
                                        <p>[QUESTION 02]</p>
                                    </div>
                                    <div class="title-q-section">
                                        <h3>スマホ、PCを</h3>
                                    </div>
                                    <ul class="ul-list-q-section-click">
                                        <li>
                                            <div class="box-list">
                                                <div class="img-box-list u-pc-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-black.svg" class="list-show" alt="">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-active.svg" class="list-hidden" alt="">
                                                </div>
                                                <div class="img-box-list u-sp-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-sp.svg" alt="">
                                                </div>
                                                <div class="txt-box-list">
                                                    <p class="f-16">よく見る</p>
                                                </div>
                                            </div>
                                            <input type="radio" name="list-q-section02" data-number="2" value="1" hidden>
                                        </li>
                                        <li>
                                            <div class="box-list">
                                                <div class="img-box-list u-pc-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-black.svg" class="list-show" alt="">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-active.svg" class="list-hidden" alt="">
                                                </div>
                                                <div class="img-box-list u-sp-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-sp.svg" alt="">
                                                </div>
                                                <div class="txt-box-list">
                                                    <p class="f-16">あまり見ない</p>
                                                </div>
                                            </div>
                                            <input type="radio" name="list-q-section02" data-number="2" value="2" hidden>
                                        </li>
                                    </ul>
                                    <div class="btn-q-section">
                                        <a href="javascript:voide(0)" class="l-500stores__btn l-500stores__btn--black l-500stores__btn--arrow-left" data-back="">BACK</a>
                                    </div>
                                    <div class="page-number">
                                        <p>02</p><img src="https://www.owndays.com/images/specials/products/500stores/line-num-page.svg" style="width: 23px;" alt="">
                                        <p>03</p>
                                    </div>
                                </div>
                                <div class="section-list" id="q_01" data-section="q_01">
                                    <div class="q-section">
                                        <p>[QUESTION 01]</p>
                                    </div>
                                    <div class="title-q-section">
                                        <h3>普段からよくメガネをしている</h3>
                                    </div>
                                    <ul class="ul-list-q-section-click">
                                        <li>
                                            <div class="box-list">
                                                <div class="img-box-list u-pc-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-black.svg" class="list-show" alt="">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-active.svg" class="list-hidden" alt="">
                                                </div>
                                                <div class="img-box-list u-sp-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/a-list-sp.svg" alt="">
                                                </div>
                                                <div class="txt-box-list">
                                                    <p>YES</p>
                                                </div>
                                            </div>
                                            <input type="radio" data-number="1" name="list-q-section02" value="1" hidden>
                                        </li>
                                        <li>
                                            <div class="box-list">
                                                <div class="img-box-list u-pc-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-black.svg" class="list-show" alt="">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-active.svg" class="list-hidden" alt="">
                                                </div>
                                                <div class="img-box-list u-sp-only">
                                                    <img src="https://www.owndays.com/images/specials/products/500stores/b-list-sp.svg" alt="">
                                                </div>
                                                <div class="txt-box-list">
                                                    <p>NO</p>
                                                </div>
                                            </div>
                                            <input type="radio" data-number="1" name="list-q-section02" value="2" hidden>
                                        </li>
                                    </ul>
                                    <div class="btn-q-section">
                                        <a href="javascript:voide(0)" class="l-500stores__btn l-500stores__btn--arrow-left" data-dismiss="modal" aria-label="Close" data-back="">BACK</a>
                                    </div>
                                    <div class="page-number">
                                        <p>01</p><img src="https://www.owndays.com/images/specials/products/500stores/line-num-page.svg" style="width: 23px;" alt="">
                                        <p>03</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>


</section>
        </main>
            </div>

   
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>


<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.14.3/js/uikit-core.min.js" integrity="sha512-PVuP9mKKMDlQ0zH2LYiyawFfgS/VTBQb06/sjwd2uitTb+IdMWIj2EMm4tQWt9wP9iaPIcN6CyvZDLFVgQaKAw==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script src="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-500stores.js?v=1698406705"></script>
       
</body>

</html>`;

const StoresHTML = () => {
  return (
    <>
      <ExternalResources />
      <MetaTagsManager
        description="Join us in celebrating OWNDAYS' achievement of 500 stores worldwide with a commemorative eyewear collection. Enjoy 'Made-in-Japan' quality at an exceptional price."
        title="Celebrate 500 Stores Worldwide | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default StoresHTML;
