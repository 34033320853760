import React from 'react';
import './PrivacyPolicy.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

export const PrivacyPolicy = () => {
  const breadcrumb = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '#', label: 'Privacy Policy' },
  ];

  return (
    <div className="od-privacy">
      <Breadcrumbs crumbs={breadcrumb} />
      <div className="od-privacy__title-container">
        <h1 className="od-privacy__title">PRIVACY POLICY</h1>
      </div>
      <div className="od-privacy__content">
        <div>
          <h3>Overview and Scope</h3>
          <p>
            Owndays Singapore Pte Ltd ("OWNDAYS") is committed to protecting your privacy and will
            do everything in its power to ensure that your Personal Data is treated with respect to
            your fundamental rights as well as with personal dignity, with particular reference to
            confidentiality.
          </p>
          <p>
            We have thus prepared this Privacy Policy in order to briefly explain to you how we will
            collect, use, share and secure your Personal Data. It also describes your choices
            regarding use, access and correction of your Personal Data.
          </p>
          <p>
            This Privacy Policy sets out the collection, use and disclosure of your Personal Data.
            "Personal Data" means any data or information, whether true or not, about an individual
            who can be identified either (a) from that data; or (b) from that data and other
            information to which OWNDAYS is likely to have access to.
          </p>
        </div>

        <div>
          <h3>When and what kind of Personal Data do we collect?</h3>
          <p>We may collect, use and disclose the following data about you:</p>
          <ul>
            <li>
              When you furnish Personal Data by filling up forms (either online or in hardcopy)when
              applying for OWNDAYS' products and services or feedback (i.e., surveys) and/orasking
              queries;
            </li>
            <li>
              If and when you contact OWNDAYS (i.e., telephone calls, emails, social media, liveweb
              chat and face to face meetings) which we may keep a record of thatcorrespondence;
            </li>
            <li>
              If you enter into any contract with OWNDAYS or purchase any products or servicesfrom
              OWNDAYS;
            </li>
            <li>
              If you make contact with any of our authorised representatives, agents and partners,we
              may keep a record of that correspondence;
            </li>
            <li>
              If and when you respond to any of OWNDAYS' marketing promotions, newsletters,
              othercommunication materials, contests and competitions.
            </li>
          </ul>
          <p>
            We may collect the following kinds of Personal Data through the different
            channelsmentioned above:
          </p>
          <ul>
            <li>Full name</li>
            <li>Age and gender</li>
            <li>Date of birth</li>
            <li>Business and/or residential addresses</li>
            <li>Business and/or personal email addresses</li>
            <li>Mobile and business telephone numbers</li>
            <li>Bank account and last 4 digits of credit card numbers, if necessary</li>
            <li>Voice, photos and CCTV images/videos, if applicable</li>
            <li>Medical records and eyeglass prescriptions</li>
            <li>Website cookie data</li>
            <li>
              Photos, Scans, and Images: When you use our "3D Try On" services and submit your
              consent (or grant us permission to take), photo, facial scan, or other image of you.
              We don't share those photos, scans, or images, or any facial data captured in the
              facial scans, with any third parties. We may use them for purposes as described below:
              to measure your pupillary distance and face width, and to gather other optical
              information, to map and measure your facial features, to address certain problems or
              concerns you may have with your glasses, to help better understand how our frames fit
              our customers and provide frame recommendations, to provide, personalize, and improve
              our products and our Service.We store the pupillary distance and face width
              measurements of the users submitted through the iPhone X TrueDepth camera.
            </li>
          </ul>
        </div>

        <div>
          <h3>What purposes does OWNDAYS collect, use and disclose Personal Data?</h3>
          <p>We may use the information we collect from you for any of the following purposes:</p>
          <ul>
            <li>
              To provide answers or services which you request, to receive information from OWNDAYS;
              to verify your identity and provide assistance in case of lost or forgotten
              login/password details for your OWNDAYS membership accounts; to allow you to create
              and maintain a registered user profile/account, to process transactions you request,
              to contact you when necessary and respond to your requests and enquiries, including
              emails; to process payments and to provide any other services which you may request;
              to send you newsletters you have subscribed to as a service (containing only
              informative content).
            </li>
            <li>
              For current and future marketing, promotional and publicity purposes, including to
              carry out direct marketing, as well as to carry out research, market statistics or
              surveys, regarding OWNDAYS products and services ("Marketing") and to send you offers,
              promotions or other information about goods and services for these purposes.
              Individuals may withdraw consent given at a later stage.
            </li>
            <li>
              For compliance with laws which impose upon OWNDAYS, the collection and/or further
              processing of certain kinds of Personal Data. When individuals provide Personal Data
              to OWNDAYS, OWNDAYS must process it in accordance with applicable laws, which may
              include retaining and reporting your Personal Data to official authorities for
              compliance with tax, customs or other legal obligations.
            </li>
            <li>
              To prevent and detect any misuse or any fraudulent activities. Processing for this
              purpose is necessary to pursue OWNDAYS' legitimate interests in preventing and
              detecting fraudulent activities or misuse of the Website (for potentially criminal
              purposes).
            </li>
            <li>
              To analyse and improve OWNDAYS' service provision, enhance OWNDAYS' website, evaluate
              the effectiveness of OWNDAYS' marketing activities and services, perform statistical
              and demographics analyses on OWNDAYS' customers and registered users ("Analytics").
              Processing for this purpose is necessary to pursue OWNDAYS' legitimate interests in
              the development and administration of its services and to improve the services
              provided.
            </li>
          </ul>
          <p>
            We may also contact you by any communication means which you have given us your contact
            details, for the purpose of getting your feedback or for providing you with information
            which we believe could be of interest to you.
          </p>
          <p>
            You also consent to the onward disclosure of your Personal Data to the agents or service
            providers (as set out in "What purposes does OWNDAYS collect, use and disclose Personal
            Data?") and the processing of your Personal Data by these agents and/or service
            providers.
          </p>
          <p>
            We only collect, process, use or disclose such Personal Data, in accordance with this
            Privacy Policy. If you are acting as an intermediary, or otherwise on behalf of a third
            party, or supply us with information regarding a third party, you undertake that you are
            an authorised representative or agent of such third party and that you have obtained
            consent from such third party to our collection, processing, use and disclosure of
            his/her Personal Data. Because we are collecting the third party's data from you, you
            undertake to make the third party aware of all matters listed in this Privacy Policy by
            referring the third party to our website.
          </p>
        </div>

        <div>
          <h3>Consent for the collection and use of your Personal Data</h3>
          <p>
            You consent to the collection, use and disclosure of your Personal Data for the
            above-mentioned purposes and agree to be bound by the obligations it imposes on you,
            when you accept this Privacy Policy. You accept this Privacy Policy when you continue to
            browse on OWNDAYS' website or continuing to engage with OWNDAYS.
          </p>
          <p>
            When you consent to us collecting and/or processing your Personal Data, it is on you to
            ensure that all Personal Data submitted to us is complete, accurate, true and correct at
            the time of submission. You are also requested to inform us should there be any changes
            to the Personal Data that you had submitted to us. Failure to do so may result in our
            inability to provide you with products and services you have requested.
          </p>
          <p>
            Please note that if you do not consent to any of the above business purposes, OWNDAYS
            may not be able to satisfy the purposes for which the information was collected.
          </p>
        </div>

        <div>
          <h3>Does OWNDAYS disclose Personal Data to third parties?</h3>
          <p>
            We may disclose your Personal Data to any member of our group, which means our
            subsidiaries, our ultimate holding company and its subsidiaries, as defined in the
            Companies Act (Cap. 50).
          </p>
          <p>
            On occasion, we may use third party agents and service providers to assist us in the use
            of your Personal Data as outlined under "What purposes does OWNDAYS collect, use and
            disclose Personal Data?" You consent to such use of your Personal Data by continuing to
            browse on OWNDAYS' website or continuing to engage with OWNDAYS.
          </p>
          <p>
            OWNDAYS will not transfer Personal Data within or outside Singapore unless we have
            assurance the Personal Data will be accorded a level of protection which is comparable
            to those of PDPA.
          </p>
        </div>

        <div>
          <h3>For how long does OWNDAYS retain your Personal Data?</h3>
          <p>
            We will cease retaining your Personal Data, as soon as it is reasonable to assume that
            the purpose for collecting/processing your Personal Data has been fulfilled, and there
            is no other legal or business purpose to continue retaining your Personal Data.
          </p>
        </div>

        <div>
          <h3>How does OWNDAYS protect your Personal Data?</h3>
          <p>
            OWNDAYS will ensure that there is a variety of reasonable security measures to maintain
            the safety of your Personal Data. All electronic storage and transmission of Personal
            Data is secured and stored on managed servers with controlled access and appropriate
            security technologies.
          </p>
          <p>
            Although every reasonable effort has been made to ensure that all Personal Data will be
            so protected, OWNDAYS cannot be responsible for any unauthorised use or misuse of such
            information and from risks which are inherent in all internet communications.
          </p>
          <p>
            Your Personal Data will only be disclosed for the express purpose of delivering the
            product or service requested and shall not be sold or disclosed to any other company for
            any other reason whatsoever without your consent.
          </p>
        </div>

        <div>
          <h3>Access and Correction of Personal Data</h3>
          <p>
            Please contact us should you wish to have access to or seek to update, correct or
            withdraw the consent to collect and use your Personal Data. Your email should identify
            yourself and state which Personal Data and information about its use and/or disclosure
            is requested.
          </p>
          <p>
            We will respond to your request as soon as reasonably possible. In the event we are not
            able to respond to your request within thirty (30) days after receiving your request, we
            will inform you before the deadline. If we are unable to provide you with any Personal
            Data or to make a correction you have requested, we shall inform you of the reasons
            unless where we are not required to do so under PDPA.
          </p>
        </div>

        <div>
          <h3>Further Information and Contact</h3>
          <p>
            If you are concerned about the handling of your Personal Data, wish to be removed from
            our email subscription or contact lists, or if you have any complaints or queries
            related to your Personal Data or our Privacy Policy, please contact OWNDAYS' Data
            Protection Officer ("DPO") at dpo@owndays.co.jp. Please clearly identify yourself and
            the purpose of your query.
          </p>
        </div>

        <div>
          <h3>Amendments and updates of this Privacy Policy</h3>
          <p>
            OWNDAYS reserves the right to revise this Privacy Policy with or without prior notice
            from time to time and your continued use of our services constitutes your
            acknowledgement and acceptance of such changes.
          </p>
        </div>

        <div>
          <p className="od-privacy__updated">Updated as at 6 Oct 2022</p>
        </div>
      </div>
    </div>
  );
};
