import React, { useEffect } from 'react';
import AOS from 'aos';

import { HeaderHuawei2Titanium } from './HeaderHuawei2Titanium';
import { BodyHuawei2Titanium } from './BodyHuawei2Titanium';
import { FooterHuawei2Titanium } from './FooterHuawei2Titanium';
import './BaseHuawei2Titanium.scss';

const Huawei2TitaniumLP = () => {
  const baseImgUrl = 'https://static.lenskart.com/owndays/img/huawei2-titanium';

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="base-huawei-2-titanium">
      <HeaderHuawei2Titanium baseImgUrl={baseImgUrl} />
      <BodyHuawei2Titanium baseImgUrl={baseImgUrl} />
      <FooterHuawei2Titanium baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default Huawei2TitaniumLP;
