import React from 'react';
import Item from './Item';

const List = ({ items }) => {
  return (
    <ul className="information__list">
      {items?.map(item => (
        <Item key={item?.id} item={item} />
      ))}
    </ul>
  );
};

export default List;
