import React, { Fragment } from 'react';
import Slider from 'react-slick';

import { KIMETSU_LINEUP } from './constants';

import './BodyKimetsu.scss';

export const BodyKimetsu = ({ baseImgUrl }) => {
  const defaultSetting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    fade: true,
    draggable: false,
    infinite: true,
    swipe: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          draggable: true,
          infinite: true,
          swipe: true,
          touchMove: true,
        },
      },
    ],
  };

  const renderProductSlider = order => {
    return (
      <Slider
        {...defaultSetting}
        className={`body-kimetsu__sec-slider body-kimetsu__sec${order}-slider`}
      >
        {Array.from({ length: 9 }, (_, index) => (
          <img
            key={index}
            alt={`Frame ${index + 1}`}
            src={`${baseImgUrl}/products/sect${order}_frame${index + 1}.webp`}
          />
        ))}
      </Slider>
    );
  };

  const renderButton = order => {
    return (
      <div className="body-kimetsu__sec-details-btn-wrap" disabled="disabled">
        <a className={`body-kimetsu__sec-details-btn body-kimetsu__sec${order}-details-btn`}>
          SOLD OUT
        </a>
      </div>
    );
  };

  const renderHeaderImage = (name, order) => {
    return (
      <span>
        <picture>
          <source media="(min-width:768px)" srcSet={`${baseImgUrl}/sg/sect${order}_name.png`} />
          <img alt={`${name} Model`} src={`${baseImgUrl}/sg/sect${order}_name_m.png`} />
        </picture>
      </span>
    );
  };

  const renderHeaderNumber = order => {
    return (
      <span className={`body-kimetsu__sec-number body-kimetsu__sec${order}-number`}>{order}</span>
    );
  };

  return (
    <Fragment>
      {KIMETSU_LINEUP.map(({ name, desc, price, materials, size }, index) => {
        const order = index + 1;

        return (
          <section
            key={name}
            className={`body-kimetsu__sec body-kimetsu__sec${order}`}
            id={`sec${order}`}
          >
            <div className="base-kimetsu__container">
              <img
                alt={name}
                className={`body-kimetsu__sec-img body-kimetsu__sec${order}-img`}
                src={`${baseImgUrl}/sect${order}_chara.webp`}
              />

              <h2 className={`body-kimetsu__sec-title body-kimetsu__sec${order}-title`}>
                {order % 2 === 0 ? (
                  <>
                    {renderHeaderImage(name, order)}
                    {renderHeaderNumber(order)}
                  </>
                ) : (
                  <>
                    {renderHeaderNumber(order)}
                    {renderHeaderImage(name, order)}
                  </>
                )}
              </h2>

              {order === 7 && (
                <div className="body-kimetsu__sec-badge body-kimetsu__sec7-badge">
                  <p className="body-kimetsu__sec-badge-header">
                    Computer
                    <br />
                    Glasses
                  </p>
                  <p className="body-kimetsu__sec-badge-body">
                    Reduce Blue Light<span>
                      Up to <strong>33%</strong>
                      <br />
                      <small>
                        based on
                        <br />
                        International
                        <br />
                        Standard
                      </small>
                    </span>
                  </p>
                </div>
              )}

              <h3 className={`body-kimetsu__sec-subtitle body-kimetsu__sec${order}-subtitle`}>
                {name.toUpperCase()}
              </h3>

              {renderProductSlider(order)}

              <p className={`body-kimetsu__sec-des body-kimetsu__sec${order}-des`}>{desc}</p>

              <div className="body-kimetsu__sec-details">
                <div className="body-kimetsu__sec-details-left">
                  <div
                    className={`body-kimetsu__sec-details-price body-kimetsu__sec${order}-details-price`}
                  >
                    <h5>{price.value}</h5>
                    <p>{price.desc}</p>
                  </div>

                  {renderButton(order)}
                </div>

                <div className="body-kimetsu__sec-details-right">
                  <dl>
                    <dt>Materials</dt>
                    {materials}
                  </dl>

                  <dl>
                    <dt>Size</dt>
                    {size}
                  </dl>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </Fragment>
  );
};
