export const LINEUP_MENU = [
  {
    id: 'funny',
    text: 'Funny',
    img: '/lineup_funny-menu.webp',
  },
  {
    id: 'chase',
    text: 'Catch & Chase',
    img: '/lineup_chase-menu.webp',
  },
  {
    id: 'friendly-face',
    text: 'Friendly Face',
    img: '/lineup_friendly-face-menu.webp',
  },
  {
    id: 'cheese',
    text: 'Cheese',
    img: '/lineup_cheese-menu.svg',
  },
  {
    id: 'collaboration-limited',
    text: 'Collaboration Exclusive',
    img: '/lineup_collaboration-limited-menu.svg',
  },
];

export const LINEUP_FUNNY = {
  title: 'Funny',
  figures: [
    {
      alt: 'TOM',
      aos: 'fade-left',
      delay: 0,
    },
    {
      alt: 'JERRY',
      aos: 'fade-right',
      delay: 200,
    },
  ],
  orders: 3,
  desc: `Boston-style metal frame with temples featuring an illustration of Tom in a flattened, staircase-like shape, and rims resembling cheese nibbled by Jerry. Comical illustrations of the two characters on the temple tips are sure to bring a smile to your face.`,
  points: [
    {
      desc: `Did Jerry nibble on it?! \nCheese-inspired metal rims`,
    },
    {
      desc: `Temples feature a textured accent \nreferencing Tom in a \nflattened, staircase-like shape`,
    },
    {
      desc: `Illustrations of Tom and Jerry \non the temple tips`,
    },
  ],
  products: {
    sku: 'TJ1001X-5S',
    colors: ['Gold', 'Matte Brown'],
    price: 'S$118',
    button: 'ONLINE STORE',
    limit: 5,
  },
  bg: {
    headerPC: '/products/funny/bg-header-pink-pc.svg',
    headerSP: '/products/funny/bg-header-pink-sp.svg',
  },
};

export const LINEUP_CHASE = {
  title: 'Catch & Chase',
  figures: [
    {
      alt: 'TOM’s hand',
      aos: '',
      delay: 0,
    },
    {
      alt: '',
      aos: '',
      delay: 0,
    },
    {
      alt: 'JERRY',
      aos: '',
      delay: 0,
    },
  ],
  orders: 3,
  desc: `Wellington-style frame featuring Tom’s hand reaching out on the left temple in an attempt to catch Jerry, who lounges in a laid-back pose on the right temple. This fun design brings to life the dynamic energy of the two characters engaged in a lively chase.`,
  points: [
    {
      desc: `The left temple features Tom’s hand reaching \nout to catch Jerry`,
    },
    {
      desc: `A laid-back Jerry on the right temple`,
    },
    {
      desc: `Dual-material front for \nenhanced comfort, paired \nwith colourful temple tips`,
    },
  ],
  products: {
    sku: 'TJ2001B-5S',
    colors: ['Gray', 'Brown'],
    price: 'S$118',
    button: 'ONLINE STORE',
    limit: 5,
  },
  bg: {
    headerPC: '/products/chase/bg-header-green-pc.svg',
    headerSP: '/products/chase/bg-header-green-sp.svg',
  },
};

export const LINEUP_FRIENDLY = {
  title: 'Friendly Face',
  figures: [
    {
      alt: 'JERRY & Nibbles & Canary ',
      aos: 'fade-up',
      delay: 0,
    },
    {
      alt: 'TOM',
      aos: 'fade-up',
      delay: 0,
    },
  ],
  orders: 3,
  desc: `Boston-style frame with adorable character faces adorning the temple tips. The Tom-and-Jerry-inspired colourway makes it feel as if the characters have transformed into the spectacle frames themselves, creating a design that is just as fun to look at as it is to wear.`,
  points: [
    {
      desc: `Material colourway inspired \nby Tom and Jerry`,
    },
    {
      desc: `Cheese-shaped motifs \nadorning the sides of the \nframe front`,
    },
    {
      desc: `Character face illustrations on \nthe temple tips`,
    },
  ],
  products: {
    sku: 'TJ2002B-5S',
    colors: ['Clear Gray', 'Brown'],
    price: 'S$118',
    button: 'ONLINE STORE',
    limit: 5,
  },
  bg: {
    headerPC: '/products/friendly-face/bg-header-gray-pc.svg',
    headerSP: '/products/friendly-face/bg-header-gray-sp.svg',
  },
};

export const LINEUP_CHEESE = {
  title: 'Cheese',
  figures: [
    {
      alt: 'JERRY',
      aos: '',
      delay: 0,
    },
    {
      alt: 'JERRY cheese',
      aos: 'fade-left',
      delay: 0,
    },
  ],
  orders: 3,
  desc: `2-way Wellington-style frame incorporating accents of Jerry’s favourite treat, cheese, throughout the design. A versatile option that can be worn as regular spectacles for everyday use and as sunglasses when outdoors.`,
  points: [
    {
      desc: `Temple tips featuring a textured pattern \ninspired by cheese `,
    },
    {
      desc: `Cheese-shaped motifs \nadorning the sides of the \nframe front`,
    },
    {
      desc: `Tom and Jerry character \nillustrations on the inside of \nthe temple`,
    },
  ],
  products: {
    sku: 'TJ2003N-5S',
    colors: ['Black', 'Brown'],
    price: 'S$178',
    button: 'ONLINE STORE',
    limit: 7,
  },
  bg: {
    headerPC: '/products/cheese/bg-header-yellow-pc.svg',
    headerSP: '/products/cheese/bg-header-yellow-sp.svg',
  },
};

export const SHARE_TOM_AND_JERRY = [
  {
    link: 'https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/tomandjerry',
    className: 'fa-facebook',
  },
  {
    link: 'https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/tomandjerry',
    className: 'fa-x-twitter',
  },
  {
    link: 'whatsapp://send?text=https://www.owndays.com/sg/en/news/tomandjerry',
    className: 'fa-whatsapp',
  },
];

export const SHOPS_01 = [
  'OWNDAYS Plaza Singapura',
  'OWNDAYS Suntec City',
  'OWNDAYS Jurong Point',
  'OWNDAYS Waterway Point',
];

export const SHOPS_02 = [
  'OWNDAYS Northpoint City',
  'OWNDAYS Causeway Point',
  'OWNDAYS MBS Premium Concept Store',
  'OWNDAYS Takashimaya S.C. Premium Concept Store',
];

export const SHOPS = [
  'OWNDAYS Plaza Singapura',
  'OWNDAYS Suntec City',
  'OWNDAYS Jurong Point',
  'OWNDAYS Waterway Point',
  'OWNDAYS Northpoint City',
  'OWNDAYS Causeway Point',
  'OWNDAYS MBS Premium Concept Store',
  'OWNDAYS Takashimaya S.C. Premium Concept Store',
];
