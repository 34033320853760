import React, { useRef, useEffect } from 'react';

import './HeaderAnniversary10thCollection.scss';

export const SwiftUpText = ({ text, tag, delay = 0 }) => {
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const elem = textRef.current;
      const characters = text.split('');
      elem.innerHTML = '';

      characters.forEach(char => {
        if (char === ' ') {
          const space = document.createTextNode(' ');
          elem.appendChild(space);
        } else {
          const span = document.createElement('span');
          const i = document.createElement('i');
          i.textContent = char;
          span.appendChild(i);
          elem.appendChild(span);
        }
      });

      const children = elem.querySelectorAll('span > i');
      children.forEach((node, index) => {
        node.style.animationDelay = `${delay + index * 0.05}s`;
      });
    }
  }, [text, delay]);

  return tag === 'strong' ? (
    <strong
      ref={textRef}
      className="header-anniversary-10th-collection__main__swift-up-text"
      data-aos="fade"
    ></strong>
  ) : (
    <small
      ref={textRef}
      className="header-anniversary-10th-collection__main__swift-up-text"
      data-aos="fade"
    ></small>
  );
};
