import React from 'react';

import { SERVICE_CONTENTS } from './constants';

import './ServiceSection.scss';

const RenderServiceContent = ({
  index,
  imageSrcSet,
  imageSrc,
  alt,
  title,
  subtitle,
  description,
  link,
  buttonText,
  price,
}) => (
  <section className={`l-services__content ${index % 2 === 1 ? 'turn-over' : ''}`}>
    <div className="l-services__content-image">
      <picture>
        <source media="(min-width:768px)" srcSet={imageSrcSet} />
        <img alt={alt} src={imageSrc} />
      </picture>
    </div>
    <div
      className={`l-services__content-text ${index === 4 ? 'l-services__content-text--frame' : ''}`}
    >
      <div className="l-services__content-text__container">
        <div className="l-services__content-text__content">
          <div className="l-services__content-text__content__title">{title}</div>
          <div className="l-services__content-text__content__subtitle">{subtitle}</div>
          {index === 0 && (
            <div className="l-services__content-text__content__table">
              <img
                alt="icon-frame"
                className="u-svg__icon-frame"
                src="https://static.lenskart.com/owndays/img/services/top/icon-frame.svg"
              />
              <div className="u-mark u-mark__plus"></div>
              <img
                alt="icon-lens"
                className="u-svg__icon-lens"
                src="https://static.lenskart.com/owndays/img/services/top/icon-lens.svg"
              />
              <div className="u-mark u-mark__equal"></div>
              <p className="l-services__content-text__content__red">{price}</p>
            </div>
          )}
          {index !== 0 && price && <p className="l-services__content-text__content__red">{price}</p>}
          <div className="l-services__content-text__content__description">{description}</div>
          {link && (
            <div className="l-services__content-text__content__btn l-services__content-text__content__btn--primary">
              <a href={link}>{buttonText}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  </section>
);

const ServiceSection = () => {
  return (
    <>
      {SERVICE_CONTENTS.map((content, index) => (
        <RenderServiceContent key={index} index={index} {...content} />
      ))}
    </>
  );
};

export default ServiceSection;
