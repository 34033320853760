export const CONCEPT = {
  OPTIONS: [
    {
      title: `Slimmer and Lighter for All-Day Comfortable Wear`,
      desc: 'Temples are approximately 20% slimmer than earlier models, making it more comfortable and sleeker to wear.',
    },
    {
      title: `Enhanced Performance for Sound Leakage Prevention`,
      desc: 'New reverse sound field technology ensures minimal sound leakage so you can listen in complete privacy.',
    },
    {
      title: `Larger Battery Capacity`,
      desc: 'A full charge provides up to 94 hours on standby; 9 hours of voice call; 11 hours of audio playback. Fast charging feature is also available.',
    },
    {
      title: `High Quality Stereo Sound`,
      desc: 'Equipped with dynamic driver; co-directional dual diaphragm large amplitude units provide strong bass.',
    },
  ],
};

export const FEATURES = [
  {
    title: `Touch \nControls`,
    desc: 'Intuitive touch controls allow you to skip tracks, control volume and take calls simply by tapping and swiping on the temples.',
  },
  {
    title: `Dust and \nSplash Resistant`,
    desc: 'The smart audio glasses are rated IP54 – protected against ingress of dust and water spray from any direction.',
  },
  {
    title: `Convert into Sunglasses \nEffortlessly`,
    desc: 'Attach the designated SNAP LENS (sold separately) to the smart audio glasses to convert them into sunglasses. With the use of built-in magnets, all it takes is a simple snap.',
  },
];

export const SHARES = [
  {
    link: 'https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/huawei2',
    className: 'fa-facebook',
  },
  {
    link: 'https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/huawei2',
    className: 'fa-x-twitter',
  },
  {
    link: 'whatsapp://send?text=https://www.owndays.com/sg/en/news/huawei2',
    className: 'fa-whatsapp',
  },
];

export const ATTENTION = {
  REMARKS: [
    `Not valid with any discount, promotion and/or privilege`,
    `No cancellation, refund, exchange or change or order details once an order is placed`,
    `Resale or transfer is strictly prohibited`,
    `Delivery within Singapore only`,
    `Certain prescription cannot be added to the lenses due to constrains in the lens power`,
    `Upgrade to optional lenses is available at additional charges`,
    `Should there be any manufacturing defect, please contact our Customer Service Team at +65 6222 0588`,
  ],
};

export const LINEUP = {
  FULL_RIM: {
    title: 'FULL-RIM',
    desc: `The full-rim design exudes a cool, sophisticated vibe. Its delicate, linear details bring a fresh, transformative look that sets it apart from previous models, achieving a smarter and more refined style.`,
    productNumber: 'HW1001-4A',
    price: 'S$438',
    colors: ['Dark Gun', 'Silver'],
    limit: 8,
    isNew: true,
    isPurchase: true,
  },
  HALF_RIM: {
    title: 'HALF-RIM',
    desc: `The half-rim design offers a comfortable, approachable look. Ideal for both business settings and casual occasions, it complements any style with ease, creating a calm and refined presence.`,
    productNumber: 'HW1002-4A',
    price: 'S$438',
    colors: ['Dark Gun', 'Silver'],
    limit: 8,
    isNew: true,
    isPurchase: true,
  },
  RECTANGLE: {
    title: 'RECTANGLE',
    desc: `The iconic rectangle style from Generation 1 makes a return. With a timeless design, it seamlessly transitions from casual to formal settings, adding an air of class and sophistication to your look.`,
    productNumber: 'HWF2003N-3A',
    price: 'S$438',
    colors: ['Black', 'Clear Grey'],
    limit: 10,
    more: [
      `Fitted with polarised lenses that can reduce glare from the road and water surface`,
      'SNAP LENS sold separately',
      'HWF2003Le-N Black',
      'S$20',
    ],
  },
  BROWLINE_RECTANGLE: {
    title: 'BROWLINE RECTANGLE',
    desc: `A stylish browline design in rectangle style made of a combination of resin and metal. This is the perfect choice for those looking to try something less conventional.`,
    productNumber: 'HWF2004N-3A',
    price: 'S$438',
    colors: ['Black', 'Clear Grey'],
    limit: 10,
    more: [
      `Fitted with polarised lenses that can reduce glare from the road and water surface`,
      'SNAP LENS sold separately',
      'HWF2004Le-N Black',
      'S$20',
    ],
  },
  WELLINGTON: {
    title: 'WELLINGTON',
    desc: `A crowd-favourite featuring a rectangular shape with rounded edges. This is a style that will create a friendly, approachable first impression.`,
    productNumber: 'HWF2005N-3A',
    price: 'S$438',
    colors: ['Black', 'Clear Brown'],
    limit: 10,
    more: [
      `Fitted with polarised lenses that can reduce glare from the road and water surface`,
      'SNAP LENS sold separately',
      'HWF2005Le-N Black',
      'S$20',
    ],
  },
  BOSTON_WELLINGTON: {
    title: 'BOSTON-WELLINGTON',
    desc: `A unisex design that is in-between the round and the rectangle style. Being stylish, modern and retro all at once, this is the ideal option to stand out from the crowd.`,
    productNumber: 'HWF2006N-3A',
    price: 'S$438',
    colors: ['Black', 'Clear Brown'],
    limit: 10,
    more: [
      `Fitted with polarised lenses that can reduce glare from the road and water surface`,
      'SNAP LENS sold separately',
      'HWF2006Le-N Black',
      'S$20',
    ],
  },
};
