import React from 'react';

const Sheet = ({ sheet, isTemporaryClosure }) => {
  return (
    <table className={isTemporaryClosure ? 'temporary-closure' : ''}>
      <thead>
        <tr>
          {sheet?.header?.map(header => (
            <th dangerouslySetInnerHTML={{ __html: header }} key={header} />
          ))}
        </tr>
      </thead>
      <tbody className={isTemporaryClosure ? 'temporary-closure' : ''}>
        {sheet?.body?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className={isTemporaryClosure ? 'temporary-closure' : ''}>
              <a
                href={isTemporaryClosure ? null : 'https://www.owndays.com/stores/sg'}
                className={isTemporaryClosure ? 'temporary-closure' : ''}
              >
                {row?.store}
              </a>
            </td>
            {row?.shedules?.map((shedule, sheduleIndex) => (
              <td key={sheduleIndex}>{shedule}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Sheet;
