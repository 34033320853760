import React, { useEffect } from 'react';
import { Redirect as ReactRedirect } from 'react-router';

const Redirect = ({ to }) => {
  const isExternalUrl = to.startsWith('https://');

  useEffect(() => {
    if (isExternalUrl) {
      window.location.href = to;
    }
  }, [to, isExternalUrl]);

  return isExternalUrl ? null : <ReactRedirect to={to} />;
};

export default Redirect;
