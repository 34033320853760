import React, { useEffect } from 'react';
import AOS from 'aos';

import { HeaderTomAndJerry } from './HeaderTomAndJerry';
import { BodyTomAndJerry } from './BodyTomAndJerry';
import { FooterTomAndJerry } from './FooterTomAndJerry';

import './BaseTomAndJerry.scss';

const TomAndJerryLP = () => {
  const baseImgUrl = 'https://static.lenskart.com/owndays/img/tomandjerry';

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const handleScroll = () => {
      AOS.refresh();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="base-tom-and-jerry">
      <HeaderTomAndJerry baseImgUrl={baseImgUrl} />
      <BodyTomAndJerry baseImgUrl={baseImgUrl} />
      <FooterTomAndJerry baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default TomAndJerryLP;
