import React, { Fragment, useRef, useState } from 'react';
import Slider from 'react-slick';

import { LINEUP } from './constants';

import './BodyHuawei2Titanium.scss';

export const BodyHuawei2Titanium = ({ baseImgUrl }) => {
  const [stateFullRim, setStateFullRim] = useState({
    currentKey: 'C1',
    colors: LINEUP.FULL_RIM.colors,
  });

  const [stateHalfRim, setStateHalfRim] = useState({
    currentKey: 'C1',
    colors: LINEUP.HALF_RIM.colors,
  });

  const [stateRectangle, setStateRectangle] = useState({
    currentKey: 'C2',
    colors: LINEUP.RECTANGLE.colors,
  });

  const [stateBrownLineRectangle, setStateBrownLineRectangle] = useState({
    currentKey: 'C1',
    colors: LINEUP.BROWLINE_RECTANGLE.colors,
  });

  const [stateWellington, setStateWellington] = useState({
    currentKey: 'C1',
    colors: LINEUP.WELLINGTON.colors,
  });

  const [stateBostonWellington, setStateBostonWellington] = useState({
    currentKey: 'C2',
    colors: LINEUP.BOSTON_WELLINGTON.colors,
  });

  const productFullRimRef = useRef(null);
  const productHalfRimRef = useRef(null);
  const productRectangleRef = useRef(null);
  const productBrownLineRectangleRef = useRef(null);
  const productWellingtonRef = useRef(null);
  const productBostonWellingtonRef = useRef(null);

  const productSetting = {
    infinite: true,
    dots: false,
    speed: 500,
    variableWidth: true,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 767, // Below 767px
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 99999, // Above 768px
        settings: {
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
        },
      },
    ],
  };

  const fullRimSetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([setStateFullRim(prev => ({ ...prev, currentIndexPoint: current }))]);
    },
  };

  const halfRimSetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([setStateHalfRim(prev => ({ ...prev, currentIndexPoint: current }))]);
    },
  };

  const rectangleSetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([setStateRectangle(prev => ({ ...prev, currentIndexPoint: current }))]);
    },
  };

  const brownLineRectangleSetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([
        setStateBrownLineRectangle(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const wellingtonSetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([setStateWellington(prev => ({ ...prev, currentIndexPoint: current }))]);
    },
  };

  const bostonWellingtonSetting = {
    ...productSetting,
    afterChange: async current => {
      await Promise.all([
        setStateBostonWellington(prev => ({ ...prev, currentIndexPoint: current })),
      ]);
    },
  };

  const productMap = {
    'HW1001-4A': {
      setting: fullRimSetting,
      ref: productFullRimRef,
      limit: LINEUP.FULL_RIM.limit,
      currentKey: stateFullRim.currentKey,
      stateProduct: stateFullRim,
      setState: setStateFullRim,
    },
    'HW1002-4A': {
      setting: halfRimSetting,
      ref: productHalfRimRef,
      limit: LINEUP.HALF_RIM.limit,
      currentKey: stateHalfRim.currentKey,
      stateProduct: stateHalfRim,
      setState: setStateHalfRim,
    },
    'HWF2003N-3A': {
      setting: rectangleSetting,
      ref: productRectangleRef,
      limit: LINEUP.RECTANGLE.limit,
      currentKey: stateRectangle.currentKey,
      stateProduct: stateRectangle,
      setState: setStateRectangle,
    },
    'HWF2004N-3A': {
      setting: brownLineRectangleSetting,
      ref: productBrownLineRectangleRef,
      limit: LINEUP.BROWLINE_RECTANGLE.limit,
      currentKey: stateBrownLineRectangle.currentKey,
      stateProduct: stateBrownLineRectangle,
      setState: setStateBrownLineRectangle,
    },
    'HWF2005N-3A': {
      setting: wellingtonSetting,
      ref: productWellingtonRef,
      limit: LINEUP.WELLINGTON.limit,
      currentKey: stateWellington.currentKey,
      stateProduct: stateWellington,
      setState: setStateWellington,
    },
    'HWF2006N-3A': {
      setting: bostonWellingtonSetting,
      ref: productBostonWellingtonRef,
      limit: LINEUP.BOSTON_WELLINGTON.limit,
      currentKey: stateBostonWellington.currentKey,
      stateProduct: stateBostonWellington,
      setState: setStateBostonWellington,
    },
  };

  const renderProducts = productNumber => {
    const { setting, ref, limit, currentKey } = productMap[productNumber];

    const productMapping = {
      'HW1001-4A': 'HWF1001-4A',
      'HW1002-4A': 'HWF1002-4A',
    };

    const newProductNumber = productMapping[productNumber] || productNumber;

    return (
      <Slider {...setting} ref={ref} className="body-huawei-2-titanium__lineup__slider">
        {Array.from({ length: limit }, (_, index) => (
          <li key={`${productNumber}-${index + 1}`}>
            <img
              alt={productNumber}
              data-index="1"
              src={`${baseImgUrl}/products/${newProductNumber}/${newProductNumber}_${currentKey}-${
                index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
              }.webp`}
            />
          </li>
        ))}
      </Slider>
    );
  };

  const handleSelectedColor = (productNumber, index) => {
    if (productMap[productNumber]) {
      const { setState } = productMap[productNumber];

      setState(prev => ({
        ...prev,
        currentKey: `C${index + 1}`,
      }));
    }
  };

  const renderMore = (productNumber, more, display) => {
    return (
      <div
        className={`body-huawei-2-titanium__lineup__product-snap__layout body-huawei-2-titanium__lineup__product-snap__layout--${display}`}
      >
        <div className="body-huawei-2-titanium__lineup__product-snap__content">
          <img
            alt={more[2]}
            loading="lazy"
            src={`${baseImgUrl}/products/${productNumber}/${productNumber}_snap.webp`}
          />
          <div className="body-huawei-2-titanium__lineup__product-snap__main">
            <p className="body-huawei-2-titanium__lineup__product-snap__desc body-huawei-2-titanium__lineup__product-snap__desc--pc">
              {more[0]}
            </p>
            <p className="body-huawei-2-titanium__lineup__product-snap__main-lens">{more[1]}</p>
            <p className="body-huawei-2-titanium__lineup__product-snap__main-name">{more[2]}</p>
            <p className="body-huawei-2-titanium__lineup__product-snap__main-price">{more[3]}</p>
          </div>

          <p className="body-huawei-2-titanium__lineup__product-snap__desc body-huawei-2-titanium__lineup__product-snap__desc--sp">
            {more[0]}
          </p>
        </div>
      </div>
    );
  };

  const goToPrev = sliderRef => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNext = sliderRef => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const renderProductDesc = product => {
    const { productNumber, price, isNew = false, isPurchase = false, more = null } = product;
    const { currentKey, stateProduct, ref } = productMap[productNumber];

    const nameColor = stateProduct.colors[currentKey === 'C1' ? 0 : 1];

    return (
      <div>
        <div className="body-huawei-2-titanium__lineup__product-layout">
          <div>
            {isNew && <p className="body-huawei-2-titanium__lineup__product-tag">New</p>}
            <p className="body-huawei-2-titanium__lineup__product-number">{productNumber}</p>

            <div className="body-huawei-2-titanium__lineup__product-main">
              <p>{`${currentKey} ${nameColor}`}</p>
              <p>{price}</p>
            </div>
          </div>

          <div className="body-huawei-2-titanium__lineup__product-color-and-arrows">
            <ul className="body-huawei-2-titanium__lineup__product-colors">
              {stateProduct.colors.map((color, index) => (
                <li key={color}>
                  <button
                    data-color={color.toLowerCase().replace(' ', '-')}
                    data-color-selected={
                      (currentKey === 'C1' && index === 0) || (currentKey === 'C2' && index === 1)
                    }
                    onClick={() => handleSelectedColor(productNumber, index)}
                  />
                </li>
              ))}
            </ul>

            <div className="body-huawei-2-titanium__lineup__arrows">
              <button
                className="body-huawei-2-titanium__lineup__arrows-prev"
                onClick={() => goToPrev(ref)}
              />
              <button
                className="body-huawei-2-titanium__lineup__arrows-next"
                onClick={() => goToNext(ref)}
              />
            </div>
          </div>
        </div>

        {isPurchase && (
          <div className="body-huawei-2-titanium__lineup__product-btn">
            <a
              className="base-huawei-2-titanium__btn base-huawei-2-titanium__btn--lg base-huawei-2-titanium__btn--gold"
              href={`/sg/en/huawei2-${productNumber.toLowerCase()}-${currentKey.toLowerCase()}-eyeglasses.html`}
            >
              Purchase Online
            </a>
          </div>
        )}

        {more && renderMore(productNumber, more, 'sp')}
      </div>
    );
  };

  const renderLineupSection = product => {
    const { title, desc, productNumber, more = null } = product;

    return (
      <section className="body-huawei-2-titanium__lineup__section" id={title.toLowerCase()}>
        <div className="body-huawei-2-titanium__lineup__desc">
          <h3>{title}</h3>
          <p>{desc}</p>
        </div>

        <div className="body-huawei-2-titanium__lineup__product-container">
          {renderProducts(productNumber)}
          {renderProductDesc(product)}
          {more && renderMore(productNumber, more, 'pc')}
        </div>
      </section>
    );
  };

  return (
    <Fragment>
      <section
        className="body-huawei-2-titanium__lineup"
        data-aos="fade-up"
        data-aos-offset="300"
        id="lineup"
      >
        <div className="base-huawei-2-titanium__container">
          <h2 className="body-huawei-2-titanium__lineup__title">LINEUP</h2>

          <p className="body-huawei-2-titanium__lineup__title-img">
            TITANIUM EDITION<span><img alt="TITANIUM EDITION" src={`${baseImgUrl}/TITANIUM_EDITION.svg`} /></span>
          </p>

          {renderLineupSection(LINEUP.FULL_RIM)}
          {renderLineupSection(LINEUP.HALF_RIM)}

          <p className="body-huawei-2-titanium__lineup__title-text">
            <span>STANDARD MODEL</span>
          </p>

          {renderLineupSection(LINEUP.RECTANGLE)}
          {renderLineupSection(LINEUP.BROWLINE_RECTANGLE)}
          {renderLineupSection(LINEUP.WELLINGTON)}
          {renderLineupSection(LINEUP.BOSTON_WELLINGTON)}
        </div>
      </section>
    </Fragment>
  );
};
