export const SENICHISAKU = {
  Products: {
    first: Array.from({ length: 5 }, (_, i) => ({
      alt: `千一作 feature${String.fromCharCode(9312 + i)}`,
    })),
    seconds: Array.from({ length: 7 }, (_, i) => ({
      alt: `sabae${String.fromCharCode(9312 + i)}`,
    })),
  },
};
